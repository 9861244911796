import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { getTiempoPromedioXInspeccion, getTiemposInspecciones } from './dashboardAPI';
import { calcularSegundos } from '../../utlis/DashboardUtils';
import { CircularProgress } from '@mui/material';
import { FiltrosDashboard } from './Dashboard';
import EmptyStateDashboard from './EmptyStateDashboard';

const chartSetting = {
    width: 250,
    height: 100,
};

const valueFormatter = (value: number) => `${value}`;

const TiempoDeUso = ({ archivadas, fechaInicial, fechaFinal }: FiltrosDashboard) => {
    const [tiemposDeUso, setTiemposDeUso] = useState([]);
    const [tiempoPromedio, setTiempoPromedio] = useState('');
    const [noResults, setNoResults] = useState(false);
    const agenteId = localStorage.getItem('idAgente');

    useEffect(() => {
        const obtenerTiemposInspecciones = async () => {
            const results = await getTiemposInspecciones(Number(agenteId), archivadas, fechaInicial, fechaFinal);
            if (results && results.status === 200) {
                const dataTiemposDeUso = results.data.map((t: any) => {
                    return {
                        label: `ID${t.inspeccion_id} (${t.minutes}' ${t.seconds}") `,
                        value: calcularSegundos(t.minutes, t.seconds),
                    };
                });
                setTiemposDeUso(dataTiemposDeUso);
                setNoResults(false);
            } else {
                setNoResults(true);
            }
        };

        const obtenerTiempoPromedio = async () => {
            const results = await getTiempoPromedioXInspeccion(Number(agenteId), archivadas, fechaInicial, fechaFinal);
            if (results && results.status === 200) {
                const tiempoPromedio = `${results.data[0].minutos}' ${results.data[0].segundos}"`;
                setTiempoPromedio(tiempoPromedio);
            }
        };

        obtenerTiemposInspecciones();
        obtenerTiempoPromedio();
    }, [archivadas, fechaInicial, fechaFinal]);

    return (
        <div className="tiempos-uso dashboard-card">
            <h2 className="dashboard-card-title">% Tiempo de uso</h2>
            {noResults ? (
                <EmptyStateDashboard size={80} />
            ) : (
                <div className="tiempos-uso-container ">
                    {tiemposDeUso.length ? (
                        <BarChart
                            dataset={tiemposDeUso}
                            series={[{ dataKey: 'value', valueFormatter }]}
                            //@ts-ignore 123
                            yAxis={[{ scaleType: 'band', dataKey: 'label', categoryGapRatio: 0.3 }]}
                            colors={['#D9D9D9']}
                            layout="horizontal"
                            tooltip={{ trigger: 'none' }}
                            margin={{ left: 90, bottom: 0, top: 0, right: 0 }}
                            {...chartSetting}
                        />
                    ) : null}
                    {tiempoPromedio ? (
                        <div className="tiempos-uso-promedio">
                            <div className="tiempos-uso-promedio-valor">
                                <CircularProgress
                                    variant="determinate"
                                    style={{ color: '#0046CF' }}
                                    size={62}
                                    value={100}
                                />
                                <div className="tiempos-uso-promedio-valor-numero">{tiempoPromedio}</div>
                            </div>
                            <p>Tiempo medio de uso</p>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default TiempoDeUso;
