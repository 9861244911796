import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandFacebook, BrandInstagram, BrandLinkedin, BrandTwitter, Mail } from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LogoAira from '../../assets/logo-aira-new.png';
import conf from '../../utlis/config';
import { cleanInspecciones } from '../inspecciones/inspeccionesSlice';
import './Login.scss';
import { aceptoToS, handleAseguradoraId, loginSync, RequestLogin, signIn } from './loginSlice';

const LoginFeacture = () => {
    const login = useAppSelector((state: any) => state.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const ENV = conf('ENV');
    const VERSION = conf('VERSION');

    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        dispatch(cleanInspecciones());
        const request: RequestLogin = { email, password };
        const response = await dispatch(loginSync(request));
        if (response.payload.result === 'OK') {
            console.log(response);
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem('email', response.payload.email);
            localStorage.setItem('admin', response.payload.admin);
            localStorage.setItem('suscripcion', response.payload.suscripcion);
            localStorage.setItem('idAseguradora', response.payload.idAseguradora);
            localStorage.setItem('terminosYCondiciones', response.payload.terminosYCondiciones ? '1' : '0');
            dispatch(aceptoToS(response.payload.terminosYCondiciones));
            dispatch(handleAseguradoraId(parseInt(response.payload.idAseguradora)));
            dispatch(signIn());
            setTimeout(() => navigate('../home'), 1000);
        } else {
            setLoginError(response.payload.message);
        }
    };

    const handleChangeEmail = (email: string) => {
        setEmail(email);
    };

    const handleChangePassword = (password: string) => {
        setPassword(password);
    };

    const renderLoginError = () => {
        if (loginError) {
            return <div className="login-error">{loginError}</div>;
        } else return;
    };

    return (
        <div className="login">
            <div className="login-container">
                <div className="login-container-header">
                    <img src={LogoAira} />
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    {renderLoginError()}
                    <div className="form-inner">
                        <div className="form-input">
                            {/* <label htmlFor="user">User:</label> */}
                            <input
                                placeholder="Nombre de usuario"
                                type="text"
                                name="user"
                                id="user"
                                onChange={(event) => handleChangeEmail(event.target.value)}
                            />
                        </div>
                        <div className="form-input">
                            {/* <label htmlFor="password">Password:</label> */}
                            <input
                                placeholder="Tu contraseña"
                                type="password"
                                name="password"
                                id="password"
                                onChange={(event) => handleChangePassword(event.target.value)}
                            />
                        </div>
                    </div>
                    <input className="primary-button" type="submit" value="Acceder" />
                </form>
                <div className="login-container-footer">
                    <div className="login-container-footer-wrapper">
                        <img src={LogoAira} />
                        <div className="login-container-footer-wrapper-info">
                            <div className="login-container-footer-wrapper-info-social">
                                <div className="login-container-footer-wrapper-info-social-logo">
                                    <BrandFacebook />
                                </div>
                                <div className="login-container-footer-wrapper-info-social-logo">
                                    <BrandTwitter />
                                </div>
                                <div className="login-container-footer-wrapper-info-social-logo">
                                    <BrandLinkedin />
                                </div>
                                <div className="login-container-footer-wrapper-info-social-logo">
                                    <BrandInstagram />
                                </div>
                                <div className="login-container-footer-wrapper-info-social-logo">
                                    <Mail />
                                </div>
                            </div>
                            <div className="login-container-footer-wrapper-info-links">
                                <ul>
                                    <li>
                                        <a
                                            href="https://aira-system.com/politica-de-privacidad/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacidad
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://aira-system.com/aviso-legal/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Aviso legal
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://aira-system.com/politica-de-cookies-ue/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Cookies
                                        </a>
                                    </li>
                                </ul>
                                <div className="login-container-footer-wrapper-info-links-version">
                                    <span>© 2022 – aira system</span>
                                    <div className="version">
                                        {VERSION}-{ENV}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginFeacture;
