import React from 'react';

interface Props {
    disabled?: boolean;
}

export const IconCopy = ({ disabled = false }: Props) => {
    let stroke = '#0046CF';
    if (disabled) {
        stroke = '#B0ADBE';
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.57143 7C7.88702 7 8.14286 7.25584 8.14286 7.57143V9.85714H10.4286C10.7442 9.85714 11 10.113 11 10.4286C11 10.7442 10.7442 11 10.4286 11H8.14286C7.83975 11 7.54906 10.8796 7.33474 10.6653C7.12041 10.4509 7 10.1602 7 9.85714V7.57143C7 7.25584 7.25584 7 7.57143 7Z"
                fill="#0046CF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.6875 8.1C3.53832 8.1 3.39524 8.15795 3.28975 8.26109C3.18426 8.36424 3.125 8.50413 3.125 8.65V16.35C3.125 16.4959 3.18426 16.6358 3.28975 16.7389C3.39524 16.8421 3.53832 16.9 3.6875 16.9H9.3125C9.46168 16.9 9.60476 16.8421 9.71025 16.7389C9.81574 16.6358 9.875 16.4959 9.875 16.35V10.5278L7.392 8.1H3.6875ZM2.49426 7.48327C2.81072 7.17384 3.23995 7 3.6875 7H7.625C7.77418 7 7.91726 7.05795 8.02275 7.16109L10.8352 9.91109C10.9407 10.0142 11 10.1541 11 10.3V16.35C11 16.7876 10.8222 17.2073 10.5057 17.5167C10.1893 17.8262 9.76005 18 9.3125 18H3.6875C3.23995 18 2.81072 17.8262 2.49426 17.5167C2.17779 17.2073 2 16.7876 2 16.35V8.65C2 8.21239 2.17779 7.79271 2.49426 7.48327Z"
                fill="#0046CF"
            />
            <path
                d="M11.8148 12H13V18.2857C13 19.4286 12.1111 20 11.2222 20H5V18.8571H10.9259C11.5185 18.8571 11.8148 18.2857 11.8148 18V12Z"
                fill="#0046CF"
            />
        </svg>
    );
};
