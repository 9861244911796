import React from 'react';
import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';

interface Props {
    open?: boolean;
    handleClose: () => void;
    severity?: AlertColor;
    message: string;
    autoHideDuration?: number;
    position?: SnackbarOrigin;
}
const ToastMessage = (props: Props) => {
    const defaultAutoHideDuration = 8000;
    const defaultStackBarPosition: SnackbarOrigin = { vertical: 'bottom', horizontal: 'center' };
    const {
        handleClose,
        open = true,
        severity,
        message = 'Message not found',
        autoHideDuration = defaultAutoHideDuration,
        position = defaultStackBarPosition,
    } = props;

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={position}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default ToastMessage;
