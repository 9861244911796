import {
    Alert,
    Snackbar,
    Autocomplete,
    Box,
    Modal,
    TextField,
    Tooltip,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    AlertCircle,
    Building,
    BuildingStore,
    CalendarEvent,
    CalendarOff,
    Check,
    ChevronDown,
    CircleCheck,
    Clock,
    CloudDownload,
    DeviceMobile,
    Eye,
    EyeOff,
    FileCheck,
    FileDownload,
    FileReport,
    FileText,
    Id,
    InfoCircle,
    Mail,
    MapPin,
    Pencil,
    Refresh,
    Send,
    Template,
    Trash,
    User,
} from 'tabler-icons-react';
import { IconComercio } from '../../../assets/IconComercio';
import { IconComunidad } from '../../../assets/iconComunidad';
import { IconHogar } from '../../../assets/IconHogar';
import { IconIndustria } from '../../../assets/IconIndustria';
import { IconVehiculo } from '../../../assets/IconVehiculo';
import IconComercio2 from '../../../assets/svg/IconComercio2';
import { renderTextWithIcon } from '../../../components/data-grid/DataGrid';
import DetailsCard from '../../../components/details-card/DetailsCard';
import DropdownMenu from '../../../components/dropdown-menu/DropdownMenu';
import ModalEliminarInspeccion from '../../../components/ModalEliminarInspeccion/ModalEliminarInspeccion';
import OpenPdfModal from '../../../components/openPdfModal/OpenPdfModal';
import { RamosNumber } from '../../../enums/ramos';
import { debounce, filterClientes, getDateFormated, isSuperUser, isTest } from '../../../utlis';
import conf from '../../../utlis/config';
import {
    actualizarCliente,
    actualizarDiasPrevencimiento,
    actualizarFechaVencimiento,
    archivarInspeccion,
    deleteInspeccion,
    desactivarInspeccion,
    desarchivarInspeccion,
    enviarEvaluacionAira0,
    GetDetalleInspeccion,
    marcarInspeccionComoGestionada,
    processAI,
    subirAdjuntosInspeccion,
    subirNota,
} from '../InspeccionesAPI';
import {
    downloadAvatarPDF,
    downloadCSVAira0,
    downloadLopdPDF,
    downloadMapaCatastralPDF,
    downloadPDF,
    downloadPDFAira0,
    downloadZipPdf,
} from '../inspeccionesHelpers';
import './DetallesInspeccion.scss';
import '../../../styles/buttons.scss';
import { JsonAnimation } from '../../../components/JsonAnimation/JsonAnimation';
import { generarLabelCliente } from '../aira0/NuevoAiraCero';
import { useAppSelector } from '../../../app/hooks';
import { ModelosSync, clientesMap, modelosMap, setModelo } from '../inspeccionesSlice';
import { ClientesSync } from '../../clientes/ClientesSlice';
import { IconCopy } from '../../../assets/IconCopy';
import { renderModelos } from '../NuevaInspeccion';
import { renderRamoIcon } from '../InspeccionesFeature';
import { IconsRamos } from '../../../assets/iconosRamos/IconsRamos';
import ListaNotas from '../../../components/notas/ListaNotas';
import ListaAdjuntos from '../../../components/adjuntos/ListaAdjuntos';
import { v4 as uuidv4 } from 'uuid';
import ChevronDownWithBg from '../../../assets/ChevronDownWithBg';
import DatePicker from '../../../components/DatePicker/DatePicker';
import SelectorVencimiento from '../../../components/SelectorVencimiento/SelectorVencimiento';

const DetallesInspeccion = (props: any) => {
    const id = parseInt(useLocation().pathname.replace(/^.*\/.*\//, ''));
    const clientes = useAppSelector(clientesMap);
    const [copied, setCopied] = useState(false);
    const baseMobileUrl = conf('MOBILE_BASE_URL');
    const [inspeccion, setInspeccion] = useState<any>();
    const [processed, setProcessed] = useState<boolean>(false);
    const [pdfError, setPdfError] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openSoftDeleteModal, setOpenSoftDeleteModal] = useState(false);
    const [openEnviarEvaluacionModal, setOpenEnviarEvaluacionModal] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ENV = conf('ENV');
    const AVATAR = conf('AVATAR');
    const AVATAR_SEGUN_SUB = conf('AVATAR_SEGUN_SUB');
    const suscripcion = localStorage.getItem('suscripcion');
    const [tieneMovil, setTieneMovil] = useState(true);
    const [movil, setMovil] = useState('cliente');
    const [numeroMovil, setNumeroMovil] = useState('');
    const [codigoPaisMovil, setCodigoPaisMovil] = useState('');
    const [caracteristicas, setCaracteristicas] = useState([
        { id: 1, label: '+34', tipo: 2 },
        { id: 2, label: '+54', tipo: 3 },
    ]);
    const [mensajeError, setMensajeError] = useState('');
    const [characteristicSelected, setCharacteristicSelected] = useState({ id: 1, label: '+34', tipo: 2 });
    const defaultClienteSeleccionado = {
        id: null,
        label: '',
        tipo: null,
        cif: '',
    };
    const [clienteSeleccionado, setClienteSeleccionado] = useState(defaultClienteSeleccionado);
    const [clienteActualizado, setClienteActualizado] = useState(false);
    const [modeloSeleccionado, setModeloSeleccionado] = useState({ id: 0, label: '' });
    const [actionError, setActionError] = React.useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState<Date | null>(null);
    const [diasAntelacion, setDiasAntelacion] = useState<number>(15);
    const modelos = useAppSelector(modelosMap);

    const hasAvatar = () => {
        return AVATAR === 'true';
    };

    const renderClienteIcon = () => {
        if (clienteSeleccionado.id) {
            return clienteSeleccionado.tipo === 1 ? <User id="cliente-icon" /> : <Building id="cliente-icon" />;
        }
    };

    const handleChangeCliente = (e: any, v: any) => {
        setClienteSeleccionado(v);
    };

    const getInspecciones = async () => {
        const response = await GetDetalleInspeccion(id);
        setInspeccion(response);
        let telefono: string = response?.cliente_inspeccion.movil || response?.cliente_inspeccion.telefonoEmpresa;
        if (telefono && telefono.startsWith('+34')) {
            setCodigoPaisMovil('+34');
            telefono = telefono.replace('+34', '');
        }
        if (telefono && telefono.startsWith('+54')) {
            setCodigoPaisMovil('+54');
            telefono = telefono.replace('+54', '');
        }
        setFechaVencimiento(response.fechaVencimiento || null);
        setDiasAntelacion(response.diasPrevencimiento || 15);
        setNumeroMovil(telefono ? telefono : '');
    };

    useEffect(() => {
        getInspecciones();
        dispatch(ClientesSync());
    }, []);

    useEffect(() => {
        dispatch(ModelosSync(inspeccion?.ramo_inspeccion.id));
    }, [inspeccion]);

    useEffect(() => {
        const getInspecciones = async () => {
            const response = await GetDetalleInspeccion(id);
            setInspeccion(response);
            let telefono: string = response?.cliente_inspeccion.movil || response?.cliente_inspeccion.telefonoEmpresa;
            if (telefono && telefono.startsWith('+34')) {
                setCodigoPaisMovil('+34');
                telefono = telefono.replace('+34', '');
            }
            if (telefono && telefono.startsWith('+54')) {
                setCodigoPaisMovil('+54');
                telefono = telefono.replace('+54', '');
            }
            setNumeroMovil(telefono ? telefono : '');
        };

        getInspecciones();
        setClienteActualizado(false);
    }, [clienteActualizado]);

    const renderClientes = () => {
        if (clientes) {
            return clientes.map((c) => {
                return {
                    id: c.id,
                    label: generarLabelCliente(c),
                    tipo: c.tipoCliente,
                    cif: c?.cif || null,
                };
            });
        } else {
            return [];
        }
    };

    const renderButtonDetallesCliente = () => {
        return (
            <button
                className="tertiary-button"
                style={{ height: '32px', borderRadius: '8px', marginTop: '8px' }}
                onClick={() =>
                    navigate(
                        `/clientes/${inspeccion?.cliente_inspeccion?.tipo_cliente_id === 1 ? 'fisicos' : 'juridicos'}/${
                            inspeccion?.cliente_inspeccion?.id
                        }`,
                    )
                }
            >
                Ver detalle del cliente
            </button>
        );
    };

    const renderPdfButton = (text: string, buttonStyle: string) => {
        if (isTest(ENV) && inspeccion?.estado_inspeccion.id !== 6) {
            if (inspeccion?.estado_inspeccion.id === 2) {
                return (
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <button
                            className={`${buttonStyle}-button`}
                            onClick={debounce(
                                isTest(ENV) || isSuperUser() ? () => handleDownloadClick(id) : () => setPdfError(true),
                                500,
                            )}
                        >
                            Generar PDF Parcial <FileDownload />
                        </button>
                    </div>
                );
            } else {
                return (
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <button
                            className={`${buttonStyle}-button`}
                            onClick={debounce(
                                isTest(ENV) || isSuperUser() ? () => handleDownloadClick(id) : () => setPdfError(true),
                                500,
                            )}
                        >
                            Generar PDF Parcial <FileDownload />
                        </button>
                    </div>
                );
            }
        } else if (
            inspeccion?.estado_inspeccion.id &&
            inspeccion?.estado_inspeccion.id !== 2 &&
            inspeccion?.estado_inspeccion.id !== 6
        ) {
            return (
                <div style={{ display: 'flex', gap: '16px' }}>
                    <button className={`${buttonStyle}-button`} onClick={debounce(() => handleDownloadClick(id), 500)}>
                        Generar PDF Parcial <FileDownload />
                    </button>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderAvatarPdfButton = () => {
        if (AVATAR_SEGUN_SUB === 'true') {
            return (
                <div
                    className={`${
                        hasAvatar() &&
                        suscripcion?.toLowerCase() === 'premium' &&
                        inspeccion?.ramo_inspeccion &&
                        inspeccion?.ramo_inspeccion.id === 1 &&
                        inspeccion?.modeloCustom.completo &&
                        inspeccion?.estado_inspeccion.id === 2
                            ? 'anexos-button'
                            : 'hide'
                    }`}
                    onClick={() => handleAvatarDownloadClick(id)}
                >
                    <div className="anexos-button-icon">
                        <CloudDownload />
                    </div>
                    <span>Avatar</span>
                </div>
            );
        } else {
            return (
                <div
                    className={`${
                        hasAvatar() && inspeccion?.ramo_inspeccion.id === 1 && inspeccion?.estado_inspeccion.id === 2
                            ? 'anexos-button'
                            : 'hide'
                    }`}
                    onClick={() => handleAvatarDownloadClick(id)}
                >
                    <div className="anexos-button-icon">
                        <CloudDownload />
                    </div>
                    <span>Avatar</span>
                </div>
            );
        }
    };

    const handleMapaCatastralPdfDownloadClick = async (id: number) => {
        setOpen(true);
        await downloadMapaCatastralPDF(id);
        setOpen(false);
    };

    const getPdfFolder = () => {
        switch (ENV) {
            case 'Localhost':
                return 'develop/';
            case 'Staging':
                return 'pruebas/';
            case 'P':
                return '';
            default: {
                return '';
            }
        }
    };

    const renderAnexos = () => {
        if (
            inspeccion &&
            ((inspeccion.referenciaCatastral && inspeccion.referenciaCatastral !== '-') ||
                inspeccion?.estado_inspeccion.id === 2)
        ) {
            return (
                <DropdownMenu text="Informes" icon={<ChevronDown />} contentWidth="max-content">
                    {inspeccion?.estado_inspeccion.id === 2 ? (
                        <a
                            className="anexos-button"
                            rel="noopener noreferrer"
                            href={`https://aira-pdf.s3.eu-west-1.amazonaws.com/${getPdfFolder()}inspeccion-${
                                inspeccion?.id
                            }.pdf`}
                            target="_blank"
                        >
                            <div className="anexos-button-icon">
                                <CloudDownload />
                            </div>
                            <span>Informe PDF</span>
                        </a>
                    ) : null}
                    {inspeccion.referenciaCatastral && inspeccion.referenciaCatastral !== '-' ? (
                        <>
                            <a
                                href={`https://www1.sedecatastro.gob.es/CYCBienInmueble/SECImprimirCroquisYDatos.aspx?refcat=${inspeccion.referenciaCatastral}`}
                                className="anexos-button"
                            >
                                <div className="anexos-button-icon">
                                    <CloudDownload />
                                </div>
                                <span>Ficha Catastral</span>
                            </a>
                            <a
                                href={`https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral=${inspeccion.referenciaCatastral}`}
                                className="anexos-button"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <div className="anexos-button-icon">
                                    <CloudDownload />
                                </div>
                                <span>Foto fachada</span>
                            </a>
                        </>
                    ) : null}
                    {inspeccion?.estado_inspeccion.id === 2 ? (
                        <a
                            className="anexos-button"
                            rel="noopener noreferrer"
                            href={`https://aira-pdf.s3.eu-west-1.amazonaws.com/${getPdfFolder()}rgpd/rgpd-inspeccion-${
                                inspeccion?.id
                            }.pdf`}
                            target="_blank"
                        >
                            <div className="anexos-button-icon">
                                <CloudDownload />
                            </div>
                            <span>RGPD</span>
                        </a>
                    ) : null}
                    <div className="anexos-button" onClick={() => handleMapaCatastralPdfDownloadClick(id)}>
                        <div className="anexos-button-icon">
                            <CloudDownload />
                        </div>
                        <span>Mapa Catastro</span>
                    </div>
                    {renderAvatarPdfButton()}
                </DropdownMenu>
            );
        }
    };

    const renderEstadoInspeccion = () => {
        if (inspeccion?.gestionado) {
            return (
                <span className="detalles-inspeccion-header-estado-gestionado">
                    <CircleCheck /> Gestionado
                </span>
            );
        } else if (inspeccion?.estado_inspeccion.id === 1) {
            return (
                <span className="detalles-inspeccion-header-estado-enviada">
                    <Send /> {inspeccion?.estado_inspeccion.estado}
                </span>
            );
        } else if (inspeccion?.estado_inspeccion.id === 2) {
            return (
                <span className="detalles-inspeccion-header-estado-completada">
                    <FileText /> {inspeccion?.estado_inspeccion.estado}
                </span>
            );
        } else if (inspeccion?.estado_inspeccion.id === 3) {
            return (
                <span className="detalles-inspeccion-header-estado-en-curso">
                    <Pencil /> {inspeccion?.estado_inspeccion.estado}
                </span>
            );
        } else if (inspeccion?.estado_inspeccion.id === 4) {
            return (
                <span className="detalles-inspeccion-header-estado-procesando">
                    <Refresh /> Procesando IA
                </span>
            );
        } else if (inspeccion?.estado_inspeccion.id === 5) {
            return (
                <span className="detalles-inspeccion-header-estado-procesando">
                    <Refresh /> Procesando IA
                </span>
            );
        }
    };

    const renderEstadoVencimiento = () => {
        if (inspeccion?.estado_vencimiento_id === 1) {
            return null;
        } else if (inspeccion?.estado_vencimiento_id === 3) {
            return (
                <span className="detalles-inspeccion-header-estado-vencida">
                    <CalendarOff /> {inspeccion?.estado_vencimiento.estado}
                </span>
            );
        } else if (inspeccion?.estado_vencimiento_id === 2) {
            return (
                <span className="detalles-inspeccion-header-estado-prevencida">
                    <Clock /> {inspeccion?.estado_vencimiento.estado}
                </span>
            );
        }
    };

    const renderMovil = () => {
        return inspeccion?.movilEnviado;
    };

    const handleDownloadClick = async (id: number) => {
        setOpen(true);
        await downloadPDF(id);
        setOpen(false);
    };

    const handleAvatarDownloadClick = async (id: number) => {
        setOpen(true);
        await downloadAvatarPDF(id);
        setOpen(false);
    };

    const horaGestionado = new Date(inspeccion?.fechaGestionado).toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const detallesInspeccion = [
        {
            label: 'Ramo',
            value: renderTextWithIcon({
                icon: inspeccion?.ramo_custom ? renderRamoIcon(inspeccion?.ramo_custom.icono) : '',
                text: inspeccion?.ramo_custom ? inspeccion?.ramo_custom.nombre : '',
                textColor: '#0046CF',
                iconColor: '#0046CF',
            }),
        },
        {
            label: 'Dirección',
            icon: <MapPin />,
            value: inspeccion?.cliente_inspeccion ? inspeccion?.cliente_inspeccion?.direccion : '',
        },
        {
            label: 'Modelo',
            icon: <Template />,
            value: getNombreModelo(inspeccion),
        },
        {
            label: 'Enviada',
            icon: <CalendarEvent />,
            value: getDateFormated(inspeccion?.fechaEnviada),
        },
        {
            label: 'Agente Responsable',
            icon: <User />,
            value: inspeccion?.agente_inspeccion?.nombre,
        },
        /*         {
            label: 'Versión vigente',
            icon: <ChevronsRight />,
            value: 'V1',
        }, */
        /*         {
            label: 'Versiones anteriores',
            icon: <History />,
            value: renderVersionValue(),
        }, */
        {
            label: 'Finalizada por el usuario',
            icon: <CalendarEvent />,
            value: getDateFormated(inspeccion?.fechaCompletada),
            hide: inspeccion?.fechaCompletada ? false : true,
        },
        {
            label: 'Móvil al que se envió',
            icon: <DeviceMobile />,
            value: renderMovil(),
        },
        {
            label: 'Gestionada',
            icon: <CalendarEvent />,
            value: getDateFormated(inspeccion?.fechaGestionado) + ' ' + horaGestionado,
            hide: inspeccion?.fechaGestionado ? false : true,
        },
    ];

    const detallesClienteJuridico = [
        {
            label: 'Nombre Fiscal',
            icon: <Building />,
            value: inspeccion?.cliente_inspeccion?.nombreFiscal,
        },
        /*         {
            label: 'Contacto',
            icon: <User />,
            value: 'Contacto',
        }, */
        {
            label: 'Nombre comercial',
            icon: <BuildingStore />,
            value: inspeccion?.cliente_inspeccion?.nombreComercio,
        },
        /*         {
            label: 'Móvil contacto',
            icon: <DeviceMobile />,
            value: inspeccion?.cliente_inspeccion?.telefonoEmpresa,
        }, */
        {
            label: 'CIF',
            icon: <Id />,
            value: inspeccion?.cliente_inspeccion?.cif,
        },
    ];

    const detallesClienteFisico = [
        {
            label: 'Nombre Completo',
            icon: <User />,
            value: inspeccion?.cliente_inspeccion?.nombreCompleto,
        },
        {
            label: 'Email',
            icon: <Mail />,
            value: inspeccion?.cliente_inspeccion?.email,
        },
        {
            label: 'Móvil',
            icon: <DeviceMobile />,
            value: inspeccion?.cliente_inspeccion?.movil,
        },
        {
            label: 'DNI',
            icon: <Id />,
            value: inspeccion?.cliente_inspeccion?.dni,
        },
        {
            value: '',
        },
    ];

    const handleAira0CSV = async () => {
        await downloadCSVAira0(id);
    };

    const handleAira0PDF = async () => {
        await downloadPDFAira0(id);
    };

    const handleProcessAI = () => {
        if (processed) return;
        processAI(id);
        setProcessed(true);
    };

    const renderInformeText = () => {
        switch (inspeccion?.estado_inspeccion.id) {
            case 1: {
                return 'La evaluación aún no ha sido abierta ni completada.';
            }
            case 3: {
                return 'La evaluación ha sido abierta pera aún no está completa. El informe se generará cuando el usuario la finalice.';
            }
            case 4: {
                return 'Estamos procesando la IA para elaborar el informe. Esto tomará unos minutos.';
            }
        }
    };

    const renderErrorToast = () => {
        return (
            <Snackbar
                open={pdfError}
                autoHideDuration={8000}
                onClose={() => setPdfError(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity="error" variant="filled">
                    La evaluación debe estar en estado Completada para poder generar su PDF
                </Alert>
            </Snackbar>
        );
    };

    const renderAira0Button = () => {
        if (inspeccion?.tienePrecarga) {
            return (
                <button disabled={processed} className="tertiary-button" onClick={handleAira0CSV}>
                    Descargar CSV <FileDownload />
                </button>
            );
        }
    };

    const renderAira0PdfButton = () => {
        if (inspeccion?.tienePrecarga) {
            return (
                <button disabled={processed} className="tertiary-button" onClick={handleAira0PDF}>
                    Descargar PDF Aira 0 <FileDownload />
                </button>
            );
        }
    };

    const renderAiButton = () => {
        if (ENV === 'Desarrollo' || ENV === 'Test' || ENV === 'Localhost' || ENV === 'Staging') {
            if (!inspeccion?.tienePrecarga) {
                return (
                    <button disabled={processed} className="primary-button" onClick={handleProcessAI}>
                        Procesar IA
                    </button>
                );
            }
        }
    };

    const handleEnviarEvaluacionAira0 = () => {
        setOpenEnviarEvaluacionModal(true);
    };

    const renderButtonEnviarEvaluacionAira0 = () => {
        if (inspeccion?.tienePrecarga && inspeccion?.estado_inspeccion.id === 6) {
            return (
                <button disabled={processed} className="primary-button" onClick={handleEnviarEvaluacionAira0}>
                    Enviar Evaluación
                    <Send></Send>
                </button>
            );
        }
    };

    const handlerEnviarEvaluacion = async () => {
        setLoading(true);
        const idCliente = inspeccion?.agente_inspeccion.aseguradora_id;
        const idEvaluacion = inspeccion?.id;
        let telefono = '';
        if (movil === 'otro') {
            telefono = characteristicSelected.label + numeroMovil;
        } else {
            telefono = codigoPaisMovil + numeroMovil;
        }
        const response = await enviarEvaluacionAira0(idCliente, idEvaluacion, telefono, modeloSeleccionado.id);
        setLoading(false);
        if (response.status === 200) {
            setOpenEnviarEvaluacionModal(false);
            setMensajeError('');
        } else {
            setMensajeError('No se ha podido completar la operación.');
        }
    };

    const renderEliminarInspeccionButton = () => {
        return (
            <button className="delete-button" onClick={() => setOpenDeleteModal(true)}>
                Eliminar Evaluación
            </button>
        );
    };

    const handleCopiarEnlace = () => {
        const uri: string = inspeccion?.uriEnviada ? inspeccion?.uriEnviada.toString() : '';
        let link = '';
        if (uri.startsWith('http')) {
            link = uri;
        } else {
            link = baseMobileUrl + inspeccion?.uriEnviada;
        }
        if (link) {
            navigator.clipboard.writeText(link);
            setCopied(true);
        }
    };

    const renderCopiarEnlaceButton = () => {
        return (
            <button
                className={`reset-button-styles boton-enlace${copied ? '-copiado' : ''}`}
                onClick={handleCopiarEnlace}
            >
                {copied ? (
                    <>
                        <span>Enlace copiado</span>
                        <Check />
                    </>
                ) : (
                    <>
                        <span>Copiar enlace</span> <IconCopy />
                    </>
                )}
            </button>
        );
    };

    const handleEliminarInspeccion = async (id: any) => {
        setLoading(true);
        const response = await deleteInspeccion(id);
        if (response === 200) {
            setOpenDeleteModal(false);
            setLoading(false);
            navigate('/inspecciones');
        } else {
            setLoading(false);
            setActionError('Ha ocurrido un error al intentar eliminar la evaluación');
            setOpenDeleteModal(false);
        }
    };

    const handleEliminarInspeccionEnCurso = async (id: any) => {
        setLoading(true);
        const response = await desactivarInspeccion(id);
        if (response === 200) {
            setOpenSoftDeleteModal(false);
            setLoading(false);
            navigate('/inspecciones', { state: { deletedId: inspeccion.id } });
        } else {
            setLoading(false);
            setActionError('Ha ocurrido un error al intentar eliminar la evaluación');
            setOpenSoftDeleteModal(false);
        }
    };

    const renderInformePdf = () => {
        if (inspeccion && inspeccion?.estado_inspeccion.id !== 6) {
            return (
                <div className="detalles-inspeccion-pdf-data">
                    <div className="detalles-inspeccion-pdf-data-item">
                        <span className="detalles-inspeccion-pdf-data-item-title">Estado</span>
                        <span className="detalles-inspeccion-pdf-data-item-content">
                            {inspeccion?.estado_inspeccion.id === 2 ? (
                                <span className="pdf-state-disponible">
                                    <FileCheck /> Disponible
                                </span>
                            ) : (
                                <span className="pdf-state-proceso">
                                    <FileReport />
                                    {inspeccion?.estado_inspeccion.id === 4 ? 'En proceso' : 'No disponible'}
                                </span>
                            )}
                        </span>
                    </div>
                    {inspeccion?.estado_inspeccion.id === 2 /*  ||
            ENV === 'Desarrollo' ||
            ENV === 'Test' ||
            ENV === 'Localhost'  */ ? (
                        <>
                            <div className="detalles-inspeccion-pdf-data-item">
                                <span className="detalles-inspeccion-pdf-data-item-title">Generado</span>
                                <span className="detalles-inspeccion-pdf-data-item-content pdf-date">
                                    <CalendarEvent />{' '}
                                    {inspeccion?.fechaCompletada
                                        ? getDateFormated(inspeccion?.fechaCompletada)
                                        : 'Pendiente'}
                                </span>
                            </div>
                            {renderPdfButton('Descargar', 'tertiary')}
                        </>
                    ) : (
                        <div className="detalles-inspeccion-pdf-data-item pdf-proceso">
                            <div>
                                <InfoCircle />
                            </div>
                            {renderInformeText()}
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderInformeCSV = () => {
        if (inspeccion && inspeccion?.tienePrecarga) {
            return (
                <div className="detalles-inspeccion-pdf-data">
                    <div className="detalles-inspeccion-pdf-data-item">
                        <span className="detalles-inspeccion-pdf-data-item-title">Estado</span>
                        <span className="detalles-inspeccion-pdf-data-item-content">
                            <span className="pdf-state-disponible">
                                <FileCheck /> Disponible
                            </span>
                        </span>
                    </div>
                    <div className="detalles-inspeccion-pdf-data-item">{renderAira0Button()}</div>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderSeccionInformes = () => {
        return (
            <>
                {renderInformeCSV()}
                {renderInformePdf()}
            </>
        );
    };

    const handleChangeCharacteristic = (e: any, v: any) => {
        setCharacteristicSelected(v);
    };

    const renderPhoneInput = () => {
        if (true) {
            return (
                <div className="phone-input">
                    {movil === 'otro' ? (
                        <Autocomplete
                            disabled={false}
                            id="caracteristicas-combo-box"
                            popupIcon={<ChevronDown />}
                            options={caracteristicas}
                            disableClearable
                            onChange={(e, v) => handleChangeCharacteristic(e, v)}
                            value={characteristicSelected}
                            isOptionEqualToValue={(o, v) => {
                                return o.id === v.id;
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return (
                                    <div style={{ position: 'relative', width: '80px', height: '100%' }}>
                                        <TextField
                                            className="prueba"
                                            {...params}
                                            InputLabelProps={{ shrink: false }}
                                            label=" "
                                            variant="outlined"
                                        />
                                    </div>
                                );
                            }}
                        />
                    ) : null}
                    <input
                        disabled={movil !== 'otro'}
                        type="tel"
                        value={movil !== 'otro' ? codigoPaisMovil + numeroMovil : numeroMovil}
                        onChange={(e) => {
                            setNumeroMovil(e.target.value);
                        }}
                        placeholder="Ingresa otro móvil"
                        className={`phone-input-input${movil === 'otro' ? '-otro' : ''}`}
                    />
                </div>
            );
        }
    };

    const handleChangeMovilButtons = (e: React.ChangeEvent<HTMLInputElement>) => {
        const radioButtonName = e.target.value;
        setMovil((e.target as HTMLInputElement).value);
        if (radioButtonName === 'otro') {
            console.log('AAA');
        }
        if (radioButtonName === 'cliente') {
            let telefono: string =
                inspeccion?.cliente_inspeccion.movil || inspeccion?.cliente_inspeccion.telefonoEmpresa;
            if (telefono && telefono.startsWith('+34')) {
                setCodigoPaisMovil('+34');
                telefono = telefono.replace('+34', '');
            }
            if (telefono && telefono.startsWith('+54')) {
                setCodigoPaisMovil('+54');
                telefono = telefono.replace('+54', '');
            }
            setNumeroMovil(telefono ? telefono : '');
        }
    };

    const handleChangeModelo = (e: any, v: any) => {
        setModeloSeleccionado(v);
    };

    const renderEnviarEvaluacionAira0 = () => {
        function renderNombreCliente() {
            if (inspeccion?.cliente_inspeccion.tipo_cliente_id === 1) {
                return (
                    <>
                        <User></User>
                        <div>{inspeccion?.cliente_inspeccion.nombre}</div>
                    </>
                );
            } else {
                return (
                    <>
                        <Building></Building>
                        <div>
                            {inspeccion?.cliente_inspeccion.nombreFiscal ||
                                inspeccion?.cliente_inspeccion.nombreComercio ||
                                inspeccion?.cliente_inspeccion.cif}
                        </div>
                    </>
                );
            }
        }

        return (
            <div className="nueva-inspeccion-modal">
                <h5>Enviar evaluación</h5>
                <div className="nueva-inspeccion-mt">
                    <div className="nueva-inspeccion-modal-tooltip">
                        <h6>1. Móvil</h6>
                        <Tooltip
                            title="Enviaremos un SMS con el link de la evaluación al móvil que indiques aquí"
                            placement="right"
                            arrow
                        >
                            <div>
                                <AlertCircle />
                            </div>
                        </Tooltip>
                    </div>
                    <RadioGroup
                        className="nueva-inspeccion-radio"
                        value={movil || null}
                        onChange={handleChangeMovilButtons}
                    >
                        <FormControlLabel
                            disabled={false}
                            value="cliente"
                            control={<Radio />}
                            label="Emplear móvil del cliente"
                            sx={{ color: `${movil === 'otro' ? '#635F76' : '#171717'}` }}
                        />
                        <FormControlLabel
                            disabled={false}
                            value="otro"
                            control={<Radio />}
                            label="Ingresar otro móvil"
                            sx={{ color: `${movil === 'cliente' ? '#635F76' : '#171717'}` }}
                        />
                        {!tieneMovil ? (
                            <div className="nueva-inspeccion-no-movil">
                                <div className="nueva-inspeccion-no-movil-icon">
                                    <InfoCircle />
                                </div>
                                <p>
                                    Este cliente no tiene móvil asociado. Por favor, ingresálo y lo guardaremos en el
                                    perfil de este cliente.
                                </p>
                            </div>
                        ) : null}
                    </RadioGroup>
                    {renderPhoneInput()}
                </div>
                <div className="nueva-inspeccion-mt">
                    <h6>2. Modelo</h6>
                    <Autocomplete
                        id="modelo-combo-box"
                        popupIcon={<ChevronDown />}
                        options={renderModelos(modelos)}
                        onChange={(e, v) => handleChangeModelo(e, v)}
                        value={modeloSeleccionado}
                        disableClearable
                        isOptionEqualToValue={(o, v) => {
                            return o.id === v.id;
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputLabelProps={{ shrink: false }}
                                    label=" "
                                    variant="outlined"
                                    placeholder="Selecciona un cliente"
                                />
                            );
                        }}
                    />
                </div>
                <div>{mensajeError ? mensajeError : ''}</div>
                <div className="nueva-inspeccion-buttons">
                    <button
                        className="secondary-button"
                        onClick={() => {
                            setMensajeError('');
                            setOpenEnviarEvaluacionModal(false);
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        disabled={
                            numeroMovil === null ||
                            numeroMovil === undefined ||
                            numeroMovil === '' ||
                            !modeloSeleccionado.id
                        }
                        onClick={() => {
                            handlerEnviarEvaluacion();
                        }}
                        className="primary-button"
                    >
                        {loading ? (
                            <JsonAnimation animation="buttonLoaderWhite" height="50px" width="182px" />
                        ) : (
                            <>Enviar Evaluación</>
                        )}
                    </button>
                </div>
            </div>
        );
    };

    const handleAsignarCliente = async () => {
        if (clienteSeleccionado.id) {
            await actualizarCliente({ idCliente: clienteSeleccionado.id, idInspeccion: inspeccion.id });
            setClienteActualizado(true);
        }
    };

    const renderAsignarACliente = () => {
        return (
            <div className="details-card">
                <h1 className="asignar-cliente-title">Asignar cliente</h1>
                <Autocomplete
                    id="clientes-combo-box"
                    sx={{ background: 'white', borderRadius: '8px' }}
                    popupIcon={<ChevronDown />}
                    options={renderClientes()}
                    disableClearable
                    value={clienteSeleccionado}
                    onChange={(e, v) => handleChangeCliente(e, v)}
                    filterOptions={(a, b) => filterClientes(a, b)}
                    isOptionEqualToValue={(o, v) => {
                        return o.id === v.id;
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                            {option.tipo === 1 ? (
                                <User id="autocomplete-option-icon" />
                            ) : (
                                <Building id="autocomplete-option-icon" />
                            )}{' '}
                            {option.label}
                        </Box>
                    )}
                    renderInput={(params) => {
                        return (
                            <div style={{ position: 'relative' }}>
                                <span
                                    style={{
                                        position: 'absolute',
                                        marginLeft: '16px',
                                        marginTop: '5px',
                                    }}
                                >
                                    {renderClienteIcon()}
                                </span>
                                <TextField
                                    className="prueba"
                                    {...params}
                                    InputLabelProps={{ shrink: false }}
                                    label=" "
                                    variant="outlined"
                                />
                            </div>
                        );
                    }}
                />
                <button onClick={handleAsignarCliente} disabled={!clienteSeleccionado.id} className="primary-button">
                    Asignar
                </button>
            </div>
        );
    };

    const agenteId = Number(localStorage.getItem('idAgente'));

    const handleSubirNotaAgente = async (nota: string) => {
        await subirNota(id, agenteId, nota);
        await getInspecciones();
    };

    const toNotas = (notas: any) => {
        return notas.map((n: any) => {
            return {
                nota: n.nota,
                nombre: `${n.agente.nombre || ''} ${n.agente.apellido || ''}`,
                fechaCreacion: n.fechaCreacion,
            };
        });
    };

    const toAdjuntos = (adjuntos: any) => {
        return adjuntos.reduce((acc: any, adjunto: any) => {
            return {
                ...acc,
                [adjunto.batchId]: [...(acc[adjunto.batchId] || []), adjunto],
            };
        }, {});
    };

    const handleSubirAdjuntosInspeccion = async (adjuntos: any) => {
        let results: any = [];
        const randomId = uuidv4();
        for (let i = 0; i < adjuntos.length; i++) {
            const response = await subirAdjuntosInspeccion(id, agenteId, adjuntos[i].file, randomId);
            results = [...results, response];
        }

        const badStatus = results.some((r: any) => r.status !== 200);

        if (badStatus) {
            console.log('Hubo un error al subir los adjuntos');
        } else {
            await getInspecciones();
        }
    };

    const descargarTodo = async () => {
        setLoading(true);
        await downloadZipPdf(
            inspeccion.id,
            inspeccion.referenciaCatastral,
            inspeccion.ramo_inspeccion.id,
            inspeccion.esAira0,
        );
        setLoading(false);
    };

    const handleArchivarInspeccion = async (inspeccionId: number) => {
        const response = await archivarInspeccion(inspeccionId);
        if (response === 200) {
            getInspecciones();
        } else {
            setActionError('Ha ocurrido un error al intentar archivar la evaluación');
        }
    };

    const handleDesarchivarInspeccion = async (inspeccionId: number) => {
        const response = await desarchivarInspeccion(inspeccionId);
        if (response === 200) {
            getInspecciones();
        } else {
            setActionError('Ha ocurrido un error al intentar desarchivar la evaluación');
        }
    };

    const handleGestionarInspeccion = async (inspeccionId: number) => {
        const response = await marcarInspeccionComoGestionada(inspeccionId);
        if (response === 200) {
            getInspecciones();
        } else {
            setActionError('Ha ocurrido un error al intentar marcar como gestionada la evaluación');
        }
    };

    const renderGestionarButton = () => {
        if (inspeccion && !inspeccion.gestionado) {
            return (
                <button onClick={() => handleGestionarInspeccion(inspeccion.id)} className="dropdown-menu-item">
                    <p>Marcar como gestionada</p>
                    <CircleCheck color="#3BA1AE" />
                </button>
            );
        }
    };

    const renderArchivarButton = () => {
        if (inspeccion && inspeccion.archivado) {
            return (
                <button onClick={() => handleDesarchivarInspeccion(inspeccion.id)} className="dropdown-menu-item">
                    <p>Activar</p>
                    <Eye />
                </button>
            );
        } else {
            return (
                <button onClick={() => handleArchivarInspeccion(inspeccion.id)} className="dropdown-menu-item">
                    <p>Archivar</p>
                    <EyeOff />
                </button>
            );
        }
    };

    const renderEliminarInspeccionEnCurso = () => {
        if (inspeccion && inspeccion.estado_inspeccion.id === 3) {
            return (
                <button className="dropdown-menu-item" onClick={() => setOpenSoftDeleteModal(true)}>
                    <p>Eliminar</p>
                    <Trash color="#E64B54" />
                </button>
            );
        }
    };

    const handleFechaVencimientoChange = async (fecha: Date) => {
        const results = await actualizarFechaVencimiento(inspeccion.id, fecha);
        if (results === 200) {
            setFechaVencimiento(fecha);
        } else {
            setActionError('Ha ocurrido un error al intentar actualizar la fecha de vencimiento');
        }
    };

    const handleAntelacionChange = async (cantidadDias: number) => {
        const results = await actualizarDiasPrevencimiento(inspeccion.id, cantidadDias);
        if (results === 200) {
            setDiasAntelacion(cantidadDias);
        } else {
            setActionError('Ha ocurrido un error al intentar actualizar la cantidad de dias previos al aviso');
        }
    };

    const renderFechaVencimiento = () => {
        return (
            <div className="detalles-inspeccion-header-fechas-completada">
                <div style={{ display: 'flex', gap: '4px' }}>
                    <CalendarOff />
                    <span>Vencimiento</span>
                </div>
                <div className="detalles-inspeccion-header-fechas-align-icon">
                    {(fechaVencimiento && getDateFormated(fechaVencimiento)) || '-'}
                    <SelectorVencimiento
                        onAntelacionChange={(e: number) => handleAntelacionChange(e)}
                        onDateChange={(e: Date) => handleFechaVencimientoChange(e)}
                        defaultDate={(inspeccion && inspeccion.fechaVencimiento) || null}
                        defaultAntelacion={(inspeccion && inspeccion.diasPrevencimiento) || 15}
                    />
                </div>
            </div>
        );
    };

    console.log(inspeccion);

    return (
        <div className="detalles-inspeccion">
            <div className="detalles-inspeccion-header">
                <div className="detalles-inspeccion-header-icon">
                    {inspeccion && inspeccion.ramo_custom && IconsRamos[inspeccion.ramo_custom.icono]}
                </div>
                <div className="detalles-inspeccion-header-data">
                    <div className="detalles-inspeccion-header-title">
                        <span>Evaluación</span>
                        <div>
                            {inspeccion && inspeccion.nombreInspeccion
                                ? inspeccion.nombreInspeccion
                                : `ID ${inspeccion && inspeccion.id}`}
                            {renderEstadoInspeccion()}
                            {renderEstadoVencimiento()}
                            <DropdownMenu icon={<ChevronDownWithBg />} onlyIcon direction="right" contentWidth="251px">
                                {renderGestionarButton()}
                                {renderArchivarButton()}
                                {renderEliminarInspeccionEnCurso()}
                            </DropdownMenu>
                        </div>
                    </div>
                    <div className="detalles-inspeccion-header-fechas">
                        {renderFechaVencimiento()}
                        {inspeccion?.estado_inspeccion.id != 2 && inspeccion?.estado_inspeccion.id !== 6
                            ? renderCopiarEnlaceButton()
                            : null}
                    </div>
                    {inspeccion?.estado_inspeccion.id !== 1 || isTest(ENV) ? (
                        <div className="detalles-inspeccion-header-fechas">
                            {isSuperUser() ? renderEliminarInspeccionButton() : null}
                            {renderAiButton()}
                            {renderPdfButton('Informe PDF', 'primary')}
                            {renderAnexos()}
                            {inspeccion && inspeccion.estado_inspeccion.id === 2 ? (
                                <button className="primary-button" disabled={loading} onClick={() => descargarTodo()}>
                                    {loading ? (
                                        <JsonAnimation animation="buttonLoaderBlue" height="30px" width="101px" />
                                    ) : (
                                        'Descargar todo'
                                    )}
                                </button>
                            ) : null}
                        </div>
                    ) : null}
                    {renderAira0Button()}
                    {inspeccion && inspeccion.tienePrecarga ? renderAira0PdfButton() : null}
                    {renderButtonEnviarEvaluacionAira0()}
                </div>
            </div>
            <div className="detalles-inspeccion-cards">
                <div className="detalles-inspeccion-cards-left">
                    <DetailsCard items={detallesInspeccion} width="100%" height="100%" />
                </div>
                <div className="detalles-inspeccion-cards-right">
                    {inspeccion?.cliente_inspeccion.prueba === 0 ? (
                        <DetailsCard
                            items={
                                inspeccion?.cliente_inspeccion?.tipo_cliente_id === 1
                                    ? detallesClienteFisico
                                    : detallesClienteJuridico
                            }
                            width="auto"
                            title="Cliente"
                            rows={3}
                            action={{
                                fn: () =>
                                    navigate(
                                        `/clientes/${
                                            inspeccion?.cliente_inspeccion?.tipo_cliente_id === 1
                                                ? 'fisicos'
                                                : 'juridicos'
                                        }/${inspeccion?.cliente_inspeccion?.id}`,
                                    ),
                                text: 'Ver detalle del cliente',
                            }}
                            tag={renderTextWithIcon({
                                icon: inspeccion?.cliente_inspeccion?.tipo_cliente_id === 1 ? <User /> : <Building />,
                                text:
                                    inspeccion?.cliente_inspeccion?.tipo_cliente_id === 1
                                        ? 'Persona física'
                                        : 'Persona jurídica',
                                textColor: '#B0ADBE',
                                background: '#ffffff',
                                verticalAlignment: 'center',
                                fontWeight: '800',
                                iconColor: '#B0ADBE',
                                padding: '4px 8px',
                                fontSize: '12px',
                            })}
                        />
                    ) : (
                        renderAsignarACliente()
                    )}
                </div>
                <ListaNotas notas={(inspeccion && toNotas(inspeccion.notas)) || []} onSubmit={handleSubirNotaAgente} />
                <ListaAdjuntos
                    adjuntos={inspeccion && toAdjuntos(inspeccion.adjuntos)}
                    onSubmit={(e) => handleSubirAdjuntosInspeccion(e)}
                    onDelete={() => getInspecciones()}
                />
            </div>
            {pdfError ? renderErrorToast() : null}
            <Modal
                open={openEnviarEvaluacionModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                {renderEnviarEvaluacionAira0()}
            </Modal>
            <OpenPdfModal open={open} />
            <Modal open={openDeleteModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ModalEliminarInspeccion
                    id={inspeccion?.id}
                    onCancel={() => setOpenDeleteModal(false)}
                    onDelete={() => handleEliminarInspeccion(inspeccion?.id)}
                />
            </Modal>
            <Modal open={openSoftDeleteModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ModalEliminarInspeccion
                    id={inspeccion?.id}
                    onCancel={() => setOpenSoftDeleteModal(false)}
                    onDelete={() => handleEliminarInspeccionEnCurso(inspeccion?.id)}
                />
            </Modal>
            <Snackbar
                open={actionError ? true : false}
                autoHideDuration={10000}
                onClose={() => setActionError('')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setActionError('')} severity="error">
                    {actionError}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DetallesInspeccion;
function getNombreModelo(inspeccion: any) {
    const ramo = inspeccion?.ramo_custom ? inspeccion?.ramo_custom.nombre : '';
    const modelo = inspeccion?.modeloCustom ? inspeccion?.modeloCustom.nombre : '';
    return ramo + ' - ' + modelo;
}
