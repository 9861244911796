import { Autocomplete, Box, Modal, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Building,
    BuildingCommunity,
    BuildingFactory,
    BuildingStore,
    Car,
    ChevronDown,
    CirclePlus,
    File,
    Files,
    Help,
    Home,
    Map,
    MapPin,
    User,
    Id,
    Template,
} from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ModalConfirmation from '../../../components/modal-confirmation/ModalConfirmation';
import { debounce, filterClientes, formatCp } from '../../../utlis';
import { obtenerCalles, obtenerDireccionCliente } from '../../clientes/ClientesAPI';
import { clearNuevoClienteId, nuevoClienteId } from '../../clientes/ClientesSlice';
import NuevoCliente from '../../clientes/NuevoCliente';
import {
    confirmarAira0,
    deleteInspeccion,
    direccion,
    getDireccion,
    getInfoCodigoPostal,
    obtenerFichasPersona,
    precargaCIF,
    precargaPersona,
} from '../InspeccionesAPI';
import { InspeccionesSync, clientesMap, ramosMap, setRamo } from '../inspeccionesSlice';
import './NuevoAiraCero.scss';
import GMap from '../../../components/GMap/GMap';
import { JsonAnimation } from '../../../components/JsonAnimation/JsonAnimation';
import { GetDetalleCliente } from '../../clientes/ClientesAPI';
import * as R from 'ramda';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import ToastMessage from '../../../components/reusable/ToastMessage';
import { IconsRamos } from '../../../assets/iconosRamos/IconsRamos';
import { RamosConModeloSync, selectRamosCustom } from '../../modelos/ModeloSlice';

interface FilterType {
    text: string;
    icon: React.ReactElement;
}

interface ClienteSeleccionado {
    id: number | null;
    label: string;
    tipo: number | null;
    cif: string;
}
export interface Coordenadas {
    latitud: string;
    longitud: string;
}

const CheckBox = ({ selected }: any) => {
    return (
        <div className="toggle-buttons-checkbox">
            <label>
                <input type="checkbox" />
                <svg
                    className={`toggle-buttons-checkbox-icon ${selected ? 'toggle-buttons-checkbox-icon-active' : ''}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.99994 13L8.99994 18L19.9999 7.00001"
                        stroke={selected ? '#ffffff' : 'none'}
                        strokeWidth="4"
                        strokeLinecap="round"
                    />
                </svg>
            </label>
        </div>
    );
};

const isValueSelected = (currentIndex: any, selectedIndex: any) => {
    if (selectedIndex === undefined || currentIndex === undefined) {
        return false;
    }
    return currentIndex === selectedIndex;
};

const parseCoordenadas = (coordenadas: string | Coordenadas): Coordenadas => {
    if (typeof coordenadas === 'string') {
        const coors = coordenadas.split(',');
        return {
            latitud: coors[0].trim(),
            longitud: coors[1].trim(),
        };
    } else return coordenadas;
};

const NuevoAiraCero = (props: any) => {
    const dispatch = useAppDispatch();
    const defaultClienteSeleccionado = {
        id: null,
        label: '',
        tipo: null,
        cif: '',
    };
    const ramos = useAppSelector(ramosMap);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const clientes = useAppSelector(clientesMap);
    const clienteId = useAppSelector(nuevoClienteId);
    const path = useLocation().pathname;
    const [modalContent, setModalContent] = useState('inspeccion');
    //falta googleMaps
    const [direccionContent, setDireccionContent] = useState('direccionCliente');
    const [clienteSeleccionado, setClienteSeleccionado] = useState<ClienteSeleccionado>(defaultClienteSeleccionado);
    const [direccionCliente, setDireccionCliente] = useState<any[]>([
        {
            direccionCompleta: 'Nueva Dirección',
            calle: '',
            numero: '',
            codigoPostal: '',
            poblacion: '',
            provincia: '',
        },
    ]);
    const [direccionClienteSeleccionada, setDireccionClienteSeleccionada] = useState<any>();
    const [datosAyuware, setDatosAyuware] = useState<any>();
    const [direccionABuscar, setDireccionABuscar] = useState<any>();
    const [codigoPostal, setCodigoPostal] = useState<string>('');
    const [infoCodigoPostal, setInfoCodigoPostal] = useState<any>(null);
    const [fichasCatastrales, setFichasCatastrales] = useState<any[]>([]);
    const [fichaCatastralSeleccionada, setFichaCatastralSeleccionada] = useState<any>(null);
    const [ramoSeleccionado, setRamoSeleccionado] = useState<any>();
    const [evaluacionId, setEvaluacionId] = useState<number>();
    const ramosCustom = useAppSelector(selectRamosCustom);
    const nombreRamoSeleccionado = ramosCustom.find(({ id }) => id === ramoSeleccionado)?.nombre || '-';
    const iconoRamoSeleccionado = ramosCustom.find(({ id }) => id === ramoSeleccionado)?.icono || '-';
    const [nombre, setNombre] = useState<string>();
    const [apellidos, setApellidos] = useState<string>();
    const [cif, setCif] = useState<string>();
    const [sinResultados, setSinResultados] = useState<string>();
    const [coordenadasDeDireccion, setCoordenadasDeDireccion] = useState<null | Coordenadas>(null);
    const [callesOptions, setCallesOptions] = useState([]);
    const [esProyecto, setEsProyecto] = useState(false);
    const [previousDireccionContent, setPreviousDireccionContent] = useState('');
    const confirmButtonRef: any = useRef();
    const [bypassDireccionClienteForm, setBypassDireccionClienteForm] = useState(false);
    const [fichasPersonas, setFichasPersonas] = useState([]);
    const [fichaPersonaSeleccionada, setFichaPersonaSeleccionada] = useState<any>(null);
    const [showToastMessage, setShowToastMessage] = useState('');

    const getOptionsDelayed = useCallback(
        debounce((texto: string, poblacion: string, provincia: string, callback: any) => {
            setCallesOptions([]);
            texto = texto + ', ' + poblacion + ', ' + provincia;
            obtenerCalles(texto).then((response: any) => {
                if (!R.isEmpty(response.data.direcciones)) {
                    const direccionesFiltradas = response.data.direcciones.filter((x: any) => {
                        return true; // return x.provincia === provincia;
                    });
                    callback(direccionesFiltradas);
                } else {
                    callback([
                        {
                            cod_ine: '',
                            cod_via: '',
                            cp: '',
                            dir: texto.replace(/[0-9]/g, '').trim(),
                            dirSugerida: texto,
                            numero_via: texto.replace(/\D/g, ''),
                            poblacion: '',
                        },
                    ]);
                }
            });
        }, 200),
        [],
    );

    useEffect(() => {
        const idAseguradora = localStorage.getItem('idAseguradora');
        idAseguradora && dispatch(RamosConModeloSync(Number(idAseguradora)));
    }, []);

    useEffect(() => {
        const obtenerDetallesCliente = async (idCliente: number) => {
            const detalleCliente = await GetDetalleCliente(idCliente);
            setNombre(detalleCliente.data.Cliente.nombreCompleto || '');
            setApellidos(detalleCliente.data.Cliente.apellido || '');
        };

        const obtenerDireccion = async (idCliente: number) => {
            const direccion = await obtenerDireccionCliente(idCliente);
            if (direccion) {
                const dirString = direccion.data
                    ? `${direccion.data.calle || ''} ${direccion.data.numero || ''} ${
                          direccion.data.codigoPostal || ''
                      } ${direccion.data.poblacion || ''} ${direccion.data.provincia || ''}`
                    : '';

                dirString
                    ? setDireccionCliente([
                          {
                              direccionCompleta: dirString.trim(),
                              calle: direccion.data.calle || '',
                              numero: direccion.data.numero || '',
                              codigoPostal: direccion.data.codigoPostal || '',
                              poblacion: direccion.data.poblacion || '',
                              provincia: direccion.data.provincia || '',
                          },
                          {
                              direccionCompleta: 'Nueva Dirección',
                              calle: '',
                              numero: '',
                              codigoPostal: '',
                              poblacion: '',
                              provincia: '',
                          },
                      ])
                    : setDireccionCliente([
                          {
                              direccionCompleta: 'Nueva Dirección',
                              calle: '',
                              numero: '',
                              codigoPostal: '',
                              poblacion: '',
                              provincia: '',
                          },
                      ]);
            }
        };

        if (clienteSeleccionado && clienteSeleccionado.id) {
            obtenerDetallesCliente(clienteSeleccionado.id);
            obtenerDireccion(clienteSeleccionado.id);
        }
    }, [clienteSeleccionado]);

    useEffect(() => {
        if (direccionClienteSeleccionada && direccionClienteSeleccionada.calle) {
            getOptionsDelayed(
                direccionClienteSeleccionada.calle,
                direccionClienteSeleccionada.poblacion,
                direccionClienteSeleccionada.provincia,
                (filteredOptions: any) => {
                    setCallesOptions(filteredOptions);
                },
            );
        }
    }, [direccionClienteSeleccionada]);

    useEffect(() => {
        if (fichaCatastralSeleccionada) {
            confirmButtonRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [fichaCatastralSeleccionada]);

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (infoCodigoPostal) {
            setDireccionClienteSeleccionada({
                poblacion: infoCodigoPostal.poblacion,
                provincia: infoCodigoPostal.provincia,
                codigoPostal: infoCodigoPostal.cp,
            });
        }
    }, [infoCodigoPostal]);

    const getDataDireccion = async (data: direccion) => {
        const direccion = await getDireccion(data);
        if (direccion?.data?.coordenadas) setCoordenadasDeDireccion(parseCoordenadas(direccion.data.coordenadas));
        return direccion;
    };

    const getInfoByCodigoPostal = async () => {
        const data = await getInfoCodigoPostal(codigoPostal);
        if (data?.coordenadas) setCoordenadasDeDireccion(data.coordenadas);
        return data;
    };

    const setDatosBusquedaAyuware = (datos: any) => {
        const calleNum = datos.direccion.split(/(\d+)/);
        setDatosAyuware({
            idEvaluacion: datos.idEvaluacion,
            calle: calleNum[0],
            numero: calleNum[1],
            poblacion: datos.poblacion,
            provincia: datos.provincia,
            codigoPostal: datos.codigoPostal || direccionClienteSeleccionada.codigoPostal,
            bloque: datos.bloque,
            escalera: datos.escalera,
            planta: datos.planta,
            puerta: datos.puerta,
            dataVerticales: datos.dataVerticales,
        });
        setDireccionABuscar({
            idEvaluacion: datos.idEvaluacion ? datos.idEvaluacion : null,
            idRamo: ramoSeleccionado,
            idCliente: clienteSeleccionado?.id,
            calle: calleNum[0],
            numero: calleNum[1],
            poblacion: datos.poblacion,
            provincia: datos.provincia,
            codigoPostal: datos.codigoPostal || direccionClienteSeleccionada.codigoPostal,
        });
        setDireccionContent('ayuware');
        setCallesOptions([]);
    };

    const setDatosBusquedaAyuwareNoExistePortal = async (datos: any) => {
        const results = await getDataDireccion({
            referenciaCatastral: datos.referenciaCatastral,
            idCliente: clienteSeleccionado?.id || 0,
            idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
            idRamo: ramoSeleccionado,
        });

        if (results.status === 200) {
            if (results.data.usoPrincipal === 'AGRARIO' && results.data.superficie === '0') {
                setPreviousDireccionContent('ayuware');
                setDireccionContent('noResultados');
                return;
            }
            setSinResultados(undefined);
            setFichasCatastrales([
                {
                    direccion: results.data.direccion,
                    superficie: results.data.superficie,
                    uso: results.data.usoPrincipal,
                    anioConstruccion: results.data.anioConstruccion,
                },
            ]);
            setDireccionContent('fichaCatastral');
            setEvaluacionId(results.data.idEvaluacion);
            if (results.data.idEvaluacion) {
                setDireccionABuscar({
                    ...direccionABuscar,
                    idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                    idRamo: ramoSeleccionado,
                    idEvaluacion: results.data.idEvaluacion,
                });
            }
        } else {
            setPreviousDireccionContent('ayuware');
            setDireccionContent('noResultados');
        }
    };

    const handleFinishForm = async () => {
        setLoading(true);
        let idEvaluacion = evaluacionId;
        if (nombre && apellidos && fichaPersonaSeleccionada) {
            const rspPersona = await precargaPersona({
                nombre: nombre,
                apellido: apellidos,
                idRamo: ramoSeleccionado,
                idCliente: 0,
                idEvaluacion: idEvaluacion || null,
                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                ficha: fichaPersonaSeleccionada.ficha,
            });
            idEvaluacion = rspPersona.idEvaluacion;
        }
        if (cif) {
            const rspCif = await precargaCIF({
                cif,
                idRamo: ramoSeleccionado,
                idCliente: 0,
                idEvaluacion: idEvaluacion || null,
                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
            });
            idEvaluacion = rspCif.idEvaluacion;
        }
        const results = await confirmarAira0({ idEvaluacion: idEvaluacion ? idEvaluacion : 0 });
        if (results.status !== 200) {
            const msgToast = 'No se ha creado el Aira 0 debido a que no se encontraron datos para la misma';
            setShowToastMessage(msgToast);
        } else {
            dispatch(InspeccionesSync(false));
        }
        clearStatesWithInitialValues();
        setModalContent('inspeccion');
    };

    const showFichasPersonas = (fichas: any) => {
        if (!fichas || !fichas.length) {
            setModalContent('confirm');
        } else if (fichas.length === 1) {
            setFichaPersonaSeleccionada({ ficha: fichas[0] });
            setModalContent('confirm');
        } else {
            setFichasPersonas(fichas);
            setModalContent('fichasPersona');
        }
    };

    const handlerConfirmButton = async () => {
        setLoading(true);
        if (bypassDireccionClienteForm) {
            if (hasMinimumFormRequirements() && nombre && apellidos) {
                const fichasPersona = await obtenerFichasPersona({ nombre: nombre, apellido: apellidos });
                fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
            } else if (hasMinimumFormRequirements()) {
                setModalContent('confirm');
            }
        } else {
            await handleDireccionContent();
        }
        setLoading(false);
    };

    const hasMinimumFormRequirements = () => {
        const sinCliente = clienteSeleccionado.id === null;
        const sinDatosCif = !cif;
        const sinNombreOApellido = !nombre || !apellidos;
        const sinRamo = !ramoSeleccionado;
        const sinDireccion = !direccionClienteSeleccionada;
        const noCargaDireccion = bypassDireccionClienteForm;

        if (sinCliente) return false;
        if (sinDatosCif && sinNombreOApellido && noCargaDireccion) return false;
        if (sinDatosCif && sinNombreOApellido && !noCargaDireccion && sinDireccion) return false;
        if (sinRamo) return false;

        return true;
    };

    useEffect(() => {
        const idClientePrueba = localStorage.getItem('idClientePrueba');
        if (esProyecto && idClientePrueba) {
            setClienteSeleccionado({
                ...defaultClienteSeleccionado,
                id: Number(idClientePrueba),
                label: 'Proyecto',
                tipo: 2,
            });
        } else {
            setClienteSeleccionado(defaultClienteSeleccionado);
        }
    }, [esProyecto]);

    const toResults = async (results: any) => {
        if (results.status === 200) {
            setEvaluacionId(results.data.idEvaluacion);
            if (results.data.inmueble) {
                setSinResultados(undefined);
                const fichas =
                    results.data.inmueble &&
                    results.data.inmueble.map((i: any) => {
                        return {
                            direccion: results.data.direccion,
                            superficie: i.superficieEdificada || results.data.superficie,
                            uso: i.uso || results.data.usoPrincipal,
                            anioConstruccion: i.anioConstruccion || results.data.anioConstruccion,
                            planta: i.planta,
                            escalera: i.escalera,
                            bloque: i.bloque,
                            puerta: i.puerta,
                            referenciaCatastralVertical: i.referenciaCatastral,
                        };
                    });
                setFichasCatastrales(fichas);
                if (fichas.length === 1) {
                    setFichaCatastralSeleccionada({ ...fichas[0], index: 0 });
                    if (nombre && apellidos) {
                        const fichasPersona = await obtenerFichasPersona({
                            nombre: nombre,
                            apellido: apellidos,
                        });
                        fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
                    } else {
                        setModalContent('confirm');
                    }
                }
                setDireccionContent('fichaCatastral');
            } else {
                setPreviousDireccionContent('calleYNumero');
                setDireccionContent('noResultados');
            }
        } else {
            setPreviousDireccionContent('calleYNumero');
            setDireccionContent('noResultados');
        }
    };

    const handleDireccionContent = async () => {
        try {
            switch (direccionContent) {
                case 'direccionCliente': {
                    if (direccionClienteSeleccionada.direccionCompleta === 'Nueva Dirección') {
                        setDireccionContent('cpAyuware');
                        return;
                    }

                    const results = await getDataDireccion({
                        idEvaluacion: evaluacionId,
                        idRamo: ramoSeleccionado,
                        idCliente: 0, //No importa porque la Aseguradora se saca del Token
                        idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                        calle: direccionClienteSeleccionada.calle,
                        numero: direccionClienteSeleccionada.numero,
                        poblacion: direccionClienteSeleccionada.poblacion,
                        provincia: direccionClienteSeleccionada.provincia,
                        codigoPostal: direccionClienteSeleccionada.codigoPostal,
                    });
                    if (results.status === 200) {
                        setSinResultados(undefined);
                        setDatosBusquedaAyuware(results.data);
                        setEvaluacionId(results.data.idEvaluacion);
                        setDireccionABuscar({
                            ...direccionABuscar,
                            idEvaluacion: results.data.idEvaluacion,
                            idCliente: 0,
                            idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                            idRamo: ramoSeleccionado,
                            calle: direccionClienteSeleccionada.calle,
                            numero: direccionClienteSeleccionada.numero,
                            poblacion: direccionClienteSeleccionada.poblacion,
                            provincia: direccionClienteSeleccionada.provincia,
                            codigoPostal: direccionClienteSeleccionada.codigoPostal,
                        });
                        if (results.data.inmueble && results.data.inmueble.length > 0) {
                            const fichas =
                                results.data.inmueble &&
                                results.data.inmueble.map((i: any) => {
                                    return {
                                        direccion: results.data.direccion,
                                        superficie: i.superficieEdificada,
                                        uso: i.uso,
                                        anioConstruccion: i.anioConstruccion,
                                        planta: i.planta,
                                        escalera: i.escalera,
                                        bloque: i.bloque,
                                        puerta: i.puerta,
                                    };
                                });
                            setFichasCatastrales(fichas);
                            if (fichas.length === 1) {
                                setFichaCatastralSeleccionada({ ...fichas[0], index: 0 });
                                if (nombre && apellidos) {
                                    const fichasPersona = await obtenerFichasPersona({
                                        nombre: nombre,
                                        apellido: apellidos,
                                    });
                                    fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
                                } else {
                                    setModalContent('confirm');
                                }
                            }
                            setDireccionContent('fichaCatastral');
                        } else {
                            setDireccionContent('calleYNumero');
                        }
                    } else {
                        setPreviousDireccionContent('direccionCliente');
                        setDireccionContent('noResultados');
                    }
                    break;
                }
                case 'ayuware': {
                    const results = await getDataDireccion(direccionABuscar);
                    toResults(results);
                    break;
                }
                case 'refCatastral': {
                    const results = await getDataDireccion({
                        ...direccionABuscar,
                        idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                        idRamo: ramoSeleccionado,
                    });
                    if (results.status === 200) {
                        if (results.data.usoPrincipal === 'AGRARIO' && results.data.superficie === '0') {
                            setPreviousDireccionContent('refCatastral');
                            setDireccionContent('noResultados');
                            return;
                        }
                        setSinResultados(undefined);
                        setFichasCatastrales([
                            {
                                direccion: results.data.direccion,
                                superficie: results.data.superficie,
                                uso: results.data.usoPrincipal,
                                anioConstruccion: results.data.anioConstruccion,
                            },
                        ]);
                        setDireccionContent('fichaCatastral');
                        setEvaluacionId(results.data.idEvaluacion);
                        if (results.data.idEvaluacion) {
                            setDireccionABuscar({
                                ...direccionABuscar,
                                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                                idRamo: ramoSeleccionado,
                                idEvaluacion: results.data.idEvaluacion,
                            });
                        }
                    } else {
                        setPreviousDireccionContent('refCatastral');
                        setDireccionContent('noResultados');
                    }
                    break;
                }
                case 'cpAyuware': {
                    const result = await getInfoByCodigoPostal();
                    if (result) {
                        setSinResultados(undefined);
                        setInfoCodigoPostal(result);
                        setDireccionContent('calleYNumero');
                        setDireccionABuscar({
                            ...direccionABuscar,
                            calle: undefined,
                            numero: undefined,
                            poblacion: result.poblacion,
                            provincia: result.provincia,
                            codigoPostal: result.cp,
                        });
                    } else {
                        setSinResultados('No se encontraron resultados.');
                    }
                    break;
                }
                case 'calleYNumero': {
                    const results = await getDataDireccion({
                        idEvaluacion: evaluacionId,
                        idRamo: ramoSeleccionado,
                        idCliente: 0, //No importa porque la Aseguradora se saca del Token
                        idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                        calle: direccionClienteSeleccionada.calle,
                        numero: direccionClienteSeleccionada.numero,
                        poblacion: direccionClienteSeleccionada.poblacion,
                        provincia: direccionClienteSeleccionada.provincia,
                        codigoPostal: direccionClienteSeleccionada.codigoPostal,
                    });
                    const { data, status } = results || {};
                    if (status === 200) {
                        setSinResultados(undefined);
                        setEvaluacionId(data.idEvaluacion);
                        if (data.idEvaluacion) {
                            setDireccionABuscar({
                                ...direccionABuscar,
                                idEvaluacion: data.idEvaluacion,
                                idCliente: 0,
                                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                                idRamo: ramoSeleccionado,
                                calle: direccionClienteSeleccionada.calle,
                                numero: direccionClienteSeleccionada.numero,
                                poblacion: direccionClienteSeleccionada.poblacion,
                                provincia: direccionClienteSeleccionada.provincia,
                                codigoPostal: direccionClienteSeleccionada.codigoPostal,
                            });
                        }
                        if (
                            (!data.bloque || data.bloque.length <= 1) &&
                            (!data.escalera || data.escalera.length <= 1) &&
                            (!data.planta || data.planta.length <= 1) &&
                            (!data.puerta || data.puerta.length <= 1)
                        ) {
                            const results = await getDataDireccion({
                                ...direccionABuscar,
                                ...(data.idEvaluacion && { idEvaluacion: data.idEvaluacion }),
                                idCliente: 0,
                                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                                idRamo: ramoSeleccionado,
                                calle: direccionClienteSeleccionada.calle,
                                numero: direccionClienteSeleccionada.numero,
                                poblacion: direccionClienteSeleccionada.poblacion,
                                provincia: direccionClienteSeleccionada.provincia,
                                codigoPostal: direccionClienteSeleccionada.codigoPostal,
                            });
                            toResults(results);
                            return;
                        }
                        if (data.existePortal) {
                            setDatosBusquedaAyuware(data);
                        } else {
                            setDatosBusquedaAyuwareNoExistePortal(data);
                        }
                    } else {
                        setPreviousDireccionContent('calleYNumero');
                        setDireccionContent('noResultados');
                    }
                    break;
                }
                case 'cpCatastro': {
                    const result = await getInfoByCodigoPostal();
                    if (result) {
                        setSinResultados(undefined);
                        setInfoCodigoPostal(result);
                        setDireccionContent('poligonoYParcela');
                        setEvaluacionId(result.idEvaluacion);
                    } else {
                        setSinResultados('No se encontraron resultados.');
                    }
                    break;
                }
                case 'poligonoYParcela': {
                    const results = await getDataDireccion({
                        idEvaluacion: evaluacionId,
                        idRamo: ramoSeleccionado,
                        idCliente: 0, //No importa porque la Aseguradora se saca del Token
                        idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                        poblacion: direccionClienteSeleccionada.poblacion,
                        provincia: direccionClienteSeleccionada.provincia,
                        codigoPostal: direccionClienteSeleccionada.codigoPostal,
                        poligono: direccionClienteSeleccionada.poligono,
                        parcela: direccionClienteSeleccionada.parcela,
                    });
                    if (results.status === 200) {
                        if (results.data.usoPrincipal === 'AGRARIO' && results.data.superficie === '0') {
                            setPreviousDireccionContent('poligonoYParcela');
                            setDireccionContent('noResultados');
                            return;
                        }
                        setSinResultados(undefined);
                        setFichasCatastrales([
                            {
                                direccion: results.data.direccion,
                                superficie: results.data.superficie,
                                uso: results.data.usoPrincipal,
                                anioConstruccion: results.data.anioConstruccion,
                            },
                        ]);
                        setDireccionContent('fichaCatastral');
                        setEvaluacionId(results.data.idEvaluacion);
                        if (results.data.idEvaluacion) {
                            setDireccionABuscar({
                                ...direccionABuscar,
                                idEvaluacion: results.data.idEvaluacion,
                                idCliente: 0,
                                idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                                idRamo: ramoSeleccionado,
                            });
                        }
                    } else {
                        setPreviousDireccionContent('poligonoYParcela');
                        setDireccionContent('noResultados');
                    }
                    break;
                }
                case 'mapa': {
                    const results = await getDataDireccion({
                        ...direccionABuscar,
                        idAsegurado: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
                        idRamo: ramoSeleccionado,
                        //l/atitud: center.lat,
                        //longitud: center.lng,
                    });
                    if (results.status === 200) {
                        setSinResultados(undefined);
                        setEvaluacionId(results.data.idEvaluacion);
                        if (results.data.idEvaluacion) {
                            setDireccionABuscar({ ...direccionABuscar, idEvaluacion: results.data.idEvaluacion });
                        }
                        if (results.data.usoPrincipal === 'AGRARIO' && results.data.superficie === '0') {
                            setPreviousDireccionContent('mapa');
                            setDireccionContent('noResultados');
                            return;
                        }
                        const fichas =
                            results.data.inmueble &&
                            results.data.inmueble.map((i: any) => {
                                return {
                                    direccion: results.data.direccion,
                                    superficie: i.superficieEdificada || results.data.superficie,
                                    uso: i.uso || results.data.usoPrincipal,
                                    anioConstruccion: i.anioConstruccion || results.data.anioConstruccion,
                                    planta: i.planta,
                                    escalera: i.escalera,
                                    bloque: i.bloque,
                                    puerta: i.puerta,
                                    referenciaCatastralVertical: i.referenciaCatastral,
                                };
                            });
                        setFichasCatastrales(fichas);
                        if (fichas && fichas.length > 0) {
                            setDireccionContent('fichaCatastral');
                            if (fichas.length === 1) {
                                setFichaCatastralSeleccionada({ ...fichas[0], index: 0 });
                                if (nombre && apellidos) {
                                    const fichasPersona = await obtenerFichasPersona({
                                        nombre: nombre,
                                        apellido: apellidos,
                                    });
                                    fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
                                } else {
                                    setModalContent('confirm');
                                }
                            }
                        } else {
                            setDireccionContent('calleYNumero');
                        }
                    } else {
                        setPreviousDireccionContent('mapa');
                        setDireccionContent('noResultados');
                    }
                    break;
                }
                case 'fichaCatastral': {
                    if (fichasCatastrales.length > 1) {
                        const bloque = fichaCatastralSeleccionada.bloque;
                        const puerta = fichaCatastralSeleccionada.puerta;
                        const planta = fichaCatastralSeleccionada.planta;
                        const escalera = fichaCatastralSeleccionada.escalera;
                        const referenciaCatastralVertical =
                            fichaCatastralSeleccionada.referenciaCatastralVertical || '';

                        const results = await getDataDireccion({
                            ...direccionABuscar,
                            bloque: bloque,
                            puerta: puerta,
                            planta: planta,
                            escalera: escalera,
                            ...(referenciaCatastralVertical && {
                                referenciaCatastralVertical: referenciaCatastralVertical,
                            }),
                            idRamo: ramoSeleccionado,
                        });
                        if (results.status === 200) {
                            setDireccionABuscar({
                                ...direccionABuscar,
                                bloque: bloque,
                                puerta: puerta,
                                planta: planta,
                                escalera: escalera,
                            });
                            setSinResultados(undefined);
                            const fichas =
                                results.data.inmueble &&
                                results.data.inmueble.map((i: any) => {
                                    return {
                                        direccion: results.data.direccion,
                                        superficie: i.superficieEdificada,
                                        uso: i.uso,
                                        anioConstruccion: i.anioConstruccion,
                                        planta: i.planta,
                                        escalera: i.escalera,
                                        bloque: i.bloque,
                                        puerta: i.puerta,
                                    };
                                });
                            setFichasCatastrales(fichas);
                            if (fichas.length === 1) {
                                setFichaCatastralSeleccionada({ ...fichas[0], index: 0 });
                                if (nombre && apellidos) {
                                    const fichasPersona = await obtenerFichasPersona({
                                        nombre: nombre,
                                        apellido: apellidos,
                                    });
                                    fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
                                } else {
                                    setModalContent('confirm');
                                }
                                break;
                            } else {
                                setDireccionContent('fichaCatastral');
                            }
                        }
                    } else {
                        if (nombre && apellidos) {
                            const fichasPersona = await obtenerFichasPersona({
                                nombre: nombre,
                                apellido: apellidos,
                            });
                            fichasPersona.status === 200 && showFichasPersonas(fichasPersona.data.fichas);
                        } else {
                            setModalContent('confirm');
                        }
                        break;
                    }
                }
            }
        } catch (error) {
            console.log('error :>> ', error);
        }
    };

    const ramoIcons: FilterType[] = [
        { text: 'Hogar', icon: <Home /> },
        { text: 'Comercio / Oficina', icon: <BuildingStore /> },
        { text: 'Pyme / Industria', icon: <BuildingFactory /> },
        { text: 'Vehiculo', icon: <Car /> },
        { text: 'Comunidad', icon: <BuildingCommunity /> },
    ];

    const VerificarIcono = (nombre: string) => {
        switch (nombre) {
            case 'Hogar':
                return <Home />;
            case 'Comercio / Oficina':
                return <BuildingStore />;
            case 'Pyme / Industria':
                return <BuildingFactory />;
            case 'Vehiculo':
                return <Car />;
            case 'Comunidad':
                return <BuildingCommunity />;
        }
    };

    const VerificarTipoCliente = (nombre: string) => {
        if (nombre === 'Físico') {
            return 1;
        }
        if (nombre === 'Jurídico') {
            return 2;
        }
    };

    const renderRamoText = (r: any) => {
        return r.nombre;
    };

    const renderSinResultados = () => {
        if (sinResultados) {
            return (
                <div className="sin-resultados">
                    <span>No se encontraron resultados</span>
                </div>
            );
        }
    };

    const renderBotonBuscarEnMapa = () => {
        return (
            <button
                className="button-mapa"
                onClick={() => {
                    setSinResultados(undefined);
                    setDireccionContent('mapa');
                }}
            >
                <Map />
                <span>¿No encuentras tu dirección? Ir al mapa</span>
            </button>
        );
    };

    const renderButtons = () => {
        return ramosCustom.map((ramo: any) => {
            const fillException = ['accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar'];
            const hasException = fillException.includes(ramo.icono);
            return (
                <ToggleButton
                    className={`toggle-button-inspeccion ${hasException ? 'toggle-button-inspeccion-exception' : ''}`}
                    disableRipple
                    disabled={false}
                    key={ramo.id}
                    value={ramo.id}
                    onClick={() => setRamoSeleccionado(ramo.id)}
                >
                    <div>{IconsRamos[ramo.icono]}</div>
                    <p>{ramo.nombre}</p>
                </ToggleButton>
            );
        });
    };

    const renderNombreCliente = (i: any) => {
        if (i.tipoCliente === 1) {
            return i.nombre;
        }
        if (i.tipoCliente === 2) {
            return i.nombreComercio;
        }
    };

    const renderClientes = () => {
        if (clientes) {
            return clientes.map((c) => {
                return {
                    id: c.id,
                    label: generarLabelCliente(c),
                    tipo: c.tipoCliente,
                    cif: c?.cif || null,
                };
            });
        } else {
            return [];
        }
    };

    const renderButtonNuevaInspeccion = () => {
        if (path.includes('/clientes/fisicos') || path.includes('/clientes/juridicos')) {
            return null;
        } else {
            return (
                <button onClick={() => setModalContent('cliente')} className="text-with-icon">
                    O añade un nuevo cliente <CirclePlus />
                </button>
            );
        }
    };

    const handleChangeCliente = (e: any, v: any) => {
        setSinResultados(undefined);
        setDireccionClienteSeleccionada({ calle: '', numero: '', codigoPostal: '', poblacion: '', provincia: '' });
        setCodigoPostal('');
        setInfoCodigoPostal(null);
        setEvaluacionId(undefined);
        setDireccionContent('direccionCliente');
        setCif(v?.cif || '');
        setClienteSeleccionado(v);
    };

    const renderClienteIcon = () => {
        if (clienteSeleccionado.id) {
            return clienteSeleccionado.tipo === 1 ? <User id="cliente-icon" /> : <Building id="cliente-icon" />;
        }
    };

    const handleDireccionClienteChange = (e: any, v: any) => {
        setDireccionClienteSeleccionada(v);
    };

    const renderDireccionCliente = () => {
        return (
            <div>
                <p className="nuevo-airacero-text">Elige la dirección de la que quieres obtener los datos</p>
                <Autocomplete
                    id="direccion-combo-box"
                    key={direccionCliente[0].direccionCompleta}
                    popupIcon={<ChevronDown />}
                    options={direccionCliente}
                    disabled={!clienteSeleccionado.id || !ramoSeleccionado}
                    getOptionLabel={(o) => o.direccionCompleta}
                    onChange={handleDireccionClienteChange}
                    isOptionEqualToValue={(o, v) => {
                        return o.direccionCompleta === v.direccionCompleta;
                    }}
                    disableClearable
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: false }}
                                label=" "
                                variant="outlined"
                                placeholder="Selecciona un cliente"
                            />
                        );
                    }}
                />
            </div>
        );
    };

    const renderDireccionOptions = () => {
        return (
            <div>
                <p className="nuevo-airacero-text">
                    Puedes introducir una nueva dirección a través de una búsqueda por:
                </p>
                <div className="direccion-options">
                    <button
                        onClick={() => {
                            setSinResultados(undefined);
                            setDireccionContent('cpAyuware');
                        }}
                        className={`option-button${
                            direccionContent === 'cpAyuware' ||
                            direccionContent === 'ayuware' ||
                            direccionContent === 'mapa' ||
                            direccionContent === 'calleYNumero'
                                ? '-active'
                                : ''
                        }`}
                    >
                        <MapPin /> Dirección
                    </button>
                    <button
                        onClick={() => {
                            setSinResultados(undefined);
                            setDireccionContent('refCatastral');
                        }}
                        className={`option-button${direccionContent === 'refCatastral' ? '-active' : ''}`}
                    >
                        <Files /> Referencia Catastral
                    </button>
                    <button
                        onClick={() => {
                            setSinResultados(undefined);
                            setDireccionContent('cpCatastro');
                        }}
                        className={`option-button${
                            direccionContent === 'poligonoYParcela' || direccionContent === 'cpCatastro'
                                ? '-active'
                                : ''
                        }`}
                    >
                        <Map /> Polígono y parcela
                    </button>
                </div>
            </div>
        );
    };

    const renderDireccionCP = () => {
        return (
            <div>
                <div className="nuevo-airacero-input mtop-16">
                    <span>CP</span>
                    <input
                        key="CP"
                        className="text-input"
                        onChange={(e) => setCodigoPostal(e.target.value)}
                        type="text"
                    />
                </div>
                {renderSinResultados()}
            </div>
        );
    };

    const handleChangeCalle = (v: any) => {
        const calle = v.dir.split(',')[0];
        const numero = v.numero_via ? v.numero_via.toString() : '';
        const cp = v.cp;
        if (cp) {
            setDireccionClienteSeleccionada({
                ...direccionClienteSeleccionada,
                calle: calle,
                numero: numero,
                codigoPostal: cp,
            });
        } else {
            setDireccionClienteSeleccionada({ ...direccionClienteSeleccionada, calle: calle, numero: numero });
        }
    };

    const renderDireccionCalleYNumero = () => {
        return (
            <div>
                <div className="nuevo-airacero-input mtop-16">
                    <span>Calle</span>
                    <Autocomplete
                        id="calle-combo-box"
                        key="combo-calle"
                        options={callesOptions}
                        getOptionLabel={(option: any) => option.dirSugerida}
                        isOptionEqualToValue={(option, value) => option.dir === value.dir}
                        onChange={(e, v: any) => handleChangeCalle(v)}
                        renderInput={(params: any) => {
                            return (
                                <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        className={`dropdown-input-calle`}
                                        type="text"
                                        key="combo-calle-input"
                                        autoComplete="off"
                                        //disabled={hasDataOptions || (!invocarValidar && !preguntas.mostrarIncidencia)}
                                        value={direccionClienteSeleccionada.calle}
                                        onChange={(e) => {
                                            //setIsOpcionSelected(false);
                                            setDireccionClienteSeleccionada({
                                                ...direccionClienteSeleccionada,
                                                calle: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
                <div className="direccion-input-grid">
                    <div className="nuevo-airacero-input">
                        <span>Número</span>
                        <input
                            className="text-input"
                            value={direccionClienteSeleccionada.numero}
                            onChange={(e) =>
                                setDireccionClienteSeleccionada({
                                    ...direccionClienteSeleccionada,
                                    numero: e.target.value,
                                })
                            }
                            type="text"
                        />
                    </div>
                    <div className="nuevo-airacero-input">
                        <span>CP</span>
                        <input
                            className="text-input"
                            value={formatCp(direccionClienteSeleccionada.codigoPostal) || formatCp(codigoPostal) || ''}
                            disabled
                            type="text"
                        />
                    </div>
                    <div className="nuevo-airacero-input">
                        <span>Población</span>
                        <input
                            className="text-input"
                            value={direccionClienteSeleccionada.poblacion || infoCodigoPostal?.poblacion || ''}
                            disabled
                            type="text"
                        />
                    </div>
                    <div className="nuevo-airacero-input">
                        <span>Provincia</span>
                        <input
                            className="text-input"
                            value={direccionClienteSeleccionada.provincia || infoCodigoPostal?.provincia || ''}
                            disabled
                            type="text"
                        />
                    </div>
                </div>
                {renderSinResultados()}
                {renderBotonBuscarEnMapa()}
            </div>
        );
    };

    const renderDireccionAyuware = () => {
        function filtrarEscaleras(): readonly any[] {
            const data =
                datosAyuware.dataVerticales &&
                datosAyuware.dataVerticales
                    .filter((d: any) => {
                        return d.escalera && (d.bloque === undefined || d.bloque === direccionABuscar.bloque);
                    })
                    .map((x: any) => {
                        return x.escalera;
                    });
            return Array.from(new Set(data));
        }

        function filtrarPlanta(): readonly any[] {
            const data =
                datosAyuware.dataVerticales &&
                datosAyuware.dataVerticales
                    .filter((d: any) => {
                        return (
                            d.planta &&
                            (d.bloque === undefined ||
                                (direccionABuscar.bloque && d.bloque === direccionABuscar.bloque)) &&
                            (d.escalera === undefined ||
                                (direccionABuscar.escalera && d.escalera === direccionABuscar.escalera))
                        );
                    })
                    .map((x: any) => {
                        return x.planta;
                    });
            return Array.from(new Set(data));
        }

        function filtrarPuerta(): readonly any[] {
            const data =
                datosAyuware.dataVerticales &&
                datosAyuware.dataVerticales
                    .filter((d: any) => {
                        return (
                            d.puerta &&
                            (d.bloque === undefined ||
                                (direccionABuscar.bloque && d.bloque === direccionABuscar.bloque)) &&
                            (d.escalera === undefined ||
                                (direccionABuscar.escalera && d.escalera === direccionABuscar.escalera)) &&
                            (d.planta === undefined ||
                                (direccionABuscar.planta && d.planta === direccionABuscar.planta))
                        );
                    })
                    .map((x: any) => {
                        return x.puerta;
                    });
            return Array.from(new Set(data));
        }

        return (
            <div>
                <div className="nuevo-airacero-input mtop-16">
                    <span>Calle</span>
                    <input value={datosAyuware && datosAyuware.calle} disabled className="text-input" type="text" />
                </div>
                <div className="nuevo-airacero-input mtop-16">
                    <span>Número</span>
                    <input className="text-input" value={datosAyuware && datosAyuware.numero} disabled type="text" />
                </div>
                <div className="direccion-input-grid dropdown-input mtop-16">
                    {datosAyuware.bloque && datosAyuware.bloque.length ? (
                        <Autocomplete
                            id="bloque-combo-box"
                            key="combo-bloque"
                            options={datosAyuware.bloque}
                            //value={direccion.bloque}
                            //getOptionLabel={(option) => option}
                            //isOptionEqualToValue={(o, v) => v === o || v === ''}
                            onChange={(e, v: any) => setDireccionABuscar({ ...direccionABuscar, bloque: v })}
                            renderInput={(params: any) => (
                                <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        className={`dropdown-input-input`}
                                        type="text"
                                        key="combo-bloque-input"
                                        autoComplete="none"
                                        readOnly
                                    />
                                    <div className="chevron-down">
                                        <ChevronDown />
                                    </div>
                                    <label className={params.inputProps.value && 'filled'}>Bloque</label>
                                </div>
                            )}
                        />
                    ) : null}
                    {datosAyuware.escalera && datosAyuware.escalera.length ? (
                        <Autocomplete
                            id="escalera-combo-box"
                            key={`combo-escalera ${direccionABuscar.bloque || ''}`}
                            options={filtrarEscaleras()}
                            //value={direccion.bloque}
                            //getOptionLabel={(option) => option}
                            //isOptionEqualToValue={(o, v) => v === o || v === ''}
                            onChange={(e, v: any) => setDireccionABuscar({ ...direccionABuscar, escalera: v })}
                            renderInput={(params: any) => (
                                <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        className={`dropdown-input-input`}
                                        type="text"
                                        key="combo-bloque-input"
                                        autoComplete="none"
                                        readOnly
                                    />
                                    <div className="chevron-down">
                                        <ChevronDown />
                                    </div>
                                    <label className={params.inputProps.value && 'filled'}>Escalera</label>
                                </div>
                            )}
                        />
                    ) : null}
                    {datosAyuware.planta && datosAyuware.planta.length ? (
                        <Autocomplete
                            id="planta-combo-box"
                            key={`combo-planta ${direccionABuscar.escalera || ''}`}
                            options={filtrarPlanta()}
                            //value={direccion.bloque}
                            //getOptionLabel={(option) => option}
                            //isOptionEqualToValue={(o, v) => v === o || v === ''}
                            onChange={(e, v: any) => setDireccionABuscar({ ...direccionABuscar, planta: v })}
                            renderInput={(params: any) => (
                                <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        className={`dropdown-input-input`}
                                        type="text"
                                        key="combo-bloque-input"
                                        autoComplete="none"
                                        readOnly
                                    />
                                    <div className="chevron-down">
                                        <ChevronDown />
                                    </div>
                                    <label className={params.inputProps.value && 'filled'}>Planta</label>
                                </div>
                            )}
                        />
                    ) : null}
                    {datosAyuware.puerta && datosAyuware.puerta.length ? (
                        <Autocomplete
                            id="puerta-combo-box"
                            key={`combo-puerta ${direccionABuscar.escalera || ''} ${direccionABuscar.planta || ''}`}
                            options={filtrarPuerta()}
                            //value={direccion.bloque}
                            //getOptionLabel={(option) => option}
                            //isOptionEqualToValue={(o, v) => v === o || v === ''}
                            onChange={(e, v: any) => setDireccionABuscar({ ...direccionABuscar, puerta: v })}
                            renderInput={(params: any) => (
                                <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                    <input
                                        {...params.inputProps}
                                        className={`dropdown-input-input`}
                                        type="text"
                                        key="combo-bloque-input"
                                        autoComplete="none"
                                        readOnly
                                    />
                                    <div className="chevron-down">
                                        <ChevronDown />
                                    </div>
                                    <label className={params.inputProps.value && 'filled'}>Puerta</label>
                                </div>
                            )}
                        />
                    ) : null}
                </div>
                {renderSinResultados()}
                {renderBotonBuscarEnMapa()}
            </div>
        );
    };

    const renderDireccionPoligonoYParcela = () => {
        return (
            <div className="mtop-16">
                <div className="direccion-input-grid">
                    <div className="nuevo-airacero-input">
                        <span>Polígono</span>
                        <input
                            className="text-input"
                            onChange={(e) =>
                                setDireccionClienteSeleccionada({
                                    ...direccionClienteSeleccionada,
                                    poligono: e.target.value,
                                })
                            }
                            type="text"
                        />
                    </div>
                    <div className="nuevo-airacero-input">
                        <span>Parcela</span>
                        <input
                            className="text-input"
                            onChange={(e) =>
                                setDireccionClienteSeleccionada({
                                    ...direccionClienteSeleccionada,
                                    parcela: e.target.value,
                                })
                            }
                            type="text"
                        />
                    </div>
                </div>
                <div className="nuevo-airacero-input mtop-8">
                    <span>CP</span>
                    <input className="text-input" value={formatCp(codigoPostal)} disabled type="text" />
                </div>
                <div className="direccion-input-grid">
                    <div className="nuevo-airacero-input">
                        <span>Población</span>
                        <input className="text-input" value={infoCodigoPostal?.poblacion || ''} disabled type="text" />
                    </div>
                    <div className="nuevo-airacero-input">
                        <span>Provincia</span>
                        <input className="text-input" value={infoCodigoPostal.provincia || ''} disabled type="text" />
                    </div>
                </div>
                {renderSinResultados()}
                {renderBotonBuscarEnMapa()}
            </div>
        );
    };

    const renderDireccionRefCatastral = () => {
        return (
            <div>
                <div className="nuevo-airacero-input mtop-16">
                    <span>N° de Referencia Catastral</span>
                    <input
                        key="input-referencia-catastral"
                        className="text-input"
                        onChange={(e) =>
                            setDireccionABuscar({
                                idRamo: ramoSeleccionado,
                                idCliente: clienteSeleccionado?.id,
                                referenciaCatastral: e.target.value,
                            })
                        }
                        type="text"
                    />
                </div>{' '}
                {renderSinResultados()}
            </div>
        );
    };

    const renderFichaCatastral = () => {
        return (
            <ToggleButtonGroup exclusive className="toggle-buttons-list">
                {fichasCatastrales &&
                    fichasCatastrales.map((v: any, index: number) => {
                        return (
                            <ToggleButton
                                className="toggle-buttons"
                                disableRipple
                                key={v.direccion + index}
                                value={v.direccion + index}
                                onClick={() => setFichaCatastralSeleccionada({ ...v, index })}
                            >
                                <div className="toggle-buttons-text">
                                    <p className="toggle-buttons-text-primary">{v.direccion}</p>
                                    <p className="toggle-buttons-text-secondary">
                                        Superficie: {v.superficie} m2 ({v.uso}) |{' '}
                                        {v.anioConstruccion && `Año de construcción: ${v.anioConstruccion}`}{' '}
                                    </p>
                                    <p className="toggle-buttons-text-secondary">
                                        {v.bloque ? 'Bloque: ' : ''}
                                        {v.bloque ? v.bloque + ' ' : ''}
                                        {v.planta ? 'Planta: ' : ''}
                                        {v.planta ? v.planta + ' ' : ''}
                                        {v.escalera ? 'Escalera: ' : ''}
                                        {v.escalera ? v.escalera + ' ' : ''}
                                        {v.puerta ? 'Puerta: ' : ''}
                                        {v.puerta ? v.puerta + ' ' : ''}
                                    </p>
                                </div>
                                <CheckBox selected={isValueSelected(index, fichaCatastralSeleccionada?.index)} />
                            </ToggleButton>
                        );
                    })}
            </ToggleButtonGroup>
        );
    };

    const onCenterChange = (e: any) => {
        setDireccionABuscar({
            idRamo: ramoSeleccionado,
            idCliente: (clienteSeleccionado && clienteSeleccionado?.id) || 0,
            ...e,
        });
    };

    const renderMapa = () => {
        const inputValue =
            direccionABuscar &&
            `${direccionABuscar.calle || direccionABuscar.plusCode || ''} ${direccionABuscar.numero || ''}, ${
                direccionABuscar.codigoPostal || ''
            } ${direccionABuscar.poblacion} ${direccionABuscar.provincia}`;
        return (
            <div className="mtop-16 map-container">
                <GMap onCenterChange={(e: any) => onCenterChange(e)} initialCenter={coordenadasDeDireccion} />
                <p className="mtop-16 fs-14">Arrastra para ajustar tu ubicación.</p>
                {/* <input type="Text" value={inputValue || ''} readOnly /> */}
                {renderSinResultados()}
            </div>
        );
    };

    const renderNoResultados = () => {
        return (
            <div style={{ marginTop: '8px' }}>
                <ErrorMessage
                    title="¡Ups! No hemos encontrado una ficha catastral a partir de los datos facilitados."
                    subtitle="Puedes retroceder para modificarla."
                />
            </div>
        );
    };

    const direccionContents: any = {
        direccionCliente: renderDireccionCliente,
        cpAyuware: renderDireccionCP,
        cpCatastro: renderDireccionCP,
        calleYNumero: renderDireccionCalleYNumero,
        ayuware: renderDireccionAyuware,
        poligonoYParcela: renderDireccionPoligonoYParcela,
        refCatastral: renderDireccionRefCatastral,
        fichaCatastral: renderFichaCatastral,
        mapa: renderMapa,
        noResultados: renderNoResultados,
    };

    const handleCancelar = async () => {
        if (direccionContent === 'mapa') {
            setDireccionContent('calleYNumero');
            return;
        }
        if (direccionContent === 'noResultados') {
            setDireccionContent(previousDireccionContent);
            return;
        }
        if (evaluacionId) {
            deleteInspeccion(evaluacionId);
            setEvaluacionId(undefined);
        }
        clearStatesWithInitialValues();
    };

    const clearStatesWithInitialValues = () => {
        setEvaluacionId(undefined);
        setDireccionContent('direccionCliente');
        setModalContent('inspeccion');
        setClienteSeleccionado(defaultClienteSeleccionado);
        setDireccionCliente([
            {
                direccionCompleta: 'Nueva Dirección',
                calle: '',
                numero: '',
                codigoPostal: '',
                poblacion: '',
                provincia: '',
            },
        ]);
        setDireccionClienteSeleccionada(null);
        setCallesOptions([]);
        setDatosAyuware(null);
        setDireccionABuscar(null);
        setCodigoPostal('');
        setInfoCodigoPostal(null);
        setFichasCatastrales([]);
        setFichaCatastralSeleccionada('');
        setRamoSeleccionado(null);
        setOpen(false);
        setCoordenadasDeDireccion(null);
        setLoading(false);
        setCif('');
        setNombre('');
        setApellidos('');
        setSinResultados(undefined);
        setEsProyecto(false);
        setFichasPersonas([]);
        setFichaPersonaSeleccionada(null);
        setBypassDireccionClienteForm(false);
    };

    const clearDirectionStatesWithInitialValues = () => {
        setDireccionContent('direccionCliente');
        setDireccionCliente([
            {
                direccionCompleta: 'Nueva Dirección',
                calle: '',
                numero: '',
                codigoPostal: '',
                poblacion: '',
                provincia: '',
            },
        ]);
        setDireccionClienteSeleccionada(null);
        setCallesOptions([]);
        setDatosAyuware(null);
        setDireccionABuscar(null);
        setCodigoPostal('');
        setInfoCodigoPostal(null);
        setFichasCatastrales([]);
        setFichaCatastralSeleccionada('');
        setCoordenadasDeDireccion(null);
        setSinResultados(undefined);
    };

    const handleByPassDireccion = () => {
        clearDirectionStatesWithInitialValues();
        setBypassDireccionClienteForm((prev) => !prev);
    };

    const renderModalContent = () => {
        function handlerEsProyecto(): void {
            setEsProyecto(!esProyecto);
            const clienteProyecto = {
                id: null,
                label: 'Proyecto',
                tipo: 2,
                cif: '',
            };
            setClienteSeleccionado(clienteProyecto);
        }

        return (
            <div className="nuevo-airacero-modal">
                <h5>Crear aira 0</h5>
                <div className="nuevo-airacero-select">
                    <div className="title-and-button">
                        <div className="nuevo-airacero-modal-tooltip">
                            <h6>1. Cliente</h6>
                            <Tooltip title="Puedes buscar persona físicas o jurídicas" placement="right" arrow>
                                <div>
                                    <Help />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <Autocomplete
                        id="clientes-combo-box"
                        popupIcon={<ChevronDown />}
                        options={renderClientes()}
                        disableClearable
                        disabled={esProyecto}
                        value={clienteSeleccionado}
                        onChange={(e, v) => handleChangeCliente(e, v)}
                        filterOptions={(a, b) => filterClientes(a, b)}
                        isOptionEqualToValue={(o, v) => {
                            return o.id === v.id;
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                                {option.tipo === 1 ? (
                                    <User id="autocomplete-option-icon" />
                                ) : (
                                    <Building id="autocomplete-option-icon" />
                                )}{' '}
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => {
                            return (
                                <div style={{ position: 'relative' }}>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '16px',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {renderClienteIcon()}
                                    </span>
                                    <TextField
                                        className="prueba"
                                        {...params}
                                        InputLabelProps={{ shrink: false }}
                                        label=" "
                                        disabled={esProyecto}
                                        variant="outlined"
                                    />
                                </div>
                            );
                        }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        {renderButtonNuevaInspeccion()}

                        <button onClick={() => handlerEsProyecto()} className="text-with-icon">
                            <p style={{ margin: 0 }}>Es un proyecto</p>
                            <CheckBox key="esproyecto" selected={esProyecto} />
                        </button>
                    </div>
                </div>
                <div className="nuevo-airacero-mt">
                    <div className="title-and-button">
                        <div className="nuevo-airacero-modal-tooltip">
                            <h6>2. Nombre y apellidos</h6>
                            <Tooltip
                                title="Encontraremos las empresas a las que pertenece esta persona y su rol en ellas."
                                placement="right"
                                arrow
                            >
                                <div>
                                    <Help />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="inputs-nombre">
                        <input
                            className="text-input"
                            placeholder="Nombre"
                            onChange={(e) => setNombre(e.target.value)}
                            type="text"
                            value={nombre}
                        />
                        <input
                            className="text-input"
                            placeholder="Apellidos"
                            onChange={(e) => setApellidos(e.target.value)}
                            type="text"
                            value={apellidos}
                        />
                    </div>
                </div>
                <div className="nuevo-airacero-mt">
                    <div className="title-and-button">
                        <div className="nuevo-airacero-modal-tooltip">
                            <h6>3. CIF</h6>
                            <Tooltip
                                title="Encontraremos los datos mercantiles asociados a esta empresa."
                                placement="right"
                                arrow
                            >
                                <div>
                                    <Help />
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <input
                        key="cif-input"
                        className="text-input"
                        minLength={9}
                        onChange={(e) => setCif(e.target.value)}
                        type="text"
                        value={cif}
                    />
                </div>
                <div className="nuevo-airacero-mt">
                    <h6>4. Ramo</h6>
                    <ToggleButtonGroup exclusive value={ramoSeleccionado} className="toggle-buttons-container">
                        {renderButtons()}
                    </ToggleButtonGroup>
                </div>
                <div className="nuevo-airacero-mt">
                    <div className="title-and-button">
                        <div className="nuevo-airacero-modal-tooltip">
                            <h6>5. Dirección del inmueble</h6>
                            <Tooltip
                                title="Encontraremos los datos zonales y los datos del inmueble disponibles."
                                placement="right"
                                arrow
                            >
                                <div>
                                    <Help />
                                </div>
                            </Tooltip>
                            <button onClick={handleByPassDireccion} className="text-with-icon">
                                <p style={{ margin: 0 }}>No deseo cargar dirección</p>
                                <CheckBox selected={bypassDireccionClienteForm} />
                            </button>
                        </div>
                    </div>
                    {!bypassDireccionClienteForm && direccionContent !== 'direccionCliente'
                        ? renderDireccionOptions()
                        : null}
                    {!bypassDireccionClienteForm ? direccionContents[direccionContent]() : null}
                </div>
                <div className="nuevo-airacero-buttons">
                    <button className="secondary-button" onClick={handleCancelar}>
                        {direccionContent === 'mapa' || direccionContent === 'noResultados' ? 'Volver' : 'Cancelar'}
                    </button>
                    <button
                        ref={confirmButtonRef}
                        onClick={handlerConfirmButton}
                        disabled={loading || !hasMinimumFormRequirements()}
                        className="primary-button"
                    >
                        {!loading ? (
                            'Comenzar aira 0'
                        ) : (
                            <JsonAnimation animation="buttonLoaderBlue" height="30px" width="105px" />
                        )}
                    </button>
                </div>
            </div>
        );
    };

    interface Data {
        label: string;
        icon: JSX.Element;
        value: string | JSX.Element;
    }

    const renderCancel = () => {
        return (
            <div>
                <ModalConfirmation
                    button="Cancel"
                    title="¿Deseas cancelar la creación y envío de esta evaluación?"
                    message="Al confirmar, se cancelará todo el proceso y perderás los datos ya ingresados."
                    cancel={{
                        fn: () => setModalContent('inspeccion'),
                        buttonText: 'No, deseo continuar con el proceso',
                    }}
                    success={{ fn: () => handleCancelar(), buttonText: 'Si, deseo cancelar' }}
                />
            </div>
        );
    };

    const handleEmpezarDeNuevo = () => {
        setFichaCatastralSeleccionada(null);
        setFichaPersonaSeleccionada(null);
        setFichasPersonas([]);
        setModalContent('inspeccion');
    };

    const renderConfirm = () => {
        return (
            <div>
                <ModalConfirmation
                    title="¿Todo correcto para generar aira 0?"
                    cancel={{
                        fn: handleEmpezarDeNuevo,
                        buttonText: 'No, empezar de nuevo',
                    }}
                    success={{ fn: () => handleFinishForm(), buttonText: 'Si, generar aira 0' }}
                    message="Al confirmar, crearás un CSV y un PDF disponible en el panel de evaluaciones y en el panel de tu cliente"
                    component={
                        <div>
                            <h5 className="text-bold">
                                Esta acción es irreversible, por favor verifica los datos ingresados:
                            </h5>
                            <div className="detalles-cliente-fisico-vinculados">
                                <div className="d-flex flex-row">
                                    <div className="flex-column w-50 p-1">
                                        <div className="detalles-cliente-fisico-vinculados-text mb-4">
                                            <p className="mb-1">Cliente: </p>
                                            <h5 className="text-value">
                                                <Building />
                                                {clienteSeleccionado?.label || '-'}
                                            </h5>
                                        </div>
                                        <div>
                                            {bypassDireccionClienteForm && (
                                                <div>
                                                    <p className="toggle-buttons-text-primary mb-1">Direccion:</p>
                                                    <p className="toggle-buttons-text-secondary">
                                                        No se cargaron datos de dirección
                                                    </p>
                                                </div>
                                            )}
                                            {fichaCatastralSeleccionada && (
                                                <div>
                                                    <p className="toggle-buttons-text-primary mb-1">Direccion:</p>
                                                    <div className="toggle-buttons">
                                                        <div
                                                            className="toggle-buttons-text"
                                                            key={fichaCatastralSeleccionada?.direccion}
                                                        >
                                                            <div>
                                                                <strong className="toggle-buttons-text-primary">
                                                                    {fichaCatastralSeleccionada?.direccion || ''}
                                                                </strong>
                                                                <p className="toggle-buttons-text-secondary">
                                                                    Superficie:{' '}
                                                                    {fichaCatastralSeleccionada?.superficie || ''}
                                                                    m2 ({fichaCatastralSeleccionada?.uso || ''})
                                                                    {fichaCatastralSeleccionada?.anioConstruccion &&
                                                                        `Año de construcción: ${
                                                                            fichaCatastralSeleccionada?.anioConstruccion ||
                                                                            ''
                                                                        }`}
                                                                </p>
                                                                <p className="toggle-buttons-text-secondary">
                                                                    {fichaCatastralSeleccionada.bloque
                                                                        ? 'Bloque: '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.bloque
                                                                        ? fichaCatastralSeleccionada.bloque + ' '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.planta
                                                                        ? 'Planta: '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.planta
                                                                        ? fichaCatastralSeleccionada.planta + ' '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.escalera
                                                                        ? 'Escalera: '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.escalera
                                                                        ? fichaCatastralSeleccionada.escalera + ' '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.puerta
                                                                        ? 'Puerta: '
                                                                        : ''}
                                                                    {fichaCatastralSeleccionada.puerta
                                                                        ? fichaCatastralSeleccionada.puerta + ' '
                                                                        : ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex-column w-50 p-3">
                                        {cif && (
                                            <div className="detalles-cliente-fisico-vinculados-text mb-3">
                                                <p>CIF:</p>
                                                <h5 className="text-value">
                                                    <Id /> {cif}
                                                </h5>
                                            </div>
                                        )}
                                        <div className="detalles-cliente-fisico-vinculados-text mb-3">
                                            <p>Ramo: </p>
                                            <h2 className="text-blue">
                                                {IconsRamos[iconoRamoSeleccionado] || ''}
                                                {nombreRamoSeleccionado || '-'}
                                            </h2>
                                        </div>
                                        <div className="detalles-cliente-fisico-vinculados-text mb-3">
                                            <p>Modelo: </p>
                                            <h5 className="text-value">
                                                <Template /> Aira 0
                                            </h5>
                                        </div>
                                        {(nombre || apellidos) && (
                                            <div className="detalles-cliente-fisico-vinculados-text mb-3">
                                                <p>Nombre y Apellidos: </p>
                                                <h5 className="text-value">{`${nombre || ''} ${apellidos}`}</h5>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    loadingSuccess={loading}
                />
            </div>
        );
    };

    const handleChangeCallBackNuevoCliente = () => {
        const cliente = clientes?.find((cliente) => cliente.id === clienteId);
        if (cliente) {
            setClienteSeleccionado({
                id: cliente.id,
                label: cliente.nombreComercio || `${cliente.nombre} ${cliente.apellido || ''}`,
                tipo: cliente.tipoCliente,
                cif: cliente.cif || '',
            });
        }
        dispatch(clearNuevoClienteId());
    };

    const renderPersonaCards = () => {
        return (
            <ToggleButtonGroup exclusive className="toggle-buttons-list-personas">
                {fichasPersonas &&
                    fichasPersonas.map((v: any, index: number) => {
                        return (
                            <ToggleButton
                                className="toggle-buttons-white"
                                disableRipple
                                key={v.ficha + index}
                                value={v.ficha + index}
                                onClick={() => setFichaPersonaSeleccionada({ ficha: v, index })}
                                sx={{ backgroundColor: 'white' }}
                            >
                                <div className="toggle-buttons-text">
                                    <p className="toggle-buttons-text-primary">{v.nombre.toLowerCase()}</p>
                                    <p className="toggle-buttons-text-secondary">{v.localidad}</p>
                                </div>
                                <CheckBox selected={isValueSelected(index, fichaPersonaSeleccionada?.index)} />
                            </ToggleButton>
                        );
                    })}
            </ToggleButtonGroup>
        );
    };

    const handleCancelarPersona = () => {
        setModalContent('inspeccion');
        setFichasPersonas([]);
        setFichaPersonaSeleccionada(null);
    };

    const renderFichasPersona = () => {
        return (
            <div className="nuevo-airacero-modal">
                <h5 style={{ marginBottom: '16px' }}>Selecciona tu cliente</h5>
                <p className="texto-personas">
                    Hemos encontrado varias personas con empresas a su cargo, selecciona una:
                </p>
                {renderPersonaCards()}
                <div className="nuevo-airacero-buttons">
                    <button className="secondary-button" onClick={handleCancelarPersona}>
                        Volver
                    </button>
                    <button
                        ref={confirmButtonRef}
                        onClick={() => setModalContent('confirm')}
                        disabled={!fichaPersonaSeleccionada}
                        className="primary-button"
                    >
                        {!loading ? (
                            'Continuar'
                        ) : (
                            <JsonAnimation animation="buttonLoaderBlue" height="30px" width="105px" />
                        )}
                    </button>
                </div>
            </div>
        );
    };

    const modalContents: any = {
        confirm: renderConfirm,
        cancel: renderCancel,
        inspeccion: renderModalContent,
        fichasPersona: renderFichasPersona,
        cliente: () => (
            <div>
                <NuevoCliente
                    onCancel={() => setModalContent('inspeccion')}
                    onSubmit={() => {
                        setModalContent('inspeccion');
                        if (!clienteSeleccionado.id) {
                            handleChangeCallBackNuevoCliente();
                        }
                    }}
                />
            </div>
        ),
    };

    return (
        <div>
            <button onClick={handleOpen} className="nuevo-airacero">
                <File />
                Aira 0
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {modalContents[modalContent]()}
            </Modal>
            {showToastMessage && (
                <ToastMessage
                    message={showToastMessage || ''}
                    handleClose={() => setShowToastMessage('')}
                    severity="error"
                />
            )}
        </div>
    );
};

export default NuevoAiraCero;
export function generarLabelCliente(c: {
    id: any;
    nombre: any;
    movil: any;
    email: any;
    direccion: any;
    dni: any;
    nombreFiscal: any;
    cif: any;
    nombreComercio: any;
    telefonoEmpresa: any;
    agenteCreador: any;
    tipoCliente: any;
    activo: boolean;
    apellido: any;
}): any {
    if (determinarTipoCliente(c.tipoCliente) === 1) {
        //FISICO
        let nombre = c.nombre || c.nombreComercio || c.dni;
        if (nombre === null || nombre === undefined || nombre === '') {
            nombre = '-';
        }
        return nombre;
    } else {
        //JURIDICO
        let nombre = c.nombreFiscal || c.nombreComercio || c.cif;
        if (nombre === null || nombre === undefined || nombre === '') {
            nombre = '-';
        }
        return nombre;
    }
}

function determinarTipoCliente(tipo_cliente_id: any) {
    return tipo_cliente_id != null ? tipo_cliente_id : 1;
}
