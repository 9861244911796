import { Modal } from '@mui/material';
import React from 'react';
import './ModalEliminar.scss';

interface IPropsModalEliminar {
    open: boolean;
    onDelete: () => void;
    onCancelDelete: () => void;
    titulo: string;
    descripcion: string;
}

const ModalEliminar: React.FunctionComponent<IPropsModalEliminar> = ({
    open,
    onDelete,
    onCancelDelete,
    descripcion,
    titulo,
}) => {
    return (
        <Modal sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={open}>
            <div className="modaleliminar">
                <h1>¿Eliminar {titulo}?</h1>
                <p>{descripcion}</p>
                <div className="modaleliminar-actions">
                    <button className="delete-button" onClick={onDelete}>
                        Eliminar {titulo}
                    </button>
                    <button className="primary-button" onClick={onCancelDelete}>
                        Conservar {titulo}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalEliminar;
