import {
    GetAvatarPDF,
    GetLopdPDF,
    GetMapaCatastralPDF,
    GetPDF,
    getAdjunto,
    getZipInspeccion,
    processAira0CSV,
    processAira0PDF,
} from './InspeccionesAPI';
import * as R from 'ramda';

export const downloadPDF = async (id: number) => {
    const pdf = await GetPDF(id);
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
};

export const downloadAdjuntos = async (arn: string, contentType: string, fileName: string) => {
    const adjunto = await getAdjunto(arn);
    const anchorHiddenElement = document.createElement('a');
    const blob = new Blob([adjunto], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    anchorHiddenElement.href = url;
    anchorHiddenElement.download = fileName;
    document.body.appendChild(anchorHiddenElement);
    anchorHiddenElement.click();
    window.URL.revokeObjectURL(url);
    anchorHiddenElement.remove();
};

export const downloadZipPdf = async (inspeccionId: number, refCatastral: string, ramoId: number, esAira0: boolean) => {
    const adjunto = await getZipInspeccion(inspeccionId, refCatastral, ramoId, esAira0);
    const anchorHiddenElement = document.createElement('a');
    const blob = new Blob([adjunto], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    anchorHiddenElement.href = url;
    anchorHiddenElement.download = `inspeccion-${inspeccionId}.zip`;
    document.body.appendChild(anchorHiddenElement);
    anchorHiddenElement.click();
    window.URL.revokeObjectURL(url);
    anchorHiddenElement.remove();
};

export const downloadPDFAira0 = async (id: number) => {
    const data = await processAira0PDF(id);
    const anchorHiddenElement = document.createElement('a');
    anchorHiddenElement.hidden = true;
    const url = window.URL.createObjectURL(data);
    anchorHiddenElement.href = url;
    anchorHiddenElement.download = `Evaluacion${id}-Aira0.pdf`;
    document.body.appendChild(anchorHiddenElement);
    anchorHiddenElement.click();
    window.URL.revokeObjectURL(url);
    anchorHiddenElement.remove();
};

const toCsvData = (data: any) => {
    return data.map((d: any, index: number) => {
        const tituloBloque = `${index > 0 ? '\n' : ''}${d.titulo} \n \n`;
        const preguntasOrdenadas = d.preguntas.sort((a: any, b: any) => a.ordenPregunta - b.ordenPregunta);
        const preguntasBloque = preguntasOrdenadas.map((p: any) => {
            return `${p.titulo}; ${p.valor} \n`;
        });
        const pepe = [tituloBloque, ...preguntasBloque];
        return pepe;
    });
};

export const downloadCSVAira0 = async (id: number) => {
    const data = await processAira0CSV(id);
    const sortedData = data.sort((a: any, b: any) => a.ordenBloque - b.ordenBloque);
    const lineas: BlobPart[] = toCsvData(sortedData) || [];
    const file = new File(R.flatten(lineas), `Evaluacion${id}-Aira0.csv`, { type: 'text/csv' });
    const anchorHiddenElement = document.createElement('a');
    anchorHiddenElement.hidden = true;
    const url = window.URL.createObjectURL(file);
    anchorHiddenElement.href = url;
    anchorHiddenElement.download = `Evaluacion${id}-Aira0.csv`;
    document.body.appendChild(anchorHiddenElement);
    anchorHiddenElement.click();
    window.URL.revokeObjectURL(url);
    anchorHiddenElement.remove();
};

export const downloadAvatarPDF = async (id: number) => {
    const pdf = await GetAvatarPDF(id);
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
};

export const downloadLopdPDF = async (id: number) => {
    const pdf = await GetLopdPDF(id);
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
};

export const downloadMapaCatastralPDF = async (id: number) => {
    const pdf = await GetMapaCatastralPDF(id);
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
};
