import React from 'react';

interface Props {
    background?: string;
}

export const IconClienteJuridico = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 14H14"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 5.33334H6.66667"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 8H6.66667"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 10.6667H6.66667"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33333 5.33334H10"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33333 8H10"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33333 10.6667H10"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.33333 14V3.33333C3.33333 2.97971 3.4738 2.64057 3.72385 2.39052C3.9739 2.14048 4.31304 2 4.66666 2H11.3333C11.6869 2 12.0261 2.14048 12.2761 2.39052C12.5262 2.64057 12.6667 2.97971 12.6667 3.33333V14"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
