import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RamosSync } from '../inspecciones/inspeccionesSlice';
import ModeloCard from './ModeloCard';
import { ModeloInterface, RamosConModeloSync, modelListSelector } from './ModeloSlice';
import './ModelosFeature.scss';
import NuevoModelo from './NuevoModelo';
import NuevoRamo from './NuevoRamo';
import SideBar from './SideBar';
import { Plus } from 'tabler-icons-react';
import Loader from '../../components/Loader/Loader';
import { useLocation } from 'react-router-dom';

const ModelosFeature = () => {
    const [selectedModel, setSelectedModel] = useState<ModeloInterface | undefined>(undefined);
    const [showSidebar, setShowSidebar] = useState(false);
    const models = useSelector(modelListSelector);
    const dispatch = useDispatch();
    const location: any = useLocation();

    const onModeloCardClickHandler = (ramoCustomId: number) => {
        const selected = models.find((model) => model.id === ramoCustomId);
        setSelectedModel(selected);
        setShowSidebar(true);
    };

    useEffect(() => {
        const idAseguradora = localStorage.getItem('idAseguradora');
        /* dispatch(ModelosSync()); */
        dispatch(RamosSync());
        idAseguradora && dispatch(RamosConModeloSync(Number(idAseguradora)));
    }, []);

    useEffect(() => {
        if (!selectedModel && location.state && location.state.ramoId) {
            const selected = models.find((model) => model.id === location.state.ramoId);
            setSelectedModel(selected);
            setShowSidebar(true);
            window.history.replaceState({}, '');
        }
    }, [models]);

    const onSidebarCloseHandler = () => {
        setShowSidebar(false);
    };

    const cards = () => {
        return models.map((model) => (
            <ModeloCard
                key={model.id}
                icono={model.icono}
                active={model.active}
                nombre={model.nombre}
                amountModelsAvailable={model.amountModelsAvailable}
                lastUpdated={model.lastUpdated}
                ramoCustomId={model.id}
                onClick={onModeloCardClickHandler}
            />
        ));
    };

    return (
        <div className="modelos">
            <div className="modelos-title-bar">
                <h3 className="modelos-title">Modelos</h3>
                <div className="modelos-title-actions">
                    <NuevoRamo />
                    <NuevoModelo selectedModelId={undefined} />
                </div>
            </div>
            <div className="modelos-construction">
                {cards()}
                <NuevoRamo bigButton={true} />
            </div>
            <div>
                <SideBar show={showSidebar} modelo={selectedModel} onClose={onSidebarCloseHandler} />
            </div>
        </div>
    );
};

export default ModelosFeature;
