import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './DropdownMenu.scss';

interface DropdownMenuProps {
    text?: string;
    icon: React.ReactElement;
    children?: React.ReactNode;
    onlyIcon?: boolean;
    direction?: string;
    contentWidth?: string;
    buttonPadding?: string;
}

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = (props) => {
    const [open, setOpen] = useState(false);

    const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
        setOpen((prevState) => !prevState);
        e.stopPropagation();
    };

    const renderButton = () => {
        if (props.onlyIcon) {
            return <div onClick={(e) => handleClick(e)}>{props.icon}</div>;
        } else {
            return (
                <button
                    className={`dropdownmenu-button ${open ? 'dropdownmenu-button-active' : ''}`}
                    onClick={handleClick}
                    style={{ padding: props.buttonPadding || '8px 16px 8px 24px' }}
                >
                    {props.text ? props.text : null}
                    {props.icon}
                </button>
            );
        }
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className="dropdownmenu-container">
                {renderButton()}
                <div
                    className={`dropdownmenu-content
                    ${props.onlyIcon ? 'dropdownmenu-content-left' : ''} 
                    ${props.direction === 'right' ? 'dropdownmenu-content-right' : ''}
                    ${open ? 'show' : ''}`}
                    style={{ width: props.contentWidth || '300px' }}
                >
                    {props.children}
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default DropdownMenu;
