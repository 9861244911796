import * as React from 'react';
import { Search } from 'tabler-icons-react';
import './QuickSearch.scss';

interface QuickSearchToolBar {
    onChange?: (searchTerm: string) => unknown;
    placeholder?: string;
    styleInputBackground?: 'grey50';
}

const QuickSearch: React.FunctionComponent<QuickSearchToolBar> = (props) => {
    const [value, setValue] = React.useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        props.onChange && props.onChange(newValue);
    };

    return (
        <div className="search-filter">
            <div className="search-filter-icon">
                <Search />
            </div>
            <input
                type="text"
                className={`search-filter-input ${props.styleInputBackground ? props.styleInputBackground : ''}`}
                value={value}
                onChange={handleChange}
                placeholder={props.placeholder}
            />
        </div>
    );
};

export default QuickSearch;
