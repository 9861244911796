import React from 'react';

const useHubspotChat = (portalId: string) => {
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [activeConversation, setActiveConversation] = React.useState(false);
    const eventRef: any = React.useRef(null);

    React.useEffect(() => {
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.hsConversationsOnReady = [
            () => {
                setHasLoaded(true);
            },
        ];

        const script = document.createElement('script');
        script.src = `//js.hs-scripts.com/${portalId}.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.hsConversationsOnReady = [];
        };
    }, []);

    React.useEffect(() => {
        eventRef.current = (payload: any) => {
            setActiveConversation(payload.conversation.conversationId);
        };

        if (hasLoaded) {
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.HubSpotConversations.on('conversationStarted', eventRef.current);
        }

        return () => {
            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            window.HubSpotConversations.off('conversationStarted', eventRef.current);
        };
    }, [hasLoaded]);

    const openHandler = React.useCallback(() => {
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.HubSpotConversations.widget.open();
    }, [hasLoaded]);

    const closeHandler = React.useCallback(() => {
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.HubSpotConversations.widget.close();
    }, [hasLoaded]);

    return {
        hasLoaded,
        activeConversation,
        openHandler,
        closeHandler,
    };
};

export default useHubspotChat;
