import React from 'react';
import { Plus } from 'tabler-icons-react';
import './AddButton.scss';

interface IPropsAddButton {
    text: string;
    onClick: () => void;
}

const AddButton = ({ text, onClick }: IPropsAddButton) => {
    return (
        <button className="add-button" onClick={onClick}>
            <Plus />
            <p>{text}</p>
        </button>
    );
};

export default AddButton;
