import React, { useEffect, useState } from 'react';
import { getApiStatus } from '../inspecciones/InspeccionesAPI';

const ApiStatus = () => {
    const [status, setStatus] = useState([{ api: '', url: '' }]);

    useEffect(() => {
        const getStatus = async () => {
            const results = await getApiStatus();
            if (results && results.length) {
                setStatus(results);
            }
        };

        getStatus();
    }, []);

    const renderStatus = () =>
        status.map((s) => (
            <p key={s.api}>
                <b>{s.api}</b>: {s.url}
            </p>
        ));

    return <div>{renderStatus()}</div>;
};

export default ApiStatus;
