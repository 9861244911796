import React from 'react';

const PreguntaFotosActive = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.6257 9.44592C21.1826 9.44592 20.0085 10.6436 20.0085 12.1156C20.0085 13.5877 21.1826 14.7853 22.6257 14.7853C24.0687 14.7853 25.2428 13.5877 25.2428 12.1156C25.2428 10.6435 24.0688 9.44592 22.6257 9.44592ZM22.6257 13.7493C21.7426 13.7493 21.0242 13.0165 21.0242 12.1156C21.0242 11.2148 21.7426 10.482 22.6257 10.482C23.5087 10.482 24.2271 11.2148 24.2271 12.1156C24.2271 13.0164 23.5087 13.7493 22.6257 13.7493Z"
                fill="#86AFFF"
            />
            <path
                d="M22.6433 26.0286C22.6306 25.997 22.6148 25.967 22.5966 25.939C22.5783 25.9105 22.5569 25.8841 22.5336 25.8603C22.5102 25.8364 22.4843 25.8147 22.4564 25.796C22.429 25.7774 22.3995 25.7613 22.3685 25.7484C22.3381 25.7354 22.3061 25.7251 22.2736 25.7188C22.2081 25.7054 22.141 25.7054 22.0755 25.7188C22.043 25.7251 22.011 25.7354 21.9806 25.7484C21.9496 25.7613 21.9201 25.7774 21.8927 25.796C21.8648 25.8147 21.8389 25.8364 21.8155 25.8603C21.7922 25.8841 21.7708 25.9105 21.7526 25.939C21.7343 25.967 21.7185 25.997 21.7058 26.0286C21.6932 26.0597 21.683 26.0923 21.6769 26.1255C21.6703 26.1586 21.6667 26.1928 21.6667 26.2265C21.6667 26.2602 21.6703 26.2944 21.6769 26.3275C21.683 26.3607 21.6932 26.3933 21.7058 26.4244C21.7185 26.456 21.7343 26.486 21.7526 26.514C21.7708 26.5425 21.7922 26.5689 21.8155 26.5927C21.8272 26.6046 21.8395 26.6159 21.8523 26.6267C21.8652 26.6374 21.8787 26.6476 21.8927 26.657C21.9201 26.6756 21.9496 26.6917 21.9806 26.7046C22.011 26.7176 22.043 26.7279 22.0755 26.7342C22.108 26.7409 22.1416 26.7445 22.1746 26.7445C22.2076 26.7445 22.2411 26.7409 22.2736 26.7342C22.3061 26.7279 22.3381 26.7176 22.3685 26.7046C22.3995 26.6917 22.429 26.6756 22.4564 26.657C22.4704 26.6476 22.4839 26.6374 22.4969 26.6267C22.5097 26.6159 22.522 26.6046 22.5336 26.5927C22.5569 26.5689 22.5783 26.5425 22.5966 26.514C22.6148 26.486 22.6306 26.456 22.6433 26.4244C22.656 26.3933 22.6661 26.3607 22.6727 26.3275C22.6793 26.2944 22.6824 26.2602 22.6824 26.2265C22.6824 26.1928 22.6793 26.1586 22.6727 26.1255C22.6661 26.0923 22.656 26.0596 22.6433 26.0286Z"
                fill="#86AFFF"
            />
            <path
                d="M14.6513 5.52923C14.6386 5.49815 14.6229 5.4681 14.6046 5.43961C14.5863 5.41164 14.565 5.3847 14.5416 5.36087C14.5183 5.33704 14.4924 5.31529 14.4645 5.29664C14.437 5.27799 14.4076 5.26193 14.3766 5.24898C14.3461 5.23603 14.3141 5.22619 14.2816 5.21945C14.2161 5.20598 14.1491 5.20598 14.0836 5.21945C14.0511 5.22619 14.0191 5.23603 13.9881 5.24898C13.9577 5.26193 13.9282 5.27799 13.9008 5.29664C13.8729 5.31529 13.847 5.33704 13.8236 5.36087C13.8002 5.3847 13.7789 5.41164 13.7601 5.43961C13.7418 5.4681 13.7266 5.49815 13.7139 5.52923C13.7012 5.56031 13.6911 5.59294 13.685 5.6261C13.6784 5.65977 13.6748 5.69344 13.6748 5.72763C13.6748 5.7613 13.6784 5.79497 13.685 5.82864C13.6911 5.86179 13.7012 5.89443 13.7139 5.92551C13.7266 5.95659 13.7418 5.98664 13.7601 6.01513C13.7789 6.0431 13.8002 6.07004 13.8236 6.09387C13.847 6.1177 13.8729 6.13945 13.9008 6.1581C13.9282 6.17675 13.9577 6.19281 13.9881 6.20576C14.0191 6.21871 14.0511 6.22855 14.0836 6.23529C14.1161 6.24202 14.1496 6.24565 14.1826 6.24565C14.2156 6.24565 14.2491 6.24202 14.2816 6.23529C14.3141 6.22855 14.3461 6.21871 14.3766 6.20576C14.4076 6.19281 14.437 6.17675 14.4645 6.1581C14.4924 6.13945 14.5183 6.1177 14.5416 6.09387C14.565 6.07004 14.5863 6.0431 14.6046 6.01513C14.6229 5.98664 14.6386 5.95659 14.6513 5.92551C14.664 5.89443 14.6742 5.86179 14.6803 5.82864C14.6869 5.79497 14.6904 5.7613 14.6904 5.72763C14.6904 5.69344 14.6869 5.65977 14.6803 5.6261C14.6742 5.59294 14.664 5.56031 14.6513 5.52923Z"
                fill="#86AFFF"
            />
            <path
                d="M26.4977 5.21144C26.1932 3.89832 25.0352 2.91797 23.6562 2.91797H7.72332C5.3036 2.91792 3.33496 4.92612 3.33496 7.39447V23.6475C3.33496 25.2617 4.60028 26.5793 6.17221 26.6245C6.4767 27.9376 7.63471 28.918 9.01373 28.918H26.4145C28.0249 28.918 29.335 27.5815 29.335 25.9388V22.03V8.18834C29.335 6.57419 28.0696 5.25666 26.4977 5.21144ZM28.3193 25.939C28.3193 27.0104 27.4648 27.882 26.4145 27.882H9.01373C8.20072 27.882 7.50542 27.3596 7.23257 26.6267C7.1529 26.4128 7.10892 26.1811 7.10892 25.939V25.5907V23.1908L12.9348 13.1141C13.1122 12.8073 13.4285 12.6282 13.7755 12.6339C14.1247 12.64 14.4327 12.8303 14.5997 13.1431L17.1081 17.8417L20.7542 24.6714C20.8464 24.844 21.0207 24.9417 21.2007 24.9417C21.2827 24.9417 21.366 24.9214 21.4431 24.8785C21.6894 24.7417 21.7803 24.4271 21.6462 24.1758L20.463 21.9595C20.9825 21.9839 21.4935 21.8123 21.9004 21.468L24.1841 19.5355C24.5503 19.2255 25.0911 19.2392 25.4419 19.5672L25.5611 19.6787L26.5767 20.6284L28.3193 22.2579V25.939ZM28.3193 20.8524L26.5767 19.2228L25.5611 18.434C24.8993 18.1516 24.1125 18.2499 23.5356 18.738L21.2519 20.6706C21.0007 20.8831 20.6728 20.969 20.3523 20.9062C20.0317 20.8434 19.7584 20.6396 19.6023 20.3473L18 17.3461L17.1054 15.6702V15.6702L15.4917 12.6475C15.1475 12.0029 14.5124 11.6105 13.7929 11.5979C13.0736 11.5864 12.4256 11.9554 12.06 12.5877L7.10887 21.1513V8.18834C7.10887 7.11692 7.96337 6.24525 9.01368 6.24525H12.1516C12.432 6.24525 12.6594 6.01333 12.6594 5.72723C12.6594 5.44113 12.432 5.20921 12.1516 5.20921H9.01368C7.40335 5.20921 6.09325 6.54565 6.09325 8.18834V23.0493V25.5831C5.1186 25.4988 4.35059 24.6632 4.35059 23.6475V7.39447C4.35059 5.49734 5.86361 3.95395 7.72332 3.95395H23.6562C24.4692 3.95395 25.1645 4.47637 25.4373 5.20927H16.2141C15.9336 5.20927 15.7062 5.44118 15.7062 5.72728C15.7062 6.01339 15.9336 6.2453 16.2141 6.2453H25.561L26.5766 6.25287C27.5513 6.3371 28.3193 7.17266 28.3193 8.18834V20.8524Z"
                fill="#0046CF"
            />
        </svg>
    );
};

export default PreguntaFotosActive;
