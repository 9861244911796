import { Autocomplete, Modal, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './ModalPantallaBienvenida.scss';
import { ChevronDown, Edit } from 'tabler-icons-react';
import * as R from 'ramda';
import DragAndDrop from '../../components/dragAndDrop/DragAndDrop';
import PreguntasLibresCsv from '../../components/PreguntasLibresCsv/PreguntasLibresCsv';

interface input {
    placeholder: string;
    required: boolean;
    key: string;
    type: string;
    options?: any[];
}

interface option {
    title: string;
    inputs: input[];
}

interface IPropsEditarSubmodulo {
    titulo: string;
    buttonText?: string;
    options: option[];
    onSubmit: (e: any) => Promise<boolean>;
    onClose: (e: any) => void;
    defaultValues?: any;
    forceOpen?: boolean;
}

const EditarSubmodulo2 = ({
    titulo,
    options,
    buttonText = 'Confirmar',
    onSubmit,
    defaultValues,
    forceOpen,
    onClose,
}: IPropsEditarSubmodulo) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState<any>();

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [forceOpen]);

    const setDefaultvalues = () => {
        if (defaultValues) {
            const opciones = defaultValues.opciones
                ? defaultValues.opciones.length
                    ? defaultValues.opciones.reduce((acc: any, e: string, index: number) => {
                          return {
                              ...acc,
                              [`opcion${index + 1}`]: e,
                          };
                      }, {})
                    : {}
                : {};
            setValues({
                tituloPregunta: defaultValues.subtitulo,
                opcionEscrita: defaultValues.subtitulo,
                opcionCargaArchivos: defaultValues.subtitulo,
                ...opciones,
                tituloBienvenida: defaultValues.tituloBienvenida,
                fotosLibres: defaultValues.cantidad,
                subtituloBienvenida: defaultValues.subtituloBienvenida,
            });
        }
    };

    useEffect(() => {
        setDefaultvalues();
    }, [defaultValues, open]);

    const canConfirm = () => {
        const requiredInputs = R.flatten(options.map((o) => o.inputs))
            .filter((i) => i.required)
            .map((k) => k.key);
        if (values) {
            const valores = Object.entries(values).reduce((acc: any, [key, value]) => {
                return [...acc, { [key]: value }];
            }, []);
            const valoresObligatorios = valores.filter((d: any) => {
                return requiredInputs.find((e) => {
                    return d[e];
                });
            });

            return valoresObligatorios.length === requiredInputs.length;
        } else {
            return true;
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        onClose(values && defaultValues);
        setValues(null);
        setOpen(false);
    };

    const handleChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>, input: string) => {
        setValues({ ...values, [input]: e.target.value });
    };

    const handleDropDownChange = (v: any, key: string) => {
        setValues({ ...values, [key]: v });
    };

    const handleDragAndDropChange = (v: any, key: string) => {
        const optionValues = v.reduce((acc: any, v: any, index: number) => {
            return {
                ...acc,
                [`opcion${index + 1}`]: v[0],
            };
        }, {});

        setValues({ ...values, [key]: optionValues });
    };

    const handleDragAndDropRemove = (key: string) => {
        setValues({ ...values, [key]: null });
    };

    const renderInputs = (inputs: input[]) => {
        return inputs.map((i: input, index: number) => {
            if (i.type === 'text') {
                return (
                    <input
                        key={i.key + index}
                        value={values ? values[i.key] : ''}
                        onChange={(e) => handleChangeTextInput(e, i.key)}
                        placeholder={i.placeholder}
                    />
                );
            } else if (i.type === 'dropdown') {
                return (
                    <Autocomplete
                        value={values ? values[i.key] : ''}
                        key={i.key + index}
                        id={`dropdown-${i.key}`}
                        disableClearable={true}
                        popupIcon={<ChevronDown />}
                        options={i.options || []}
                        onChange={(e, v) => {
                            handleDropDownChange(v, i.key);
                        }}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                                label={!values ? i.placeholder : ''}
                                margin="normal"
                            />
                        )}
                    />
                );
            } else if (i.type === 'cargaCsv') {
                return (
                    <PreguntasLibresCsv
                        onChange={(e: any[]) => handleDragAndDropChange(e, i.key)}
                        onRemove={() => handleDragAndDropRemove(i.key)}
                    />
                );
            }
        });
    };

    const renderForm = () => {
        return (
            <div className="pantalla-bienvenida-modal-items">
                {options.map((o: option) => {
                    return (
                        <div key={o.title} className="pantalla-bienvenida-modal-items-item">
                            <h3>{o.title}</h3>
                            {renderInputs(o.inputs)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleSubmit = async () => {
        const results = await onSubmit(values);
        setOpen(results);
    };

    return (
        <div>
            <div className="pantalla-bienvenida-modal">
                <h1>{titulo.replace(/[\d_]+/g, ' ')}</h1>
                {renderForm()}
                <div className="pantalla-bienvenida-modal-actions">
                    <button className="secondary-button" onClick={handleClose}>
                        Cancelar
                    </button>
                    <button className="primary-button" disabled={!canConfirm()} onClick={handleSubmit}>
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditarSubmodulo2;
