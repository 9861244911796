import React from 'react';

const RamoHogar = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.9876 25.7785L24.0434 25.774V15.3674M21.8567 11.586C24.0073 13.5801 25.9392 15.3709 25.9392 15.3709H25.9465L21.8567 11.586ZM16.1823 6.23999L16.1089 6.25752C16.1089 6.25752 16.9301 7.01904 18.0982 8.10171L16.1823 6.23999Z"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.9441 7.27917L22.2238 7.28431L22.2318 11.0967"
                stroke="#4A88FF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3638 9.08165V7.23462H22.2131V11.7384"
                stroke="#4A88FF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5227 25.7773V17.4219H18.5785V25.7773"
                stroke="#4A88FF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.08008 15.3707L16.0506 6.25732L25.8809 15.3707"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.9519 13.9108V25.7772H24.0469V14.262"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RamoHogar;
