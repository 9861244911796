import { GridRowId } from '@mui/x-data-grid';
import React from 'react';
import { JsonAnimation } from '../JsonAnimation/JsonAnimation';
import ModalConfirmation from '../modal-confirmation/ModalConfirmation';

interface IModalEliminarInspeccionProps {
    id: GridRowId | null;
    onDelete: () => void;
    onCancel: () => void;
    loading?: boolean;
}

const ModalEliminarInspeccion: React.FunctionComponent<IModalEliminarInspeccionProps> = ({
    id,
    onCancel,
    onDelete,
    loading,
}) => {
    return (
        <ModalConfirmation
            button={`Eliminar evaluación`}
            message={`Al aceptar, se borraran todos los datos relacionados a la evaluación seleccionada.`}
            title={`¿Deseas eliminar la evaluación ${id}?`}
            disable={loading}
            cancel={{
                fn: () => onCancel(),
                buttonText: 'No',
            }}
            success={{
                fn: () => onDelete(),
                buttonText: loading ? (
                    <JsonAnimation animation="buttonLoaderWhite" height="50px" width="144px" />
                ) : (
                    'Si, eliminar evaluación'
                ),
            }}
        />
    );
};

export default ModalEliminarInspeccion;
