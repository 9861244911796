import React, { useState } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './DatePicker.scss';
import 'dayjs/locale/es';

interface IPropsDatePicker {
    defaultDate: Date | null;
    onSelect: (e: Date) => void;
}

const DatePicker = ({ defaultDate, onSelect }: IPropsDatePicker) => {
    const [selectedDate, setSelectedDate] = useState(defaultDate);

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        onSelect(date);
    };

    const renderDefaultDatePicker = () => {
        return (
            <LocalizationProvider
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                adapterLocale={'es'}
                dateAdapter={AdapterDayjs}
            >
                <StaticDatePicker
                    value={dayjs(selectedDate)}
                    disablePast
                    onChange={(newValue: any) => handleDateChange(newValue)}
                />
            </LocalizationProvider>
        );
    };
    return renderDefaultDatePicker();
};

export default DatePicker;
