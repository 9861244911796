import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertCircle } from 'tabler-icons-react';
import { useAppSelector } from '../../../app/hooks';
import { TipoCliente } from '../../../enums/TipoCliente';
import { validateCifFormat, validateDniFormat, validateEmail, validatePhone } from '../../../utlis';
import { clientesMap } from '../../inspecciones/inspeccionesSlice';
import { detalleCliente, RequestCliente } from '../ClientesSlice';
import './DetallesClienteFisico.scss';

const EditarCliente = (props: any) => {
    const cliente = useAppSelector(detalleCliente);
    const clienteId = useLocation().pathname.replace(/^.*\/(.*)\/.*\//, '');

    const [invalidDataFisico, setInvalidDataFisico] = useState({ movil: false, email: false, dni: false });
    const [invalidDataJuridico, setInvalidDataJuridico] = useState({
        telefonoEmpresa: false,
        email: false,
        cif: false,
    });
    const [inspecciones, setInspecciones] = useState<any>([]);
    const [editClient, setEditClient] = useState<any>({
        id: 0,
        nombre: '',
        movil: '',
        email: '',
        direccion: '',
        dni: '',
        nombreFiscal: '',
        cif: '',
        nombreComercio: '',
        telefonoEmpresa: '',
        agente_cliente_id: 0,
        tipo_cliente_id: TipoCliente.Fisico,
        activo: true,
    });

    useEffect(() => {
        setEditClient({
            ...editClient,
            id: parseInt(clienteId),
            nombre: cliente.nombre,
            movil: cliente.movil,
            email: cliente.email,
            direccion: cliente.direccion,
            dni: cliente.dni,
            nombreFiscal: cliente.nombreFiscal,
            cif: cliente.cif,
            nombreComercio: cliente.nombreComercio,
            telefonoEmpresa: cliente.telefonoEmpresa,
            tipo_cliente_id: cliente.tipoClienteId,
            agente_cliente_id: cliente.agenteClienteId,
        });
        setInspecciones(cliente.inspeccion);
    }, []);

    useEffect(() => {
        setEditClient({
            ...editClient,
            id: props.idCliente ? parseInt(props.idCliente) : parseInt(clienteId),
            nombre: cliente.nombre,
            movil: cliente.movil,
            email: cliente.email,
            direccion: cliente.direccion,
            dni: cliente.dni,
            nombreFiscal: cliente.nombreFiscal,
            cif: cliente.cif,
            nombreComercio: cliente.nombreComercio,
            telefonoEmpresa: cliente.telefonoEmpresa,
            tipo_cliente_id: cliente.tipoClienteId,
            agente_cliente_id: cliente.agenteClienteId,
        });
        setInspecciones(cliente.inspeccion);
    }, [cliente]);

    const handleChangeNombreCompleto = (nombre: string) => {
        setEditClient({ ...editClient, nombre: nombre });
    };

    const handleChangeDni = (dni: string) => {
        setEditClient({ ...editClient, dni: dni });
    };

    const handleChangeCif = (cif: string) => {
        setEditClient({ ...editClient, cif: cif });
    };

    const handleChangeNombreComercio = (nombreComercio: string) => {
        setEditClient({ ...editClient, nombreComercio: nombreComercio });
    };

    const handleChangeTelefonoEmpresa = (telefonoEmpresa: string) => {
        setEditClient({ ...editClient, telefonoEmpresa: telefonoEmpresa });
    };

    const handleChangeNombreFiscal = (nombreFiscal: string) => {
        setEditClient({ ...editClient, nombreFiscal: nombreFiscal });
    };

    const handleChangeMovil = (movil: string) => {
        setEditClient({ ...editClient, movil: movil });
    };

    const handleChangeEmail = (email: string) => {
        setEditClient({ ...editClient, email: email });
    };

    const handleChangeDireccion = (direccion: string) => {
        setEditClient({ ...editClient, direccion: direccion });
    };

    const validateFieldsClienteJuridico = (data: any) => {
        const isPhoneValid = data.telefonoEmpresa && !validatePhone(data.telefonoEmpresa);
        const isEmailValid = data.email && !validateEmail(data.email);
        const isCifValid = data.cif && !validateCifFormat(data.cif);

        setInvalidDataJuridico({ telefonoEmpresa: isPhoneValid, email: isEmailValid, cif: isCifValid });
    };

    const validateFieldsClienteFisico = (data: any) => {
        const isPhoneValid = data.movil && !validatePhone(data.movil);
        const isEmailValid = data.email && !validateEmail(data.email);
        const isDniValid = data.dni && !validateDniFormat(data.dni);

        setInvalidDataFisico({ movil: isPhoneValid, email: isEmailValid, dni: isDniValid });
    };

    const handleSubmit = (editClient: any) => {
        if (
            invalidDataFisico.dni ||
            invalidDataFisico.email ||
            invalidDataFisico.movil ||
            invalidDataJuridico.cif ||
            invalidDataJuridico.email ||
            invalidDataJuridico.telefonoEmpresa
        )
            return;
        props.onEditSubmit(editClient);
        props.onModalClose();
    };

    const renderFormFisico = () => {
        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Editar cliente</h3>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Nombre y apellido</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa el nombre y apellido"
                        value={editClient.nombre}
                        onChange={(nombre) => handleChangeNombreCompleto(nombre.target.value)}
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">
                        Documento de identificación (DNI / NIE / NIF)
                    </span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa el DNI, NIE o NIF"
                        value={editClient.dni}
                        onChange={(nombre) => handleChangeDni(nombre.target.value)}
                        onBlur={() => validateFieldsClienteFisico(editClient)}
                    />
                    {invalidDataFisico.dni ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de documento inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Móvil</span>
                    <input
                        className="modal-input"
                        type="tel"
                        placeholder="Ingresa el móvil"
                        value={editClient.movil}
                        onChange={(movil) => handleChangeMovil(movil.target.value)}
                        onBlur={() => validateFieldsClienteFisico(editClient)}
                    />
                    {invalidDataFisico.movil ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de teléfono inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Email</span>
                    <input
                        className="modal-input"
                        type="email"
                        placeholder="Ingresa el email"
                        value={editClient.email}
                        onChange={(email) => handleChangeEmail(email.target.value)}
                        onBlur={() => validateFieldsClienteFisico(editClient)}
                    />
                    {invalidDataFisico.email ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de email inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Dirección (opcional)</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa calle, número, portal, piso y puertal"
                        value={editClient.direccion}
                        onChange={(direccion) => handleChangeDireccion(direccion.target.value)}
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={() => props.onModalClose()}>
                        Cancelar
                    </button>
                    <button className="primary-button" onClick={() => handleSubmit(editClient)} disabled={false}>
                        Guardar edición
                    </button>
                </div>
            </>
        );
    };

    const renderFormJuridico = () => {
        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Editar cliente</h3>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Nombre Fiscal (SL)</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingrese el nombre fiscal"
                        value={editClient.nombreFiscal}
                        onChange={(nombreFiscal) => handleChangeNombreFiscal(nombreFiscal.target.value)}
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Código de Identificación (CIF)</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa el CIF"
                        value={editClient.cif}
                        onChange={(cif) => handleChangeCif(cif.target.value)}
                        onBlur={() => validateFieldsClienteJuridico(editClient)}
                    />
                    {invalidDataJuridico.cif ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de documento inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Nombre Comercial</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa el nombre comercial"
                        value={editClient.nombreComercio}
                        onChange={(nombreComercio) => handleChangeNombreComercio(nombreComercio.target.value)}
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Teléfono empresa (preferentemente móvil)</span>
                    <input
                        className="modal-input"
                        type="tel"
                        placeholder="Ingresa el teléfono empresa"
                        value={editClient.telefonoEmpresa}
                        onChange={(telefonoEmpresa) => handleChangeTelefonoEmpresa(telefonoEmpresa.target.value)}
                        onBlur={() => validateFieldsClienteJuridico(editClient)}
                    />
                    {invalidDataJuridico.telefonoEmpresa ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de teléfono inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Email</span>
                    <input
                        className="modal-input"
                        type="email"
                        placeholder="Ingresa el email"
                        value={editClient.email}
                        onChange={(email) => handleChangeEmail(email.target.value)}
                        onBlur={() => validateFieldsClienteJuridico(editClient)}
                    />
                    {invalidDataJuridico.email ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de email inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Dirección Fiscal</span>
                    <input
                        className="modal-input"
                        type="text"
                        placeholder="Ingresa calle, número, portal, piso y puertal"
                        value={editClient.direccion}
                        onChange={(direccion) => handleChangeDireccion(direccion.target.value)}
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={() => props.onModalClose()}>
                        Cancelar
                    </button>
                    <button className="primary-button" onClick={() => handleSubmit(editClient)} disabled={false}>
                        Guardar edición
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="nuevo-cliente-modal">
            {cliente.tipoClienteId === 1 ? renderFormFisico() : renderFormJuridico()}
        </div>
    );
};

export default EditarCliente;
