import { Switch, SwitchProps, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

const StyledSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#D9D7E1' : '#0046CF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

interface IPropsSwitch {
    defaultValue: boolean;
    onChange: (e: boolean) => void;
}

const CustomSwitch: React.FunctionComponent<IPropsSwitch> = ({ defaultValue, onChange }) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(defaultValue);
    }, [defaultValue]);

    const handleSwitchChange = (e: any) => {
        onChange(!checked);
        setChecked(!checked);
    };

    return <StyledSwitch checked={checked} onChange={handleSwitchChange} />;
};

export default CustomSwitch;
