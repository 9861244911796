import React, { useState } from 'react';
import './Tabs.scss';

interface tab {
    title: string;
    id: number;
}

interface IPropsTabs {
    onChange: (id: number) => void;
    tabs: tab[];
}

const Tabs: React.FunctionComponent<IPropsTabs> = ({ tabs, onChange }) => {
    const [selectedTab, setSelectedTab] = useState<number>(tabs[0].id);

    const handleTabChange = (id: number) => {
        setSelectedTab(id);
        onChange(id);
    };

    const renderTabs = () => {
        return tabs.map((tab) => {
            return (
                <button
                    key={tab.id}
                    onClick={() => handleTabChange(tab.id)}
                    className={`tabs-container-tab${selectedTab === tab.id ? '-active' : ''}`}
                >
                    {tab.title}
                </button>
            );
        });
    };

    return <div className="tabs-container">{renderTabs()}</div>;
};

export default Tabs;
