import OnBoardingComercio from '../assets/svg/onBoardingScreens/OnBoardingComercio.svg';
import OnBoardingIndustria from '../assets/svg/onBoardingScreens/OnBoardingIndustria.svg';
import OnBoardingHogar from '../assets/svg/onBoardingScreens/OnBoardingHogar.svg';

export const getOnBoardingSVG = (ramoId?: number): string => {
    switch (ramoId || 0) {
        case 1: // Hogar
            return OnBoardingHogar;
        case 2: // Comercio
            return OnBoardingIndustria;
        case 3: // Industria
            return OnBoardingComercio;
        default:
            return OnBoardingHogar;
    }
};
