import { Modal } from '@mui/material';
import { identity } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InfoCircle, X } from 'tabler-icons-react';
import {
    clearCsvRowsWithError,
    ClientesSync,
    DetalleClienteSync,
    EditarClienteSync,
    getClienteById,
    removeRowWithError,
} from '../../features/clientes/ClientesSlice';
import EditarCliente from '../../features/clientes/detalles/EditarCliente';
import { setCliente } from '../../features/inspecciones/inspeccionesSlice';
import BulletItems from '../BulletItems/BulletItems';
import './DataGrid.scss';

const RowsWithError = (props: any) => {
    const [rowLimit, setRowLimit] = useState(4);
    const [openEdit, setOpenEdit] = useState(false);
    const [clienteId, setClienteId] = useState<number | null>(null);
    const dispatch = useDispatch();

    const renderRows = (rows: any) => {
        return rows.map((r: any, index: number) => {
            if (index < rowLimit) {
                return (
                    <BulletItems
                        key={r.fila}
                        valueColor="#EA3A44"
                        item={{ key: `Fila ${r.fila}`, values: r.datos }}
                        onClick={() => handleEditClick(r.clienteId)}
                    />
                );
            }
        });
    };

    const handleEditClick = (id: number) => {
        setClienteId(id);
        setOpenEdit(true);
        dispatch(DetalleClienteSync({ id }));
    };

    const onEditSubmit = (editClient: any) => {
        dispatch(EditarClienteSync(editClient));
        dispatch(removeRowWithError(editClient.id));
        dispatch(ClientesSync());
        setOpenEdit(false);
    };

    return (
        <div className="datagrid-rows-with-error">
            <div className="datagrid-rows-with-error-header">
                <div className="datagrid-rows-with-error-header-title">
                    <InfoCircle />
                    <h1>Clientes importados con errores</h1>
                </div>
                <button onClick={() => dispatch(clearCsvRowsWithError())}>
                    <X />
                </button>
            </div>
            <p>No hemos podido validar los siguientes emails, teléfonos y/o documentos.</p>
            <div className="datagrid-rows-with-error-rows">{renderRows(props.rows)}</div>
            {rowLimit <= 4 && props.rows && props.rows.length > 4 ? (
                <p className="datagrid-rows-with-error-show-more" onClick={() => setRowLimit(props.rows.length)}>
                    Ver todos ({props.rows.length})
                </p>
            ) : null}
            <Modal open={openEdit} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <EditarCliente
                        onEditSubmit={onEditSubmit}
                        onModalClose={() => setOpenEdit(false)}
                        idCliente={clienteId}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default RowsWithError;
