import React from 'react';
import './Nota.scss';
import { CalendarEvent } from 'tabler-icons-react';
import { getDateFormated } from '../../utlis';

interface IPropsNota {
    nota: string;
    nombre: string;
    fechaCreacion: Date;
}

const Nota = ({ nota, nombre, fechaCreacion }: IPropsNota) => {
    const hora = new Date(fechaCreacion).toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return (
        <div className="nota-agente">
            <div className="nota-agente-header">
                <CalendarEvent />
                <p>
                    {hora} {getDateFormated(fechaCreacion)} - {nombre}
                </p>
            </div>
            <p className="nota-agente-texto">{nota}</p>
        </div>
    );
};

export default Nota;
