import React from 'react';
import './ErrorMessage.scss';

interface ErrorMessageProps {
    title: string;
    subtitle: string;
}

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({ title, subtitle }) => {
    return (
        <div className="error-message">
            <p className="error-message-title">{title}</p>
            <p className="error-message-subtitle">{subtitle}</p>
        </div>
    );
};

export default ErrorMessage;
