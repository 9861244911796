import { none } from 'ramda';
import React from 'react';
import { LetterF } from 'tabler-icons-react';

const BulletItems = (props: any) => {
    const renderValues = (itemValues: any) => {
        const valuesArr = itemValues && Object.values(itemValues);
        if (valuesArr && valuesArr.length) {
            return valuesArr.map((v: any, index: number) => (
                <span style={{ color: props.valueColor ? props.valueColor : '#171717' }} key={v}>{`${v}${
                    index !== valuesArr.length - 1 ? ', ' : ''
                }`}</span>
            ));
        }
    };

    const renderBullets = () => {
        return (
            <li>
                {props.item.key}: {renderValues(props.item.values)}
                <span
                    onClick={() => props.onClick()}
                    style={{ color: '#0046CF', marginLeft: '8px', fontWeight: 600, cursor: 'pointer' }}
                >
                    Editar
                </span>
            </li>
        );
    };

    return <ul style={{ marginBottom: '4px', listStyle: 'none' }}>{renderBullets()}</ul>;
};

export default BulletItems;
