import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import * as loader from '../../assets/loader.json';
import * as buttonLoaderBlue from '../../assets/buttonLoaderBlue.json';
import * as buttonLoaderWhite from '../../assets/buttonLoaderWhite.json';
import * as construction from '../../assets/under-construction.json';
import * as loaderUpload from '../../assets/loaderUpload.json';
import * as success from '../../assets/success.json';
import * as error from '../../assets/error.json';

export const JsonAnimation = ({ width, height, animation }: any) => {
    const lottieInstance = useRef<any>();
    const divRef = useRef<any>();

    useEffect(() => {
        const selectAnimation = () => {
            switch (animation) {
                case 'loader': {
                    return loader;
                }
                case 'buttonLoaderBlue': {
                    return buttonLoaderBlue;
                }
                case 'buttonLoaderWhite': {
                    return buttonLoaderWhite;
                }
                case 'construction': {
                    return construction;
                }
                case 'loaderUpload': {
                    return loaderUpload;
                }
                case 'success': {
                    return success;
                }
                case 'error': {
                    return error;
                }
            }
        };

        const animationData = selectAnimation();
        if (divRef.current) {
            lottieInstance.current = Lottie.loadAnimation({
                animationData,
                container: divRef.current,
            });
        }

        return () => lottieInstance.current.destroy();
    }, [animation]);

    return <div style={{ height: height ? height : '200px', width: width ? width : '200px' }} ref={divRef}></div>;
};
