import { createAsyncThunk, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
    GetClientes,
    GetInspecciones,
    GetModelos,
    GetRamos,
    PostInspeccion,
    PostCliente,
    GetDetalleInspeccion,
    GetInspeccionesPorModelo,
} from './InspeccionesAPI';
import { GetModeloCustomXRamo } from '../modelos/ModelosAPI';

export interface Inspecciones {
    id: string;
    nombre: string;
    cliente: string;
    direccion: string;
    ramo: string;
}

export interface RequestCliente {
    nombre: string;
    movil: string;
    email: string;
    direccion: string;
    dni: string;
    nombreFiscal: string;
    cif: string;
    nombreComercio: string;
    telefonoEmpresa: string;
    agente_cliente_id: number;
    tipo_cliente_id: number;
    activo: boolean;
}

export interface RequestAltaInspecciones {
    activo: boolean;
    estadoInspeccionId: number;
    ramoInspeccionId: number;
    agenteInspeccionId: number;
    clienteInspeccionId: number;
    modeloInspeccionId: number;
    movilNuevo: string;
}

interface Error {
    code: number;
    messagge: string;
}

export interface InspeccionesState {
    inspecciones: Array<any>;
    inspeccionesPorModelo: Array<any>;
    notified?: boolean;
    nuevaInspeccionId: number;
    clientes: Array<any>;
    ramos: Array<any>;
    modelos: Array<any>;
    requestInspecion: RequestAltaInspecciones;
    requestCliente: RequestCliente;
    status: 'idle' | 'loading' | 'failed';
    statusInspeccion: 'idle' | 'loading' | 'failed';
    error: Error;
}

const initialState: InspeccionesState = {
    inspecciones: [],
    inspeccionesPorModelo: [],
    clientes: [],
    ramos: [],
    modelos: [],
    notified: false,
    nuevaInspeccionId: 0,
    status: 'idle',
    statusInspeccion: 'idle',
    requestInspecion: {
        activo: true,
        estadoInspeccionId: 1,
        ramoInspeccionId: 0,
        agenteInspeccionId: 1,
        clienteInspeccionId: 0,
        modeloInspeccionId: 0,
        movilNuevo: '',
    },
    requestCliente: {
        agente_cliente_id: 0,
        tipo_cliente_id: 1,
        activo: true,
        cif: '',
        direccion: '',
        dni: '',
        email: '',
        movil: '',
        nombre: '',
        nombreComercio: '',
        nombreFiscal: '',
        telefonoEmpresa: '',
    },
    error: { code: 0, messagge: '' },
};

export const InspeccionesSync = createAsyncThunk('/inspecciones', async (archivadas: boolean) => {
    let response;
    try {
        response = await GetInspecciones(archivadas);
    } catch (error) {
        console.log('Error al obtener las inspecciones');
    }

    return response;
});

export const InspeccionesPorModeloSync = createAsyncThunk('/inspeccionesPorModelo', async (modeloCustomId: number) => {
    let response;
    try {
        response = (await GetInspeccionesPorModelo(modeloCustomId)).data;
    } catch (error) {
        console.log('Error al obtener las inspecciones');
    }

    return response;
});

export const ClientesSync = createAsyncThunk('/clientes', async () => {
    let response;
    try {
        response = await GetClientes();
    } catch (error) {
        console.log('Error al obtener las inspecciones');
    }

    return response;
});

export const RamosSync = createAsyncThunk('/ramos', async () => {
    let response;
    try {
        response = await GetRamos();
    } catch (error) {
        console.log('Error al obtener los ramos');
    }

    return response;
});

export const ModelosSync = createAsyncThunk('/modelosXramo', async (ramoId: number) => {
    let response;
    try {
        response = await GetModeloCustomXRamo(ramoId);
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

export const AltaInspeccionSync = createAsyncThunk(
    '/alta-inspecciones',
    async (req: RequestAltaInspecciones, thunkApi) => {
        let response;
        try {
            console.log(req);
            response = await PostInspeccion(
                req.activo,
                req.estadoInspeccionId,
                req.ramoInspeccionId,
                req.agenteInspeccionId,
                req.clienteInspeccionId,
                req.modeloInspeccionId,
                req.movilNuevo,
            );
        } catch (error) {
            console.log('Error al crear inspeccion');
        }

        return response;
    },
);

/* export const DetalleInspeccionSync = createAsyncThunk(
    '/clientes/detalle',
    async (req: RequestDetalleInspeccion, thunkApi) => {
        let response;
        try {
            response = await GetDetalleInspeccion(req.id);
        } catch (error) {
            console.log('Error al obtener el cliente');
        }

        return response;
    },
); */

export const AltaClienteSync = createAsyncThunk('/alta-clientes', async (req: RequestCliente, thunkApi) => {
    let response;
    try {
        response = await PostCliente(
            req.nombre,
            req.movil,
            req.email,
            req.direccion,
            req.dni,
            req.nombreFiscal,
            req.cif,
            req.nombreComercio,
            req.telefonoEmpresa,
            req.agente_cliente_id,
            req.tipo_cliente_id,
            req.activo,
        );
    } catch (error) {
        console.log('Error al crear inspeccion');
    }

    return response;
});

export const InspeccionesSlice = createSlice({
    name: 'inspecciones',
    initialState,
    reducers: {
        actualizarInspecciones: (state, action) => {
            state.inspecciones = [...state.inspecciones, action.payload];
        },
        setCliente: (state, action) => {
            state.requestInspecion = {
                ...state.requestInspecion,
                clienteInspeccionId: action.payload,
            };
        },
        setRamo: (state, action) => {
            state.requestInspecion = {
                ...state.requestInspecion,
                ramoInspeccionId: action.payload,
            };
        },
        setModelo: (state, action) => {
            console.log(action.payload);
            state.requestInspecion = {
                ...state.requestInspecion,
                modeloInspeccionId: action.payload,
            };
        },
        setMovilEnvioDispatch: (state, action) => {
            state.requestInspecion = {
                ...state.requestInspecion,
                movilNuevo: action.payload,
            };
        },
        setAgenteDispatch: (state, action) => {
            state.requestInspecion = {
                ...state.requestInspecion,
                agenteInspeccionId: action.payload,
            };
        },
        setRequestAltaInspeccion: (state, action) => {
            state.requestInspecion = action.payload;
        },
        cleanStateAltaInspeccionDispatch: (state, action) => {
            state.requestInspecion = {
                activo: true,
                estadoInspeccionId: 1,
                ramoInspeccionId: 0,
                agenteInspeccionId: 1,
                clienteInspeccionId: 0,
                modeloInspeccionId: 0,
                movilNuevo: '',
            };
        },
        setTipoClienteDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                tipo_cliente_id: action.payload,
            };
        },
        setNombreClienteDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                nombre: action.payload,
            };
        },
        //DNI / NIE / NIF
        setDniDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                dni: action.payload,
            };
        },
        setMovilDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                movil: action.payload,
            };
        },
        setEmailDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                email: action.payload,
            };
        },
        setDireccionDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                direccion: action.payload,
            };
        },
        setNombreFiscalDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                nombreFiscal: action.payload,
            };
        },
        setCifDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                nombreFiscal: action.payload,
            };
        },
        setNombreComercialDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                nombreComercio: action.payload,
            };
        },
        setTelefonoEmpresaDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                telefonoEmpresa: action.payload,
            };
        },
        setDireccionFiscalDispatch: (state, action) => {
            state.requestCliente = {
                ...state.requestCliente,
                direccion: action.payload,
            };
        },

        setRequestAltaClientes: (state, action) => {
            state.requestCliente = action.payload;
        },
        setNotified: (state, action) => {
            state.notified = action.payload.toast;
            state.nuevaInspeccionId = 0;
        },
        cleanInspecciones: (state) => {
            return initialState;
        },
        removeInspeccion: (state, action) => {
            state.inspecciones = state.inspecciones.filter((i) => i.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        //Inspecciones
        builder.addCase(InspeccionesSync.fulfilled, (state, { payload }) => {
            state.inspecciones = payload;
            state.status = 'idle';
            state.statusInspeccion = 'idle';
        });
        builder.addCase(InspeccionesSync.pending, (state, { payload }) => {
            state.status = 'loading';
            state.statusInspeccion = 'loading';
        });
        builder.addCase(InspeccionesSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Inspecciones por modelo
        builder.addCase(InspeccionesPorModeloSync.fulfilled, (state, { payload }) => {
            console.log('InspeccionesPorModeloSync payload :>> ', payload);
            state.inspeccionesPorModelo = payload;
            state.status = 'idle';
        });
        builder.addCase(InspeccionesPorModeloSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(InspeccionesPorModeloSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Alta Inspecciones
        builder.addCase(AltaInspeccionSync.fulfilled, (state, { payload }) => {
            if (payload.resultCode !== 0) {
                state.error.code = payload.resultError;
                state.error.messagge = payload.error;
            } else {
                state.inspecciones = [...state.inspecciones, payload.data];
                state.nuevaInspeccionId = payload.data.id;
                state.notified = true;
                state.status = 'idle';
            }
        });
        builder.addCase(AltaInspeccionSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(AltaInspeccionSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Clientes
        builder.addCase(ClientesSync.fulfilled, (state, { payload }) => {
            state.clientes = payload;
            state.status = 'idle';
        });
        builder.addCase(ClientesSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ClientesSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Alta Clientes
        builder.addCase(AltaClienteSync.fulfilled, (state, { payload }) => {
            state.clientes = [...state.clientes, payload];
            state.status = 'idle';
        });
        builder.addCase(AltaClienteSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(AltaClienteSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Ramos
        builder.addCase(RamosSync.fulfilled, (state, { payload }) => {
            state.ramos = payload;
            state.status = 'idle';
        });
        builder.addCase(RamosSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(RamosSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Modelos
        builder.addCase(ModelosSync.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.modelos = payload.data;
            state.status = 'idle';
        });
        builder.addCase(ModelosSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ModelosSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });
    },
});

export const agentesInspeccion = (state: RootState) => {
    const agentes = state.inspecciones.inspecciones.reduce((acc, e) => {
        return {
            ...acc,
            [e.nombreAgente]: { label: e.nombreAgente },
        };
    }, {});

    return Object.values(agentes).filter((a: any) => a.label !== null);
};

export const notifiedInspeccion = (state: RootState) => state.inspecciones.notified;

export const nuevaInspeccion = (state: RootState) => state.inspecciones.nuevaInspeccionId;

export const listaInspecciones = (state: RootState) => state.inspecciones.inspecciones;

export const listaInspeccionesPorModelo = (state: RootState) => state.inspecciones.inspeccionesPorModelo;

export const listaClientes = (state: RootState) => state.inspecciones.clientes;

export const listaRamos = (state: RootState) => state.inspecciones.ramos;

export const listaModelos = (state: RootState) => state.inspecciones.modelos;

export const requestInspecion = (state: RootState) => state.inspecciones.requestInspecion;

export const requestCliente = (state: RootState) => state.inspecciones.requestCliente;

export const statusAltaInspeccion = (state: RootState) => state.inspecciones.status;

export const statusSyncInspeccion = (state: RootState) => state.inspecciones.statusInspeccion;

export const clientesMap = createSelector(listaClientes, (clientes) => {
    if (clientes.length >= 1) {
        return clientes.map((i) => {
            return {
                id: i.id,
                nombre: i.nombreCompleto,
                movil: i.movil,
                email: i.email,
                direccion: i.direccion,
                dni: i.dni,
                nombreFiscal: i.nombreFiscal,
                cif: i.cif,
                nombreComercio: i.nombreComercio,
                telefonoEmpresa: i.telefonoEmpresa,
                agenteCreador: i.agenteCreador,
                tipoCliente: i.tipo_cliente_id,
                activo: true,
                apellido: i.apellido,
            };
        });
    }
});

export const ramosMap = createSelector(listaRamos, (ramos) => {
    return ramos.map((i) => {
        return {
            id: i.id,
            nombre: i.nombre,
            activo: i.activo,
        };
    });
});

export const modelosMap = createSelector(listaModelos, (modelos) => {
    return modelos.map((i) => {
        return {
            id: i.id,
            nombre: i.descripcion,
            activo: i.activo,
            ramoId: i.ramoId,
        };
    });
});

export const {
    setCliente,
    setRamo,
    setModelo,
    setNotified,
    setRequestAltaInspeccion,
    setNombreClienteDispatch,
    setTipoClienteDispatch,
    setDniDispatch,
    setMovilDispatch,
    setEmailDispatch,
    setDireccionDispatch,
    cleanStateAltaInspeccionDispatch,
    setNombreFiscalDispatch,
    setCifDispatch,
    setNombreComercialDispatch,
    setTelefonoEmpresaDispatch,
    setDireccionFiscalDispatch,
    actualizarInspecciones,
    setMovilEnvioDispatch,
    setAgenteDispatch,
    cleanInspecciones,
    removeInspeccion,
} = InspeccionesSlice.actions;

export default InspeccionesSlice.reducer;
