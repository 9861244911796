import React, { useRef, useState } from 'react';
import Adjunto from './Adjunto';
import './ListaAdjuntos.scss';
import { CirclePlus, Paperclip, Pencil, Trash } from 'tabler-icons-react';
import { Modal } from '@mui/material';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import { v4 as uuidv4 } from 'uuid';
import * as R from 'ramda';
import { JsonAnimation } from '../JsonAnimation/JsonAnimation';

interface ModalFile {
    id: string;
    file: File;
}
interface IPropsListaAdjuntos {
    onSubmit: (e: ModalFile[]) => Promise<void>;
    adjuntos: any;
    onDelete: () => void;
}

const ListaAdjuntos = ({ onSubmit, adjuntos, onDelete }: IPropsListaAdjuntos) => {
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [editId, setEditId] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef: any = useRef();

    const handleSelectFile = (file: File) => {
        console.log(file);
        const newFile = { id: uuidv4(), file: file };
        setFiles([...files, newFile]);
    };

    const handleClose = () => {
        setFiles([]);
        setOpen(false);
    };

    const handleUpload = async () => {
        setLoading(true);
        await onSubmit(files);
        handleClose();
        setLoading(false);
    };

    const removeModalFile = (id: string) => {
        const newFiles = files.filter((f: ModalFile) => f.id !== id);
        setFiles(newFiles);
    };

    const handleEditClick = (id: string) => {
        setEditId(id);
        inputRef.current.click();
    };

    const editModalFile = (file: any) => {
        if (file) {
            const index = files.findIndex((f: ModalFile) => f.id === editId);
            const newFiles = R.update(index, { id: editId, file: file[0] }, files);
            setFiles(newFiles);
            setEditId('');
        }
    };

    const renderModalFile = () => {
        return files.map((f: ModalFile) => {
            return (
                <div key={f.id} className="modal-file">
                    <div className="modal-file-name">
                        <Paperclip />
                        <p>{f.file.name}</p>
                    </div>
                    <div className="modal-file-actions">
                        <Pencil className="modal-file-actions-edit" onClick={() => handleEditClick(f.id)} />
                        <Trash className="modal-file-actions-delete" onClick={() => removeModalFile(f.id)} />
                    </div>
                    <input
                        type="file"
                        accept=".pdf, .xml, .csv, image/jpg, image/jpeg, image/png"
                        onChange={(e) => editModalFile(e.target.files)}
                        hidden={true}
                        ref={inputRef}
                    />
                </div>
            );
        });
    };

    const renderModalFileList = () => {
        if (files.length) {
            return <div className="modal-file-list">{renderModalFile()}</div>;
        } else {
            return null;
        }
    };

    const renderAdjuntos = () => {
        return Object.values(adjuntos).map((a: any) => {
            return <Adjunto key={a.batchId} adjuntos={a} onDelete={onDelete} />;
        });
    };

    return (
        <div className="lista-adjuntos">
            <div className="lista-adjuntos-main">
                <p className="lista-adjuntos-header">Archivos</p>
                <div className="lista-adjuntos-items">{adjuntos ? renderAdjuntos() : null}</div>
            </div>
            <div className="lista-adjuntos-actions">
                <button className="tertiary-button-withIcon" onClick={() => setOpen(true)}>
                    Cargar archivo <CirclePlus />
                </button>
            </div>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="lista-adjuntos-modal">
                    <h2>Subir archivo</h2>
                    <p>Carga el documento</p>
                    <DragAndDrop
                        acceptedFiles={['pdf', 'xml', 'csv', 'jpg', 'png']}
                        text="Arrastra o sube el documento (PDF, XML, CSV, JPG o PNG)"
                        onChange={handleSelectFile}
                    />
                    <div className="lista-adjuntos-modal-cargados">{renderModalFileList()}</div>
                    <div className="lista-adjuntos-modal-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="primary-button" disabled={!files.length || loading} onClick={handleUpload}>
                            {loading ? (
                                <JsonAnimation animation="buttonLoaderBlue" height="30px" width="105px" />
                            ) : (
                                'Subir archivo'
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ListaAdjuntos;
