import React from 'react';

const ChevronDownWithBg = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24.0942" height="24" rx="6" fill="#EBF1FE" />
            <path d="M8.0314 10L12.0471 14L16.0628 10" stroke="#0046CF" strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};

export default ChevronDownWithBg;
