import React, { useState } from 'react';
import './ConfigHeader.scss';
import { Check, Pencil, Trash } from 'tabler-icons-react';
import { IconCopy } from '../../assets/IconCopy';
import { conf } from '../../utlis/config';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';

interface IPropsConfigHeader {
    title: string;
    fechaCreacion: string;
    icono: string;
    link: string;
    onDelete: () => void;
    onEdit: (e: string) => void;
    onSave: () => void;
    valuesChanged: boolean;
    onRestoreToDefault: () => void;
}

const ConfigHeader: React.FunctionComponent<IPropsConfigHeader> = ({
    title,
    fechaCreacion,
    icono,
    link,
    onDelete,
    onEdit,
    onSave,
    valuesChanged = false,
    onRestoreToDefault,
}) => {
    const baseUrl = conf('MOBILE_BASE_URL');
    const [copied, setCopied] = useState(false);
    const [edit, setEdit] = useState(false);
    const [newTitle, setNewTitle] = useState(title);

    const handleCopiarEnlace = (link: string) => {
        navigator.clipboard.writeText(baseUrl + link);
        setCopied(true);
    };

    const handleSubmit = (event: any) => {
        if (event.key === 'Enter' || (event.keyCode === 13 && newTitle)) {
            onEdit(newTitle);
            setEdit(false);
            setNewTitle(title);
        }
    };

    const handlePressEdit = () => {
        setNewTitle(title);
        setEdit(true);
    };

    return (
        <div className="configheader">
            <div className="configheader-left">
                <div className="configheader-left-icon">{IconsRamos[icono]}</div>
                <h1 className="configheader-left-title">
                    {edit ? (
                        <input
                            className="configheader-left-input"
                            onChange={(e) => setNewTitle(e.target.value)}
                            value={newTitle}
                            onKeyUp={handleSubmit}
                            autoFocus
                        />
                    ) : (
                        title
                    )}
                </h1>
                <button className="configheader-left-edit" onClick={handlePressEdit}>
                    {edit ? null : <Pencil />}
                </button>
                <button className="configheader-left-delete" onClick={onDelete}>
                    <Trash />
                </button>
                {/*                 <EditarSubmodulo
                    titulo="Pantalla bienvenida"
                    options={inputsEditarOnboarding}
                    onSubmit={(e: any) => console.log(e)}
                /> */}
            </div>
            <div className="configheader-right">
                <p className="configheader-right-fecha">{fechaCreacion}</p>
                <button
                    className={`configheader-right-enlace${copied ? '-copiado' : ''}`}
                    onClick={() => handleCopiarEnlace(link)}
                >
                    {copied ? (
                        <>
                            <span>Enlace copiado</span>
                            <Check />
                        </>
                    ) : (
                        <>
                            <span>Copiar enlace</span> <IconCopy />
                        </>
                    )}
                </button>
                <button className="primary-button" onClick={onRestoreToDefault}>
                    Restablecer modelo
                </button>
                <button className="primary-button" disabled={!valuesChanged} onClick={onSave}>
                    Guardar cambios
                </button>
            </div>
        </div>
    );
};

export default ConfigHeader;
