import React, { useEffect, useRef, useState } from 'react';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import { Autocomplete, TextField } from '@mui/material';
import { ChevronDown, InfoCircle, Paperclip, Pencil, Trash } from 'tabler-icons-react';
import { isCsv } from '../../utlis';
import Papa from 'papaparse';
import './PreguntasLibresCsv.scss';

interface IPropsPreguntasLibresCsv {
    onChange: (e: any[]) => void;
    onRemove: () => void;
}

const PreguntasLibresCsv = ({ onChange, onRemove }: IPropsPreguntasLibresCsv) => {
    const initialFileState = { name: '', parsedCsv: [], filteredRows: [] };
    const [file, setFile] = useState<any>(initialFileState);
    const [startingRow, setStartingRow] = useState<any>(null);
    const [fileExtensionError, setFileExtensionError] = useState(false);
    const inputRef: any = useRef();
    const acceptedFileTypes = ['csv'];

    useEffect(() => {
        const filteredRows = file.parsedCsv.slice(startingRow, file.parsedCsv.length);
        setFile({ ...file, filteredRows: filteredRows });
        onChange(filteredRows);
    }, [startingRow]);

    const handleFileChange = (file: any) => {
        if (isCsv(file.name)) {
            setFileExtensionError(false);
            Papa.parse(file, {
                skipEmptyLines: true,
                encoding: 'UTF-8',
                complete: (results) => {
                    setFile({ name: file.name, parsedCsv: results.data, filteredRows: results.data });
                    onChange(results.data);
                },
            });
        } else {
            setFileExtensionError(true);
        }
    };

    const removeFile = () => {
        setFile(initialFileState);
        setStartingRow(null);
        onRemove();
    };

    const renderUploadForm = () => {
        if (file.parsedCsv.length) {
            return (
                <div className="modal-carga-archivo-subido">
                    <div className="modal-carga-archivo-subido-data">
                        <Paperclip />
                        <span>{file.name}</span>
                    </div>
                    <div className="modal-carga-archivo-subido-actions">
                        <Pencil
                            className="modal-carga-archivo-subido-actions-edit"
                            onClick={() => inputRef.current.click()}
                        />
                        <Trash onClick={removeFile} className="modal-carga-archivo-subido-actions-delete" />
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <DragAndDrop
                        acceptedFiles={acceptedFileTypes}
                        onChange={handleFileChange}
                        text="Arrastra o sube el documento (csv) desde tu PC o móvil"
                    />
                    {fileExtensionError ? (
                        <div className="modal-carga-error">
                            <p className="modal-carga-error-item">
                                <InfoCircle /> Tipo de archivo no soportado, por favor seleccione un archivo CSV
                            </p>
                        </div>
                    ) : null}
                </>
            );
        }
    };

    const changeFile = (file: any) => {
        handleFileChange(file);
        setStartingRow(null);
    };

    return (
        <div className="preguntas-libres-csv">
            <p>Carga el documento e indícanos a partir de que fila comienzan los datos.</p>
            {renderUploadForm()}
            <input
                type="file"
                accept=".csv"
                onChange={(e) => changeFile(e.target.files && e.target.files[0])}
                hidden={true}
                ref={inputRef}
            />
            <div className="preguntas-libres-csv-selection-fila">
                <p>Los datos comienzan en la fila n°:</p>
                <Autocomplete
                    value={startingRow}
                    id="row-selection-combo-box"
                    disableClearable={true}
                    popupIcon={<ChevronDown />}
                    options={file.parsedCsv.length && Array.from(Array(file.parsedCsv.length).keys())}
                    sx={{ width: 160 }}
                    onChange={(e, v) => {
                        setStartingRow(v);
                    }}
                    disabled={!file.parsedCsv.length}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: false }}
                            label=" "
                            variant="outlined"
                            placeholder="n°"
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default PreguntasLibresCsv;
