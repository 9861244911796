import React from 'react';

const AccidenteCoche = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.07877 13.5783L1.97137 11.4741L3.0127 10.4343L5.12006 12.5385L4.07877 13.5782L4.07877 13.5783ZM12.5085 21.9954L10.4011 19.8911L11.4424 18.8514L13.5499 20.9556L12.5086 21.9954L12.5085 21.9954Z"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1985 7.84344L16.1448 6.79134M7.80499 6.7016L13.3224 3.24354C13.5627 3.09293 13.8471 3.02823 14.129 3.06002C14.4109 3.09181 14.6737 3.21821 14.8743 3.41853L20.5764 9.11205C20.777 9.31235 20.9036 9.57472 20.9354 9.85622C20.9673 10.1377 20.9025 10.4217 20.7516 10.6616L17.2884 16.1707L7.80499 6.7016Z"
                stroke="#4A88FF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3379 12.2262C14.0536 11.5116 15.214 11.5116 15.9297 12.2262C16.6454 12.9408 16.6454 14.0996 15.9297 14.8142"
                stroke="#4A88FF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66195 10.7892L6.08142 9.21109C5.79044 8.92054 5.79048 8.44941 6.08146 8.15887C6.37241 7.86836 6.8442 7.86836 7.13519 8.15891L8.71571 9.73706L7.66195 10.7892ZM14.8541 17.8129L13.2736 16.2347L14.3273 15.1826L15.9078 16.7607C16.1988 17.0513 16.1988 17.5224 15.9079 17.8129C15.6169 18.1034 15.1451 18.1034 14.8541 17.8129Z"
                stroke="#4A88FF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8608 16.4029L7.57254 13.1196L8.62622 12.0675L11.9145 15.3508L10.8608 16.4029Z"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.1921 21.5969L2.37052 9.79312C2.1519 9.57482 2.17414 9.21461 2.4179 9.02474L5.69773 6.46996C6.29935 6.00129 7.15641 6.05399 7.69596 6.59273L17.3973 16.2795C17.9368 16.8182 17.9896 17.6739 17.5202 18.2747L14.9616 21.5496C14.7715 21.793 14.4107 21.8152 14.1921 21.5969Z"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M0.262529 19.0561L1.41646 19.2361L1.33146 20.4249C1.32228 20.5543 1.39134 20.6761 1.50569 20.7323C1.61991 20.7886 1.75635 20.7677 1.84956 20.6797L2.70683 19.8707L3.56409 20.6797C3.62241 20.7348 3.69775 20.7635 3.77394 20.7635C3.81946 20.7635 3.86522 20.7533 3.90797 20.7323C4.02231 20.6761 4.09137 20.5542 4.0822 20.4249L3.99731 19.2361L5.15112 19.0561C5.27657 19.0366 5.37764 18.9407 5.40577 18.8143C5.43402 18.688 5.38379 18.5568 5.27911 18.4836L4.31595 17.8101L4.89757 16.7766C4.96083 16.6643 4.95033 16.5239 4.87124 16.4225C4.79204 16.3213 4.66043 16.2796 4.53908 16.3176L3.42295 16.6666L2.99431 15.5579C2.94771 15.4373 2.83373 15.3582 2.70683 15.3582C2.57993 15.3582 2.46595 15.4373 2.41922 15.5579L1.99071 16.6666L0.874569 16.3176C0.753224 16.2796 0.621495 16.3213 0.542409 16.4225C0.463323 16.5239 0.452818 16.6643 0.516087 16.7766L1.09758 17.8101L0.134543 18.4836C0.0298596 18.5568 -0.0204897 18.688 0.00776384 18.8143C0.0360174 18.9407 0.137078 19.0366 0.262529 19.0561ZM1.77603 17.7485L1.44218 17.1553L2.08283 17.3556C2.2369 17.4039 2.40159 17.323 2.46088 17.1698L2.70683 16.5334L2.9529 17.1698C3.01206 17.323 3.17675 17.4039 3.33082 17.3556L3.97148 17.1553L3.63762 17.7485C3.55733 17.8913 3.59802 18.0731 3.73108 18.1662L4.28384 18.5528L3.62157 18.6561C3.46219 18.681 3.34821 18.8269 3.35992 18.9911L3.4087 19.6734L2.91655 19.2091C2.85739 19.1532 2.78205 19.1252 2.70683 19.1252C2.6316 19.1252 2.55626 19.1532 2.4971 19.2091L2.00495 19.6734L2.05373 18.9911C2.06545 18.8269 1.95159 18.681 1.79209 18.6561L1.12982 18.5528L1.68257 18.1662C1.81575 18.0732 1.85632 17.8913 1.77603 17.7485Z"
                fill="#4A88FF"
            />
        </svg>
    );
};

export default AccidenteCoche;
