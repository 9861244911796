import { LoadScript, GoogleMap } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import { conf } from '../../utlis/config';
import airaMarker from '../../assets/svg/Icon_mapa_aira.svg';
import { debounce, getCoordinates } from '../../utlis';
import './Gmap.scss';
import {
    getDirectionByAddress,
    getDirectionFromCoords,
    getMapDirectionSuggestion,
} from '../../features/inspecciones/InspeccionesAPI';
import { Coordenadas } from '../../features/inspecciones/aira0/NuevoAiraCero';
import { Autocomplete } from '@mui/material';

type CenterGoogleMap = google.maps.LatLngLiteral | undefined;
interface IPropsGMap {
    onCenterChange: (e: string) => void;
    initialCenter?: Coordenadas | null;
}

const GMap = ({ onCenterChange, initialCenter }: IPropsGMap) => {
    const [map, setMap] = useState<any>(null);
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
    const [center, setCenter] = useState<CenterGoogleMap>(undefined);
    const [directionOptions, setDirectionOptions] = useState([]);
    const [directionToSearch, setDirectionToSearch] = useState<string>('');
    const apiKey = conf('GOOGLE_MAPS_API_KEY') || '';
    const containerStyle = {
        width: '100%',
        height: '272px',
        display: 'flex',
        alignSelf: 'center',
        borderRadius: '15px',
    };

    const getOptionsDelayed = useCallback(
        debounce((texto: string, callback: any) => {
            /* setCallesOptions([]); */
            getMapDirectionSuggestion(texto).then((response: any) => {
                callback(response);
            });
        }, 200),
        [],
    );

    const toOptions = (searchResults: any) => {
        return searchResults.data.predictions.map((p: any) => p.description);
    };

    useEffect(() => {
        const getCoords = async () => {
            const coords = await getCoordinates();
            if (coords) {
                //@ts-expect-error: asd
                setCenter({ lat: coords.coords.latitude, lng: coords.coords.longitude });
            }
        };

        const { latitud, longitud } = initialCenter || {};
        if (!center && latitud && longitud) {
            setCenter({ lat: parseFloat(latitud), lng: parseFloat(longitud) });
        } else getCoords();
    }, []);

    useEffect(() => {
        getOptionsDelayed(directionToSearch, (e: any) => {
            setDirectionOptions(toOptions(e));
        });
    }, [directionToSearch]);

    useEffect(() => {
        if (center) {
            const getCenterDirection = async () => {
                const direction = await getDirectionFromCoords(center.lat, center.lng);
                const directionObj = direction.results[0].address_components.reduce(
                    (acc: any, a: any) => {
                        return {
                            ...acc,
                            ...(a.types.includes('route') && { calle: a.long_name }),
                            ...(a.types.includes('street_number') && { numero: a.long_name }),
                            ...(a.types.includes('postal_code') && { codigoPostal: a.long_name }),
                            ...(a.types.includes('locality') && { poblacion: a.long_name }),
                            ...(a.types.includes('administrative_area_level_2') && { provincia: a.long_name }),
                            ...(a.types.includes('plus_code') && { plusCode: a.long_name }),
                            latitud: center.lat,
                            longitud: center.lng,
                        };
                    },
                    {
                        calle: '',
                        numero: '',
                        codigoPostal: '',
                        poblacion: '',
                        provincia: '',
                        latitud: center.lat,
                        longitud: center.lng,
                    },
                );
                onCenterChange(directionObj);
                setDirectionToSearch(direction.results[0].formatted_address);
            };

            getCenterDirection();
        }
    }, [center]);

    const handleCenterChange = () => {
        if (map) {
            const newCenter = map.getCenter().toJSON();
            setCenter(newCenter);
        }
    };

    const renderCoords = () => {
        if (center) {
            return <p className="google-map-coords">{`${center.lat}, ${center.lng}`}</p>;
        } else {
            return null;
        }
    };

    const handleInputSubmit = async (direction: string) => {
        const direccion = await getDirectionByAddress(direction, apiKey);
        if (direccion) {
            const coords = direccion.results[0].geometry.location;
            setCenter(coords);
        }
    };

    const handleInputChange = async (e: any) => {
        setDirectionToSearch(e);
        await handleInputSubmit(e);
    };

    const renderInputs = () => {
        return (
            <>
                <div className="google-map">
                    <LoadScript googleMapsApiKey={apiKey} onLoad={() => setIsGoogleLoaded(true)}>
                        <div className="custom-input input-mapa">
                            <Autocomplete
                                id="calle-combo-box"
                                key="combo-calle"
                                options={directionOptions}
                                getOptionLabel={(option: any) => option}
                                value={directionToSearch}
                                onChange={(e, v: any) => handleInputChange(v)}
                                renderInput={(params: any) => {
                                    return (
                                        <div className="dropdown-input-container" ref={params.InputProps.ref}>
                                            <input
                                                {...params.inputProps}
                                                className={`dropdown-input-input`}
                                                type="text"
                                                key="combo-calle-input"
                                                autoComplete="off"
                                                value={directionToSearch}
                                                onChange={(e) => {
                                                    setDirectionToSearch(e.target.value);
                                                }}
                                            />
                                            <label className={params.inputProps.value && 'filled'}>Dirección</label>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={18}
                            onLoad={(map: any) => setMap(map)}
                            onDragEnd={handleCenterChange}
                            options={{
                                disableDefaultUI: false,
                                streetViewControl: false,
                                fullscreenControl: false,
                                mapTypeId: 'satellite',
                                tilt: 0,
                            }}
                        >
                            {renderCoords()}
                        </GoogleMap>
                    </LoadScript>
                    <img className="google-map-marker" src={airaMarker} alt="aira-marker" />
                </div>
            </>
        );
    };
    return <div>{renderInputs()}</div>;
};

export default GMap;
