import React, { useState } from 'react';
import ConsumoLeads from './ConsumoLeads';
import TiempoDeUso from './TiempoDeUso';
import RamosYModelos from './RamosYModelos';
import ConversionLeads from './ConversionLeads';
import UsuariosHabilitados from './UsuariosHabilitados';
import DistribucionLeads from './DistribucionLeads';
import { AdjustmentsAlt, Eye, EyeOff, X } from 'tabler-icons-react';
import DropdownMenu from '../../components/dropdown-menu/DropdownMenu';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatDateDashboard } from '../../utlis/DashboardUtils';

export interface FiltrosDashboard {
    archivadas: number;
    fechaInicial: string;
    fechaFinal: string;
}

const Dashboard = () => {
    const [showArchivadas, setShowArchivadas] = useState(0);
    const [fechaInicial, setFechaInicial] = useState<string>('');
    const [fechaFinal, setFechaFinal] = useState<string>('');

    const handleArchivadas = () => {
        if (showArchivadas) {
            setShowArchivadas(0);
        } else {
            setShowArchivadas(1);
        }
    };

    const handleFechaInicialChange = (value: any) => {
        const date = formatDateDashboard(value);
        setFechaInicial(date);
    };

    const handleFechaFinalChange = (value: any) => {
        const date = formatDateDashboard(value);
        setFechaFinal(date);
    };

    return (
        <div className="dashboard">
            <div className="dashboard-toolbar">
                <h3 className="home-title">Inicio</h3>
                <div className="dashboard-toolbar-filters">
                    <button className="dashboard-toolbar-archivadas" onClick={handleArchivadas}>
                        Archivadas
                        <div className="dashboard-toolbar-archivadas-icon">
                            {!showArchivadas ? <EyeOff /> : <Eye />}
                        </div>
                    </button>
                    <DropdownMenu buttonPadding="8px 40px" icon={<AdjustmentsAlt />}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="dashboard-toolbar-date">
                                <p>Desde</p>
                                <DatePicker
                                    onChange={handleFechaInicialChange}
                                    sx={{ '.MuiIconButton-root': { marginRight: 0 } }}
                                />
                            </div>
                            <div className="dashboard-toolbar-date">
                                <p>Hasta</p>
                                <DatePicker
                                    onChange={handleFechaFinalChange}
                                    sx={{ '.MuiIconButton-root': { marginRight: 0 } }}
                                />
                            </div>
                        </LocalizationProvider>
                    </DropdownMenu>
                </div>
            </div>
            <div className="dashboard-main">
                <div className="dashboard-main-col1">
                    <ConversionLeads archivadas={showArchivadas} fechaInicial={fechaInicial} fechaFinal={fechaFinal} />
                    <RamosYModelos />
                </div>
                <div className="dashboard-main-col2">
                    <ConsumoLeads archivadas={showArchivadas} fechaInicial={fechaInicial} fechaFinal={fechaFinal} />
                    <UsuariosHabilitados />
                    <TiempoDeUso archivadas={showArchivadas} fechaInicial={fechaInicial} fechaFinal={fechaFinal} />
                </div>
                <div className="dashboard-main-col3">
                    <DistribucionLeads
                        archivadas={showArchivadas}
                        fechaInicial={fechaInicial}
                        fechaFinal={fechaFinal}
                    />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
