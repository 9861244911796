import React, { useState } from 'react';
import Nota from './Nota';
import './ListaNotas.scss';
import TextArea from '../TextArea/TextArea';
import { debounce } from '../../utlis';

interface IPropsListaNotas {
    notas: INota[];
    onSubmit: (e: string) => void;
}
interface INota {
    nota: string;
    nombre: string;
    fechaCreacion: Date;
}

const ListaNotas = ({ notas, onSubmit }: IPropsListaNotas) => {
    const [nuevaNota, setNuevaNota] = useState('');

    const renderNotas = (notas: INota[]) => {
        return notas.map((nota: INota) => {
            return (
                <Nota
                    key={nota.nombre + nota.fechaCreacion + nota.nota}
                    nombre={nota.nombre}
                    fechaCreacion={nota.fechaCreacion}
                    nota={nota.nota}
                />
            );
        });
    };

    const handleSubmit = () => {
        onSubmit(nuevaNota);
    };

    return (
        <div className="lista-notas">
            <div className="lista-notas-main">
                <p className="lista-notas-header">Notas</p>
                <div className="lista-notas-items">{renderNotas(notas)}</div>
                <TextArea key={notas.length} placeholder="Nueva nota" onChange={(e: string) => setNuevaNota(e)} />
            </div>
            <div className="lista-notas-actions">
                <button className="tertiary-button" disabled={!nuevaNota.trim()} onClick={debounce(handleSubmit, 200)}>
                    Subir nota
                </button>
            </div>
        </div>
    );
};

export default ListaNotas;
