/* eslint-disable prettier/prettier */
import React from 'react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { desactivarTutorialAgente } from '../../features/login/LoginAPI';

const joyrideStyles = {
        options: {
            primaryColor: '#0046CF',
            arrowColor: '0046CF',
        },                
};

export default function UseTour(steps: Step[], tourViewed: string | null): ReactNode {
    const [run, setRun] = useState(false);

    useEffect(
        function () {
            console.log('tour', tourViewed)
            if (tourViewed && tourViewed === "false") {
                setRun(false);
            } else if (tourViewed && tourViewed === "true") {
                setRun(true); 
            }            
        },
        [tourViewed],
    );

    const handleJoyrideCallback = useCallback(function (data: CallBackProps) {
        const idAgente = localStorage.getItem('idAgente')
        if (data.action === ACTIONS.CLOSE) {
            console.log('pepe')
            window.localStorage.setItem('tutorialActivo', 'false');
            idAgente && desactivarTutorialAgente(idAgente)
            setRun(false);
        }

        if(data.action === ACTIONS.NEXT && data.type === "tour:end") {
            console.log('epepepepep')
            window.localStorage.setItem('tutorialActivo', 'false');
            idAgente && desactivarTutorialAgente(idAgente)
        }

        console.log('callback called', data);
       
    }, []);

    const tour = useMemo<ReactNode>(
        () => (
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                showSkipButton={false}
                steps={steps}
                styles={joyrideStyles}
            />
        ),
        [steps, handleJoyrideCallback, run],
    );

    return tour;
}
