import React from 'react';

const Completado = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.457031" width="33.1901" height="32" rx="8" fill="#E7FCF2" />
            <g clipPath="url(#clip0_2338_54267)">
                <path
                    d="M18.8574 10V12.6667C18.8574 12.8435 18.9279 13.013 19.0534 13.1381C19.1789 13.2631 19.3491 13.3333 19.5266 13.3333H22.2032"
                    stroke="#53BD8A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.8658 22H14.1742C13.8193 22 13.4789 21.8595 13.2279 21.6095C12.9769 21.3594 12.8359 21.0203 12.8359 20.6667V11.3333C12.8359 10.9797 12.9769 10.6406 13.2279 10.3905C13.4789 10.1405 13.8193 10 14.1742 10H18.8583L22.2041 13.3333V20.6667C22.2041 21.0203 22.0631 21.3594 21.8121 21.6095C21.5611 21.8595 21.2207 22 20.8658 22Z"
                    stroke="#53BD8A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5117 14H16.1809"
                    stroke="#53BD8A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5117 16.6667H19.5266"
                    stroke="#53BD8A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5117 19.3333H19.5266"
                    stroke="#53BD8A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2338_54267">
                    <rect width="16.0597" height="16" fill="white" transform="translate(9.49023 8)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Completado;
