import React from 'react';

const EditSquare = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2316_5290)">
                <path
                    d="M6.8116 4.76807H4.8116C4.45798 4.76807 4.11884 4.90854 3.8688 5.15859C3.61875 5.40864 3.47827 5.74778 3.47827 6.1014V12.1014C3.47827 12.455 3.61875 12.7942 3.8688 13.0442C4.11884 13.2943 4.45798 13.4347 4.8116 13.4347H10.8116C11.1652 13.4347 11.5044 13.2943 11.7544 13.0442C12.0045 12.7942 12.1449 12.455 12.1449 12.1014V10.1014"
                    stroke="#0046CF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.81152 10.1014H8.81152L14.4782 4.43472C14.7434 4.1695 14.8924 3.80979 14.8924 3.43472C14.8924 3.05965 14.7434 2.69994 14.4782 2.43472C14.213 2.1695 13.8533 2.02051 13.4782 2.02051C13.1031 2.02051 12.7434 2.1695 12.4782 2.43472L6.81152 8.10139V10.1014Z"
                    stroke="#0046CF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4783 3.43457L13.4783 5.43457"
                    stroke="#0046CF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2316_5290">
                    <rect width="16" height="16" fill="white" transform="translate(0.811523 0.101318)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditSquare;
