import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconHogar } from '../../assets/IconHogar';
import { IconIndustria2 } from '../../assets/IconIndustria2';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import IconComercio2 from '../../assets/svg/IconComercio2';
import { RamosNumber } from '../../enums/ramos';
import { ramosMap } from '../inspecciones/inspeccionesSlice';
import { RamosConModeloSync } from './ModeloSlice';
import './NuevoRamo.scss';
import { PostRamo } from './RamosAPI';
import { Plus } from 'tabler-icons-react';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import Loader from '../../components/Loader/Loader';
import IconParticularesActive from '../../assets/IconParticularesActive';
import IconParticulares from '../../assets/IconParticulares';
import IconEmpresarialesActive from '../../assets/IconEmpresarialesActive';
import IconEmpresariales from '../../assets/IconEmpresariales';
import IconIndustriales from '../../assets/IconIndustriales';
import IconIndustrialesActive from '../../assets/IconIndustrialesActive';

interface RamoToCreate {
    nombre: string;
    idRamoMaestro: number;
    icono: string;
}

interface Ramo {
    id: number;
    nombre: string;
    activo: boolean;
}

interface IPropsNuevoRamo {
    bigButton?: boolean;
}

const NuevoRamo = ({ bigButton }: IPropsNuevoRamo) => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const initialRamoToCreateState = { idRamoMaestro: 1, nombre: '', icono: '' };
    const [ramoToCreate, setRamoToCreate] = useState<RamoToCreate>(initialRamoToCreateState);
    const [loading, setLoading] = useState(false);
    const ramos = useSelector(ramosMap);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setStep(1);
        setRamoToCreate(initialRamoToCreateState);
        setOpen(false);
    };

    const selectModelTypeHandler = (ramo: Ramo) => {
        setRamoToCreate({ ...ramoToCreate, idRamoMaestro: ramo.id });
    };

    const renderSugerencias = (ramoId: number, ramoActivo: boolean) => {
        switch (ramoId) {
            case RamosNumber.Hogar: {
                return (
                    <div className="ramos-maestros-data">
                        <h3
                            className={`ramos-maestros-data-titulo ${
                                ramoActivo ? 'ramos-maestros-data-titulo-activo' : ''
                            }`}
                        >
                            Particulares
                        </h3>
                        <p>Sugerido para:</p>
                        <ul className="ramos-maestros-data-sugerencias">
                            <li>Hogar</li>
                            <li>Salud</li>
                            <li>Vida</li>
                            <li>Autos</li>
                        </ul>
                    </div>
                );
            }
            case RamosNumber.Comercio: {
                return (
                    <div className="ramos-maestros-data">
                        <h3
                            className={`ramos-maestros-data-titulo ${
                                ramoActivo ? 'ramos-maestros-data-titulo-activo' : ''
                            }`}
                        >
                            Empresariales
                        </h3>
                        <p>Sugerido para:</p>
                        <ul className="ramos-maestros-data-sugerencias">
                            <li>Empresas (no industrias)</li>
                            <li>Comercio</li>
                            <li>Construcción</li>
                            <li>Flotas</li>
                        </ul>
                    </div>
                );
            }
            case RamosNumber.Industria: {
                return (
                    <div className="ramos-maestros-data">
                        <h3
                            className={`ramos-maestros-data-titulo ${
                                ramoActivo ? 'ramos-maestros-data-titulo-activo' : ''
                            }`}
                        >
                            Industriales
                        </h3>
                        <p>Sugerido para:</p>
                        <ul className="ramos-maestros-data-sugerencias">
                            <li>Industrias</li>
                        </ul>
                    </div>
                );
            }
        }
    };

    const modelButton = (ramo: Ramo) => {
        let logo;
        let title;

        const active = ramo.id === ramoToCreate.idRamoMaestro;

        switch (ramo.id) {
            case RamosNumber.Hogar:
                logo = active ? <IconParticularesActive /> : <IconParticulares />;
                break;
            case RamosNumber.Comercio:
                logo = active ? <IconEmpresarialesActive /> : <IconEmpresariales />;
                break;
            case RamosNumber.Industria:
                logo = active ? <IconIndustrialesActive /> : <IconIndustriales />;
                break;
        }

        return (
            <div className="ramos-maestros">
                <button
                    className={`ramo-button ${active ? 'active' : 'disabled'}`}
                    disabled={!ramo.activo}
                    onClick={() => selectModelTypeHandler(ramo)}
                >
                    <div className="ramo-button-logo">{logo}</div>
                </button>
                {renderSugerencias(ramo.id, active)}
            </div>
        );
    };

    const modelButtons = () => {
        return ramos.map((ramo) => {
            return modelButton(ramo);
        });
    };

    const handleNextStep = async () => {
        if (step === 1) {
            setStep(2);
        } else {
            setLoading(true);
            const results = await PostRamo(ramoToCreate);
            console.log(results);
            const idAseguradora = localStorage.getItem('idAseguradora');
            dispatch(RamosConModeloSync(Number(idAseguradora)));
            handleClose();
            setLoading(false);
        }
    };

    const ramoCustomButtons = () => {
        return Object.entries(IconsRamos).map(([k, v]) => {
            const active = ramoToCreate.icono === k;
            const fillException = ['accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar'];
            const hasException = fillException.includes(k);
            return (
                <button
                    className={`ramo-button ${active ? 'active' : 'disabled'}`}
                    onClick={() => setRamoToCreate({ ...ramoToCreate, icono: k })}
                    key={k}
                >
                    <div className={`ramo-button-icon ${hasException ? 'ramo-button-icon-exception' : ''}`}>{v}</div>
                </button>
            );
        });
    };

    const renderContent = () => {
        if (step === 1) {
            return (
                <div className="nuevo-ramo-modal-section">
                    <div className="nuevo-ramo-modal-section-title">
                        <h6>1.</h6>
                        <h6>Selecciona el tipo de datos que deseas recoger.</h6>
                    </div>

                    <div className="nuevo-ramo-modal-types-list">{modelButtons()}</div>
                </div>
            );
        } else if (step === 2) {
            return (
                <>
                    <div className="nuevo-ramo-modal-section">
                        <h6>1. Portada.</h6>
                        <div className="nuevo-ramo-modal-custom-types-list">{ramoCustomButtons()}</div>
                    </div>
                    <div className="nuevo-ramo-modal-section">
                        <h6>2. Nombre del ramo.</h6>
                        <input
                            className="modal-input"
                            value={ramoToCreate.nombre}
                            onChange={(e) => setRamoToCreate({ ...ramoToCreate, nombre: e.target.value })}
                            type="text"
                            placeholder="Ingresa un nombre"
                        ></input>
                    </div>
                </>
            );
        }
    };

    const handleButtonDisable = () => {
        return loading || (step === 2 && (!ramoToCreate.nombre || !ramoToCreate.icono)) ? true : false;
    };

    const renderButton = () => {
        if (bigButton) {
            return (
                <button onClick={handleOpen} className="big-nuevo-ramo">
                    <Plus />
                </button>
            );
        } else {
            return (
                <button onClick={handleOpen} className="tertiary-button">
                    Crear ramo
                </button>
            );
        }
    };

    return (
        <div>
            {renderButton()}
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="nuevo-ramo-modal">
                    <p className="nuevo-ramo-modal-steps">
                        <span>{step}</span> / 2
                    </p>
                    <div className="nuevo-ramo-modal-title">
                        <h3>Nuevo ramo</h3>
                    </div>
                    {renderContent()}
                    <div className="nuevo-ramo-modal-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="primary-button" disabled={handleButtonDisable()} onClick={handleNextStep}>
                            {step === 1 ? (
                                'Continuar'
                            ) : loading ? (
                                <JsonAnimation animation="buttonLoaderBlue" height="40px" width="90px" />
                            ) : (
                                'Crear modelo'
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <Loader open={loading} />
        </div>
    );
};

export default NuevoRamo;
