import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import './NuevoModelo.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ModeloInterface, ModelosCustomSync, ModelosSync, modelListSelector } from './ModeloSlice';
import { PostModelo } from './ModelosAPI';
import { useNavigate } from 'react-router-dom';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import Loading from '../../components/Loading';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import Loader from '../../components/Loader/Loader';

type NuevoModeloPorps = {
    selectedModelId?: number | undefined;
};

interface ModelToCreate {
    nombre: string;
    idModeloMaestro: number;
    ramoCustomId: number;
}

const NuevoModelo = ({ selectedModelId: selectedModelId }: NuevoModeloPorps) => {
    const [open, setOpen] = useState(false);
    const initialModelToCreateState = { idModeloMaestro: 1, nombre: '', ramoCustomId: 1 };
    const [modelToCreate, setModelToCreate] = useState<ModelToCreate>(initialModelToCreateState);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const models = useSelector(modelListSelector);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedModelId) {
            const selectedModel = models.find((model) => model.id === selectedModelId);
            if (selectedModel)
                setModelToCreate({
                    ...modelToCreate,
                    ramoCustomId: selectedModel.id,
                    idModeloMaestro: selectedModel.idModeloMaestro,
                });
        }
    }, [selectedModelId]);

    const selectModelTypeHandler = (model: ModeloInterface) => {
        setModelToCreate({ ...modelToCreate, idModeloMaestro: model.idModeloMaestro, ramoCustomId: model.id });
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setModelToCreate(initialModelToCreateState);
    };

    const modelButton = (model: ModeloInterface) => {
        let logo;
        let title;

        const active = model.id === modelToCreate.ramoCustomId;
        const fillException = ['accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar'];
        const hasException = fillException.includes(model.icono);

        return (
            <button
                className={`modelo-button ${active ? 'active' : 'disabled'}`}
                disabled={!model.active}
                onClick={() => selectModelTypeHandler(model)}
            >
                <div className={`modelo-button-logo ${hasException ? 'modelo-button-logo-exception' : ''}`}>
                    {IconsRamos[model.icono]}
                </div>
                <span className="modelo-button-title">{model.nombre}</span>
            </button>
        );
    };

    const modelButtons = () => {
        return models.map((model) => {
            return modelButton(model);
        });
    };

    const handleSubmitModelo = async () => {
        setLoading(true);
        const response = await PostModelo(
            modelToCreate.nombre,
            modelToCreate.idModeloMaestro,
            modelToCreate.ramoCustomId,
        );
        dispatch(ModelosSync());
        dispatch(ModelosCustomSync(modelToCreate.idModeloMaestro));
        handleClose();
        setLoading(false);
        if (response.id) {
            navigate(`/modelos/${response.id}`);
        }
    };

    return (
        <div>
            <button onClick={handleOpen} className="nuevo-modelo-button">
                Crear Modelo
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="nuevo-modelo-modal">
                    <div className="nuevo-modelo-modal-title">
                        <h3>Crear modelo</h3>
                    </div>
                    <div className="nuevo-modelo-modal-section">
                        <h6>1. Ramo</h6>
                        <div className="nuevo-modelo-modal-types-list">{modelButtons()}</div>
                    </div>
                    <div className="nuevo-modelo-modal-section">
                        <h6>2. Nombre del modelo</h6>
                        <input
                            className="modal-input"
                            value={modelToCreate.nombre}
                            onChange={(e) => setModelToCreate({ ...modelToCreate, nombre: e.target.value })}
                            type="text"
                            placeholder="Introduce el nombre del modelo"
                        ></input>
                    </div>
                    <div className="nuevo-modelo-modal-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="primary-button" disabled={loading} onClick={handleSubmitModelo}>
                            {loading ? (
                                <JsonAnimation animation="buttonLoaderBlue" height="40px" width="90px" />
                            ) : (
                                'Crear modelo'
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
            <Loader open={loading} />
        </div>
    );
};

export default NuevoModelo;
