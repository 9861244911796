import React from 'react';

const Gestionado = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.457031" width="33.1901" height="32" rx="8" fill="#E3F6F9" />
            <g clipPath="url(#clip0_2338_54374)">
                <path
                    d="M17.5204 22C20.8465 22 23.5428 19.3137 23.5428 16C23.5428 12.6863 20.8465 10 17.5204 10C14.1944 10 11.498 12.6863 11.498 16C11.498 19.3137 14.1944 22 17.5204 22Z"
                    stroke="#3BA1AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5117 16.0001L16.85 17.3334L19.5266 14.6667"
                    stroke="#3BA1AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2338_54374">
                    <rect width="16.0597" height="16" fill="white" transform="translate(9.49023 8)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Gestionado;
