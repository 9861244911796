import React from 'react';
import EnCurso from '../assets/dashboard/EnCurso';
import Gestionado from '../assets/dashboard/Gestionado';
import Enviado from '../assets/dashboard/Enviado';
import Completado from '../assets/dashboard/Completado';
import Aira0 from '../assets/dashboard/Aira0';
import dayjs from 'dayjs';

export const getEstadoColor = (estado: string) => {
    const estadoLowercase = estado.toLowerCase();
    switch (estadoLowercase) {
        case 'en curso':
            return '#E4B305';
        case 'aira 0':
            return '#0046CF';
        case 'completada':
            return '#53BD8A';
        case 'enviada':
            return '#E79142';
        case 'gestionado':
            return '#3BA1AE';
        default:
            return '#53BD8A';
    }
};

export const getEstadoLabel = (estado: string) => {
    const estadoLowercase = estado.toLowerCase();
    switch (estadoLowercase) {
        case 'en curso':
            return 'En curso';
        case 'aira 0':
            return 'Aira 0';
        case 'completada':
            return 'Completados';
        case 'enviada':
            return 'Enviados';
        case 'gestionado':
            return 'Gestionados';
        default:
            return '#53BD8A';
    }
};

export const getEstadoIcon = (estado: string) => {
    const estadoLowercase = estado.toLowerCase();
    switch (estadoLowercase) {
        case 'en curso':
            return <EnCurso />;
        case 'aira 0':
            return <Aira0 />;
        case 'completados':
            return <Completado />;
        case 'enviados':
            return <Enviado />;
        case 'gestionados':
            return <Gestionado />;
        default:
            return <EnCurso />;
    }
};

export const calcularSegundos = (minutos: number, segundos: number) => {
    const minutosASegundos = minutos * 60;
    return minutosASegundos + segundos;
};

export const formatDateDashboard = (date: Date) => {
    return dayjs(date).format('YYYY-MM-DD');
};
