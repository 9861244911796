import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Login } from './LoginAPI';

export class RequestLogin {
    email?: string;
    password?: string;
}

export interface LoginState {
    isLoggedIn: boolean;
    status: 'idle' | 'loading' | 'failed';
    aseguradoraLogo: string;
    aseguradoraId: number | null;
    aceptoToS: boolean;
}

const initialState: LoginState = {
    isLoggedIn: false,
    status: 'idle',
    aseguradoraLogo: '',
    aseguradoraId: null,
    aceptoToS: false,
};

export const loginSync = createAsyncThunk('/iniciar-sesion', async (req: RequestLogin, thunkApi) => {
    let response;
    if (req.email != undefined && req.password != undefined) {
        response = await Login(req.email, req.password);
        return response;
    }

    return;
});

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.clear();
            state.isLoggedIn = false;
            state.aseguradoraId = null;
            state.aseguradoraLogo = '';
        },
        signIn: (state) => {
            state.isLoggedIn = true;
        },
        changeLogo: (state, action) => {
            if (action.payload) {
                state.aseguradoraLogo = action.payload;
            } else {
                state.aseguradoraLogo = 'https://aira-system.com/wp-content/uploads/2022/04/Logo-Aira-firma.png';
            }
        },
        handleAseguradoraId: (state, action) => {
            state.aseguradoraId = action.payload;
        },
        aceptoToS: (state, action) => {
            state.aceptoToS = action.payload;
        },
    },

    extraReducers: {
        [loginSync.fulfilled.type]: (state, action) => {
            if (action.payload.result === 'OK') {
                state.isLoggedIn = true;
            } else return;
        },
    },
});

export const selectLogin = (state: RootState) => state.login;
export const selectLogo = (state: RootState) => state.login.aseguradoraLogo;
export const selectAseguradoraId = (state: RootState) => state.login.aseguradoraId;
export const selectToS = (state: RootState) => state.login.aceptoToS;

export const { logout, signIn, changeLogo, handleAseguradoraId, aceptoToS } = loginSlice.actions;

export default loginSlice.reducer;
