import React, { useEffect } from 'react';
import './Home.scss';
import Dashboard from '../../features/dashboard/Dashboard';

const Home: React.FunctionComponent = () => {
    return (
        <div className="home">
            <Dashboard />
        </div>
    );
};

export default Home;
