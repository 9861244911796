import React, { useEffect, useState } from 'react';
import './TextArea.scss';
interface ITextAreaProps {
    onChange: (e: string) => void;
    placeholder: string;
}

const TextArea = ({ onChange, placeholder }: ITextAreaProps) => {
    const [value, setValue] = useState('');

    const handleChange = (e: any) => {
        setValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <div className="textarea">
            <textarea maxLength={255} onChange={handleChange} value={value} placeholder={placeholder}></textarea>
        </div>
    );
};

export default TextArea;
