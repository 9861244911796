import React from 'react';

const RamoIndustria = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.4332 6.97221C20.4324 6.98855 20.4319 7.00501 20.4319 7.0216C20.4319 7.54167 20.0104 7.96324 19.4903 7.96324C18.9702 7.96324 18.5487 7.54167 18.5487 7.0216C18.5487 6.50152 18.1272 6.07996 17.6071 6.07996C17.087 6.07996 16.6654 6.50152 16.6654 7.0216C16.6654 7.54167 16.2439 7.96324 15.7238 7.96324C15.2037 7.96324 14.7822 7.54167 14.7822 7.0216C14.7822 6.50152 14.3606 6.07996 13.8405 6.07996C13.3204 6.07996 12.8989 6.50152 12.8989 7.0216C12.8989 7.54167 12.4773 7.96324 11.9572 7.96324C11.4372 7.96324 11.0156 7.54167 11.0156 7.0216C11.0156 6.50152 10.594 6.07996 10.074 6.07996C9.55388 6.07996 9.13232 6.50152 9.13232 7.0216L9.13238 7.05729M8.14111 25.7441V20.3972H10.6186V25.7441"
                stroke="#4A88FF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.40015 25.7441H25.5944V14.0905L20.7958 18.889V14.0905L15.9973 18.889V14.0905L11.1987 18.889L10.5132 9.29187H7.77118L6.40015 18.889V25.7441Z"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.1896 22.6032H12.2539V20.6591H17.1896V22.6032ZM23.7705 22.6032H18.8348V20.6591H23.7705V22.6032Z"
                stroke="#4A88FF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RamoIndustria;
