import { createAsyncThunk, createSlice, createSelector, PayloadAction, current } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { GetClientes, PostCliente, GetDetalleCliente, PutCliente } from './ClientesAPI';

export interface direccion {
    calle?: string;
    numero?: string;
    bloque?: string;
    escalera?: string;
    piso?: string;
    puerta?: string;
    cp: string;
    poblacion?: string;
    provincia?: string;
    poligono?: string;
    parcela?: string;
}

export interface RequestCliente {
    id?: any;
    nombre: string;
    apellido: string;
    movil: string;
    email: string;
    direccion: direccion;
    dni: string;
    nombreFiscal: string;
    cif: string;
    nombreComercio: string;
    telefonoEmpresa: string;
    agente_cliente_id: number;
    tipo_cliente_id: number;
    activo: boolean;
}

export interface RequestDetalleCliente {
    id: number;
}

export interface Ramo {
    nombre: string;
    activo: boolean;
}

export interface TipoCliente {
    nombre: string;
    activo: boolean;
}

export interface Inspeccion {
    estado: number;
    ramo: number;
    agente: number;
    cliente: number;
    modelo: number;
    riesgo: number;
    direccion: number;
    fechaEnvio: string;
    fechaVencimiento: string;
    activo: boolean;
}

export interface Cliente {
    nombre: string;
    apellido: string;
    movil: string;
    email: string;
    dni: string;
    direccion: string;
    nombreFiscal: string;
    cif: string;
    nombreComercio: string;
    telefonoEmpresa: string;
    activo: boolean;
    tipoClienteId: number;
    codigoVerificacion: string;
    ramo: Ramo;
    tipoCliente: TipoCliente;
    inspeccion: Array<any>;
    fechaCreacion: string;
    agenteClienteId: number;
}
export interface ClientesState {
    clientes: Array<any>;
    cliente: Cliente;
    requestCliente: RequestCliente;
    status: 'idle' | 'loading' | 'failed';
    nuevoClienteId: number;
    csvRowsWithError: any;
}

const initialState: ClientesState = {
    clientes: [
        {
            id: 1,
            nombreCliente: 'Juan',
            tipo: '1',
            identificacion: 'e-89898989',
            direccionFiscal: 'Madrid 192',
            inspecciones: '4',
            ramo: '1',
            activo: true,
        },
    ],
    status: 'idle',
    requestCliente: {
        agente_cliente_id: 0,
        tipo_cliente_id: 1,
        activo: true,
        cif: '',
        direccion: {
            calle: '',
            numero: '',
            bloque: '',
            escalera: '',
            piso: '',
            puerta: '',
            cp: '',
            poblacion: '',
            provincia: '',
            poligono: '',
            parcela: '',
        },
        dni: '',
        email: '',
        movil: '',
        nombre: '',
        apellido: '',
        nombreComercio: '',
        nombreFiscal: '',
        telefonoEmpresa: '',
    },
    csvRowsWithError: [],
    cliente: {
        activo: true,
        apellido: '',
        agenteClienteId: 0,
        cif: '',
        codigoVerificacion: '',
        dni: '',
        email: '',
        direccion: '',
        inspeccion: [
            {
                id: 0,
                activo: true,
                agente: 0,
                cliente: 0,
                direccion: 0,
                estado: 0,
                fechaEnvio: '',
                fechaVencimiento: '',
                modelo: 0,
                ramo: 0,
                riesgo: 0,
            },
        ],
        movil: '',
        nombre: '',
        nombreComercio: '',
        nombreFiscal: '',
        ramo: {
            nombre: '',
            activo: true,
        },
        telefonoEmpresa: '',
        tipoCliente: { nombre: '', activo: true },
        tipoClienteId: 0,
        fechaCreacion: '',
    },
    nuevoClienteId: 0,
};

export const ClientesSync = createAsyncThunk('/clientes', async () => {
    let response;
    try {
        response = await GetClientes();
    } catch (error) {
        console.log('Error al obtener las inspecciones');
    }

    return response;
});

export const AltaClienteSync = createAsyncThunk('/alta-clientes', async (req: RequestCliente, thunkApi) => {
    let response;
    try {
        response = await PostCliente(
            req.nombre,
            req.apellido,
            req.movil,
            req.email,
            req.direccion,
            req.dni,
            req.nombreFiscal,
            req.cif,
            req.nombreComercio,
            req.telefonoEmpresa,
            req.agente_cliente_id,
            req.tipo_cliente_id,
            req.activo,
        );
    } catch (error) {
        console.log('Error al crear inspeccion');
    }

    return response;
});

export const DetalleClienteSync = createAsyncThunk(
    '/clientes/detalle',
    async (req: RequestDetalleCliente, thunkApi) => {
        let response;
        try {
            response = await GetDetalleCliente(req.id);
        } catch (error) {
            console.log('Error al obtener el cliente');
        }

        return response;
    },
);

export const EditarClienteSync = createAsyncThunk('/editar-cliente', async (req: RequestCliente, thunkApi) => {
    let response;
    try {
        response = await PutCliente(
            req.id,
            req.nombre,
            req.movil,
            req.email,
            req.direccion,
            req.dni,
            req.nombreFiscal,
            req.cif,
            req.nombreComercio,
            req.telefonoEmpresa,
            req.agente_cliente_id,
            req.tipo_cliente_id,
            req.activo,
        );
    } catch (error) {
        console.log('Error al editar cliente');
    }

    return response;
});

export const ClientesSlice = createSlice({
    name: 'clientes',
    initialState,
    reducers: {
        setNuevoClienteId: (state, action) => {
            state.nuevoClienteId = action.payload;
        },
        clearNuevoClienteId: (state) => {
            state.nuevoClienteId = 0;
        },
        cleanClientes: (state) => {
            return initialState;
        },
        setCsvRowsWithError: (state, action) => {
            state.csvRowsWithError = [...state.csvRowsWithError, action.payload];
        },
        clearCsvRowsWithError: (state) => {
            state.csvRowsWithError = [];
        },
        removeRowWithError: (state, action) => {
            state.csvRowsWithError = state.csvRowsWithError.filter((r: any) => r.clienteId !== action.payload);
        },
        getClienteById: (state, action) => {
            console.log(current(state));
            console.log(action.payload);
            return state.clientes.find((c) => c.id === action.payload);
        },
    },
    extraReducers: (builder) => {
        //Clientes
        builder.addCase(ClientesSync.fulfilled, (state, { payload }) => {
            state.clientes = payload;
            state.status = 'idle';
        });
        builder.addCase(ClientesSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ClientesSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Alta Clientes
        builder.addCase(AltaClienteSync.fulfilled, (state, { payload }) => {
            state.clientes = [...state.clientes, payload];
            state.nuevoClienteId = payload.id;
            state.status = 'idle';
        });
        builder.addCase(AltaClienteSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(AltaClienteSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Detalle Cliente
        builder.addCase(DetalleClienteSync.fulfilled, (state, { payload }) => {
            console.log('get payload', payload);
            const {
                nombreCompleto,
                apellido,
                movil,
                email,
                direccion,
                dni,
                nombreFiscal,
                cif,
                nombreComercio,
                telefonoEmpresa,
                codigoVerificacion,
                activo,
                tipo_cliente_id,
                ramo_cliente,
                tipo_cliente,
                agente_cliente_id,
                cliente_inspeccion,
                fechaCreacion,
            } = payload.data.Cliente;
            const inspecciones = payload.data.Inspecciones;
            state.cliente.nombre = nombreCompleto;
            state.cliente.apellido = apellido;
            state.cliente.movil = movil;
            state.cliente.email = email;
            state.cliente.direccion = direccion;
            state.cliente.dni = dni;
            state.cliente.nombreFiscal = nombreFiscal;
            state.cliente.cif = cif;
            state.cliente.nombreComercio = nombreComercio;
            state.cliente.telefonoEmpresa = telefonoEmpresa;
            state.cliente.codigoVerificacion = codigoVerificacion;
            state.cliente.activo = activo;
            state.cliente.agenteClienteId = agente_cliente_id;
            state.cliente.tipoClienteId = tipo_cliente_id;
            state.cliente.ramo = ramo_cliente;
            state.cliente.tipoCliente = tipo_cliente;
            state.cliente.inspeccion = inspecciones;
            state.cliente.fechaCreacion = fechaCreacion;
            state.status = 'idle';
        });
        builder.addCase(DetalleClienteSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(DetalleClienteSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Editar Cliente
        builder.addCase(EditarClienteSync.fulfilled, (state, { payload }) => {
            console.log('AltaClienteSync sync', payload);
            const {
                nombreCompleto,
                movil,
                email,
                direccion,
                dni,
                nombreFiscal,
                cif,
                nombreComercio,
                telefonoEmpresa,
                codigoVerificacion,
                activo,
                tipo_cliente_id,
                ramo_cliente,
                tipo_cliente,
                cliente_inspeccion,
                fechaCreacion,
            } = payload.data.Cliente;
            const inspecciones = payload.data.Inspecciones;
            state.cliente.nombre = nombreCompleto;
            state.cliente.movil = movil;
            state.cliente.email = email;
            state.cliente.direccion = direccion;
            state.cliente.dni = dni;
            state.cliente.nombreFiscal = nombreFiscal;
            state.cliente.cif = cif;
            state.cliente.nombreComercio = nombreComercio;
            state.cliente.telefonoEmpresa = telefonoEmpresa;
            state.cliente.codigoVerificacion = codigoVerificacion;
            state.cliente.activo = activo;
            state.cliente.tipoClienteId = tipo_cliente_id;
            state.cliente.ramo = ramo_cliente;
            state.cliente.tipoCliente = tipo_cliente;
            state.cliente.inspeccion = inspecciones;
            state.cliente.fechaCreacion = fechaCreacion;
            state.status = 'idle';
        });
        builder.addCase(EditarClienteSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(EditarClienteSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });
    },
});
export const detalleCliente = (state: RootState) => state.clientes.cliente;

export const listaClientes = (state: RootState) => state.inspecciones.clientes;

export const requestCliente = (state: RootState) => state.inspecciones.requestCliente;

export const nuevoClienteId = (state: RootState) => state.clientes.nuevoClienteId;

export const requestClienteStatus = (state: RootState) => state.clientes.status;

export const selectCsvRowsWithError = (state: RootState) => state.clientes.csvRowsWithError;

export const {
    clearNuevoClienteId,
    setNuevoClienteId,
    cleanClientes,
    setCsvRowsWithError,
    clearCsvRowsWithError,
    removeRowWithError,
    getClienteById,
} = ClientesSlice.actions;

export default ClientesSlice.reducer;
