import React from 'react';
import { EmptyStateImage } from '../../assets/EmptyStateImage';

interface IPropsEmptyStateDashboard {
    size?: number;
}

const EmptyStateDashboard = ({ size = 100 }: IPropsEmptyStateDashboard) => {
    return (
        <div className="dashboard-empty-state">
            <div style={{ height: size }}>
                <EmptyStateImage />
            </div>
            <p>No se encontraron datos</p>
        </div>
    );
};

export default EmptyStateDashboard;
