import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconHogar } from '../../assets/IconHogar';
import { IconIndustria2 } from '../../assets/IconIndustria2';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import IconComercio2 from '../../assets/svg/IconComercio2';
import { RamosNumber } from '../../enums/ramos';
import { ramosMap } from '../inspecciones/inspeccionesSlice';
import { RamosConModeloSync } from './ModeloSlice';
import './NuevoRamo.scss';
import { EditarRamoCustom, PostRamo } from './RamosAPI';
import { Plus } from 'tabler-icons-react';

interface RamoToEdit {
    idRamoCustom: number;
    nombre: string;
    icono: string;
}

interface Ramo {
    id: number;
    nombre: string;
    activo: boolean;
}

interface IPropsNuevoRamo {
    idRamoCustom: number;
    defaultIcon: string;
    defaultName: string;
    onError: (m: string) => void;
    onEdit: () => void;
}

const EditarRamo = ({ defaultIcon, defaultName, idRamoCustom, onError, onEdit }: IPropsNuevoRamo) => {
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const initialRamoToCreateState = { idRamoCustom: 0, nombre: '', icono: '' };
    const [ramoToEdit, setRamoToEdit] = useState<RamoToEdit>(initialRamoToCreateState);
    const ramos = useSelector(ramosMap);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setRamoToEdit({ idRamoCustom: idRamoCustom, nombre: defaultName, icono: defaultIcon });
    }, [idRamoCustom]);

    const handleClose = () => {
        setRamoToEdit(initialRamoToCreateState);
        setOpen(false);
    };

    const handleSubmitEdit = async () => {
        console.log('edit');
        const results = await EditarRamoCustom(ramoToEdit);
        if (results && results.status === 200) {
            const idAseguradora = localStorage.getItem('idAseguradora');
            dispatch(RamosConModeloSync(Number(idAseguradora)));
            onEdit();
        } else {
            onError('Hubo un error al intentar editar el ramo');
        }
        handleClose();
    };

    const ramoCustomButtons = () => {
        return Object.entries(IconsRamos).map(([k, v]) => {
            const active = ramoToEdit.icono === k;
            const fillException = ['accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar'];
            const hasException = fillException.includes(k);
            return (
                <button
                    className={`ramo-button ${active ? 'active' : 'disabled'}`}
                    onClick={() => setRamoToEdit({ ...ramoToEdit, icono: k })}
                    key={k}
                >
                    <div className={`ramo-button-icon ${hasException ? 'ramo-button-icon-exception' : ''}`}>{v}</div>
                </button>
            );
        });
    };

    const renderContent = () => {
        return (
            <>
                <div className="nuevo-ramo-modal-section">
                    <h6>1. Portada.</h6>
                    <div className="nuevo-ramo-modal-custom-types-list">{ramoCustomButtons()}</div>
                </div>
                <div className="nuevo-ramo-modal-section">
                    <h6>2. Nombre del ramo.</h6>
                    <input
                        className="modal-input"
                        value={ramoToEdit.nombre}
                        onChange={(e) => setRamoToEdit({ ...ramoToEdit, nombre: e.target.value })}
                        type="text"
                        placeholder="Ingresa un nombre"
                    ></input>
                </div>
            </>
        );
    };

    const handleButtonDisable = () => {
        return !ramoToEdit.nombre || !ramoToEdit.icono ? true : false;
    };

    return (
        <div>
            <button onClick={handleOpen} className="secondary-button">
                Editar Ramo
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="nuevo-ramo-modal">
                    <div className="nuevo-ramo-modal-title">
                        <h3>Editar ramo</h3>
                    </div>
                    {renderContent()}
                    <div className="nuevo-ramo-modal-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="primary-button" disabled={handleButtonDisable()} onClick={handleSubmitEdit}>
                            {step === 1 ? 'Continuar' : 'Crear ramo'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default EditarRamo;
