import React from 'react';

export const User = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15" fill="#F7F7F9" stroke="#B0ADBE" strokeWidth="2" />
            <path
                d="M16.0001 15.1667C17.841 15.1667 19.3334 13.6743 19.3334 11.8333C19.3334 9.99238 17.841 8.5 16.0001 8.5C14.1591 8.5 12.6667 9.99238 12.6667 11.8333C12.6667 13.6743 14.1591 15.1667 16.0001 15.1667Z"
                stroke="#B0ADBE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 23.5V21.8333C11 20.9493 11.3512 20.1014 11.9763 19.4763C12.6014 18.8512 13.4493 18.5 14.3333 18.5H17.6667C18.5507 18.5 19.3986 18.8512 20.0237 19.4763C20.6488 20.1014 21 20.9493 21 21.8333V23.5"
                stroke="#B0ADBE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
