import React from 'react';
import './TerminosYCondiciones.scss';

const TerminosYCondiciones = () => {
    return (
        <div className="terminos">
            <h1 className="terminos-titulo">Política de privacidad</h1>
            <p className="terminos-fecha">Fecha de última actualización: agosto de 2022</p>
            {/* INTRODUCCION */}
            <div className="terminos-seccion">
                <h2>Introducción</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        De acuerdo con el <b>Reglamento (UE) 2016/679 del Parlamento y del Consejo de abril de 2016</b>
                         relativo a la protección de las personas físicas en lo que respecta al tratamiento de sus datos
                        personales y <b>la Ley Orgánica 3/2018, de 5 de diciembre</b> de Protección de Datos Personales
                        y Garantía de los Derechos Digitales, le informa de lo siguiente:
                    </p>
                    <p>
                        Este documento contiene la información acerca de cómo 
                        <b>recogemos, tratamos y protegemos sus datos</b>, y cómo <b>garantizamos sus derechos</b> en
                        relación con su uso y acceso. 
                    </p>
                    <p>
                        En Aira System consideramos 
                        <b>
                            de vital importancia proteger su derecho a la privacidad y la protección de sus datos
                            personales
                        </b>
                        , y por ello queremos garantizar que se sienta seguro en todo momento.
                    </p>
                    <p>
                        En nuestra actividad, tratamos y recogemos sus datos de forma <b>lícita, leal y transparente</b>
                        , con <b>fines determinados, explícitos y legítimos</b>. Aira System no tratará sus datos para 
                        <b>finalidades incompatibles</b> con dichos fines.
                    </p>
                    <p>
                        Además, serán <b>adecuados, pertinentes y limitados</b> en relación con los fines para los que
                        se utilicen, <b>exactos, se mantendrán el tiempo necesario</b>, y Aira System adoptará medidas
                        técnicas u organizativas adecuadas para <b>garantizar su seguridad. </b>
                    </p>
                    <p>
                        Por favor, 
                        <b>
                            <u>lea atentamente este documento</u>
                        </b>
                         para saber más acerca de nuestra Política de Privacidad.
                    </p>
                </div>
            </div>
            {/* IDENTIDAD */}
            <div className="terminos-seccion">
                <h2>Identidad y datos de contacto del responsable del tratamiento de sus datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        <b>Denominación e identidad:</b> Digitalización de Procesos Técnicos S.L. (en adelante, aira
                        system)
                    </p>
                    <p>
                        <b>CIF:</b> B06765671
                    </p>
                    <p>
                        <b>Dirección y Código Postal:</b>  C/ Cochabamba 24, 1ºA 28016, Madrid, España
                    </p>
                    <p>
                        <b>Información de contacto:</b> 647 519 007 -  info@aira-system.com
                    </p>
                </div>
            </div>
            <div className="terminos-seccion">
                {/* DATOS PERSONALES */}
                <h2>Datos personales que tratamos y de donde proceden</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Con ocasión de su relación con nosotros, se podrán tratar las siguientes categorías de datos
                        personales:
                    </p>
                    <ul>
                        <li>
                            <b>Datos identificativos</b> (nombre, apellidos)
                        </li>
                        <li>
                            <b>Datos de contacto</b>
                        </li>
                        <li>
                            <b>Datos financieros</b> (datos de facturación)
                        </li>
                        <li>
                            <b>Datos del riesgo asegurado</b> (los relativos a los bienes que han sido asegurado,
                            condiciones, riesgos de los mismos, entre otros)
                        </li>
                        <li>
                            <b>Imágenes</b>: relativas a los activos y con el objetivo de asegurar la preexistencia del
                            elemento asegurado.
                        </li>
                    </ul>
                    <p>
                        Los datos personales mencionados anteriormente pueden provenir del propio interesado o, en su
                        caso, de su representante legal o voluntario. 
                    </p>
                </div>
            </div>
            {/* FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES */}
            <div className="terminos-seccion">
                <h2>Finalidades del tratamiento de sus datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Aira System tratará sus datos para <b>fines específicos, explícitos y legítimos</b> de acuerdo
                        con cualquier información proporcionada. Dependiendo de los diferentes 
                        <b>productos o servicios</b> que utilice en cada momento, necesitaremos tratar 
                        <b>unos datos y otros</b>, por ejemplo, al registrarse en nuestra 
                        <b>página web o contratar alguno de nuestros servicios.</b>
                    </p>
                    <p>
                        También queremos transmitirle que Aira System no 
                        <b>tratará ningún dato de categoría especial o sensible</b>, en los términos que establece el
                        artículo 9 del RGPD. 
                    </p>
                    <p>
                        Aira System tendrá en cuenta <b>el principio de minimización de los datos</b>, y recogerá y
                        tratará los datos que sean necesarios y pertinentes para las finalidades que se recogen a
                        continuación. 
                    </p>
                    <p>
                        En consonancia con lo anterior, <b>nuestra base de legitimación</b> dependerá del{' '}
                        <b>tipo de información</b> y podrá ser, entre otros, 
                        <b>su consentimiento o el interés legítimo (la base de legitimación</b> específica para cada 
                        <b>finalidad</b> se detalla a continuación).
                    </p>
                    <table>
                        <tr>
                            <th>Finalidad</th>
                            <th>Descripción</th>
                        </tr>
                        <tr>
                            <td>Cumplimiento de obligaciones legales</td>
                            <td>
                                Puede resultar necesario que tratemos sus datos con el fin de gestionar y cumplir con
                                los requerimientos legales que correspondan. 
                            </td>
                        </tr>
                        <tr>
                            <td>Formalización y ejecución del contrato</td>
                            <td>
                                Los datos personales serán tratados para gestionar y ejecutar la relación contractual en
                                la que toma parte
                            </td>
                        </tr>
                        <tr>
                            <td>Acreditación preexistencia del elemento asegurado</td>
                            <td>
                                En ocasiones, se van a recibir fotografías por parte del asegurado de aquellos elementos
                                o activos objeto del contrato de seguro, con el único fin de asegurar la existencia de
                                éstos en caso de siniestro. Estas imágenes únicamente van a ser visualizadas por Aira en
                                el caso de que finalmente se produzca el siniestro y sea necesario acreditar la
                                preexistencia de los activos asegurados. 
                            </td>
                        </tr>
                        <tr>
                            <td>Colaboración con Cuerpos y Fuerzas de Seguridad del Estado.</td>
                            <td>
                                En caso de siniestro u otras situaciones que lo requieran, es necesario comunicar
                                ciertos datos de los asegurados para la correcta actuación de los Cuerpos y Fuerzas de
                                la Seguridad del Estado.
                            </td>
                        </tr>
                        <tr>
                            <td>Prevención del fraude</td>
                            <td>
                                El tratamiento de datos para tal fin tiene como objetivo reducir dicha actividad
                                delictiva, que incrementa la siniestralidad de las entidades aseguradoras, perjudicando
                                a los propios asegurados. 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Envío de comunicaciones comerciales relacionados con productos o servicios ya
                                contratados
                            </td>
                            <td>
                                Es posible que se envíen comunicaciones comerciales o publicitarias al cliente,
                                relacionadas con los productos ya contratados, para mejorar la prestación del servicio
                                en los términos que las partes convengan.
                            </td>
                        </tr>
                        <tr>
                            <td>Envío de comunicaciones comerciales a clientes</td>
                            <td>
                                Siempre y cuando usted nos dé su consentimiento, trataremos sus datos para fines
                                promocionales y publicitarios sobre nuestros servicios.
                            </td>
                        </tr>
                        <tr>
                            <td>Fines estadísticos </td>
                            <td>
                                En este caso, se analiza la base de datos para generar estadísticas interesantes para el
                                sector, pero no se utilizan datos concretos de ningún interesado para tal fin. 
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            {/* BASES DE LEGITIMACION DEL TRATAMIENTO DE DATOS PERSONALES */}
            <div className="terminos-seccion">
                <h2>Bases de legitimación del tratamiento de sus datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Las <b>bases de legitimación</b> que nos permiten tratar sus datos personales de acuerdo con la
                        legislación vigente son las que se detallan a continuación: 
                    </p>
                    <table>
                        <tr>
                            <th>Finalidad</th>
                            <th>Legitimación</th>
                        </tr>
                        <tr>
                            <td>Acreditación de preexistencia del elemento asegurado</td>
                            <td>
                                Las fotografías del elemento asegurado son necesarias para la correcta ejecución del
                                contrato y prestación del servicio ofrecido por Aira 
                            </td>
                        </tr>
                        <tr>
                            <td>Envío de comunicaciones comerciales</td>
                            <td>El interesado debe prestar su consentimiento expreso para tal fin </td>
                        </tr>
                        <tr>
                            <td>
                                Comunicaciones comerciales relacionadas con productos o servicios similares a los
                                contratados
                            </td>
                            <td>Interés legítimo </td>
                        </tr>
                        <tr>
                            <td>Cumplimiento de obligaciones legales</td>
                            <td>
                                Tratamiento necesario para cumplir con las obligaciones legales que se derivan de la
                                legislación vigente aplicable al sector de las aseguradoras
                            </td>
                        </tr>
                        <tr>
                            <td>Formalización y ejecución del contrato</td>
                            <td>
                                Tratamiento necesario para la ejecución de un contrato en el que el interesado es parte 
                            </td>
                        </tr>
                        <tr>
                            <td>Colaboración con Cuerpos y Fuerzas de Seguridad del Estado</td>
                            <td>Interés público</td>
                        </tr>
                        <tr>
                            <td>Prevención del fraude</td>
                            <td>Interés público / Interés legítimo</td>
                        </tr>
                        <tr>
                            <td>Fines estadísticos </td>
                            <td>Interés legítimo </td>
                        </tr>
                    </table>
                </div>
            </div>
            {/* DESTINATARIOS DE LOS DATOS PERSONALES */}
            <div className="terminos-seccion">
                <h2>Destinatarios de los datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        En relación a los destinatarios de los datos personales, le informamos que Aira system toma las
                        medidas necesarias para asegurar el cumplimiento de los principios de protección de datos y
                        privacidad con las terceras partes con las cuáles mantiene sus relaciones, y que su fin
                        principal es cumplir con el deber de diligencia en dichas actividades.
                    </p>
                    <p>
                        Por tanto, le informamos que, para cumplir con las finalidades indicadas en la presente Política
                        de Privacidad, es necesario que demos acceso a terceras partes que nos presten apoyo en los
                        servicios que ofrecemos, a saber:
                    </p>
                    <p>
                        <b>-Amazon Web Services:</b> Aira es asistida por Amazon Web Services para la implementación de
                        medidas de seguridad idóneas para proteger los datos de los interesados. Advertimos de que AWS
                        tiene su propia Política de Privacidad, Política de Cookies y condiciones legales, por lo que
                        recomendamos su lectura, ya que no nos hacemos responsables de la forma en la que tratan los
                        datos las terceras partes. 
                    </p>
                    <p>
                        <b>-Corredurías de seguro y aseguradoras:</b> En virtud del cumplimiento de los servicios
                        expresados, será necesario mantener las relaciones contractuales oportunas con corredurías de
                        seguro y aseguradoras, con el fin de desarrollar de manera efectiva nuestros servicios. 
                    </p>
                    <p>
                        <b>-Google Analytics:</b> Los datos recopilados por esta herramienta son totalmente anónimos y
                        no permiten identificar al usuario, sin embargo, a la luz del RGPD, pueden ser considerados como
                        datos personales. Las cookies utilizadas en este caso son las de la compañía Google LLC,
                        específicamente las relacionadas con el servicio Google Analytics. El uso de Google Analytics
                        implica la implementación del código de rastreo suministrado por Google (llamado &quot;tracking
                        code&quot;) en nuestra página. Dicho código está basado en cookies, sin embargo, puede ser
                        utilizado por otras tecnologías de rastreo. Bajo el siguiente 
                        <a href="https://www.google.com/intl/en_us/analytics/" target="_blank" rel="noreferrer">
                            enlace
                        </a>
                         puede encontrar información más detallada acerca de Google Analytics.
                    </p>
                    <p>
                        Ud. puede evitar esta modalidad de tratamiento de sus datos personales (bloquear Google
                        Analytics) seleccionando las opciones apropiadas en dicho servicio, a través del siguiente 
                        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
                            enlace
                        </a>
                        .
                    </p>
                    <p>
                        <b>-Hotjar:</b> Usamos Hotjar para comprender mejor las necesidades de nuestros usuarios y
                        optimizar este servicio y experiencia. Hotjar es un servicio de tecnología que nos ayuda a
                        comprender mejor la experiencia de nuestros usuarios (por ejemplo, cuánto tiempo pasan en qué
                        páginas, en qué enlaces eligen hacer clic, qué les gusta y no les gusta a los usuarios, etc.) y
                        esto nos permite construir y mantener nuestro servicio con los comentarios de los usuarios.
                        Hotjar utiliza cookies y otras tecnologías para recopilar datos sobre el comportamiento de
                        nuestros usuarios y sus dispositivos (en particular, la dirección IP del dispositivo (capturada
                        y almacenada solo de forma anónima), el tamaño de la pantalla del dispositivo, el tipo de
                        dispositivo (identificadores únicos del dispositivo), la información del navegador, la ubicación
                        geográfica (solo país), idioma preferido utilizado para mostrar nuestro sitio web). Hotjar
                        almacena esta información en un perfil de usuario seudonimizado. Ni Hotjar ni nosotros
                        utilizaremos nunca esta información para identificar usuarios individuales o para compararla con
                        más datos sobre un usuario individual. Para obtener más detalles, consulte la política de
                        privacidad de Hotjar haciendo clic en este 
                        <a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank" rel="noreferrer">
                            enlace
                        </a>
                        . Puede optar por no participar en la creación de un perfil de usuario, el almacenamiento de
                        datos de Hotjar sobre su uso de nuestro sitio y el uso de Hotjar de cookies de seguimiento en
                        otros sitios web siguiendo este 
                        <a href="https://www.hotjar.com/policies/do-not-track/" target="_blank" rel="noreferrer">
                            enlace
                        </a>
                        .
                    </p>
                    <p>
                        <b>-Autoridades públicas, reguladores u órganos gubernamentales:</b> Estos sujetos serán
                        destinatarios de los datos en aquellos supuestos en que es necesario hacerlo por ley, normativa
                        local o en el cumplimiento de obligaciones regulatorias. 
                    </p>
                    <p>
                        <b>-Fuerzas y Cuerpos de la Seguridad del Estado: </b>Los datos podrán ser comunicados en el
                        caso de la producción de un siniestro, cuando sea necesario. 
                    </p>
                </div>
            </div>
            {/* PLAZO DE CONSERVACION DE DATOS PERSONALES */}
            <div className="terminos-seccion">
                <h2>Plazo de conservación de sus datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        En Aira System conservaremos sus datos 
                        <b>
                            el tiempo estrictamente necesario para llevar a cabo las actividades para las que son
                            necesarios, salvo
                        </b>
                         en aquellos casos en los que sea necesario para 
                        <b>cumplir con nuestras obligaciones legales o contractuales</b> o cuando sea necesario para 
                        <b>prestar nuestros servicios.</b>
                    </p>
                </div>
            </div>
            {/* DERECHOS EN MATERIA DE PROTECCION DE DATOS */}
            <div className="terminos-seccion">
                <h2>Sus derechos en materia de protección de datos</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Aira System reconoce y garantiza el <b>ejercicio</b>, de manera <b>gratuita</b> en los supuestos
                        establecidos en la legislación vigente, en <b>cualquier momento</b> y de manera 
                        <b>efectiva y accesible</b> de los siguientes derechos:
                    </p>
                    <ul className="dash-list">
                        <li>
                            <b>Acceso.</b> Tiene derecho a <b>acceder a sus datos</b>, así como 
                            <b>a saber si sus datos personales están siendo o no tratados.</b> En este sentido, puede
                            obtener <b>una copia de sus datos personales</b>, aunque el ejercicio de este derecho 
                            <b>
                                no podrá afectar negativamente a los derechos de otras personas y su derecho a la
                                protección de datos.
                            </b>
                        </li>
                        <li>
                            <b>Rectificación.</b> Tiene derecho como interesado a 
                            <b>
                                obtener, sin dilación indebida, la rectificación de datos inexactos o a completar datos
                                incompletos. 
                            </b>
                        </li>
                        <li>
                            <b>Limitación.</b> Tiene derecho como interesado a obtener la{' '}
                            <b>limitación del tratamiento de sus datos,</b> siempre que se dé alguna de las siguientes
                            circunstancias:
                            <ul className="dash-list indent-list">
                                <li>
                                    Impugne la exactitud de los datos, durante un plazo que nos permita verificar su
                                    exactitud.
                                </li>
                                <li>Cuando prefiera la limitación a la supresión de los datos.</li>
                                <li>
                                    Cuando sus datos no sean tratados y deban ser suprimidos, pero se necesiten para
                                    formular una reclamación o responder a un requerimiento.
                                </li>
                                <li>
                                    Cuando se haya opuesto al tratamiento, pero se estén verificando los motivos
                                    legítimos de la misma.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>Oposición.</b> Tiene derecho a 
                            <b>oponerse en cualquier momento, por motivos relacionados con su situación particular</b>
                            , a que sus <b>datos personales sean objeto de un tratamiento legítimo.</b>
                        </li>
                        <li>
                            <b>Supresión.</b> Tiene derecho como interesado a 
                            <b>que, sin dilación indebida, se supriman</b> sus datos, siempre y cuando se de 
                            <b>una causa justificada para ello. </b>
                        </li>
                        <li>
                            <b>Portabilidad.</b> Tiene derecho como interesado <b>a recibir sus datos personales</b> en
                            un formato <b>estructurado, de uso común y de lectura mecánica</b>, siempre y cuando sea
                            técnicamente posible. Este derecho no podrá 
                            <b>afectar negativamente a los derechos y libertades de otro.</b>
                        </li>
                        <li>
                            <b>A no ser objeto de decisiones automatizadas.</b> Tiene derecho como interesado a no ser
                            objeto de decisiones adoptadas como consecuencia de un{' '}
                            <b>tratamiento automatizado, incluyendo la elaboración de perfiles</b>, que puedan resultar
                            en efectos significativos para usted
                        </li>
                    </ul>
                    <p>
                        No obstante, según declara 
                        <a
                            href="https://www.aepd.es/es/derechos-y-deberes/conoce-tus-derechos/derecho-no-ser-objeto-de-decisiones-individuales"
                            target="_blank"
                            rel="noreferrer"
                        >
                            la Agencia Española de Protección de Datos
                        </a>
                        , este derecho no será aplicable cuando ello sea 
                        <b>necesario para la celebración de un contrato entre usted y el responsable</b>, o cuando el 
                        <b>tratamiento de sus datos se funde en su consentimiento prestado con anterioridad.</b>
                    </p>
                    <p>
                        En ambos casos, garantizamos su derecho a obtener la intervención humana, expresar su punto de
                        vista e impugnar dicha decisión. 
                    </p>
                    <p>
                        La Agencia Española de Protección de Datos pone a su disposición información actualizada para
                        que conozca en profundidad y en todo momento 
                        <a
                            href="https://www.aepd.es/es/derechos-y-deberes/conoce-tus-derechos"
                            target="_blank"
                            rel="noreferrer"
                        >
                            cuáles son sus derechos en materia de protección de datos.
                        </a>
                    </p>
                    <p>
                        Si quiere ejercer sus derechos ante el responsable de tratamiento, haga clic 
                        <a
                            href="https://seifti.io/site/right?c=3ab72d8fdbc822c9cf1a553a42653260aac2857941a471d8646f37c3a15aeb75"
                            target="_blank"
                            rel="noreferrer"
                        >
                            aquí.
                        </a>
                         
                    </p>
                </div>
            </div>
            {/* DERECHOS A PRESENTAR UNA RECLAMACION ANTE LA AGENCIA ESPAÑOLA DE PROTECCION DE DATOS */}
            <div className="terminos-seccion">
                <h2>Derecho a presentar una reclamación ante la Agencia Española de Protección de Datos</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Además, Aira System le informa que, en virtud de lo dispuesto en el artículo 77 del Reglamento
                        General de Protección de Datos (RGPD), tiene usted derecho como interesado a 
                        <b>presentar una reclamación ante una autoridad de control</b>, si considera que el tratamiento
                        de datos personales que le conciernen infringe dicho Reglamento.
                    </p>
                </div>
            </div>
            {/* COMO EJERCITAR SUS DERECHOS */}
            <div className="terminos-seccion">
                <h2>Cómo ejercitar sus derechos</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Podrá ejercitar sus derechos en relación a sus datos personales haciendo clic 
                        <a
                            href="https://seifti.io/site/right?c=3ab72d8fdbc822c9cf1a553a42653260aac2857941a471d8646f37c3a15aeb75"
                            target="_blank"
                            rel="noreferrer"
                        >
                            aquí
                        </a>
                        , o enviando una comunicación por escrito a  C/ Cochabamba 24, 1ºA 28016, Madrid, España,
                        indicando el motivo de su reclamación, o través de nuestro correo electrónico{' '}
                        <a href="mailto:info@aira-system.com">info@aira-system.com</a>
                    </p>
                    <p>
                        También podrá ejercitar sus derechos a través de su representante legal, y pondremos a su
                        disposición cualquier información necesaria para ejercitar sus derechos. 
                    </p>
                </div>
            </div>
            {/* EXISTENCIA DE DECISIONES AUTOMATIZADAS O ELABORACION DE PERFILES */}
            <div className="terminos-seccion">
                <h2>Existencia de decisiones automatizadas o elaboración de perfiles</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Sobre este punto, le informamos que según <b>el artículo 22 del RGPD</b>, todo interesado tendrá
                        derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado,
                        incluida la elaboración de perfiles, que produzca efectos jurídicos en él o le afecte
                        significativamente de modo similar. 
                    </p>
                    <p>
                        Por tanto, solo se permitirán dichas situaciones cuando sean necesarias para la celebración o 
                        <b>ejecución de un contrato</b> entre el interesado y un responsable de tratamiento, cuando esté
                        autorizada por el Derecho de la Unión, o cuando se base en el 
                        <b>consentimiento explícito del interesado.</b>
                    </p>
                    <p>
                        Aira system de acuerdo con el RGPD y las directrices sobre esta materia, cumplirá con las
                        exigencias impuestas por las mismas, cumplirá con los 
                        <b>principios de licitud, lealtad y transparencia</b>, y tiene la intención a través de esta
                        política de <b>cumplir con su deber de información</b> acerca de la 
                        <b>existencia de decisiones automatizadas y elaboración de perfiles</b> y con el 
                        <b>deber de transparencia</b>, así como facilitar información significativa sobre la lógica
                        aplicada, su importancia y las consecuencias previstas de este tratamiento para el interesado. 
                    </p>
                    <p>
                        El uso de inteligencia artificial en Aira, tiene como finalidad principal la prevención del
                        fraude, garantizando, en todo caso, la veracidad sobre la existencia -o no- de los activos
                        asegurados por el cliente. 
                    </p>
                    <p>
                        Este proceso se lleva a cabo a través del análisis de las fotografías de los activos asegurados
                        (otorgadas por el asegurado de manera libre, y con su consentimiento expreso), que permite
                        detectar la preexistencia del mismo en caso de siniestro. 
                    </p>
                    <p>
                        Informamos a los usuarios de que Aira no toma ninguna decisión en base al resultado del
                        análisis. 
                    </p>
                    <p>
                        En cumplimiento del principio de información y transparencia de los artículos 13 y 14 RGPD, se
                        expone la siguiente información sobre la <b>lógica aplicada</b> a los tratamientos: 
                        <ul className="dash-list remove-indent add-v-gap">
                            <li>
                                <b>Datos empleados para la toma de decisiones:</b>
                                <br />
                                Los datos empleados para la toma de decisiones consisten en fotografías tomadas y
                                entregadas voluntariamente por los interesados, mediando su consentimiento expreso. Las
                                fotografías no son personales, sino que son imágenes de bienes y otros activos
                                asegurados. 
                            </li>
                            <li>
                                {' '}
                                <b>Antigüedad de los datos empleados para tomar las decisiones:</b>
                                <br />
                                Aira no toma decisiones con efectos jurídicos para los interesados. Los datos empleados
                                para el funcionamiento de la IA, son siempre actualizados, garantizando la exactitud y
                                veracidad de los mismos.
                            </li>
                            <li>
                                {' '}
                                <b>Importancia relativa de los datos personales a la hora de tomar la decisión:</b>
                                <br />
                                Los datos empleados son importantes para poder acreditar, la existencia del elemento
                                asegurado, pero únicamente se hace uso de los estrictamente necesarios para tal
                                finalidad. Los datos personales no se emplean para tomar tomar una decisión sobre el
                                interesado que pueda ser decisiva para él o discriminatoria en función de los datos
                                suministrados al sistema, sino que, únicamente se usan para acreditar una situación
                                objetiva ( la existencia o no del elemento asegurado).  
                            </li>
                            <li>
                                {' '}
                                <b>Calidad de los datos de entrenamiento y el tipo de patrones utilizados:</b>
                                <br />
                                Los datos utilizados para el entrenamiento del sistema son exactos, completos, íntegros
                                y actualizados, con el objetivo de que los análisis realizados por el sistema sean
                                coherentes, confiables y no presenten errores que puedan perjudicar al interesado. No
                                obstante, las evaluaciones realizadas son revisadas por personal humano cualificado, lo
                                que supone una garantía añadida en el proceso. 
                            </li>
                            <li>
                                {' '}
                                <b>Existencia de supervisión humana cualificada:</b>
                                <br />
                                Si. Las evaluaciones realizadas son supervisadas por personal humano cualificado. 
                            </li>
                            <li>
                                {' '}
                                <b>Auditorías sobre las posibles desviaciones de los resultados de las inferencias:</b>
                                <br />
                                La tecnología empleada en Aira está en constante revisión para garantizar la fiabilidad
                                y efectividad de la misma. 
                            </li>
                            <li>
                                {' '}
                                <b>
                                    ¿El sistema de Inteligencia Artificial contiene información sobre terceros
                                    identificables?
                                </b>
                                <br />
                                Destacamos, asimismo, que las fotografías no aparecen terceros identificables,
                                procediendo al pixelado de estos en caso de que aparezcan accidentalmente, por lo que no
                                se realiza ningún tipo de tratamiento de ese de dato biométrico. 
                            </li>
                        </ul>
                    </p>
                    <p>
                        Además de aplicar los principios de <b>limitación de la finalidad</b> y de 
                        <b>minimización de datos</b>, Aira System se asegura de adoptar e implementar otras medidas
                        técnicas adecuadas para garantizar el derecho a la privacidad y la protección de datos, así como
                        las medidas técnicas y organizativas adecuadas para tales fines. 
                    </p>
                    <p>
                        Por ello, y sin perjuicio de los principios aplicables al tratamiento de datos personales a los
                        que hemos hecho referencia anteriormente, Aira system  asegura también el cumplimiento{' '}
                        <b>las bases o condiciones de legitimación del tratamiento</b>, así como 
                        <b>
                            la atención a las solicitudes de ejercicio de derechos en protección de datos cuando los
                            datos personales se utilizan para la realización de perfiles
                        </b>
                        , considerando particularmente en los mayores riesgos derivados de un tratamiento que implicase
                        una evaluación de aspectos personales con el fin de crear o utilizar perfiles personales de los
                        afectados.
                    </p>
                </div>
            </div>
            {/* MEDIDAS DE SEGURIDAD APLICADAS A LOS DATOS PERSONALES */}
            <div className="terminos-seccion">
                <h2>Medidas de seguridad aplicadas a los datos personales</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        Con la finalidad de proteger y salvaguardar sus datos personales, Aira System{' '}
                        <b>aplica medidas técnicas y organizativas</b>, con el fin de garantizar la integridad,
                        confidencialidad, disponibilidad y resiliencia de los datos.
                    </p>
                </div>
            </div>
            {/* TRANSFERENCIAS INTERNACIONALES DE DATOS */}
            <div className="terminos-seccion">
                <h2>Transferencias internacionales de datos</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        En este sentido y con la intención de cumplir con el <b>principio de transparencia</b>, queremos
                        informarle en virtud del <b>artículo 45 Y 46 RGPD</b>, de la intención de realizar 
                        <b>
                            transferencias de datos personales que sean objeto de tratamiento o vayan a serlo tras su
                            transferencia a un tercer país u organización internacional.
                        </b>
                    </p>
                    <p>
                        A este respecto, Aira System actualmente realiza únicamente transferencias internacionales de
                        datos a través de los servicios que ofrece Amazon Web Services, y en todo caso, cumpliendo con
                        las garantías recogidas en la legislación vigente para la adecuación de estas a los derechos de
                        los interesados.
                    </p>
                </div>
            </div>
            {/* INFORMACION ACERCA DE LAS COOKIES */}
            <div className="terminos-seccion">
                <h2>Información acerca de las Cookies</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        De manera breve, también queremos informarle que utilizamos cookies, y que puede encontrar toda
                        la información detallada en nuestra 
                        <b>
                            <u>
                                <a
                                    href="https://aira-system.com/politica-de-cookies-ue"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Política de Cookies
                                </a>
                            </u>
                        </b>
                        . A través de esta, podrá conocer qué cookies utilizamos, con qué finalidades y más información
                        de importancia en relación a las mismas. 
                    </p>
                </div>
            </div>
            {/* CAMBIOS Y ACTUALIZACIONES EN NUESTRA POLITICA DE PRIVACIDAD */}
            <div className="terminos-seccion">
                <h2>Cambios y actualizaciones en nuestra Política de Privacidad</h2>
                <div className="terminos-seccion-parrafos">
                    <p>
                        En Aira System, 
                        <b>
                            con el objetivo de mejorar nuestra Política de Privacidad, podremos actualizar y modificar
                            nuestra Política de Privacidad en cualquier momento y cuando lo consideremos oportuno.  
                        </b>
                    </p>
                    <p>
                        En este sentido, 
                        <b>
                            nos reservamos el derecho a modificar esta Política en cualquier momento, por lo que le
                            recomendamos que consulte esta Política de Privacidad frecuentemente
                        </b>
                        , para así poder estar atento a cualquier cambio o modificación.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TerminosYCondiciones;
