import { Modal, Pagination, Tooltip, Typography } from '@mui/material';
import {
    DataGrid as MuiDataGrid,
    GridColDef,
    gridPageCountSelector,
    gridPageSelector,
    GridRowsProp,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClientesSync, EditarClienteSync, getClienteById } from '../../features/clientes/ClientesSlice';
import EditarCliente from '../../features/clientes/detalles/EditarCliente';
import { nuevaInspeccion, statusSyncInspeccion } from '../../features/inspecciones/inspeccionesSlice';
import { JsonAnimation } from '../JsonAnimation/JsonAnimation';
import './DataGrid.scss';
import RowsWithError from './RowsWithError';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import { CalendarOff, Clock } from 'tabler-icons-react';

interface textWithIcons {
    item: Array<any>;
    textColor: string;
    iconColor: string;
    background: string;
    fontSize: string;
    padding: string;
    border: string;
    width: string;
}

interface textWithIcon {
    text: string;
    textColor: string;
    icon: any;
    iconColor?: string;
    background?: string;
    fontSize?: string;
    padding?: string;
    border?: string;
    width?: string;
    alignment?: string;
    verticalAlignment?: string;
    fontWeight?: string;
    disableScale?: boolean;
    gap?: string;
    iconMarginBottom?: string;
    vencimiento?: number;
}

interface twoLinesText {
    line1?: string;
    line2?: string;
    pending?: boolean;
}

//interface Columns extends GridColDef {}
export const renderRamosCliente = (properties: textWithIcons) => {
    if (!properties.item[0]) {
        return <div></div>;
    }
    return properties.item.map((i: any) => {
        return (
            // eslint-disable-next-line react/jsx-key
            <>
                <div
                    key={i.key}
                    style={{
                        backgroundColor: `${properties.background || 'transparent'}`,
                        padding: `${properties.padding || 0}`,
                        color: `${properties.textColor || '#171717'}`,
                        border: `${properties.border || 0}`,
                        borderRadius: '8px',
                        fontSize: `${properties.fontSize || '14px'}`,
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        width: `${properties.width || 'max-content'}`,
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ transform: 'scale(0.7)', color: `${properties.iconColor}` }}>
                        {IconsRamos[i.icono]}
                    </div>
                    <span
                        style={{
                            maxWidth: '152px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {i.ramo}
                    </span>
                </div>
                <div style={{ width: 10 }}></div>
            </>
        );
    });
};

const renderTextWithIcons = (properties: textWithIcons) => {
    return properties.item.map((i: any) => {
        return (
            // eslint-disable-next-line react/jsx-key
            <>
                <div
                    key={i.key}
                    style={{
                        backgroundColor: `${properties.background || 'transparent'}`,
                        padding: `${properties.padding || 0}`,
                        color: `${properties.textColor || '#171717'}`,
                        border: `${properties.border || 0}`,
                        borderRadius: '8px',
                        fontSize: `${properties.fontSize || '14px'}`,
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        width: `${properties.width || 'max-content'}`,
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ transform: 'scale(0.7)', color: `${properties.iconColor}` }}>{i.icon}</div>
                    <span
                        style={{
                            maxWidth: '152px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {i.key}
                    </span>
                </div>
                <div style={{ width: 10 }}></div>
            </>
        );
    });
};

const renderEstadoVencimiento = (estadoVencimientoId: number) => {
    if (estadoVencimientoId === 1) {
        return null;
    } else if (estadoVencimientoId === 2) {
        return (
            <div className="estado-prevencida-grid">
                <Clock />
            </div>
        );
    } else if (estadoVencimientoId === 3) {
        return (
            <div className="estado-vencida-grid">
                <CalendarOff />
            </div>
        );
    }
};

export const renderEstadosYSubestados = (properties: textWithIcon) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {renderTextWithIcon(properties)}
            {renderEstadoVencimiento(properties.vencimiento || 1)}
        </div>
    );
};

const renderTextWithIcon = (properties: textWithIcon) => {
    return (
        <div
            style={{
                backgroundColor: `${properties.background || 'transparent'}`,
                padding: `${properties.padding || 0}`,
                color: `${properties.textColor || '#171717'}`,
                border: `${properties.border || 0}`,
                borderRadius: '8px',
                fontSize: `${properties.fontSize || '14px'}`,
                display: 'flex',
                alignItems: `${properties.verticalAlignment || 'center'}`,
                height: '24px',
                width: `${properties.width || 'max-content'}`,
                justifyContent: `${properties.alignment || 'flex-start'}`,
                fontWeight: `${properties.fontWeight || '600'}`,
                gap: `${properties.gap || '4px'}`,
            }}
        >
            <div
                style={{
                    transform: `${properties.disableScale ? 'scale(1)' : 'scale(0.7)'}`,
                    color: `${properties.iconColor}`,
                    marginBottom: `${properties.iconMarginBottom ? properties.iconMarginBottom : 0}`,
                }}
            >
                {properties.icon}
            </div>
            <span style={{ maxWidth: '152px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {properties.text}
            </span>
        </div>
    );
};

const renderText = (lines: twoLinesText) => {
    if (lines.pending) {
        return <span style={{ color: '#B0ADBE', fontWeight: '600', fontSize: '14px' }}>Pendiente</span>;
    } else {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {lines.line1}
                <span style={{ fontWeight: '400', fontSize: '12px', color: '#635F76' }}>{lines.line2}</span>
            </div>
        );
    }
};

export const renderTextWithTooltip = (text: string) => {
    return (
        <Tooltip title={text} placement="right" arrow>
            <div>{text}</div>
        </Tooltip>
    );
};

interface IDataGridProps {
    rows: GridRowsProp;
    columns: GridColDef[];
    toolbar?: () => JSX.Element;
    disablePagination?: boolean;
    route?: string;
    height?: string;
    pageSize?: number;
    sortingModel?: any;
    loader?: boolean;
    rowsWithError?: any;
}

const DataGrid: React.FunctionComponent<IDataGridProps> = (props) => {
    const navigate = useNavigate();
    const inspeccionMasReciente = useSelector(nuevaInspeccion);
    const statusInspeccion = useSelector(statusSyncInspeccion);

    const noRows = () => {
        if (statusInspeccion !== 'idle') {
            return (
                <div className="no-rows">
                    <JsonAnimation animation="loader" height="100px" />
                </div>
            );
        } else {
            return (
                <div className="no-rows">
                    <Typography>No se han encontrado inspecciones</Typography>
                </div>
            );
        }
    };

    const CustomPagination = () => {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                shape="rounded"
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    };

    const clienteQuery = (tipo: string) => {
        if (tipo) {
            switch (tipo) {
                case 'Físico':
                    return '/fisicos';
                case 'Jurídico':
                    return '/juridicos';
            }
        } else {
            return '';
        }
    };

    return (
        <>
            <div className="toolbar">{props.toolbar ? props.toolbar() : null}</div>
            {props.rowsWithError && props.rowsWithError.length ? <RowsWithError rows={props.rowsWithError} /> : null}
            <div
                style={{
                    height: props.height
                        ? props.height
                        : (props.disablePagination ? props.rows.length : 10) * 60 +
                          (props.disablePagination ? 60 : 119),
                }}
            >
                <MuiDataGrid
                    disableSelectionOnClick
                    components={{
                        Pagination: CustomPagination,
                        BaseTooltip: (e: any) => e.children.props.children,
                        LoadingOverlay: () => noRows(),
                    }}
                    hideFooter={props.disablePagination}
                    disableColumnMenu
                    loading={statusInspeccion !== 'idle'}
                    getRowClassName={(params) => {
                        if (params.row.id === inspeccionMasReciente) {
                            return 'highlight-row';
                        } else {
                            return '';
                        }
                    }}
                    initialState={{
                        sorting: {
                            sortModel: props.sortingModel,
                        },
                    }}
                    pageSize={props.pageSize || 100}
                    rows={props.rows}
                    columns={props.columns}
                    onRowClick={(params, event) => {
                        console.log(event);
                        return props.route
                            ? navigate(`../${props.route}${clienteQuery(params.row.tipo?.text)}/${params.row.id}`)
                            : null;
                    }}
                />
            </div>
        </>
    );
};

export { DataGrid, renderTextWithIcon, renderText, renderTextWithIcons };
