import React from 'react';

const Aira0 = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.457031" width="33.1901" height="32" rx="8" fill="#EBF1FE" />
            <g clipPath="url(#clip0_2338_54360)">
                <path
                    d="M18.8555 11V13.6667C18.8555 13.8435 18.926 14.013 19.0515 14.1381C19.177 14.2631 19.3472 14.3333 19.5246 14.3333H22.2012"
                    stroke="#0046CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.8638 23H14.1723C13.8174 23 13.4769 22.8595 13.226 22.6095C12.975 22.3594 12.834 22.0203 12.834 21.6667V12.3333C12.834 11.9797 12.975 11.6406 13.226 11.3905C13.4769 11.1405 13.8174 11 14.1723 11H18.8564L22.2021 14.3333V21.6667C22.2021 22.0203 22.0611 22.3594 21.8102 22.6095C21.5592 22.8595 21.2188 23 20.8638 23Z"
                    stroke="#0046CF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2338_54360">
                    <rect width="16.0597" height="16" fill="white" transform="translate(9.48828 9)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Aira0;
