import React, { useEffect, useState } from 'react';
import { getConsumoLeads } from '../../features/dashboard/dashboardAPI';
import CustomPieCart from '../../components/CustomPieChart/CustomPieCart';
import './Dashboard.scss';
import { getEstadoColor, getEstadoIcon, getEstadoLabel } from '../../utlis/DashboardUtils';
import { FiltrosDashboard } from './Dashboard';
import { EmptyStateImage } from '../../assets/EmptyStateImage';
import EmptyStateDashboard from './EmptyStateDashboard';
import { Link, useNavigate } from 'react-router-dom';

const coloresConsumoLead = ['#E4B305', '#0046CF', '#53BD8A', '#E79142', '#3BA1AE'];

interface consumoLeads {
    label?: string;
    value: number;
    color?: string;
    id?: number;
}

const ConsumoLeads = ({ archivadas, fechaInicial, fechaFinal }: FiltrosDashboard) => {
    const [consumoLeads, setConsumoLeads] = useState([]);
    const agenteId = localStorage.getItem('idAgente');
    const [noResults, setNoResults] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const obtenerConsumoLeads = async () => {
            const results = await getConsumoLeads(Number(agenteId), archivadas, fechaInicial, fechaFinal);
            if (results && results.status === 200) {
                const dataConsumoLeads = results.data.map((cl: any) => {
                    return {
                        label: getEstadoLabel(cl.estado),
                        value: cl.count,
                        color: getEstadoColor(cl.estado),
                        id: cl.estado_id,
                    };
                });
                setConsumoLeads(dataConsumoLeads);
                setNoResults(false);
            } else {
                setNoResults(true);
            }
        };

        obtenerConsumoLeads();
    }, [archivadas, fechaInicial, fechaFinal]);

    const renderConsumoLeads = (leads: consumoLeads[]) => {
        return leads.map((l: consumoLeads) => {
            const estadoClass = l.label ? l.label.replace(/\s/g, '').toLowerCase() : '';
            return (
                <Link
                    key={l.label}
                    to={'/inspecciones'}
                    state={{ estadoId: l.id, estadoLabel: l.label }}
                    className={`consumo-leads-leyenda-item consumo-leads-leyenda-${estadoClass}`}
                >
                    {getEstadoIcon(l.label || '')}
                    <div className="consumo-leads-leyenda-item-info">
                        <p className="consumo-leads-leyenda-item-info-count">{l.value}</p>
                        <p className="consumo-leads-leyenda-item-info-label">{l.label}</p>
                    </div>
                </Link>
            );
        });
    };

    const totalFormularios = (leads: consumoLeads[]) => {
        return leads.reduce((acc, cur) => acc + cur.value, 0);
    };

    return (
        <div className="consumo-leads dashboard-card">
            <h2 className="dashboard-card-title">Consumo leads</h2>
            {noResults ? (
                <EmptyStateDashboard size={120} />
            ) : (
                <div className="consumo-leads-data">
                    <div className="consumo-leads-chart">
                        <CustomPieCart
                            data={consumoLeads}
                            colores={coloresConsumoLead}
                            onPieClick={(id: number, label: string) =>
                                navigate('/inspecciones', { state: { estadoId: id, estadoLabel: label } })
                            }
                        />
                        <div className="consumo-leads-chart-info">
                            <h2>{totalFormularios(consumoLeads)}</h2>
                            <p>Formularios</p>
                        </div>
                    </div>
                    <div className="consumo-leads-leyenda">{renderConsumoLeads(consumoLeads)}</div>
                </div>
            )}
        </div>
    );
};

export default ConsumoLeads;
