import { createAsyncThunk, createSlice, createSelector, PayloadAction, current } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { GetModeloCustom, GetModelos, GetModelosCustomXMaestro, PostModelo, UpdateModeloName } from './ModelosAPI';
import { GetRamosCustomConModelo } from './RamosAPI';
import * as R from 'ramda';

export interface ModeloInterface {
    id: number;
    idModeloMaestro: number;
    nombre: string;
    icono: string;
    active: boolean;
    amountModelsAvailable: number;
    modelosCustom: ModeloInstanceInterface[];
    lastUpdated: string;
}

export interface ModeloInstanceInterface {
    id: number;
    nombre: string;
    activo: boolean;
    ultima_actualizacion: string;
    enlace: string;
}

export interface SubModuloCustomDto {
    id: number;
    titulo: string;
    mostrarInferirDatosAutomaticos: boolean;
    inferirDatosAutomaticos: boolean;
    preguntarAlUsuario: boolean;
    ayuda: string;
    urlPreview: string;
    orden: number;
    esPadre: boolean;
    padreId: number;
    hijos: number[];
    maestroSubModuloId: number;
    preguntaConfiguracion: any;
    activo: boolean;
    tipoPregunta: number;
}
export interface ModuloCustomDto {
    id: number;
    titulo: string;
    activo: boolean;
    obligatorio: boolean;
    tituloCustom: string;
    subModulos: SubModuloCustomDto[];
}
export interface ModeloCustomInterface {
    id: number;
    titulo: string;
    modulos: ModuloCustomDto[];
    ramo_id: number;
    icono: string;
    nombre_ramo: string;
    fecha_creacion: string;
    fecha_ultima_actualizacion: string;
    maestro_modelo_id: number;
    tituloBienvenida: string;
    subtituloBienvenida: string;
    invitacion: string;
    tituloPoliticas: string;
    politicasDePrivacidad: string;
}
export interface RequestModelo {
    nombre: string;
    modelType: string;
    activo: boolean;
}

export interface ModelosState {
    modelos: ModeloInterface[];
    instancias: ModeloInstanceInterface[];
    modeloCustom: ModeloCustomInterface | undefined;
    //requestModelo: RequestModelo;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: ModelosState = {
    modelos: [],
    instancias: [],
    modeloCustom: undefined,
    status: 'idle',
};

export const ModelosSync = createAsyncThunk('/modelos', async () => {
    let response;
    try {
        response = await GetModelos();
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

export const RamosConModeloSync = createAsyncThunk('/ramosConModelo', async (idAseguradora: number) => {
    let response;
    try {
        response = await GetRamosCustomConModelo(idAseguradora);
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

export const ModelosCustomSync = createAsyncThunk('/modelosCustom', async (idModeloMaestro: number) => {
    let response;
    try {
        response = await GetModelosCustomXMaestro(idModeloMaestro);
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

export const ConfigModeloSync = createAsyncThunk('/configModelo', async (modeloCustomId: number) => {
    let response;
    try {
        response = await GetModeloCustom(modeloCustomId);
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

interface UpdateNameData {
    idModeloCustom: number;
    nombre: string;
}

export const UpdateModelName = createAsyncThunk('/updateModeloName', async (data: UpdateNameData) => {
    let response;
    try {
        response = await UpdateModeloName(data.idModeloCustom, data.nombre);
        return data.nombre;
    } catch (error) {
        console.log('Error al obtener los modelos');
    }

    return response;
});

// export const AltaModeloSync = createAsyncThunk('/alta-modelo', async (req: RequestModelo, thunkApi) => {
//     let response;
//     try {
//         response = await PostModelo(req.nombre, req.modelType, req.activo);
//     } catch (error) {
//         console.log('Error al crear modelo');
//     }

//     return response;
// });
const changeValue = (
    state: ModelosState,
    submoduleId: number,
    key: string,
    value: boolean | { [key: string]: any } | null,
    esHijo: boolean,
) => {
    if (state.modeloCustom) {
        let moduleIndex = 0;
        let subModuleIndex = 0;

        if (esHijo) {
            moduleIndex = state.modeloCustom?.modulos.findIndex((m) =>
                m.subModulos.some((sm) => sm.maestroSubModuloId === submoduleId),
            );

            subModuleIndex = state.modeloCustom.modulos[moduleIndex].subModulos.findIndex(
                (sm) => sm.maestroSubModuloId === submoduleId,
            );
        } else {
            moduleIndex = state.modeloCustom?.modulos.findIndex((m) =>
                m.subModulos.some((sm) => sm.id === submoduleId),
            );
            subModuleIndex = state.modeloCustom.modulos[moduleIndex].subModulos.findIndex(
                (sm) => sm.id === submoduleId,
            );
        }

        state.modeloCustom.modulos[moduleIndex].subModulos[subModuleIndex] = {
            ...state.modeloCustom.modulos[moduleIndex].subModulos[subModuleIndex],
            [key]: value,
        };

        /* if (typeof value === 'object' && value.subtitulo) {
            state.modeloCustom.modulos[moduleIndex].subModulos[subModuleIndex].titulo = value.subtitulo;
        } */
    }
};

export const ModelosSlice = createSlice({
    name: 'modelos',
    initialState,
    reducers: {
        changeSubmoduleValue: (state, action) => {
            if (action.payload.esPadre && action.payload.key === 'inferirDatosAutomaticos') {
                changeValue(state, action.payload.submoduleId, action.payload.key, action.payload.value, false);
                changeValue(state, action.payload.submoduleId, 'preguntarAlUsuario', action.payload.value, false);
                if (!action.payload.value) {
                    action.payload.hijos.forEach((h: number) => {
                        changeValue(state, h, action.payload.key, action.payload.value, true);
                    });
                }
            } else if (action.payload.padreId) {
                changeValue(state, action.payload.submoduleId, action.payload.key, action.payload.value, false);
                if (action.payload.value) {
                    changeValue(state, action.payload.padreId, action.payload.key, action.payload.value, true);
                    changeValue(state, action.payload.padreId, 'preguntarAlUsuario', action.payload.value, true);
                }
            } else if (action.payload.esPadre && action.payload.key === 'preguntarAlUsuario') {
                changeValue(state, action.payload.submoduleId, action.payload.key, action.payload.value, false);
                changeValue(state, action.payload.submoduleId, 'inferirDatosAutomaticos', action.payload.value, false);
                if (!action.payload.value) {
                    action.payload.hijos.forEach((h: number) => {
                        changeValue(state, h, 'inferirDatosAutomaticos', action.payload.value, true);
                    });
                }
            } else {
                changeValue(state, action.payload.submoduleId, action.payload.key, action.payload.value, false);
            }
        },
        changeModuleTitle: (state, action) => {
            const indexModulo = state.modeloCustom?.modulos.findIndex((m) => m.id === action.payload.idModulo);
            console.log(indexModulo);
            if (state.modeloCustom && (indexModulo || indexModulo === 0)) {
                state.modeloCustom.modulos[indexModulo].tituloCustom = action.payload.titulo;
            }
        },
        activarPregunta: (state, action) => {
            const modulo = state.modeloCustom?.modulos.find((m) => m.id === action.payload.idModulo);
            console.log(modulo);
            const submodulos = modulo?.subModulos.filter((s) => s.id === action.payload.idSubmodulo);
            console.log(submodulos);
            if (submodulos && submodulos.length) {
                changeValue(state, submodulos[0].id, 'activo', true, false);
            }
        },
        hacerUltimoAlSubmodulo: (state, action) => {
            let indexModulo: any = 0;
            let indexSubmodulo: any = 0;
            indexModulo = state.modeloCustom?.modulos.findIndex((m) => m.id === action.payload.idModulo);
            indexSubmodulo = state.modeloCustom?.modulos[indexModulo].subModulos.findIndex(
                (sm: any) => sm.id === action.payload.idSubmodulo,
            );
            const modulo = state.modeloCustom?.modulos.find((m) => m.id === action.payload.idModulo);
            if (modulo) {
                const submodulo = modulo.subModulos.find((s) => s.id === action.payload.idSubmodulo);
                const submodulos = modulo.subModulos.filter((s) => s.id !== action.payload.idSubmodulo);

                if (submodulo) {
                    const newSubmodulos: SubModuloCustomDto[] = [...submodulos, submodulo];
                    const submodulosOrdenados = newSubmodulos.map((sm, index) => {
                        return {
                            ...sm,
                            orden: index,
                        };
                    });

                    if (state.modeloCustom && state.modeloCustom.modulos[indexModulo]) {
                        state.modeloCustom.modulos[indexModulo].subModulos = submodulosOrdenados;
                    }
                }
            }
        },
        cambiarOrdenPreguntaLibre: (state, action) => {
            let indexModulo: any = 0;
            let indexSubmodulo: any = 0;
            let submoduloAReemplazarId = 0;
            indexModulo = state.modeloCustom?.modulos.findIndex(
                (m: ModuloCustomDto) => m.id === action.payload.idModulo,
            );
            indexSubmodulo = state.modeloCustom?.modulos[indexModulo].subModulos.findIndex(
                (sm: SubModuloCustomDto) => sm.id === action.payload.idSubmodulo,
            );

            if (state.modeloCustom) {
                const submodulosActivos = state.modeloCustom.modulos[indexModulo].subModulos
                    .filter((sm) => sm.activo)
                    .sort((a, b) => a.orden - b.orden);
                const indexSubmoduloActivo = submodulosActivos.findIndex(
                    (sm: SubModuloCustomDto) => sm.id === action.payload.idSubmodulo,
                );

                if (action.payload.direccion === 'subir') {
                    submoduloAReemplazarId = submodulosActivos[indexSubmoduloActivo - 1].id;
                } else {
                    submoduloAReemplazarId = submodulosActivos[indexSubmoduloActivo + 1].id;
                }

                const indexSubmoduloAReemplazar = state.modeloCustom.modulos[indexModulo].subModulos.findIndex(
                    (sm: SubModuloCustomDto) => sm.id === submoduloAReemplazarId,
                );

                const listaReordenada = R.move(
                    indexSubmodulo,
                    indexSubmoduloAReemplazar,
                    state.modeloCustom.modulos[indexModulo].subModulos,
                );

                const submodulosOrdenados = listaReordenada.map((sm, index) => {
                    return {
                        ...sm,
                        orden: index,
                    };
                });

                state.modeloCustom.modulos[indexModulo].subModulos = submodulosOrdenados;
            }
        },
        borrarPreguntaLibre: (state, action) => {
            changeValue(state, action.payload, 'activo', false, false);
            changeValue(state, action.payload, 'preguntaConfiguracion', null, false);
        },
        cambiarDatosBienvenida: (state, action) => {
            if (state.modeloCustom) {
                state.modeloCustom.tituloBienvenida = action.payload.tituloBienvenida;
                state.modeloCustom.subtituloBienvenida = action.payload.subtituloBienvenida;
            }
        },
        cambiarDatosPoliticasPrivacidad: (state, action) => {
            if (state.modeloCustom) {
                state.modeloCustom.tituloPoliticas = action.payload.tituloPoliticas;
                state.modeloCustom.politicasDePrivacidad = action.payload.politicasPrivacidad;
            }
        },
    },
    extraReducers: (builder) => {
        //ramos con modelos
        builder.addCase(RamosConModeloSync.fulfilled, (state, { payload }) => {
            state.modelos = payload.map((d: any) => {
                return {
                    id: d.id,
                    nombre: d.nombre,
                    active: true,
                    icono: d.icono,
                    amountModelsAvailable: d.modelosCustom.length,
                    lastUpdated: d.fechaActualizacion,
                    modelosCustom: d.modelosCustom,
                    idModeloMaestro: d.ramo_id,
                };
            });
            state.status = 'idle';
        });
        builder.addCase(RamosConModeloSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(RamosConModeloSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });
        //modelos
        /* builder.addCase(ModelosSync.fulfilled, (state, { payload }) => {
            state.modelos = payload.data.map((d: any) => {
                return {
                    id: d.id,
                    modelType: d.nombre.toLowerCase(),
                    active: d.activo,
                    amountModelsAvailable: d.modelos_disponibles,
                    lastUpdated: d.ultima_actualizacion.split('n:')[1],
                };
            });
            state.status = 'idle';
        });
        builder.addCase(ModelosSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ModelosSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        }); */

        //modelos custom
        builder.addCase(ModelosCustomSync.fulfilled, (state, { payload }) => {
            state.instancias = payload.data.map((d: any) => {
                return {
                    id: d.id,
                    modelType: d.nombre.toLowerCase(),
                    active: d.activo,
                    amountModelsAvailable: d.modelos_disponibles,
                    lastUpdated: d.ultima_actualizacion.split('n:')[1],
                    link: d.enlace,
                };
            });
            state.status = 'idle';
        });
        builder.addCase(ModelosCustomSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ModelosCustomSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Configuracion modelo custom
        builder.addCase(ConfigModeloSync.fulfilled, (state, { payload }) => {
            state.modeloCustom = payload.data;
            state.status = 'idle';
        });
        builder.addCase(ConfigModeloSync.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(ConfigModeloSync.rejected, (state, { payload }) => {
            state.status = 'failed';
        });

        //Actualizar nombre modelo
        builder.addCase(UpdateModelName.fulfilled, (state, { payload }) => {
            if (state.modeloCustom) {
                state.modeloCustom.titulo = payload;
            }
            state.status = 'idle';
        });
        builder.addCase(UpdateModelName.pending, (state, { payload }) => {
            state.status = 'loading';
        });
        builder.addCase(UpdateModelName.rejected, (state, { payload }) => {
            state.status = 'failed';
        });
    },
});

export const {
    changeSubmoduleValue,
    activarPregunta,
    hacerUltimoAlSubmodulo,
    changeModuleTitle,
    cambiarOrdenPreguntaLibre,
    borrarPreguntaLibre,
    cambiarDatosBienvenida,
    cambiarDatosPoliticasPrivacidad,
} = ModelosSlice.actions;

export const modelListSelector = (state: RootState) => state.modelos.modelos;
export const modelInstancesSelector = (state: RootState) => state.modelos.instancias;
export const configModeloCustom = (state: RootState) => state.modelos.modeloCustom;
export const selectRamosCustom = (state: RootState) => state.modelos.modelos;
export const requestModelosStatus = (state: RootState) => state.modelos.status;

export default ModelosSlice.reducer;
