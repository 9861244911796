import React from 'react';
import './DragAndDrop.scss';
import { FileUploader } from 'react-drag-drop-files';
import { FileX } from 'tabler-icons-react';

interface IDragAndDrop {
    onChange: (file: any) => void;
    acceptedFiles: string[];
    text: string;
    ref?: any;
}

const DragAndDrop: React.FunctionComponent<IDragAndDrop> = ({ onChange, acceptedFiles, text, ref }) => {
    return (
        <FileUploader classes="drop-area" handleChange={onChange} multiple={false} types={acceptedFiles}>
            <div className="drag-area">
                <FileX />
                <p>{text}</p>
            </div>
        </FileUploader>
    );
};

export default DragAndDrop;
