import React, { useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import DragAndDrop from '../../components/dragAndDrop/DragAndDrop';
import './ModalCargaMasiva.scss';
import {
    ChevronDown,
    ChevronDownRight,
    ChevronRight,
    InfoCircle,
    Paperclip,
    Pencil,
    Trash,
    X,
} from 'tabler-icons-react';
import { Autocomplete, Modal, TextField } from '@mui/material';
import { excelColumnToNumber, isClienteFisico, isCsv, validateCifFormat } from '../../utlis';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearCsvRowsWithError,
    ClientesSync,
    detalleCliente,
    selectCsvRowsWithError,
    setCsvRowsWithError,
} from './ClientesSlice';
import { PostClienteMasivo } from './ClientesAPI';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import { selectAseguradoraId } from '../login/loginSlice';
import * as R from 'ramda';
import ErrorCard from '../../components/ErrorCard/ErrorCard';

interface Column {
    label: string;
    example: string;
    onChange: (i: any) => void;
}

interface ColumnIndexes {
    documento: number[];
    nombre: number[];
    apellido: number[];
    telefono: number[];
    email: number[];
    calle: number[];
    numero: number[];
    bloque: number[];
    escalera: number[];
    piso: number[];
    puerta: number[];
    codigoPostal: number[];
    poblacion: number[];
    provincia: number[];
    poligono: number[];
    parcela: number[];
}

const ModalCargaMasiva = () => {
    const initialFileState = { name: '', parsedCsv: [], filteredRows: [] };
    const initialColumnIndexesState = {
        documento: [],
        nombre: [],
        apellido: [],
        telefono: [],
        email: [],
        calle: [],
        numero: [],
        bloque: [],
        escalera: [],
        piso: [],
        puerta: [],
        codigoPostal: [],
        poblacion: [],
        provincia: [],
        poligono: [],
        parcela: [],
    };
    const cliente = useSelector(detalleCliente);
    const aseguradoraId = useSelector(selectAseguradoraId);
    const [modalContent, setModalContent] = useState('renderColumnSelection');
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<any>(initialFileState);
    const [startingRow, setStartingRow] = useState<any>(null);
    const [columnIndexes, setColumnIndexes] = useState<ColumnIndexes>(initialColumnIndexesState);
    const [progress, setProgress] = useState({ currentRow: 0, rowQuantity: 0 });
    const [fileExtensionError, setFileExtensionError] = useState(false);
    const [rowsWithoutName, setRowsWithoutName] = useState<number[]>([]);
    const rowsWithErrors = useSelector(selectCsvRowsWithError);
    const inputRef: any = useRef();
    const dispatch = useDispatch();
    const acceptedFileTypes = ['csv'];
    const agenteId = localStorage.getItem('idAgente');
    const [showTour, setShowTour] = React.useState(false);

    const columns: Column[] = [
        {
            label: 'Documento (DNI, NIE, NIF, CIF)',
            example: 'Ej: Y1233454Q',
            onChange: (d) => setColumnIndexes({ ...columnIndexes, documento: d }),
        },
        {
            label: 'Nombre o Nombre Fiscal',
            example: 'Ej: Alberto / Ej: San Roman Cocina Natural SL',
            onChange: (n) => setColumnIndexes({ ...columnIndexes, nombre: n }),
        },
        {
            label: 'Apellido',
            example: 'Ej: Gómez',
            onChange: (a) => setColumnIndexes({ ...columnIndexes, apellido: a }),
        },
        {
            label: 'Teléfono',
            example: 'Ej: 645346586',
            onChange: (t) => setColumnIndexes({ ...columnIndexes, telefono: t }),
        },
        {
            label: 'Email',
            example: 'Ej: ejemplo@dominio.com',
            onChange: (e) => setColumnIndexes({ ...columnIndexes, email: e }),
        },
        {
            label: 'Dirección (calle)',
            example: 'Ej: Calle de las minas',
            onChange: (c) => setColumnIndexes({ ...columnIndexes, calle: c }),
        },
        {
            label: 'Dirección (número)',
            example: 'Ej: 9',
            onChange: (n) => setColumnIndexes({ ...columnIndexes, numero: n }),
        },
        {
            label: 'Dirección (bloque)',
            example: 'Ej: 75',
            onChange: (b) => setColumnIndexes({ ...columnIndexes, bloque: b }),
        },
        {
            label: 'Dirección (escalera)',
            example: 'Ej: 75',
            onChange: (e) => setColumnIndexes({ ...columnIndexes, escalera: e }),
        },
        {
            label: 'Dirección (piso)',
            example: 'Ej: 75',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, piso: p }),
        },
        {
            label: 'Dirección (puerta)',
            example: 'Ej: 75',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, puerta: p }),
        },
        {
            label: 'Dirección (CP)',
            example: 'Ej: 28004',
            onChange: (c) => setColumnIndexes({ ...columnIndexes, codigoPostal: c }),
        },
        {
            label: 'Dirección (población)',
            example: 'Ej: Madrid',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, poblacion: p }),
        },
        {
            label: 'Dirección (provincia)',
            example: 'Ej: Madrid',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, provincia: p }),
        },
        {
            label: 'Dirección (polígono)',
            example: 'Ej: 75',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, poligono: p }),
        },
        {
            label: 'Dirección (parcela)',
            example: 'Ej: 150',
            onChange: (p) => setColumnIndexes({ ...columnIndexes, calle: p }),
        },
    ];

    const handleFileChange = (file: any) => {
        if (isCsv(file.name)) {
            setFileExtensionError(false);
            Papa.parse(file, {
                skipEmptyLines: true,
                encoding: 'UTF-8',
                complete: (results) => {
                    setFile({ name: file.name, parsedCsv: results.data, filteredRows: results.data });
                },
            });
        } else {
            setFileExtensionError(true);
        }
    };

    const removeFile = () => {
        setFile(initialFileState);
        setStartingRow(null);
    };

    const changeFile = (file: any) => {
        handleFileChange(file);
        setStartingRow(null);
    };

    useEffect(() => {
        setFile({ ...file, filteredRows: file.parsedCsv.slice(startingRow, file.parsedCsv.length) });
    }, [startingRow]);

    useEffect(() => {
        if (progress.currentRow > 0 && progress.currentRow === progress.rowQuantity) {
            if (rowsWithErrors.length) {
                setModalContent('renderErrorModal');
            } else {
                setModalContent('renderSuccessModal');
            }
        }
    }, [progress.currentRow]);

    const toClientes = (rows: any[], indexes: ColumnIndexes) => {
        return rows.map((row: string[], index) => {
            const documento = indexes.documento.map((i: number) => row[i] && row[i].trim()).join(' ');
            if (validateCifFormat(documento)) {
                return {
                    dni: '',
                    nombre: '',
                    apellido: '',
                    movil: '',
                    direccion: {
                        calle: indexes.calle.map((i: number) => row[i] && row[i].trim()).join(' '),
                        numero: indexes.numero.map((i: number) => row[i] && row[i].trim()).join(' '),
                        bloque: indexes.bloque.map((i: number) => row[i] && row[i].trim()).join(' '),
                        escalera: indexes.escalera.map((i: number) => row[i] && row[i].trim()).join(' '),
                        piso: indexes.piso.map((i: number) => row[i] && row[i].trim()).join(' '),
                        puerta: indexes.puerta.map((i: number) => row[i] && row[i].trim()).join(' '),
                        codigoPostal: indexes.codigoPostal.map((i: number) => row[i] && row[i].trim()).join(' '),
                        poblacion: indexes.poblacion.map((i: number) => row[i] && row[i].trim()).join(' '),
                        provincia: indexes.provincia.map((i: number) => row[i] && row[i].trim()).join(' '),
                        poligono: indexes.poligono.map((i: number) => row[i] && row[i].trim()).join(' '),
                        parcela: indexes.parcela.map((i: number) => row[i] && row[i].trim()).join(' '),
                    },
                    activo: 1,
                    tipo_cliente_id: 2,
                    idAgente: agenteId,
                    nombreFiscal: indexes.nombre.map((i: number) => row[i] && row[i].trim()).join(' '),
                    telefonoEmpresa: indexes.telefono.map((i: number) => row[i] && row[i].trim()).join(' '),
                    cif: documento,
                    nombreComercio: indexes.nombre.map((i: number) => row[i] && row[i].trim()).join(' '),
                    email: indexes.email.map((i: number) => row[i] && row[i].trim()).join(' '),
                    idAseguradora: aseguradoraId,
                    rowNumber: index + startingRow + 1,
                };
            } else {
                return {
                    dni: documento,
                    nombre: indexes.nombre.map((i: number) => row[i] && row[i].trim()).join(' '),
                    apellido: indexes.apellido.map((i: number) => row[i] && row[i].trim()).join(' '),
                    movil: indexes.telefono.map((i: number) => row[i] && row[i].trim()).join(' '),
                    direccion: {
                        calle: indexes.calle.map((i: number) => row[i] && row[i].trim()).join(' '),
                        numero: indexes.numero.map((i: number) => row[i] && row[i].trim()).join(' '),
                        bloque: indexes.bloque.map((i: number) => row[i] && row[i].trim()).join(' '),
                        escalera: indexes.escalera.map((i: number) => row[i] && row[i].trim()).join(' '),
                        piso: indexes.piso.map((i: number) => row[i] && row[i].trim()).join(' '),
                        puerta: indexes.puerta.map((i: number) => row[i] && row[i].trim()).join(' '),
                        codigoPostal: indexes.codigoPostal.map((i: number) => row[i] && row[i].trim()).join(' '),
                        poblacion: indexes.poblacion.map((i: number) => row[i] && row[i].trim()).join(' '),
                        provincia: indexes.provincia.map((i: number) => row[i] && row[i].trim()).join(' '),
                        poligono: indexes.poligono.map((i: number) => row[i] && row[i].trim()).join(' '),
                        parcela: indexes.parcela.map((i: number) => row[i] && row[i].trim()).join(' '),
                    },
                    activo: 1,
                    tipo_cliente_id: 1,
                    idAgente: agenteId,
                    nombreFiscal: '',
                    telefonoEmpresa: '',
                    cif: '',
                    nombreComercio: '',
                    email: indexes.email.map((i: number) => row[i] && row[i].trim()).join(' '),
                    idAseguradora: aseguradoraId,
                    rowNumber: index + startingRow + 1,
                };
            }
        });
    };

    const handleImportarClientes = (rows: any[], indexes: ColumnIndexes) => {
        const clientes = toClientes(rows, indexes);
        setModalContent('renderProgress');
        setProgress({ ...progress, rowQuantity: clientes.length });
        dispatch(clearCsvRowsWithError());
        clientes.map(async (c: any) => {
            if ((c.nombre.trim() || c.nombreFiscal.trim()) && (c.dni || c.movil || c.direccion)) {
                const results = await PostClienteMasivo(c);
                if (!R.isEmpty(results?.data.datoConError)) {
                    dispatch(
                        setCsvRowsWithError({
                            datos: results?.data.datoConError,
                            fila: results?.data.rowNumber,
                            clienteId: results?.data.clienteId,
                        }),
                    );
                }
                setProgress((prevState) => {
                    return { ...prevState, currentRow: prevState.currentRow + 1 };
                });
            } else {
                setRowsWithoutName((prevState) => [...prevState, c.rowNumber]);
                setProgress((prevState) => {
                    return { ...prevState, currentRow: prevState.currentRow + 1 };
                });
            }
        });
    };

    const renderUploadForm = () => {
        if (file.parsedCsv.length) {
            return (
                <div className="modal-carga-archivo-subido">
                    <div className="modal-carga-archivo-subido-data">
                        <Paperclip />
                        <span>{file.name}</span>
                    </div>
                    <div className="modal-carga-archivo-subido-actions">
                        <Pencil
                            className="modal-carga-archivo-subido-actions-edit"
                            onClick={() => inputRef.current.click()}
                        />
                        <Trash onClick={removeFile} className="modal-carga-archivo-subido-actions-delete" />
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <DragAndDrop
                        acceptedFiles={acceptedFileTypes}
                        onChange={handleFileChange}
                        text="Arrastra o sube el documento (csv) desde tu PC o móvil"
                    />
                    {fileExtensionError ? (
                        <div className="modal-carga-error">
                            <p className="modal-carga-error-item">
                                <InfoCircle /> Tipo de archivo no soportado, por favor seleccione un archivo CSV
                            </p>
                        </div>
                    ) : null}
                </>
            );
        }
    };

    const renderFileSelection = () => {
        return (
            <div className="modal-carga-file-selection">
                <div className="modal-carga-header">
                    <h1 className="modal-carga-header-title">Importar clientes desde un CSV</h1>
                    <p className="modal-carga-header-steps">
                        <span>2 </span>/ 2
                    </p>
                </div>
                <div className="modal-carga-file-selection-subtitle">
                    <h2 className="modal-carga-subtitle">2. Sube el documento</h2>
                    <p>Carga el documento e indícanos a partir de que fila comienzan los datos.</p>
                </div>
                {renderUploadForm()}
                <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => changeFile(e.target.files && e.target.files[0])}
                    hidden={true}
                    ref={inputRef}
                />
                <div className="modal-carga-file-selection-fila">
                    <p>Los datos de los clientes comienzan en la fila n°:</p>
                    <Autocomplete
                        value={startingRow}
                        id="row-selection-combo-box"
                        disableClearable={true}
                        popupIcon={<ChevronDown />}
                        options={file.parsedCsv.length && Array.from(Array(file.parsedCsv.length).keys())}
                        sx={{ width: 160 }}
                        onChange={(e, v) => {
                            setStartingRow(v);
                        }}
                        disabled={!file.parsedCsv.length}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: false }}
                                label=" "
                                variant="outlined"
                                placeholder="n°"
                            />
                        )}
                    />
                </div>
                <div className="modal-carga-actions">
                    <button className="secondary-button" onClick={() => setModalContent('renderCancelar')}>
                        Cancelar
                    </button>
                    <button
                        disabled={!file.filteredRows.length}
                        onClick={() => handleImportarClientes(file.filteredRows, columnIndexes)}
                    >
                        Importar clientes
                    </button>
                </div>
            </div>
        );
    };

    const onChangeInputColumns = (value: string) => {
        const letterArray = value.split(',');
        const indexesArray = letterArray.map((l: string) => {
            return excelColumnToNumber(l);
        });

        return indexesArray.filter((i: number | null) => i !== null);
    };

    const allowedChars = (e: any) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode === 44 || (charCode >= 65 && charCode <= 90) || e.key === 'Backspace' || charCode === 188) {
            return true;
        } else {
            e.preventDefault();
        }
    };

    const renderColumnsInputs = (columns: Column[]) => {
        return columns.map((column: Column) => {
            return (
                <div key={column.label} className="modal-carga-columns-inputs-item">
                    <div className="modal-carga-columns-inputs-item-label">
                        <h3>{column.label}</h3>
                        <p>{column.example}</p>
                    </div>
                    <input
                        placeholder="Ej: A"
                        type="text"
                        onKeyDown={allowedChars}
                        onChange={(e) => column.onChange(onChangeInputColumns(e.target.value))}
                    />
                </div>
            );
        });
    };

    const renderColumnSelection = () => {
        return (
            <div className="modal-carga-columns">
                <div className="modal-carga-header">
                    <h1 className="modal-carga-header-title">Importar clientes desde un CSV</h1>
                    <p className="modal-carga-header-steps">
                        <span>1 </span>/ 2
                    </p>
                </div>
                <div className="modal-carga-columns-data">
                    <h2 className="modal-carga-subtitle">1. Indica qué columnas se corresponden con cada dato</h2>
                    <p>
                        Indica la/s columna/s que empleaste para cada uno de los datos requeridos. Si usaste varias,
                        escríbelas a todas separándolas con comas. Ej: A,B,C.
                        <br />
                        <br />
                        No hay datos obligatorios pero recomendamos que cuentes con todos para que los clientes se creen
                        de forma completa.
                    </p>
                </div>
                <div className="modal-carga-columns-inputs">
                    <div className="modal-carga-columns-inputs-item">
                        <span>Dato</span>
                        <span> Columna/s</span>
                    </div>
                    {renderColumnsInputs(columns)}
                </div>
                <div className="modal-carga-actions">
                    <button className="secondary-button" onClick={() => setOpen(false)}>
                        Cancelar
                    </button>
                    <button className="primary-button" onClick={() => setModalContent('renderFileSelection')}>
                        Siguiente
                    </button>
                </div>
            </div>
        );
    };

    const handleClose = () => {
        setFile(initialFileState);
        setColumnIndexes(initialColumnIndexesState);
        setStartingRow(null);
        setModalContent('renderColumnSelection');
        setRowsWithoutName([]);
        setProgress({ currentRow: 0, rowQuantity: 0 });
        setFileExtensionError(false);
        setOpen(false);
    };

    const closeOnSuccess = () => {
        handleClose();
        dispatch(ClientesSync());
    };

    const renderProgress = () => {
        return (
            <div className="modal-carga-progress">
                <JsonAnimation animation="loaderUpload" width="85px" height="70px" />
                <div className="modal-carga-progress-text">
                    <h1 className="modal-carga-header-title">Estamos importando tus clientes</h1>
                    <p>Esto tomará unos segundos.</p>
                </div>
                <ProgressBar max={progress.rowQuantity} value={progress.currentRow} />
            </div>
        );
    };

    const renderCancelar = () => {
        return (
            <div className="modal-carga-cancelar">
                <h1 className="modal-carga-header-title">¿Deseas cancelar la importación de clientes?</h1>
                <p>Al confirmar, se cancelará todo el proceso.</p>
                <div className="modal-carga-actions">
                    <button className="secondary-button" onClick={() => setModalContent('renderFileSelection')}>
                        No, deseo continuar con el proceso
                    </button>
                    <button className="primary-button" onClick={handleClose}>
                        Sí, deseo cancelar
                    </button>
                </div>
            </div>
        );
    };

    const renderSuccessModal = () => {
        return (
            <div className="modal-carga-success">
                <div className="modal-carga-success-top">
                    <JsonAnimation animation="success" width="72px" height="72px" />
                    <X onClick={closeOnSuccess} />
                </div>
                <div className="modal-carga-success-text">
                    <h1 className="modal-carga-header-title">Importación de clientes existosa</h1>
                    <p>
                        Revisa los datos de tus clientes importados ingresando al panel de clientes.
                        <br />
                        Para introducir cambios o completar los datos faltantes edita cada cliente manualmente o revisa
                        tu documento cvs, editálo y realiza otra importación. Los datos de los clientes ya añadidos
                        serán actualizados.
                    </p>
                </div>
                <div className="modal-carga-actions">
                    <button className="secondary-button-withIcon" onClick={closeOnSuccess}>
                        Ir al panel de clientes <ChevronRight />
                    </button>
                </div>
            </div>
        );
    };

    const renderErrorModal = () => {
        return (
            <div className="modal-carga-errormsg">
                <JsonAnimation animation="error" width="72px" height="72px" />
                <div>
                    <h1 className="modal-carga-header-title">Importación finalizada con errores</h1>
                    <p>Hemos finalizado con la importación pero hemos detectado algunos errores</p>
                </div>
                {rowsWithoutName.length ? (
                    <ErrorCard
                        type="error"
                        title="Clientes no importados"
                        text="No hemos podido cargar los siguientes clientes ya que no tenían asignado un nombre."
                    >
                        <ul style={{ margin: 0, fontWeight: 600 }}>
                            <li>Filas: {rowsWithoutName.join(', ')}</li>
                        </ul>
                    </ErrorCard>
                ) : null}
                {rowsWithErrors.length ? (
                    <ErrorCard
                        type="warning"
                        title="Clientes importados con errores"
                        text="No hemos podido validar los siguientes emails, teléfonos y/o documentos."
                    />
                ) : null}
                <p>
                    Para resolver los errores puedes modificar el csv y cargarlo de nuevo o corregir los fallos uno a
                    uno en los perfiles de cada cliente
                </p>
                <div className="modal-carga-errormsg-actions">
                    <button className="secondary-button-withIcon" onClick={closeOnSuccess}>
                        Ir al panel de clientes <ChevronRight />
                    </button>
                </div>
            </div>
        );
    };

    const modalContents: any = {
        renderColumnSelection: renderColumnSelection,
        renderFileSelection: renderFileSelection,
        renderCancelar: renderCancelar,
        renderProgress: renderProgress,
        renderSuccessModal: renderSuccessModal,
        renderErrorModal: renderErrorModal,
    };

    return (
        <>
            <button className="secondary-button second-step" onClick={() => setOpen(true)}>
                Importar clientes
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="modal-carga">{modalContents[modalContent]()}</div>
            </Modal>
        </>
    );
};

export default ModalCargaMasiva;
