import React from 'react';
import { Pencil } from 'tabler-icons-react';
import './InspectionCard.scss';

interface Action {
    fn: () => void;
    text: string;
}
interface Data {
    label: string;
    icon?: JSX.Element;
    value: string | JSX.Element;
}
interface IInspectionCardProps {
    items: Array<Data>;
    action?: Action;
    border?: boolean;
    title?: string;
    tag?: JSX.Element;
    columns?: string;
    break?: string;
    edit?: () => void;
}

const InspectionCard: React.FunctionComponent<IInspectionCardProps> = (props) => {
    const renderItems = () => {
        return props.items.map((e) => {
            return (
                <div key={e.label} className="inspection-card-row">
                    <span className="inspection-card-row-label">{e.label}</span>
                    <div
                        className={`inspection-card-row-content ${
                            typeof e.value === 'string' && e.value.length > 20 ? 'long-text-alignment' : ''
                        }`}
                    >
                        <div>{e.icon ? e.icon : ''}</div>
                        <span className="inspection-card-row-content-text">{e.value}</span>
                    </div>
                </div>
            );
        });
    };

    const renderAction = () => {
        if (props.action) {
            return (
                <div className="inspection-card-actions">
                    <button onClick={props.action?.fn}>{props.action?.text}</button>
                </div>
            );
        }
    };

    const renderTitle = () => {
        if (props.title) {
            return (
                <div className="inspection-card-title">
                    <span className="inspection-card-title">{props.title}</span>
                    {props.tag ? props.tag : null}
                </div>
            );
        }
    };

    return (
        <div className="inspection-card">
            {renderTitle()}
            <div
                className="inspection-card-items"
                style={{
                    display: 'grid',
                    gridTemplateColumns: props.columns || '228px 228px',
                }}
            >
                {renderItems()}
            </div>
            {renderAction()}
        </div>
    );
};

export default InspectionCard;
