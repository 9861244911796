import { Modal } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Building,
    BuildingStore,
    CalendarEvent,
    Car,
    CircleCheck,
    ClipboardList,
    DeviceMobile,
    FileText,
    Id,
    Mail,
    MapPin,
    Send,
    User,
} from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ContactCard from '../../../components/contact-card/ContactCard';
import { DataGrid, renderText, renderTextWithIcon } from '../../../components/data-grid/DataGrid';
import DetailsCard from '../../../components/details-card/DetailsCard';
import { getDateFormated } from '../../../utlis';
import {
    renderBorderEstado,
    renderColorEstado,
    renderEstadoText,
    renderIconEstado,
} from '../../inspecciones/InspeccionesFeature';
import NuevaInspeccion from '../../inspecciones/NuevaInspeccion';
import {
    ClientesSync,
    detalleCliente,
    DetalleClienteSync,
    EditarClienteSync,
    RequestCliente,
    RequestDetalleCliente,
    setNuevoClienteId,
} from '../ClientesSlice';
import './DetallesClienteJuridico.scss';
import EditarCliente from './EditarCliente';
import { IconsRamos } from '../../../assets/iconosRamos/IconsRamos';

const DetallesClienteJuridico = (props: any) => {
    const dispatch = useAppDispatch();
    const cliente = useAppSelector(detalleCliente);

    const [open, setOpen] = useState(false);

    const slug = useParams();
    const id = Number(slug.id);
    const request: RequestDetalleCliente = { id: 0 };
    request.id = id;
    useEffect(() => {
        dispatch(setNuevoClienteId(request.id));
        dispatch(DetalleClienteSync(request));
        dispatch(ClientesSync());
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            hide: true,
        },
        { field: 'nombreInspeccion', headerName: 'Nombre evaluación', flex: 1.5, cellClassName: 'data-grid-bold-cell' },
        {
            field: 'ramo',
            headerName: 'Ramo',
            renderCell: (params: any) => renderTextWithIcon(params.value),
            flex: 1,
            sortable: false,
        },
        { field: 'agente', headerName: 'Agente', flex: 1, sortable: false },
        {
            field: 'estado',
            headerName: 'Estado',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1,
            sortable: false,
        },
        {
            field: 'vence',
            headerName: 'Vence',
            renderCell: (params) => renderText(params.value),
            sortComparator: (d1: any, d2: any) => d1.fechaHoraVencimiento - d2.fechaHoraVencimiento,
            flex: 1,
            hide: true,
        },
    ];

    const detalles = [
        { icon: <Building />, label: 'Nombre Fiscal', value: cliente.nombreFiscal },
        { icon: <Building />, label: 'Teléfono empresa', value: cliente.telefonoEmpresa },
        { icon: <Mail />, label: 'Email', value: cliente.email },
        { icon: <BuildingStore />, label: 'Nombre comercial', value: cliente.nombreComercio },
        {
            icon: <MapPin />,
            label: 'Dirección Fiscal',
            value: cliente.direccion,
        },
        { icon: <Id />, label: 'CIF', value: cliente.cif },
        { icon: <CalendarEvent />, label: 'Fecha de creación', value: cliente.fechaCreacion },
    ];

    const datagridHeader = () => {
        return (
            <div className="detalles-cliente-datagrid-header">
                <span>Evaluaciones ({cliente.inspeccion?.length || 0})</span>
                {/* <button>
                    Nueva evaluación <CirclePlus />
                </button> */}
                <NuevaInspeccion icon={true} />
            </div>
        );
    };

    const rowsMap = () => {
        return cliente.inspeccion?.map((inspeccion) => {
            const horaVencimientoFormated =
                inspeccion.fechaVencimiento != null
                    ? new Date(inspeccion.fechaVencimiento).getHours() +
                      ':' +
                      new Date(inspeccion.fechaVencimiento).getMinutes()
                    : '';
            return {
                id: inspeccion.id,
                nombreInspeccion: inspeccion?.nombreInspeccion,
                ramo: {
                    text: inspeccion.ramo_custom?.nombre,
                    icon: IconsRamos[inspeccion.ramo_custom?.icono],
                    background: '#EBF1FE',
                    textColor: '#0046CF',
                    fontSize: '12px',
                    padding: '4px 8px',
                    width: 'auto',
                    alignment: 'flex-end',
                },
                cliente: inspeccion.cliente,
                agente: inspeccion.agente_inspeccion?.nombre,
                estado: {
                    id: inspeccion.estado_inspeccion ? inspeccion.estado_inspeccion.id : '',
                    text: renderEstadoText(inspeccion),
                    icon: inspeccion.gestionado ? (
                        <CircleCheck />
                    ) : (
                        renderIconEstado(inspeccion.estado_inspeccion ? inspeccion.estado_inspeccion.id : '')
                    ),
                    textColor: inspeccion.gestionado
                        ? '#3BA1AE'
                        : renderColorEstado(inspeccion.estado_inspeccion ? inspeccion.estado_inspeccion.id : ''),
                    fontSize: '12px',
                    border: inspeccion.gestionado
                        ? '2px solid #E3F6F9'
                        : renderBorderEstado(inspeccion.estado_inspeccion ? inspeccion.estado_inspeccion.id : ''),
                    padding: '4px 12px 4px 8px',
                    fontWeight: '800',
                    vencimiento: inspeccion.estado_vencimiento_id,
                },
                vence:
                    inspeccion.fechaVencimiento != null
                        ? {
                              line1:
                                  inspeccion.fechaVencimiento != '' ? getDateFormated(inspeccion.fechaVencimiento) : '',
                              line2: inspeccion.fechaVencimiento != '' ? horaVencimientoFormated : '',
                              fechaHoraVencimiento: new Date(inspeccion.fechaVencimiento),
                          }
                        : { pending: true },
            };
        });
    };

    const CloseModal = () => {
        setOpen(false);
    };

    const OpenModal = () => {
        setOpen(true);
    };

    const onEditSubmit = async (editClient: any) => {
        await dispatch(EditarClienteSync(editClient));
        await dispatch(ClientesSync());
        setOpen(false);
    };

    return (
        <div className="detalles-cliente">
            <div className="detalles-cliente-header">
                <div className="detalles-cliente-header-title">
                    <div className="detalles-cliente-header-title-icon">
                        <Building />
                    </div>
                    <div className="detalles-cliente-header-title-info">
                        <span>Cliente</span>
                        <h6>{cliente.nombreComercio}</h6>
                    </div>
                </div>
                <div className="detalles-cliente-header-info">
                    <div className="detalles-cliente-header-info-tipo">
                        <span className="detalles-cliente-header-info-title">Tipo</span>
                        <span className="detalles-cliente-header-info-tipo-value">Jurídico</span>
                    </div>
                    <div className="detalles-cliente-header-info-inspecciones">
                        <span className="detalles-cliente-header-info-title">Evaluaciones</span>
                        <div>
                            <ClipboardList /> <span>{cliente.inspeccion?.length || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detalles-cliente-content">
                <div className="detalles-cliente-left">
                    <DetailsCard items={detalles} edit={() => OpenModal()} rows={3} />
                    <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <EditarCliente onEditSubmit={onEditSubmit} onModalClose={CloseModal} />
                        </div>
                    </Modal>
                    <div className="detalles-cliente-datagrid">
                        <DataGrid
                            rows={cliente.inspeccion ? rowsMap() : []}
                            columns={columns}
                            toolbar={datagridHeader}
                            disablePagination={true}
                            height="400px"
                            sortingModel={[{ field: 'id', sort: 'desc' }]}
                            route="inspecciones"
                        />
                    </div>
                </div>
                {/*                 <div className="detalles-cliente-right">
                    <ContactCard items={contacts} />
                    <div className="detalles-cliente-socios">
                        <span>Socios</span>
                        <ul>{renderSocios()}</ul>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default DetallesClienteJuridico;
