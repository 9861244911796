export const getDateFormated = (fecha: Date) => {
    const month = new Date(fecha).getUTCMonth() + 1;
    const fechaFormated = new Date(fecha).getUTCDate() + '/' + month + '/' + new Date(fecha).getUTCFullYear();
    return fechaFormated;
};

export const getTimeFormated = (fecha: Date) => {
    if (fecha) {
        const f = new Date(fecha);
        const hora = f.getUTCHours().toString().padStart(2, '0');
        const minutos = f.getUTCMinutes().toString().padStart(2, '0');
        const horaFormated = hora + ':' + minutos;
        return horaFormated;
    } else {
        return '--:--';
    }
};

export function debounce<T extends unknown[]>(func: (...args: T) => void, delay: number): (...args: T) => void {
    let timer: any | null = null;
    return (...args: T) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func.call(null, ...args);
        }, delay);
    };
}

export const isTest = (env: any) => {
    return env === 'Desarrollo' || env === 'Test' || env === 'Localhost' || env === 'Staging';
};

export const isSuperUser = () => {
    const idAseguradora = localStorage.getItem('idAseguradora');
    return idAseguradora === '0';
};

export const filterClientes = (clientes: any, searchTerm: any) => {
    const inputValue = normalizeText(searchTerm.inputValue.toLowerCase());
    const newClientesArr = clientes.filter((c: any) => {
        return c.label && normalizeText(c.label.toLowerCase()).includes(inputValue);
    });

    const sortedArr = newClientesArr.sort(
        (a: any, b: any) => b.label.toLowerCase().startsWith(inputValue) - a.label.toLowerCase().startsWith(inputValue),
    );

    return sortedArr;
};

export const excelColumnToNumber = (val: string) => {
    const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let i = 0;
    let j = 0;
    let result = 0;
    const upperCaseVal = val.toUpperCase();

    if (val) {
        for (i = 0, j = upperCaseVal.length - 1; i < upperCaseVal.length; i += 1, j -= 1) {
            result += Math.pow(base.length, j) * (base.indexOf(upperCaseVal[i]) + 1);
        }

        return result - 1;
    } else {
        return null;
    }
};

export const isClienteFisico = (documento: string) => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lastCharacter = documento.slice(-1).toUpperCase();
    return letters.includes(lastCharacter);
};

export const validateCifFormat = (documento: string) => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const firstCharacter = documento.slice(0, 1).toUpperCase();
    const digitQty = documento.match(/\d+/);
    const isValidDigitQty = digitQty !== null && digitQty[0].length === 8;
    if (letters.includes(firstCharacter) && isValidDigitQty && documento.length === 9) {
        return true;
    } else {
        return false;
    }
};

export const isCsv = (fileName: string) => {
    const fileExtension = fileName.toLowerCase().match(/\.[^.]*$/);
    return fileExtension && fileExtension[0] === '.csv';
};

export const normalizeText = (text: string) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const validateDniFormat = (documento: string) => {
    //DNI, NIF, NIE
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lastCharacter = documento.slice(-1).toUpperCase();
    const firstCharacter = documento.slice(0, 1).toUpperCase();
    const digitQty = documento.match(/\d+/);
    const isValidDigitQty =
        (digitQty !== null && digitQty[0].length === 8) || (digitQty !== null && digitQty[0].length === 7);
    if (letters.includes(firstCharacter) && letters.includes(lastCharacter) && isValidDigitQty) {
        return true;
    } else if (letters.includes(lastCharacter) && isValidDigitQty) {
        return true;
    } else return false;
};

export const validatePhone = (phone: string) => {
    const validPhoneWithPrefix = phone.trim().match(/(\(\+34\)|\+34)(([6-9]{1})[0-9]{8})$/) !== null;
    const validPhoneWithoutPrefix = phone.trim().match(/(([6-9]{1})[0-9]{8})$/) !== null;
    return validPhoneWithPrefix || validPhoneWithoutPrefix;
};

export const validateEmail = (email: string) => {
    const lastAtPos = email.lastIndexOf('@');
    const lastDotPos = email.lastIndexOf('.');
    return (
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf('@@') == -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
    );
};

export const getCoordinates = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (pos) => resolve(pos),
            (error) => console.log(error),
            { enableHighAccuracy: true },
        );
    });
};

export function nombreClienteFisico(c: any) {
    let nombre = checkNullUndefined(c?.nombre) + ' ' + checkNullUndefined(c?.apellido);
    nombre = nombre.trim();
    nombre = nombre != '' ? nombre : c?.nombreComercio || c?.dni;
    if (nombre === null || nombre === undefined || nombre === '') {
        nombre = '-';
    }
    return nombre;
}

export function checkNullUndefined(s: string) {
    return s == null || s == undefined ? '' : s;
}

export function nombreClienteJuridico(c: any) {
    let nombre = c?.nombreFiscal || c?.nombreComercio || c?.cif;
    if (nombre === null || nombre === undefined || nombre === '') {
        nombre = '-';
    }
    return nombre;
}

export function determinarTipoCliente(cliente: any) {
    return cliente?.tipo_cliente_id != null ? cliente?.tipo_cliente_id : 1;
}

export const formatCp = (cp: string) => {
    if (cp && cp.length < 5) {
        return `0${cp}`;
    } else {
        return cp;
    }
};

export const dateStringToObj = (date: string) => {
    console.log(date);
    if (date) {
        const dateArr = date.split('T');
        const newDate = dateArr[0];
        const newTime = dateArr[1].slice(0, 5);
        return {
            date: newDate,
            time: newTime,
        };
    } else {
        return { date: '-', time: '-' };
    }
};

export const createFormData = (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            //@ts-ignore 123
            v.forEach((e) => formData.append(k, e));
        } else {
            //@ts-ignore 123
            formData.append(k, v);
        }
    });

    return formData;
};
