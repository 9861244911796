import React from 'react';

const EnCurso = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.457031" width="33.1901" height="32" rx="8" fill="#FDF7E1" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7493 11.9167C20.3937 11.2723 21.4383 11.2723 22.0827 11.9167V11.9167C22.727 12.561 22.727 13.6057 22.0827 14.25L16.2518 20.0809C15.8767 20.456 15.368 20.6667 14.8376 20.6667L13.3327 20.6667L13.3327 19.1618C13.3327 18.6313 13.5434 18.1226 13.9185 17.7475L19.7493 11.9167Z"
                stroke="#E4B305"
                strokeWidth="2"
            />
            <path d="M19.166 12.5L21.4993 14.8333" stroke="#E4B305" strokeWidth="2" />
        </svg>
    );
};

export default EnCurso;
