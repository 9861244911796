import React, { useState } from 'react';
import './DropdownCustom.scss';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ChevronDown } from 'tabler-icons-react';

interface DropdownOption {
    label: string;
    id: number;
}

interface IPropsDropdownCustom {
    options: DropdownOption[];
    onSelect: (id: number) => void;
}

const DropdownCustom = ({ options, onSelect }: IPropsDropdownCustom) => {
    const [selected, setSelected] = useState(options[0].id.toString());

    const renderOptions = (options: DropdownOption[]) => {
        return options.map((o) => {
            return (
                <MenuItem value={o.id.toString()} key={o.id}>
                    {o.label}
                </MenuItem>
            );
        });
    };

    const handleChange = (e: SelectChangeEvent) => {
        setSelected(e.target.value);
        onSelect(Number(e.target.value));
    };

    return (
        <Select
            onChange={handleChange}
            value={selected}
            className="dropdown-custom"
            IconComponent={() => <ChevronDown />}
        >
            {renderOptions(options)}
        </Select>
    );
};

export default DropdownCustom;
