import React from 'react';

export const EmptyStateImage = () => {
    return (
        <svg width="182" height="202" viewBox="0 0 182 202" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse opacity="0.6" cx="90.0508" cy="174.218" rx="89.5" ry="27.5" fill="#EBEAF0" />
            <path
                d="M133.565 105.913L86.5493 133.109L39.5332 105.913L86.5493 78.7178L133.565 105.913Z"
                fill="#86AFFF"
            />
            <path
                opacity="0.5"
                d="M86.5508 81.7178L86.5508 121.718"
                stroke="#D0DFFB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M133.567 160.151L86.5508 187.5V132.802L133.567 105.453V160.151Z" fill="white" />
            <path
                d="M133.751 105.836L86.5508 133.087L98.3508 153.525L145.551 126.274L133.751 105.836Z"
                fill="#D0DFFB"
            />
            <path d="M86.5493 187.5L39.5332 160.151V105.453L86.5493 132.802V187.5Z" fill="white" />
            <path
                d="M42.2988 154.312L51.5177 159.844"
                stroke="#B9D0FD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.2988 158L51.5177 162.609"
                stroke="#B9D0FD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.3508 105.835L27.5508 126.274L74.7507 153.525L86.5507 133.086L39.3508 105.835Z"
                fill="#D0DFFB"
            />
            <path
                d="M86.5508 153.718L86.5508 187.718"
                stroke="#D0DFFB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M155.551 139.718C155.951 135.318 161.051 136.218 163.551 137.218C167.551 150.018 155.551 149.551 149.051 147.718C149.384 147.718 150.499 147.606 152.051 147.218C154.051 146.718 155.051 145.218 155.551 139.718Z"
                fill="#D0DFFB"
            />
            <path
                d="M158.641 144.704C160.96 142.771 165.103 137.434 163.128 131.546"
                stroke="#86AFFF"
                strokeLinecap="round"
            />
            <path
                d="M167.876 124.802C169.744 122.34 172.203 115.466 167.084 107.66"
                stroke="#D0DFFB"
                strokeLinecap="round"
            />
            <path
                d="M170.733 130.744C172.994 128.637 177.265 120.282 173.551 111.718"
                stroke="#D0DFFB"
                strokeLinecap="round"
            />
            <path
                opacity="0.3"
                d="M114.059 43.5329C105.02 69.3971 97.4966 94.1168 95.3602 102.377C114.081 93.2535 143.579 99.4392 143.579 99.4392C143.579 99.4392 146.401 63.8094 163.007 39.3341C136.171 32.4129 114.059 43.5329 114.059 43.5329Z"
                fill="#D9D7E1"
            />
            <path
                opacity="0.3"
                d="M87.0588 13.5329C78.0196 39.3971 70.4966 64.1168 68.3602 72.3774C87.0806 63.2535 116.579 69.4392 116.579 69.4392C116.579 69.4392 119.401 33.8094 136.007 9.33408C109.171 2.41286 87.0588 13.5329 87.0588 13.5329Z"
                fill="#D9D7E1"
            />
        </svg>
    );
};
