import React, { useEffect, useState } from 'react';
import PaginatedCard from '../../components/PaginatedCard/PaginatedCard';
import { getUsuariosHabilitados } from './dashboardAPI';
import { ClipboardList, User } from 'tabler-icons-react';
import { Tooltip } from '@mui/material';

interface UsuariosDashboard {
    id: number;
    nombre: string;
    cantidadInspecciones: number;
    tipoCliente: string;
}

interface IUsuariosHabilitadosDashboard {
    totalPaginas: number;
    clientes: UsuariosDashboard[];
}

const UsuariosHabilitados = () => {
    const [usuariosHabilitados, setUsuariosHabilitados] = useState<IUsuariosHabilitadosDashboard | null>(null);
    const [pagina, setPagina] = useState(1);
    const agenteId = localStorage.getItem('idAgente');

    useEffect(() => {
        const obtenerRamosYModelos = async () => {
            const results = await getUsuariosHabilitados(Number(agenteId), pagina);
            if (results && results.status === 200) {
                setUsuariosHabilitados(results.data);
            }
        };

        obtenerRamosYModelos();
    }, [pagina]);

    const renderUsuarios = (usuarios: UsuariosDashboard[]) => {
        return usuarios.map((usuario: UsuariosDashboard) => {
            return (
                <div key={usuario.id} className="dashboard-usuarios-item">
                    <div className="dashboard-usuarios-item-nombre">
                        <div className="dashboard-usuarios-item-icon">
                            <User />
                        </div>
                        <Tooltip title={usuario.nombre} placement="right" arrow>
                            <p>{usuario.nombre}</p>
                        </Tooltip>
                    </div>
                    <p className="dashboard-usuarios-item-tipo">{usuario.tipoCliente}</p>
                    <div className="dashboard-usuarios-item-inspecciones">
                        <div className="dashboard-usuarios-item-icon">
                            <ClipboardList />
                        </div>
                        <p>{usuario.cantidadInspecciones}</p>
                    </div>
                </div>
            );
        });
    };

    return (
        <div style={{ width: 525 }}>
            <PaginatedCard
                pageCount={usuariosHabilitados ? usuariosHabilitados.totalPaginas : 1}
                page={pagina}
                onPageChange={(nuevaPagina: number) => setPagina(nuevaPagina)}
            >
                <div className="dashboard-usuarios">
                    <h2 className="dashboard-card-title">Usuarios habilitados</h2>
                    <div className="dashboard-usuarios-main">
                        {usuariosHabilitados ? renderUsuarios(usuariosHabilitados.clientes) : null}
                    </div>
                </div>
            </PaginatedCard>
        </div>
    );
};

export default UsuariosHabilitados;
