import React from 'react';

const PreguntaEscrita = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.9591 24.8926C25.7408 24.8926 25.5639 25.0631 25.5639 25.2735V27.7165C25.5639 27.959 25.3589 28.1563 25.107 28.1563H6.58231C6.33036 28.1563 6.12542 27.959 6.12542 27.7165V16.7067C6.12542 16.4963 5.94846 16.3258 5.73019 16.3258C5.51192 16.3258 5.33496 16.4963 5.33496 16.7067V27.7165C5.33496 28.379 5.89455 28.918 6.58231 28.918H25.107C25.7948 28.918 26.3544 28.379 26.3544 27.7165V25.2735C26.3544 25.0631 26.1775 24.8926 25.9591 24.8926Z"
                fill="#635F76"
            />
            <path
                d="M7.03882 14.9435C7.03882 15.3797 7.40717 15.7347 7.8599 15.7347H8.71164C9.16442 15.7347 9.53278 15.3797 9.53278 14.9435V14.1227C9.53278 13.6864 9.16442 13.3314 8.71164 13.3314H7.8599C7.40717 13.3314 7.03882 13.6864 7.03882 14.1227V14.9435ZM7.82928 14.1227C7.82928 14.1064 7.84303 14.0932 7.8599 14.0932H8.71164C8.72856 14.0932 8.74231 14.1064 8.74231 14.1227V14.9435C8.74231 14.9598 8.72856 14.973 8.71164 14.973H7.8599C7.84303 14.973 7.82928 14.9598 7.82928 14.9435V14.1227Z"
                fill="#635F76"
            />
            <path
                d="M19.3583 13.3314H10.841C10.6228 13.3314 10.4458 13.5019 10.4458 13.7123C10.4458 13.9226 10.6228 14.0931 10.841 14.0931H19.3583C19.5766 14.0931 19.7535 13.9226 19.7535 13.7123C19.7535 13.5019 19.5766 13.3314 19.3583 13.3314Z"
                fill="#635F76"
            />
            <path
                d="M10.841 15.7346H15.9173C16.1356 15.7346 16.3126 15.5641 16.3126 15.3538C16.3126 15.1434 16.1356 14.9729 15.9173 14.9729H10.841C10.6228 14.9729 10.4458 15.1434 10.4458 15.3538C10.4458 15.5641 10.6228 15.7346 10.841 15.7346Z"
                fill="#635F76"
            />
            <path
                d="M7.03882 18.2264C7.03882 18.6627 7.40717 19.0177 7.8599 19.0177H8.71164C9.16442 19.0177 9.53278 18.6627 9.53278 18.2264V17.4057C9.53278 16.9693 9.16442 16.6144 8.71164 16.6144H7.8599C7.40717 16.6144 7.03882 16.9693 7.03882 17.4057V18.2264ZM7.82928 17.4057C7.82928 17.3894 7.84303 17.3761 7.8599 17.3761H8.71164C8.72856 17.3761 8.74231 17.3894 8.74231 17.4057V18.2264C8.74231 18.2427 8.72856 18.2559 8.71164 18.2559H7.8599C7.84303 18.2559 7.82928 18.2427 7.82928 18.2264V17.4057Z"
                fill="#635F76"
            />
            <path
                d="M19.3583 16.6144H10.841C10.6228 16.6144 10.4458 16.7849 10.4458 16.9952C10.4458 17.2056 10.6228 17.3761 10.841 17.3761H19.3583C19.5766 17.3761 19.7535 17.2056 19.7535 16.9952C19.7535 16.7849 19.5766 16.6144 19.3583 16.6144Z"
                fill="#635F76"
            />
            <path
                d="M10.841 19.0176H15.9173C16.1356 19.0176 16.3126 18.8471 16.3126 18.6367C16.3126 18.4264 16.1356 18.2559 15.9173 18.2559H10.841C10.6228 18.2559 10.4458 18.4264 10.4458 18.6367C10.4458 18.8471 10.6228 19.0176 10.841 19.0176Z"
                fill="#635F76"
            />
            <path
                d="M7.03882 21.5094C7.03882 21.9457 7.40717 22.3006 7.8599 22.3006H8.71164C9.16442 22.3006 9.53278 21.9457 9.53278 21.5094V20.6886C9.53278 20.2523 9.16442 19.8973 8.71164 19.8973H7.8599C7.40717 19.8973 7.03882 20.2523 7.03882 20.6886V21.5094ZM7.82928 20.6886C7.82928 20.6723 7.84303 20.6591 7.8599 20.6591H8.71164C8.72856 20.6591 8.74231 20.6723 8.74231 20.6886V21.5094C8.74231 21.5256 8.72856 21.5389 8.71164 21.5389H7.8599C7.84303 21.5389 7.82928 21.5256 7.82928 21.5094V20.6886Z"
                fill="#635F76"
            />
            <path
                d="M19.3583 19.8976H10.841C10.6228 19.8976 10.4458 20.0681 10.4458 20.2784C10.4458 20.4888 10.6228 20.6593 10.841 20.6593H19.3583C19.5766 20.6593 19.7535 20.4888 19.7535 20.2784C19.7535 20.0681 19.5766 19.8976 19.3583 19.8976Z"
                fill="#635F76"
            />
            <path
                d="M10.841 22.3008H15.9173C16.1356 22.3008 16.3126 22.1303 16.3126 21.9199C16.3126 21.7096 16.1356 21.5391 15.9173 21.5391H10.841C10.6228 21.5391 10.4458 21.7096 10.4458 21.9199C10.4458 22.1303 10.6228 22.3008 10.841 22.3008Z"
                fill="#635F76"
            />
            <path
                d="M7.03882 24.7926C7.03882 25.2289 7.40717 25.5838 7.8599 25.5838H8.71164C9.16442 25.5838 9.53278 25.2289 9.53278 24.7926V23.9718C9.53278 23.5355 9.16442 23.1805 8.71164 23.1805H7.8599C7.40717 23.1805 7.03882 23.5355 7.03882 23.9718V24.7926ZM7.82928 23.9719C7.82928 23.9556 7.84303 23.9423 7.8599 23.9423H8.71164C8.72856 23.9423 8.74231 23.9556 8.74231 23.9719V24.7926C8.74231 24.8089 8.72856 24.8221 8.71164 24.8221H7.8599C7.84303 24.8221 7.82928 24.8089 7.82928 24.7926V23.9719Z"
                fill="#635F76"
            />
            <path
                d="M19.7535 23.5614C19.7535 23.3511 19.5766 23.1805 19.3583 23.1805H10.841C10.6228 23.1805 10.4458 23.3511 10.4458 23.5614C10.4458 23.7717 10.6228 23.9423 10.841 23.9423H19.3583C19.5766 23.9423 19.7535 23.7717 19.7535 23.5614Z"
                fill="#635F76"
            />
            <path
                d="M10.841 24.822C10.6228 24.822 10.4458 24.9925 10.4458 25.2029C10.4458 25.4132 10.6228 25.5837 10.841 25.5837H15.9173C16.1356 25.5837 16.3126 25.4132 16.3126 25.2029C16.3126 24.9925 16.1356 24.822 15.9173 24.822H10.841Z"
                fill="#635F76"
            />
            <path
                d="M16.5901 6.25232H7.8599C7.40717 6.25232 7.03882 6.60728 7.03882 7.04354V7.86432C7.03882 8.30063 7.40717 8.65559 7.8599 8.65559H16.5901C17.0428 8.65559 17.4112 8.30063 17.4112 7.86432V7.04354C17.4111 6.60723 17.0428 6.25232 16.5901 6.25232ZM16.6207 7.86427C16.6207 7.88057 16.607 7.89382 16.5901 7.89382H7.8599C7.84303 7.89382 7.82928 7.88057 7.82928 7.86427V7.04354C7.82928 7.02729 7.84303 7.01404 7.8599 7.01404H16.5901C16.607 7.01404 16.6207 7.02729 16.6207 7.04354V7.86427Z"
                fill="#635F76"
            />
            <path
                d="M7.43405 10.297H12.5444C12.7627 10.297 12.9397 10.1265 12.9397 9.91614C12.9397 9.7058 12.7627 9.53528 12.5444 9.53528H7.43405C7.21578 9.53528 7.03882 9.7058 7.03882 9.91614C7.03882 10.1265 7.21572 10.297 7.43405 10.297Z"
                fill="#635F76"
            />
            <path
                d="M7.43405 11.9387H12.5444C12.7627 11.9387 12.9397 11.7682 12.9397 11.5579C12.9397 11.3475 12.7627 11.177 12.5444 11.177H7.43405C7.21578 11.177 7.03882 11.3475 7.03882 11.5579C7.03882 11.7682 7.21572 11.9387 7.43405 11.9387Z"
                fill="#635F76"
            />
            <path
                d="M28.6548 9.42061L27.4539 8.99968C27.0518 8.85856 26.6162 8.97419 26.3417 9.25923C26.2965 8.9035 26.1282 8.56591 25.8639 8.3118L20.7567 3.38978C20.4448 3.08991 20.0139 2.91797 19.5743 2.91797H6.58231C5.8945 2.91797 5.33496 3.45696 5.33496 4.11945V14.9242C5.33496 15.1345 5.51192 15.305 5.73019 15.305C5.94846 15.305 6.12542 15.1345 6.12542 14.9242V4.11945C6.12542 3.87697 6.33041 3.67969 6.58231 3.67969H19.5743C19.6193 3.67969 19.6639 3.68411 19.7081 3.69076V7.91556C19.7081 8.69124 20.363 9.32235 21.1679 9.32235H25.5524C25.5593 9.365 25.5639 9.40807 25.5639 9.45143V11.0907L23.6348 16.198C23.5601 16.3957 23.6659 16.6142 23.871 16.6861C24.0761 16.758 24.3029 16.6561 24.3775 16.4585L25.4984 13.4913L27.1566 14.0729L23.0861 24.849C23.0343 24.9862 22.9345 25.1037 22.8057 25.1796L21.7995 25.7699L21.4228 24.6949C21.3745 24.5563 21.3763 24.4046 21.4281 24.2676L23.7447 18.1338C23.8193 17.9361 23.7135 17.7176 23.5084 17.6456C23.3032 17.5736 23.0766 17.6756 23.0019 17.8733L20.6854 24.007C20.5725 24.3055 20.5684 24.6361 20.6739 24.9382L21.1644 26.3382L21.0298 26.6946C20.9552 26.8923 21.0609 27.1108 21.266 27.1827C21.3107 27.1984 21.3563 27.2058 21.4011 27.2058C21.5625 27.2058 21.7142 27.1098 21.7726 26.9551L21.9072 26.5985L23.2174 25.8299C23.499 25.664 23.7161 25.4082 23.829 25.1096L25.5639 20.5164V23.4909C25.5639 23.7013 25.7408 23.8718 25.9591 23.8718C26.1775 23.8718 26.3544 23.7013 26.3544 23.4909V18.4238L29.2727 10.6977C29.4674 10.1814 29.1902 9.60845 28.6548 9.42061ZM20.4985 7.91561V4.21827L25.0043 8.56068H21.1679C20.7988 8.56068 20.4985 8.27128 20.4985 7.91561ZM25.7687 12.7755L26.2267 11.5632L27.8849 12.1448L27.427 13.3571L25.7687 12.7755ZM28.5298 10.4374L28.1553 11.429L26.497 10.8474L26.8717 9.8555C26.9175 9.73403 27.0573 9.67122 27.1836 9.7155L28.3845 10.1364C28.5105 10.1807 28.5757 10.3157 28.5298 10.4374Z"
                fill="#635F76"
            />
        </svg>
    );
};

export default PreguntaEscrita;
