/* eslint-disable prettier/prettier */
import React, { useRef, useState } from 'react';
import JoyRide, { ACTIONS, Step } from 'react-joyride';
import { CSSProperties } from 'react';

import { AiraLogo } from '../../assets/AiraLogo';
import iconEvaluaciones from '../../assets/svg/iconEvaluaciones.svg';
import iconoClientes from '../../assets/svg/iconoClientes.svg';
import paso1De5 from '../../assets/svg/onboarding/paso1De5.svg';
import paso2De5 from '../../assets/svg/onboarding/paso2De5.svg';
import paso3De5 from '../../assets/svg/onboarding/paso3De5.svg';
import paso4De5 from '../../assets/svg/onboarding/paso4De5.svg';
import paso5De5 from '../../assets/svg/onboarding/paso5De5.svg';

export const STEPS: Step[] = [
    {
        content: (
            <>
                {' '}
                <AiraLogo />
                <br />
                <h4 className="steps">
                    <br />
                    <strong>¡Te damos la bienvenida a Aira System!</strong>
                </h4>
                <br />
                <p className="steps">
                    A continuación te mostramos un <strong>breve tutorial</strong> sobre los aspectos esenciales del
                    panel para que puedas sacarle el máximo partido a la herramienta.
                </p>
            </>
        ),
        locale: {
            skip: <strong aria-label="skip">Omitir por Ahora</strong>,
            next: <strong aria-label="next">Siguiente</strong>,
        },
        placement: 'center',
        target: '.guia',
        styles: {
            options: {
                width: 500,
                spotlightShadow: '10',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
            },
            spotlight: {
                borderRadius: 80,
            },
            buttonNext: {
                fontSize: '.75rem',
                borderRadius: '5px',
            },
        },
    },
    {
        content: (
            <>
                <img src={iconoClientes} width={40} alt="importar clientes"></img>
                <br />
                <h4>
                    <strong>Carga tus clientes</strong>{' '}
                </h4>
                <p>
                    En este apartado encontrarás un botón para <strong>importar tus propios clientes </strong>
                    existentes a través de un archivo <strong>CSV</strong> donde solamente tendrás que decirnos qué
                    datos estás importando.
                </p>
                <br />
                <video width="100%" autoPlay controls>
                    <source
                        src="https://aira-assets.s3.eu-west-1.amazonaws.com/onboarding_tutorial/Importarclientes.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>

                <br />
                <img src={paso1De5} alt="progreso: paso 1"></img>
            </>
        ),
        locale: { back: <strong aria-label="back">Volver</strong>, next: <strong aria-label="next">Siguiente</strong> },
        spotlightPadding: 35,
        placement: 'center',
        styles: {
            tooltipContent: {
                width: '98%',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
                position: 'relative',
                boxSizing: 'border-box',
                width: '90%',
            },
            buttonNext: {
                fontSize: '.75rem',
            },
        },
        target: 'body',
    },

    {
        content: (
            <>
                <img src={iconoClientes} alt="clientes" width={40}></img>
                <br />
                <br />
                <h4 className="steps">
                    <strong>Crea un nuevo cliente</strong>
                </h4>
                <p className="steps">
                    También puedes <strong>crear un cliente individual </strong>
                    desde aquí, o bien, hacerlo en el momento de <strong>enviarle una evaluación.</strong>
                </p>
                <br />
                <video width="100%" autoPlay controls>
                    <source
                        src="https://aira-assets.s3.eu-west-1.amazonaws.com/onboarding_tutorial/nuevoCliente.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <img src={paso2De5} alt="progreso: paso 2"></img>
            </>
        ),
        locale: { back: <strong aria-label="back">Volver</strong>, next: <strong aria-label="next">Siguiente</strong> },
        spotlightPadding: 35,
        placement: 'center',
        styles: {
            tooltipContent: {
                width: '98%',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
                position: 'relative',
                boxSizing: 'border-box',
                width: '90%',
            },
            buttonNext: {
                fontSize: '.75rem',
            },
        },
        target: 'body',
    },
    {
        content: (
            <>
                <img src={iconEvaluaciones} alt="icono evaluaciones" width={50}></img>
                <h4 className="steps">
                    <br />
                    <strong>Evaluación</strong>
                </h4>
                <p className="steps">
                    Siempre que vayas a <strong> crear una evaluación </strong>podrás elegir si enviarla a un cliente
                    existente o crear uno nuevo durante este proceso. Después sólo tienes que{' '}
                    <strong>confirmar el teléfono y el ramo </strong>
                    que quieres enviar.{' '}
                </p>
                <video width="100%" autoPlay controls>
                    <source
                        src="https://aira-assets.s3.eu-west-1.amazonaws.com/onboarding_tutorial/airaUno.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <img src={paso3De5} alt="progreso: paso 3"></img>
            </>
        ),
        locale: { back: <strong aria-label="back">Volver</strong>, next: <strong aria-label="next">Siguiente</strong> },
        placement: 'center',
        spotlightPadding: 35,
        target: '.fourth-step',
        styles: {
            tooltipContent: {
                width: '98%',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
                position: 'relative',
                boxSizing: 'border-box',
                width: '90%',
            },
            buttonNext: {
                fontSize: '.75rem',
            },
        },
    },
    {
        content: (
            <>
                <img src={iconEvaluaciones} alt="icono evaluaciones" width={50}></img>
                <h4 className="steps">
                    <br />
                    <strong>Evaluación</strong>
                </h4>
                <p className="steps">
                    Una vez tu cliente haya <strong>completado su evaluación, </strong>
                    tendrás disponible un <strong>informe PDF con la información del riesgo </strong>y varios anexos.
                </p>
                <video width="100%" autoPlay controls>
                    <source
                        src="https://aira-assets.s3.eu-west-1.amazonaws.com/onboarding_tutorial/anexos.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                <img src={paso4De5} alt="progreso: paso 4"></img>
                <br />
            </>
        ),
        locale: { back: <strong aria-label="back">Volver</strong>, next: <strong aria-label="next">Siguiente</strong> },
        spotlightPadding: 35,
        placement: 'center',
        target: 'body',
        styles: {
            tooltipContent: {
                width: '98%',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
                position: 'relative',
                boxSizing: 'border-box',
                width: '90%',
            },
            buttonNext: {
                fontSize: '.75rem',
            },
        },
    },
    {
        content: (
            <>
                {' '}
                <AiraLogo />
                <br />
                <h4 className="steps">
                    <br />
                    <strong>¡Enhorabuena!</strong>
                </h4>
                <br />
                <p className="steps">
                    Ya puedes <strong>comenzar a usar tu panel de aira</strong> para sacarle el máximo partido. Si
                    necesitas repasar alguna funcionalidad, puedes{' '}
                    <strong>pulsar en el botón de ayuda para volver a abrir esta pestaña.</strong>
                </p>
                <br />
                <img src={paso5De5} alt="progreso: paso final"></img>
            </>
        ),
        locale: { back: <strong aria-label="back">Volver</strong>, last: <strong aria-label="last">Cerrar</strong> },
        placement: 'left-start',
        target: '.guia',
        styles: {
            options: {
                width: 450,
                spotlightShadow: '10',
            },
            tooltip: {
                fontSize: '0.85rem',
                borderRadius: '12px',
            },
            buttonNext: {
                fontSize: '.75rem',
            },
        },
    },
];

export const TourButton = (data: any) => {
    const { action, index, status, type } = data;
    const [run, setRun] = useState(true);
    const joyrideRef = useRef(null);

    const handleJoyrideCallback = (data: any) => {
        if (data.action === ACTIONS.CLOSE) {
            console.log('pepe');
            window.localStorage.setItem('tutorialActivo', 'false');
            setRun(false);
        }
    };

    return (
        <>
            <JoyRide
                steps={STEPS}
                continuous={true}
                run={run}
                callback={handleJoyrideCallback}
                showSkipButton={false}
                styles={{
                    options: {
                        primaryColor: '#0046CF',
                        arrowColor: '0046CF',
                    },
                }}
            />
        </>
    );
};
