import React from 'react';

const RamoComercio = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8076 25.0017V16.8829H23.2414V25.0017M8.80811 21.3934V16.8223H16.0247V21.3934H8.80811Z"
                stroke="#86AFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.0457 14.357V25.0016H7.00407V14.357M9.52989 12.192C9.52989 13.3877 8.56063 14.357 7.36495 14.357C6.16928 14.357 5.19995 13.3877 5.19995 12.192L7.00407 6.95996H25.0457L26.8498 12.192C26.8498 13.3877 25.8805 14.357 24.6848 14.357C23.4892 14.357 22.5199 13.3877 22.5199 12.192"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.1899 12.192C18.1899 13.3877 17.2205 14.357 16.0249 14.357C14.8292 14.357 13.8599 13.3877 13.8599 12.192"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6125 6.95996L9.53003 12.192C9.53003 13.3877 10.4994 14.357 11.695 14.357C12.8907 14.357 13.86 13.3877 13.86 12.192L14.2208 6.95996M17.8292 6.95996L18.19 12.192C18.19 13.3877 19.1594 14.357 20.355 14.357C21.5507 14.357 22.52 13.3877 22.52 12.192L21.4375 6.95996H17.8292Z"
                stroke="#0046CF"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RamoComercio;
