import React, { ReactElement } from 'react';
import './Menu.scss';

interface menuItem {
    icon?: JSX.Element;
    label: string;
    fn: () => void;
    path?: string;
}

interface IProps {
    menuItems: menuItem[];
}

const Menu: React.FunctionComponent<IProps> = ({ menuItems }) => {
    const renderItems = (items: menuItem[]) => {
        return items.map((item) => {
            return (
                <li className="menu-item" key={item.label} onClick={item.fn}>
                    {item.label}
                </li>
            );
        });
    };

    return <ul className="menu">{renderItems(menuItems)}</ul>;
};

export default Menu;
