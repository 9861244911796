import { GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { DataGrid, renderText, renderTextWithIcon, renderTextWithTooltip } from '../../components/data-grid/DataGrid';
import { Building, ChevronRight, CircleCheck, FileDownload, User, X } from 'tabler-icons-react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { determinarTipoCliente, getDateFormated, isTest, normalizeText } from '../../utlis';
import {
    InspeccionesPorModeloSync,
    listaInspeccionesPorModelo,
    removeInspeccion,
} from '../inspecciones/inspeccionesSlice';
import {
    deleteInspeccionModal,
    nombreClienteFisicoXInspeccion,
    nombreClienteJuridicoXInspeccion,
    renderBorderEstado,
    renderColorEstado,
    renderEstadoText,
    renderIconEstado,
    renderRamoIcon,
} from '../inspecciones/InspeccionesFeature';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import './EvaluacionesEnviadasTab.scss';
import { conf } from '../../utlis/config';
import { Alert, Modal, Snackbar } from '@mui/material';
import ModalEliminarInspeccion from '../../components/ModalEliminarInspeccion/ModalEliminarInspeccion';
import { deleteInspeccion } from '../inspecciones/InspeccionesAPI';

const defaultFilters = {
    quickSearch: {
        filterFn: (i: Record<string, string>, v: string) =>
            v === '' || normalizeText(i.nombreInspeccion.toLowerCase()).search(normalizeText(v.toLowerCase())) != -1,
        value: '',
    },
    ramo: {
        filterFn: (i: Record<string, any>, v: number) => v === 0 || i.ramo.id === v,
        value: 0,
    },
    cliente: {
        filterFn: (i: Record<string, any>, v: any) => v === null || i.cliente.id === v.id,
        value: null,
    },
    estado: {
        filterFn: (i: Record<string, any>, v: Array<any>) => v.length === 0 || v.some((s) => s.id === i.estado.id),
        value: [],
    },
    agente: {
        filterFn: (i: Record<string, any>, v: any) => v === null || i.agente === v.label,
        value: null,
    },
};

const toRows = (inspecciones: any) => {
    if (!inspecciones || !inspecciones.length) return [];
    return inspecciones?.map((i: any) => {
        const horaEnvioFormated =
            i.fechaEnviada != null
                ? new Date(i.fechaEnviada).toLocaleTimeString('es-ES', {
                      hour: '2-digit',
                      minute: '2-digit',
                  })
                : '';

        const horaVencimientoFormated =
            i.fechaVencimiento != null
                ? new Date(i.fechaVencimiento).toLocaleTimeString('es-ES', {
                      hour: '2-digit',
                      minute: '2-digit',
                  })
                : '';
        return {
            id: i.id,
            cliente: {
                id: i.cliente_inspeccion?.id,
                icon: determinarTipoCliente(i.cliente_inspeccion) == 1 ? <User /> : <Building />,
                iconColor: '#B0ADBE',
                text:
                    determinarTipoCliente(i.cliente_inspeccion) == 1
                        ? nombreClienteFisicoXInspeccion(i)
                        : nombreClienteJuridicoXInspeccion(i),
            },
            direccion: i.direccionLinea1 ? { line1: i.direccionLinea1, line2: i.direccionLinea2 } : { pending: true },
            envio: {
                line1: getDateFormated(i.fechaEnviada),
                line2: horaEnvioFormated,
                fechaHoraEnvio: new Date(i.fechaEnviada),
            },
            estado: {
                id: i.estado_inspeccion ? i.estado_inspeccion.id : '',
                text: renderEstadoText(i),
                icon: i.gestionado ? (
                    <CircleCheck />
                ) : (
                    renderIconEstado(i.estado_inspeccion ? i.estado_inspeccion.id : '')
                ),
                textColor: i.gestionado
                    ? '#3BA1AE'
                    : renderColorEstado(i.estado_inspeccion ? i.estado_inspeccion.id : ''),
                fontSize: '12px',
                border: i.gestionado
                    ? '2px solid #E3F6F9'
                    : renderBorderEstado(i.estado_inspeccion ? i.estado_inspeccion.id : ''),
                padding: '4px 12px 4px 8px',
                fontWeight: '800',
                vencimiento: i.estado_vencimiento_id,
            },
            modelo: i.modeloCustom ? i.modeloCustom.nombre : i.modelo_inspeccion ? i.modelo_inspeccion.descripcion : '',
            agente: i.agente_inspeccion ? i.agente_inspeccion.nombre : '',
            nombreInspeccion: i.nombreInspeccion ? i.nombreInspeccion : 'ID: ' + i.id,
            ramo: {
                id: i.ramo_inspeccion ? i.ramo_inspeccion.id : '',
                text: i.ramo_custom ? i.ramo_custom.nombre : '',
                icon: i.ramo_custom ? renderRamoIcon(i.ramo_custom.icono) : '',
                background: '#EBF1FE',
                textColor: '#0046CF',
                fontSize: '12px',
                padding: '4px 8px',
                alignment: 'center',
                fontWeight: '800',
                gap: '0px',
            },
            vencimiento:
                i.fechaVencimiento != null
                    ? {
                          line1: i.fechaVencimiento != '' ? getDateFormated(i.fechaVencimiento) : '',
                          line2: i.fechaVencimiento != '' ? horaVencimientoFormated : '',
                          fechaHoraVencimiento: new Date(i.fechaVencimiento),
                          pending: false,
                      }
                    : { pending: true },
        };
    });
};
interface Props {
    modeloCustomId: number;
}
const EvaluacionesEnviadasTab = ({ modeloCustomId }: Props) => {
    const dispatch = useAppDispatch();
    /* const route = useLocation().pathname; */
    const isSuperUser = localStorage.getItem('idAseguradora') === '0';
    const [filters, setFilters] = useState(defaultFilters);
    const inspecciones = useAppSelector(listaInspeccionesPorModelo);
    const inspeccionesMap2 = toRows(inspecciones);
    const [loading, setLoading] = useState(true);
    const [filteredRows, setFilteredRows] = useState(inspeccionesMap2);
    const defaultDeleteInspectModalState = { open: false, id: null };
    const [deleteInspectionModal, setDeleteInspectionModal] =
        useState<deleteInspeccionModal>(defaultDeleteInspectModalState);
    const [deleteError, setDeleteError] = useState(false);
    const ENV = conf('ENV');

    const obtenerInspecciones = async () => {
        setLoading(true);
        await dispatch(InspeccionesPorModeloSync(modeloCustomId));
        setLoading(false);
    };

    useEffect(() => {
        obtenerInspecciones();
    }, []);

    useEffect(() => {
        setFilteredRows(inspeccionesMap2);
    }, [inspecciones]);

    useEffect(() => {
        const newRows = inspeccionesMap2.filter((i: any) => {
            return Object.values(filters).reduce((acc, e: any) => {
                return acc && e.filterFn(i, e.value);
            }, true);
        });

        setFilteredRows(newRows);
    }, [filters]);

    const handleDownload = async (e: any, id: any) => {
        e.stopPropagation();
        if (isTest(ENV)) {
            window.open(`https://aira-pdf.s3.eu-west-1.amazonaws.com/pruebas/inspeccion-${id}.pdf`, '_blank');
        } else {
            window.open(`https://aira-pdf.s3.eu-west-1.amazonaws.com/inspeccion-${id}.pdf`, '_blank');
        }
    };

    const handleEliminarModal = (e: any, id: GridRowId) => {
        e.stopPropagation();
        setDeleteInspectionModal({ open: true, id });
    };

    const handleEliminarInspeccion = async (id: GridRowId | null) => {
        setLoading(true);
        const response = await deleteInspeccion(id);
        if (response === 200) {
            await dispatch(removeInspeccion(id));
            setDeleteInspectionModal(defaultDeleteInspectModalState);
            await dispatch(InspeccionesPorModeloSync(modeloCustomId));
            setLoading(false);
        } else {
            setLoading(false);
            setDeleteError(true);
            setDeleteInspectionModal(defaultDeleteInspectModalState);
        }
    };

    const columns: GridColumns = [
        {
            field: 'nombreInspeccion',
            headerName: 'Nombre evaluación',
            flex: 1.5,
            cellClassName: 'data-grid-bold-cell',
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1.2,
            sortable: false,
        },
        {
            field: 'direccion',
            headerName: 'Dirección',
            renderCell: (params) => renderText(params.value),
            //cellClassName: 'cell-direccion',
            flex: 1.5,
            sortable: false,
        },
        {
            field: 'ramo',
            headerName: 'Ramo',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1.4,
            sortable: false,
        },
        { field: 'modelo', headerName: 'Modelo', flex: 0.7, sortable: false },
        {
            field: 'agente',
            headerName: 'Agente',
            renderCell: (params) => renderTextWithTooltip(params.value),
            flex: 0.8,
            sortable: false,
        },
        {
            field: 'envio',
            headerName: 'Enviada',
            renderCell: (params) => renderText(params.value),
            sortComparator: (d1: any, d2: any) => d1.fechaHoraEnvio - d2.fechaHoraEnvio,
            flex: 1,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1.2,
            sortable: false,
        },
        {
            field: 'vencimiento',
            headerName: 'Vence',
            renderCell: (params) => renderText(params.value),
            sortComparator: (d1: any, d2: any) => {
                if (d1.pending && d2.pending) {
                    return 0;
                }
                if (d1.pending && !d2.pending) {
                    return 1;
                }
                if (!d1.pending && d2.pending) {
                    return -1;
                }
                return d1.fechaHoraVencimiento - d2.fechaHoraVencimiento;
            },
            flex: 1,
            hide: true,
        },
    ];

    return (
        <>
            {loading ? (
                <div className="loaderContainer">
                    <JsonAnimation animation="loader" height="80px" />
                </div>
            ) : (
                <DataGrid
                    rows={filteredRows}
                    loader={filteredRows ? false : true}
                    columns={columns}
                    pageSize={10}
                    route={'/inspecciones'}
                    sortingModel={[{ field: 'envio', sort: 'desc' }]}
                />
            )}
            <Modal
                open={deleteInspectionModal.open}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <ModalEliminarInspeccion
                    id={deleteInspectionModal.id}
                    onCancel={() => setDeleteInspectionModal(defaultDeleteInspectModalState)}
                    onDelete={() => handleEliminarInspeccion(deleteInspectionModal.id)}
                    loading={loading}
                />
            </Modal>
            <Snackbar
                open={deleteError}
                autoHideDuration={10000}
                onClose={() => setDeleteError(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setDeleteError(false)} severity="error">
                    Ha ocurrido un error al intentar eliminar la evaluación
                </Alert>
            </Snackbar>
        </>
    );
};

export default EvaluacionesEnviadasTab;
