import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import inspeccionesReducer from '../features/inspecciones/inspeccionesSlice';
import loginReducer from '../features/login/loginSlice';
import clientesReducer from '../features/clientes/ClientesSlice';
import modelosReducer from '../features/modelos/ModeloSlice';

const store = configureStore({
    reducer: {
        login: loginReducer,
        inspecciones: inspeccionesReducer,
        clientes: clientesReducer,
        modelos: modelosReducer,
    },
});
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
