import axios from 'axios';

export function GetModelos() {
    return axios
        .get('modelo/obtenerModelos')
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export function PostModelo(nombre: string, idModeloMaestro: number, ramoCustomId: number) {
    return axios
        .post('modelo/crearModelo', {
            nombre,
            idModeloMaestro,
            ramoCustomId,
        })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export function GetModelosCustomXMaestro(idModeloMaestro: number) {
    return axios
        .get(`modelo/obtenerModelosCustomXMaestro?idModeloMaestro=${idModeloMaestro}`)
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos custom x maestro');
        });
}

export function GetModeloCustom(idModeloCustom: number) {
    return axios
        .get(`modelo/obtenerModeloCustom?idModelo=${idModeloCustom}`)
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el modelo custom');
        });
}
interface CRUDRes {
    success: boolean;
    message: string;
}
export function DeleteModeloCustom(idModeloCustom: number): Promise<CRUDRes> {
    return axios
        .post('modelo/borrarModelo', { idModelo: idModeloCustom })
        .then((res) => ({ success: res.status === 200, message: '' }))
        .catch((error) => {
            return { success: false, message: error?.response?.data };
        });
}

export function RestablecerModeloModeloCustom(idModeloCustom: number): Promise<CRUDRes> {
    return axios
        .post('modelo/reestablecerModelo', { idModelo: idModeloCustom })
        .then((res) => {
            return { success: res.status === 200, message: res.data };
        })
        .catch((error: any) => {
            return { success: false, message: error?.response?.data };
        });
}

export function UpdateModeloName(idModeloCustom: number, nombre: string) {
    return axios
        .post('modelo/actualizarNombreModelo', { idModelo: idModeloCustom, nombre })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo actualizar el nombre del modelo custom');
        });
}

export function UpdateModeloCustom(modeloCustom: any): Promise<CRUDRes> {
    return axios
        .post('modelo/actualizarModelo', { ...modeloCustom })
        .then((res) => {
            return { success: res.status === 200, message: res.data };
        })
        .catch((error: any) => {
            return { success: false, message: error?.response?.data };
        });
}

export function GetModeloCustomXRamo(idRamo: number): Promise<any> {
    return axios
        .get(`modelo/obtenerModelosCustomXRamoId?idRamo=${idRamo}`)
        .then((res) => res.data)
        .catch(() => {
            console.log(`No se pudieron obtener los modelos custom para el ramo con id ${idRamo}`);
        });
}

export interface UpdatePreguntaModeloCustom {
    idSubModulo: number;
    titulo: string;
    subtitulo: string;
    texto: string;
    etiqueta: string;
    opciones: string[];
}

export interface UpdateBienvenidaModeloCustom {
    idModelo: number;
    titulo: string;
    subtitulo: string;
}

export interface updateFotosLibres {
    idSubModulo: number;
    cantidad: number;
}

export function updatePreguntaModeloCustom(datosPregunta: UpdatePreguntaModeloCustom): Promise<boolean> {
    return axios
        .post('modelo/actualizarPreguntaEnModelo', { ...datosPregunta })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log('No se pudo actualizar la pregunta para el modelo custom');
            return error;
        });
}

export function updateBienvenidaModeloCustom(datosBienvenida: UpdateBienvenidaModeloCustom) {
    return axios
        .post('modelo/actualizarBienvenidaModelo', { ...datosBienvenida })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo actualizar la pantalla de bienvenida para el modelo custom');
        });
}

export function updateFotosLibres(datosFotosLibres: updateFotosLibres): Promise<boolean> {
    return axios
        .post('modelo/actualizarFotosEnModelo', { ...datosFotosLibres })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return true;
            }
            return false;
        })
        .catch((error) => {
            console.log('No se pudo actualizar la pantalla de bienvenida para el modelo custom');
            return false;
        });
}

const createFormData = (data: any) => {
    const formData = new FormData();

    Object.entries(data).forEach(([k, v]: any) => {
        if (Array.isArray(v)) {
            v.forEach((e) => formData.append(k, e));
        } else {
            formData.append(k, v);
        }
    });

    return formData;
};

export function subirPoliticasPrivacidad(idModeloCustom: number, htmlPoliticas: any, titulo: string) {
    return axios
        .post('modelo/subirPoliticasDePrivacidad', {
            idModelo: idModeloCustom,
            html: htmlPoliticas,
            tituloPoliticas: titulo,
        })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo actualizar el nombre del modelo custom');
        });
}
