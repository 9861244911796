import axios from 'axios';
import { conf } from '../../utlis/config';
import { createFormData } from '../../utlis';
const ENV = conf('ENV');

const externos = axios.create({
    baseURL: conf('BASE_URL') + 'externos/',
});

export function GetInspecciones(archivadas: boolean) {
    return axios
        .get('inspecciones', { params: { archivadas: archivadas } })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener las inspecciones');
        });
}

export function GetInspeccionesPorModelo(modeloCustomId: number) {
    return axios
        .get(`modelo/obtenerEvaluacionesPorModelo?idModeloMaestro=${String(modeloCustomId)}`)
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener las inspecciones por modelo');
        });
}

export async function GetDetalleInspeccion(id: number) {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    const response = await axios.post('inspecciones/detalle', { id: id }, { headers: headers });
    if (response.status !== 200) {
        console.log(response.status + response.statusText);
    }

    if (response.data) {
        return response.data;
    }
}

export function GetClientes() {
    return axios
        .get('clientes')
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los clientes');
        });
}

export function GetRamos() {
    return axios
        .get('ramo')
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los ramos');
        });
}

export function GetModelos() {
    return axios
        .get('modelos')
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export function PostInspeccion(
    activo: boolean,
    estadoInspeccionId: number,
    ramoInspeccionId: number,
    agenteInspeccionId: number,
    clienteInspeccionId: number,
    modeloInspeccionId: number,
    movilNuevo: string,
) {
    return axios
        .post('inspecciones', {
            activo: activo,
            cliente_inspeccion_id: clienteInspeccionId,
            estado_inspeccion_id: estadoInspeccionId,
            ramo_inspeccion_id: ramoInspeccionId,
            agente_inspeccion_id: agenteInspeccionId,
            modelo_inspeccion_id: modeloInspeccionId,
            movil_nuevo: movilNuevo,
        })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo insertar la inspeccion');
        });
}

export const enviarEvaluacionAira0 = (
    idCliente: number,
    idEvaluacion: number,
    telefonoMovil: string,
    modeloInspeccionId: number,
) => {
    return axios
        .post('evaluacion/enviar', {
            idCliente: idCliente,
            idEvaluacion: idEvaluacion,
            telefonoMovil: telefonoMovil,
            idModeloCustom: modeloInspeccionId,
        })
        .then((res) => res)
        .catch((error) => error.response.status);
};

export const deleteInspeccion = (id: any) => {
    return axios
        .post('inspecciones/delete', { id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export function PostCliente(
    nombre: string,
    movil: string,
    email: string,
    direccion: string,
    dni: string,
    nombreFiscal: string,
    cif: string,
    nombreComercio: string,
    telefonoEmpresa: string,
    agente_cliente_id: number,
    tipo_cliente_id: number,
    activo: boolean,
) {
    return axios
        .post('clientes', {
            nombre,
            movil,
            email,
            direccion,
            dni,
            nombreFiscal,
            cif,
            nombreComercio,
            telefonoEmpresa,
            agente_cliente_id,
            tipo_cliente_id,
            activo,
        })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export function GetPDF(id: number) {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    return axios
        .post('preguntas/pdf', { id: id }, { headers: headers, responseType: 'blob' as 'json' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el pdf.');
        });
}

export function GetAvatarPDF(id: number) {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    return axios
        .post('preguntas/avatarPdf', { id: id }, { headers: headers, responseType: 'blob' as 'json' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el pdf.');
        });
}

export function GetLopdPDF(id: number) {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    return axios
        .post('preguntas/lopdPdf', { id: id }, { headers: headers, responseType: 'blob' as 'json' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el pdf.');
        });
}

export function GetMapaCatastralPDF(id: number) {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    return axios
        .post('preguntas/mapaCatastralPdf', { id: id }, { headers: headers, responseType: 'blob' as 'json' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el pdf.');
        });
}

export const processAira0CSV = (id: number) => {
    return axios
        .get('evaluacion/informeAira0?idEvaluacion=' + id)
        .then((res) => {
            //console.log('XXX: ' + JSON.stringify(res));
            return res.data;
        })
        .catch(() => {
            console.log('No se pudo procesar');
        });
};

export const processAira0PDF = (id: number) => {
    return axios
        .get('evaluacion/informePDFAira0?idEvaluacion=' + id, { responseType: 'blob' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo procesar');
        });
};

export const processAI = (id: number) => {
    return axios
        .post('preguntas/aws', { id })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo procesar');
        });
};

export function getMapaCatastral(id: number) {
    return axios
        .post('inspecciones/mapaCatastral', { id: id })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo obtener el mapa catastral.');
        });
}

export interface direccion {
    idEvaluacion?: number;
    idRamo: number;
    idCliente: number;
    idAsegurado: number;
    calle?: string;
    numero?: string;
    poblacion?: string;
    provincia?: string;
    codigoPostal?: string;
    poligono?: string;
    parcela?: string;
    bloque?: string;
    puerta?: string;
    planta?: string;
    escalera?: string;
    referenciaCatastral?: string;
}

export const getDireccion = (data: direccion) => {
    return axios
        .post('evaluacion/precargaDireccion', data)
        .then((res) => {
            return res;
        })
        .catch((err) => err);
};

export const getInfoCodigoPostal = (cp: string) => {
    return axios
        .post('clientes/codigoPostal', { codigoPostal: cp })
        .then((res) => res.data)
        .catch((err) => err);
};

export const getDirectionFromCoords = async (lat: number, lng: number) => {
    const googleApiKey = conf('GOOGLE_MAPS_API_KEY');
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=es&key=${googleApiKey}`;
    const results = await fetch(url, { mode: 'cors' });
    const resultsTxt = await results.text();
    return JSON.parse(resultsTxt);
};

export interface SearchCifBody {
    idCliente: number;
    idRamo: number;
    idEvaluacion: number | null;
    cif: string;
    idAsegurado: number | null;
}

export const precargaCIF = (body: SearchCifBody) => {
    return axios
        .post('evaluacion/precargaCif', body)
        .then((res) => res.data)
        .catch((err) => err);
};

export const confirmarAira0 = (body: IdEvaluacionBody) => {
    return axios
        .post('evaluacion/confirmarAira0', body)
        .then((res) => res)
        .catch((err) => {
            const error = JSON.stringify(err);
            return JSON.parse(error);
        });
};
export interface IdEvaluacionBody {
    idEvaluacion: number;
}

export interface SearchPersonaBody {
    idCliente: number;
    idRamo: number;
    idEvaluacion: number | null;
    nombre: string;
    apellido: string;
    idAsegurado: number | null;
    ficha: string;
}

export const precargaPersona = (body: SearchPersonaBody) => {
    return axios
        .post('evaluacion/precargaPersonaXFicha', body)
        .then((res) => res.data)
        .catch((err) => err);
};

export interface busquedaFichasPersona {
    nombre: string;
    apellido: string;
}

export const obtenerFichasPersona = (data: busquedaFichasPersona) => {
    return axios
        .post('evaluacion/prebusquedaPersona', data)
        .then((res) => res)
        .catch((err) => err);
};

export interface ActualizarCliente {
    idCliente: number;
    idInspeccion: number;
}

export const actualizarCliente = async (data: ActualizarCliente) => {
    return axios
        .post('inspecciones/actualizarCliente', data)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
};

const apiUrls = [
    { apiName: 'API Mobile', url: 'https://api-mobile-stg.aira-system.com/api/v1/status' },
    { apiName: 'API Panel', url: 'https://api-internal-stg.aira-system.com/api/v1/status' },
    { apiName: 'API Terceros', url: 'https://api-stg.aira-system.com/api/v1/status' },
];

const apiUrlsPRD = [
    { apiName: 'API Mobile', url: 'https://api-mobile.aira-system.com/api/v1/status' },
    { apiName: 'API Panel', url: 'https://api-internal.aira-system.com/api/v1/status' },
    { apiName: 'API Terceros', url: 'https://api.aira-system.com/api/v1/status' },
];

export const getApiStatus = async () => {
    const esPrd = ENV === 'P';
    if (esPrd) {
        const responses = await Promise.all(
            apiUrlsPRD.map(async (api) => {
                return {
                    api: api.apiName,
                    url: (await axios.get(api.url)).data,
                };
            }),
        );
        return responses;
    } else {
        const responses = await Promise.all(
            apiUrls.map(async (api) => {
                return {
                    api: api.apiName,
                    url: (await axios.get(api.url)).data,
                };
            }),
        );
        return responses;
    }
};

export const getDirectionByAddress = async (address: string, apiKey: string) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    try {
        const results = await fetch(url);
        const resultsText = await results.text();
        return JSON.parse(resultsText);
    } catch (error) {
        console.log(error);
    }
};

export const getMapDirectionSuggestion = (userInput: string) => {
    return externos.post('/obtenerDireccionSugeridaGoogle', { texto: userInput }).then((res) => res);
};

export const subirNota = (inspeccionId: number, agenteId: number, nota: string) => {
    return axios.post('inspecciones/subirNotaAgente', { inspeccionId, agenteId, nota }).then((res) => res);
};

export const subirAdjuntosInspeccion = (inspeccionId: number, agenteId: number, adjunto: File, batchId: string) => {
    return axios
        .post('inspecciones/subirAdjuntoAgente', createFormData({ inspeccionId, agenteId, adjunto, batchId }))
        .then((res) => res);
};

export const getAdjunto = (arn: string) => {
    const headers = {
        'Content-Type': 'applicaction/json',
    };
    return axios
        .post('inspecciones/descargarAdjunto', { arn }, { headers: headers, responseType: 'blob' })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo procesar');
        });
};

export const borrarAdjunto = (arn: string) => {
    return axios.post('inspecciones/borrarAdjunto', { arn }).then((res) => res);
};

export const getZipInspeccion = (inspeccionId: number, refCatastral: string, ramoId: number, esAira0: boolean) => {
    const headers = {
        'Content-Type': 'application/zip',
    };
    return axios
        .post(
            'inspecciones/obtenerZipInspeccion',
            { inspeccionId, refCatastral, ramoId, esAira0 },
            { headers: headers, responseType: 'blob' },
        )
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudo procesar');
        });
};

export const archivarInspeccion = (id: any) => {
    return axios
        .post('inspecciones/archivar', { inspeccionId: id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const desarchivarInspeccion = (id: any) => {
    return axios
        .post('inspecciones/desarchivar', { inspeccionId: id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const activarInspeccion = (id: any) => {
    return axios
        .post('inspecciones/activar', { inspeccionId: id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const desactivarInspeccion = (id: any) => {
    return axios
        .post('inspecciones/desactivar', { inspeccionId: id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const actualizarDiasPrevencimiento = (id: number, cantidadDias: number) => {
    return axios
        .post('inspecciones/actualizarDiasPrevencimiento', { inspeccionId: id, cantidadDias: cantidadDias })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const actualizarFechaVencimiento = (id: number, fechaVencimiento: Date) => {
    return axios
        .post('inspecciones/actualizarFechaVencimiento', { inspeccionId: id, fechaVencimiento: fechaVencimiento })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};

export const marcarInspeccionComoGestionada = (id: any) => {
    return axios
        .post('inspecciones/marcarInspeccionComoGestionada', { inspeccionId: id })
        .then((res) => res.status)
        .catch((error) => error.response.status);
};
