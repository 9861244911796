import { Grid, Modal } from '@mui/material';
import React, { useState } from 'react';
import { Pencil } from 'tabler-icons-react';
import './DetailsCard.scss';

interface Action {
    fn: () => void;
    text: string;
}
interface Data {
    label?: string;
    icon?: JSX.Element;
    value: string | JSX.Element;
    hide?: boolean;
}
interface IDetailsCardProps {
    items: Array<Data>;
    action?: Action;
    border?: boolean;
    title?: string;
    tag?: JSX.Element;
    rows?: number;
    break?: string;
    width?: string;
    height?: string;
    edit?: () => void;
}

const DetailsCard: React.FunctionComponent<IDetailsCardProps> = (props) => {
    const renderItems = () => {
        return props.items.map((e) => {
            return (
                <div key={e.label} className="details-card-row" style={{ display: e.hide ? 'none' : 'flex' }}>
                    <span className="details-card-row-label">{e.label}</span>
                    <div className="details-card-row-content">
                        {e.icon ? <div className="details-card-row-content-icon">{e.icon}</div> : null}
                        <span style={{ width: `${props.width || '345px'}` }}>{e.value}</span>
                    </div>
                </div>
            );
        });
    };

    const renderAction = () => {
        if (props.action) {
            return (
                <div className="details-card-actions">
                    <button className="tertiary-button" onClick={props.action?.fn}>
                        {props.action?.text}
                    </button>
                </div>
            );
        }
    };

    const renderTitle = () => {
        if (props.title) {
            return (
                <div className="details-card-title">
                    <span className="details-card-title">{props.title}</span>
                    {props.tag ? props.tag : null}
                </div>
            );
        }
    };

    const renderEditButton = () => {
        if (props.edit) {
            return (
                <div className="details-card-edit" onClick={() => (props.edit ? props.edit() : null)}>
                    <Pencil />
                </div>
            );
        }
    };

    return (
        <div className="details-card" style={{ height: `${props.height || 'auto'}` }}>
            {renderEditButton()}
            {renderTitle()}
            <div
                className="details-card-items"
                style={{
                    display: 'grid',
                    gridTemplateRows: props.rows ? `repeat(${props.rows}, 1fr)` : '1fr 1fr 1fr 1fr',
                }}
            >
                {renderItems()}
            </div>
            {renderAction()}
        </div>
    );
};

export default DetailsCard;
