import React from 'react';

export const AiraLogo = () => {
    return (
        <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.2397 0.00938809C29.178 0.00938809 29.1176 0 29.0546 0C28.9916 0 28.9299 -4.0969e-08 28.8682 0.00938809H3.76297C3.69993 0.00938809 3.63958 0 3.57654 0C3.51351 0 3.45182 -4.0969e-08 3.39012 0.00938809C2.4716 0.0681056 1.60991 0.474384 0.980193 1.14564C0.350478 1.8169 0 2.70275 0 3.62315C0 4.54354 0.350478 5.4294 0.980193 6.10066C1.60991 6.77191 2.4716 7.17819 3.39012 7.23691C3.45182 7.23691 3.51217 7.2463 3.5752 7.2463C3.63824 7.2463 3.69859 7.2463 3.76028 7.23691H28.8682C28.9312 7.23691 28.9916 7.2463 29.0546 7.2463C29.1176 7.2463 29.178 7.2463 29.2397 7.23691C30.1582 7.17819 31.0199 6.77191 31.6496 6.10066C32.2793 5.4294 32.6298 4.54354 32.6298 3.62315C32.6298 2.70275 32.2793 1.8169 31.6496 1.14564C31.0199 0.474384 30.1582 0.0681056 29.2397 0.00938809Z"
                fill="url(#paint0_linear_1318_38261)"
            />
            <path
                d="M26.9188 15.2099C26.9181 15.2887 26.8951 15.3657 26.8524 15.432C26.8098 15.4982 26.7492 15.551 26.6778 15.5843C26.6063 15.6175 26.527 15.6299 26.4488 15.6199C26.3706 15.6099 26.2969 15.578 26.2361 15.5278C24.0769 13.85 21.3087 12.9501 18.3112 12.9501C10.6103 12.9501 4.3457 19.0309 4.3457 26.5065C4.3457 33.946 10.6103 40 18.3112 40C21.3101 40 24.0782 39.1 26.2361 37.4223C26.2969 37.3721 26.3706 37.3402 26.4488 37.3302C26.527 37.3202 26.6063 37.3325 26.6778 37.3658C26.7492 37.399 26.8098 37.4518 26.8524 37.5181C26.8951 37.5843 26.9181 37.6613 26.9188 37.7401V40H32.5517V13.0802H26.9188V15.2099ZM18.0296 34.5227C13.5488 34.5227 10.0389 31.0035 10.0389 26.5119C10.0389 21.9855 13.5488 18.4381 18.0296 18.4381C22.0195 18.4381 26.1476 21.4812 26.1476 26.5723C26.1476 31.0249 22.5815 34.5173 18.0296 34.5173V34.5227Z"
                fill="#0B28D8"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1318_38261"
                    x1="-0.0472747"
                    y1="3.62248"
                    x2="47.4835"
                    y2="3.62248"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFFFFD" stopOpacity="0" />
                    <stop offset="0.08" stopColor="#FEECA4" stopOpacity="0.18" />
                    <stop offset="0.15" stopColor="#FEDD5F" stopOpacity="0.32" />
                    <stop offset="0.22" stopColor="#FDD32D" stopOpacity="0.42" />
                    <stop offset="0.28" stopColor="#FDCC0F" stopOpacity="0.48" />
                    <stop offset="0.33" stopColor="#FDCA04" stopOpacity="0.5" />
                    <stop offset="0.67" stopColor="#FDCA04" stopOpacity="0.69" />
                    <stop offset="0.89" stopColor="#FDCA04" stopOpacity="0.8" />
                </linearGradient>
            </defs>
        </svg>
    );
};
