import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    es: {
        translation: require('./locales/es.json'),
    },
    en: {
        translation: require('./locales/en.json'),
    },
};

const initI18n = async () => {
    return await i18next.use(initReactI18next).init({
        resources,
        lng: 'es',
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false,
        },
    });
};

export { i18next };

export default initI18n;
