import React, { useEffect, useState } from 'react';
import PaginatedCard from '../../components/PaginatedCard/PaginatedCard';
import { getRamosYModelosDashboard } from './dashboardAPI';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import { ChevronDownRight, ChevronRight } from 'tabler-icons-react';
import { Link } from 'react-router-dom';

interface RamoDashboard {
    id: number;
    nombre: string;
    icono: string;
    cantidadModelos: number;
}

interface IRamosYModelosDashboard {
    totalPaginas: number;
    ramos: RamoDashboard[];
}

const RamosYModelos = () => {
    const [ramosYModelos, setRamosYModelos] = useState<IRamosYModelosDashboard | null>(null);
    const [pagina, setPagina] = useState(1);
    const aseguradoraId = localStorage.getItem('idAseguradora');

    useEffect(() => {
        const obtenerRamosYModelos = async () => {
            const results = await getRamosYModelosDashboard(Number(aseguradoraId), pagina);
            if (results && results.status === 200) {
                setRamosYModelos(results.data);
            }
        };

        obtenerRamosYModelos();
    }, [pagina]);

    const renderRamos = (ramos: RamoDashboard[]) => {
        return ramos.map((ramo: RamoDashboard) => {
            return (
                <div key={ramo.id} className="dashboard-ramos-container">
                    <div className="dashboard-ramos-item">
                        <div className="dashboard-ramos-item-icono">{IconsRamos[ramo.icono]}</div>
                        <div className="dashboard-ramos-item-data">
                            <p className="dashboard-ramos-item-data-nombre">{ramo.nombre}</p>
                            <p className="dashboard-ramos-item-data-cantidad">
                                {ramo.cantidadModelos}{' '}
                                {ramo.cantidadModelos !== 1 ? 'modelos disponibles' : 'modelo disponible'}
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-ramos-container-mas">
                        <Link to="/modelos" state={{ ramoId: ramo.id }}>
                            <p>Ver más</p>
                            <ChevronRight />
                        </Link>
                    </div>
                </div>
            );
        });
    };

    return (
        <PaginatedCard
            pageCount={ramosYModelos ? ramosYModelos.totalPaginas : 1}
            page={pagina}
            onPageChange={(nuevaPagina: number) => setPagina(nuevaPagina)}
        >
            <div className="dashboard-ramos">
                <h2 className="dashboard-card-title">Ramos y modelos habilitados</h2>
                {ramosYModelos ? renderRamos(ramosYModelos.ramos) : null}
            </div>
        </PaginatedCard>
    );
};

export default RamosYModelos;
