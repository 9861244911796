import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronsLeft, ChevronsRight } from 'tabler-icons-react';
import conf from '../../utlis/config';
import './Navbar.scss';

const ENV = conf('ENV');
const VERSION = conf('VERSION');
interface IRoute {
    name: string;
    path: any;
    icon: React.ReactElement;
}

interface INavbarProps {
    routes: Array<IRoute>;
}

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {
    const [mouseOver, setMouseOver] = useState(false);

    const linksMapper = (routes: Array<IRoute>) => {
        return routes.map((route) => {
            return (
                <li key={route.name}>
                    <NavLink to={route.path}>
                        {route.icon}
                        <span className="navbar-link-text">{route.name}</span>
                    </NavLink>
                </li>
            );
        });
    };

    const handleMouseEvent = () => {
        setMouseOver((prevState) => !prevState);
    };

    return (
        <div className="navbar-main" onMouseEnter={handleMouseEvent} onMouseLeave={handleMouseEvent}>
            <div className="navbar-logo">
                <span>{mouseOver ? <ChevronsLeft /> : <ChevronsRight />}</span>
            </div>
            <ul>{linksMapper(props.routes)}</ul>
            {/*  <div className="navbar-settings">
                <Settings />
                <span>Ajustes</span>
            </div> */}
            <div className="version">
                {VERSION}-{ENV}
            </div>
        </div>
    );
};

export default Navbar;
