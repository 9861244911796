import { Modal, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconsPreguntasLibres } from '../../assets/iconosPreguntasLibres/IconsPreguntasLibres';
import { tipoPreguntaLibre } from '../../enums/tipoPreguntaLibre';
import EditarSubmodulo2 from './EditarSubmodulo2';
import './ModalPreguntaLibre.scss';
import {
    ModuloCustomDto,
    SubModuloCustomDto,
    activarPregunta,
    changeSubmoduleValue,
    configModeloCustom,
    hacerUltimoAlSubmodulo,
} from './ModeloSlice';
import { UpdatePreguntaModeloCustom } from './ModelosAPI';
import {
    opcionPreguntaLibre,
    opcionesTipoPreguntas,
    preguntaCargaArchivos,
    preguntaEscrita,
    preguntaFotosLibres,
    preguntaSeleccion,
    preguntaSeleccionDesplegable,
} from './OpcionesEdicionSubmodulos';
import { LimitePreguntasLibres, LimitePreguntasLibresBloques } from '../../enums/limitePreguntasLibres';
import PreguntaEscritaActive from '../../assets/iconosPreguntasLibres/PreguntaEscritaActive';

interface IPropsModalPreguntaLibre {
    open: boolean;
    onClose: () => void;
    moduloId: number;
    subModulos: any;
    idsSubmodulosActivables: any;
}

const ModalPreguntaLibre = ({
    open,
    onClose,
    moduloId,
    subModulos,
    idsSubmodulosActivables,
}: IPropsModalPreguntaLibre) => {
    const dispatch = useDispatch();
    const [tipoPreguntaSeleccionado, setTipoPreguntaSeleccionado] = useState(0);
    const [submoduloAEditar, setSubmoduloAEditar] = useState<SubModuloCustomDto | null>(null);
    const [step, setStep] = useState(1);
    const modeloCustom = useSelector(configModeloCustom);

    const handleSelectTipoPregunta = (tipoPregunta: number) => {
        setTipoPreguntaSeleccionado(tipoPregunta);
        const idSubmoduloAEditar = idsSubmodulosActivables[tipoPregunta];
        const submoduloSeleccionado = subModulos.find((sm: SubModuloCustomDto) => sm.id === idSubmoduloAEditar);
        setSubmoduloAEditar(submoduloSeleccionado);
    };

    const verificarLimitePreguntaLibre = (tipoDePregunta: number) => {
        const modulo = modeloCustom?.modulos.find((m: ModuloCustomDto) => m.id === moduloId);
        const submodulos = modulo?.subModulos.filter(
            (sm: SubModuloCustomDto) => sm.activo && sm.tipoPregunta === tipoDePregunta,
        );
        const esBloquePreguntasPersonalizadas = modulo?.titulo === '11.Preguntas personalizadas';
        if (submodulos && !esBloquePreguntasPersonalizadas) {
            const cantidad = submodulos.length;
            switch (tipoDePregunta) {
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA:
                    return cantidad === LimitePreguntasLibresBloques.SeleccionUnica;
                case tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION:
                    return cantidad === LimitePreguntasLibresBloques.SeleccionMultiple;
                case tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS:
                    return cantidad === LimitePreguntasLibresBloques.CargaArchivo;
                case tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA:
                    return cantidad === LimitePreguntasLibresBloques.Escrita;
                case tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE:
                    return cantidad === LimitePreguntasLibresBloques.Fotos;
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_DESPLEGABLE:
                    return cantidad === LimitePreguntasLibresBloques.Fotos;
                default:
                    return false;
            }
        } else if (submodulos) {
            const cantidad = submodulos.length;
            switch (tipoDePregunta) {
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA:
                    return cantidad === LimitePreguntasLibres.SeleccionUnica;
                case tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION:
                    return cantidad === LimitePreguntasLibres.SeleccionMultiple;
                case tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS:
                    return cantidad === LimitePreguntasLibres.CargaArchivo;
                case tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA:
                    return cantidad === LimitePreguntasLibres.Escrita;
                case tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE:
                    return cantidad === LimitePreguntasLibresBloques.Fotos;
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_DESPLEGABLE:
                    return cantidad === LimitePreguntasLibresBloques.Fotos;
                default:
                    return false;
            }
        }
    };

    const preguntaButton = (opcionPregunta: opcionPreguntaLibre, index: number) => {
        const active = tipoPreguntaSeleccionado === opcionPregunta.tipoDePregunta;
        const fillException = ['seleccionUnica'];
        const hasException = fillException.includes(opcionPregunta.icono);
        const disable = verificarLimitePreguntaLibre(opcionPregunta.tipoDePregunta);
        return (
            <button
                className={`opcionPregunta-button ${active ? 'active' : 'disabled'}`}
                key={opcionPregunta.titulo + index}
                onClick={() => handleSelectTipoPregunta(opcionPregunta.tipoDePregunta)}
                disabled={disable}
            >
                {disable ? (
                    <Tooltip
                        title="Se ha alcanzado la maxima cantidad de preguntas de este tipo"
                        placement="right"
                        arrow
                    >
                        <div
                            className={`opcionPregunta-button-logo ${
                                hasException ? 'opcionPregunta-button-logo-exception' : ''
                            } pregunta-deshabilitada`}
                        >
                            {IconsPreguntasLibres[opcionPregunta.icono]}
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        className={`opcionPregunta-button-logo ${
                            hasException ? 'opcionPregunta-button-logo-exception' : ''
                        }`}
                    >
                        {IconsPreguntasLibres[active ? opcionPregunta.iconoActivo : opcionPregunta.icono]}
                    </div>
                )}
                <p className="opcionPregunta-button-titulo">{opcionPregunta.titulo}</p>
            </button>
        );
    };

    const preguntaButtons = () => {
        return opcionesTipoPreguntas.map((opcion, index) => {
            return preguntaButton(opcion, index);
        });
    };

    const handleSubmitPreguntaLibreTitulo = async (e: any, submoduleId: number, keyValor: string) => {
        const data = Object.entries(e).reduce(
            (acc: UpdatePreguntaModeloCustom, [key, value]: any) => {
                return {
                    ...acc,
                    idSubModulo: submoduleId,
                    titulo: 'Preguntas adicionales',
                    subtitulo: key === keyValor ? value : acc.subtitulo,
                };
            },
            {
                idSubModulo: 0,
                titulo: '',
                subtitulo: '',
                texto: '',
                etiqueta: '',
                opciones: [],
            },
        );

        //setValuesChanged(true);
        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleSubmitFotosLibres = async (e: any, submoduleId: number) => {
        const data = { idSubModulo: submoduleId, cantidad: Number(e.fotosLibres), subtitulo: e.tituloPregunta };
        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleSubmitEditarSubmodulo = async (e: any, submoduleId: number) => {
        const data = Object.entries(e).reduce(
            (acc: UpdatePreguntaModeloCustom, [key, value]: any) => {
                return {
                    ...acc,
                    idSubModulo: submoduleId,
                    titulo: 'Preguntas adicionales',
                    subtitulo: key === 'tituloPregunta' ? value : acc.subtitulo,
                    opciones:
                        key === 'tituloPregunta' || key === 'opcionEscrita' || key === 'opcionCargaArchivos'
                            ? acc.opciones
                            : [...acc.opciones, value],
                };
            },
            {
                idSubModulo: 0,
                titulo: '',
                subtitulo: '',
                texto: '',
                etiqueta: '',
                opciones: [],
            },
        );

        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleSubmitEditarSubmoduloCsv = async (e: any, submoduleId: number) => {
        const opciones = Object.values(e['archivoCsv']);
        const data = {
            idSubModulo: 0,
            titulo: '',
            subtitulo: e['tituloPregunta'],
            texto: '',
            etiqueta: '',
            opciones: opciones,
        };

        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const getFormOptions = () => {
        switch (tipoPreguntaSeleccionado) {
            case tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA:
                return preguntaEscrita;
            case tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS:
                return preguntaCargaArchivos;
            case tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION:
                return preguntaSeleccion;
            case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA:
                return preguntaSeleccion;
            case tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE:
                return preguntaFotosLibres;
            case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_DESPLEGABLE:
                return preguntaSeleccionDesplegable;
            default:
                return preguntaEscrita;
        }
    };

    const handleClose = () => {
        setTipoPreguntaSeleccionado(0);
        setStep(1);
        onClose();
    };

    const updatePreguntaLibre = (e: any) => {
        if (submoduloAEditar) {
            switch (tipoPreguntaSeleccionado) {
                case tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA:
                    return handleSubmitPreguntaLibreTitulo(e, submoduloAEditar.id, 'opcionEscrita');
                case tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS:
                    return handleSubmitPreguntaLibreTitulo(e, submoduloAEditar.id, 'opcionCargaArchivos');
                case tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION:
                    return handleSubmitEditarSubmodulo(e, submoduloAEditar.id);
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA:
                    return handleSubmitEditarSubmodulo(e, submoduloAEditar.id);
                case tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE:
                    return handleSubmitFotosLibres(e, submoduloAEditar.id);
                case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_DESPLEGABLE:
                    return handleSubmitEditarSubmoduloCsv(e, submoduloAEditar.id);
                default:
                    return preguntaEscrita;
            }
        } else {
            console.log('Error');
        }
    };

    const onSubmit = async (e: any) => {
        if (submoduloAEditar) {
            dispatch(activarPregunta({ idSubmodulo: submoduloAEditar.id, idModulo: moduloId }));
            dispatch(hacerUltimoAlSubmodulo({ idSubmodulo: submoduloAEditar.id, idModulo: moduloId }));
            updatePreguntaLibre(e);
        }

        handleClose();
        return false;
    };

    const renderModalContet = () => {
        if (step === 2 && submoduloAEditar) {
            return (
                <EditarSubmodulo2
                    forceOpen={open}
                    titulo={submoduloAEditar.titulo}
                    options={getFormOptions()}
                    buttonText="Confirmar pregunta"
                    onSubmit={(e) => onSubmit(e)}
                    onClose={handleClose}
                    defaultValues={submoduloAEditar.preguntaConfiguracion}
                />
            );
        } else {
            return (
                <div className="modalpregunta">
                    <h1>Añadir pregunta personalizada</h1>
                    <div className="modalpregunta-title">
                        <h3>1. Seleccion el tipo de pregunta que quieres añadir:</h3>
                    </div>
                    <div className="modalpregunta-types-list">{preguntaButtons()}</div>
                    <div className="modalpregunta-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button
                            className="primary-button"
                            disabled={!tipoPreguntaSeleccionado}
                            onClick={() => setStep(2)}
                        >
                            Continuar
                        </button>
                    </div>
                </div>
            );
        }
    };

    return (
        <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderModalContet()}
        </Modal>
    );
};

export default ModalPreguntaLibre;
