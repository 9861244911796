import axios from 'axios';

export function Login(email: string, password: string) {
    /*   return new Promise<{ data: number }>((resolve) => setTimeout(() => resolve({ data: amount }), 500)); */
    /* email: 'aira@gmail.com', password: 'airadb' */
    return axios
        .post('iniciar-sesion', { email: email, password: password })
        .then((res) => {
            console.log('Se inicio sesion - TOKEN', res.data);
            localStorage.setItem('email', res.data.email);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('idAgente', res.data.idUser);
            localStorage.setItem('key', res.data.key);
            localStorage.setItem('idClientePrueba', res.data.idClientePrueba);
            localStorage.setItem('tutorialActivo', res.data.tutorialActivo);
            return res.data;
        })
        .catch(() => {
            console.log('No se pudo iniciar sesion');
        });
}

export const aceptarTerminosYCondiciones = (agenteId: string) => {
    return axios.post('/agentes/aceptarTerminosYCondiciones', { id: agenteId }).then((res) => res);
};

export const desactivarTutorialAgente = (agenteId: string) => {
    return axios.post('/agentes/desactivarTutorialAgente', { id: agenteId }).then((res) => res);
};
