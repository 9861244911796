import {
    Alert,
    Autocomplete,
    Box,
    Checkbox,
    Modal,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { gridClasses, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    AdjustmentsAlt,
    Building,
    CalendarOff,
    ChevronDown,
    ChevronRight,
    CircleCheck,
    Clock,
    DotsVertical,
    Eye,
    EyeOff,
    File,
    FileCheck,
    FileDownload,
    FileText,
    InfoCircle,
    Pencil,
    Refresh,
    Send,
    User,
    X,
} from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IconComercio } from '../../assets/IconComercio';
import { IconComunidad } from '../../assets/iconComunidad';
import { IconHogar } from '../../assets/IconHogar';
import { IconIndustria } from '../../assets/IconIndustria';
import { IconVehiculo } from '../../assets/IconVehiculo';
import {
    DataGrid,
    renderEstadosYSubestados,
    renderText,
    renderTextWithIcon,
    renderTextWithTooltip,
} from '../../components/data-grid/DataGrid';
import ModalConfirmation from '../../components/modal-confirmation/ModalConfirmation';
import DropdownMenu from '../../components/dropdown-menu/DropdownMenu';
import OpenPdfModal from '../../components/openPdfModal/OpenPdfModal';
import QuickSearch from '../../components/quick-search/QuickSearch';
import {
    checkNullUndefined,
    filterClientes as filterPorNombre,
    getDateFormated,
    getTimeFormated,
    isTest,
    nombreClienteFisico,
    nombreClienteJuridico,
    normalizeText,
} from '../../utlis';
import conf from '../../utlis/config';
import { obtenerLogoAseguradora } from '../clientes/ClientesAPI';
import './Inspecciones.scss';
import { activarInspeccion, archivarInspeccion, deleteInspeccion, desarchivarInspeccion } from './InspeccionesAPI';
import {
    agentesInspeccion,
    clientesMap,
    ClientesSync,
    InspeccionesSync,
    listaInspecciones,
    notifiedInspeccion,
    removeInspeccion,
    setNotified,
} from './inspeccionesSlice';
import NuevaInspeccion from './NuevaInspeccion';
import ModalEliminarInspeccion from '../../components/ModalEliminarInspeccion/ModalEliminarInspeccion';
import { aceptarTerminosYCondiciones } from '../login/LoginAPI';
import { selectToS } from '../login/loginSlice';
import NuevoAiraCero from './aira0/NuevoAiraCero';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import DropdownCustom from '../../components/DropdownCustom/DropdownCustom';
import { modelListSelector } from '../modelos/ModeloSlice';
import Tabs from '../../components/tabs/Tabs';

function debounce<T extends unknown[]>(func: (...args: T) => void, delay: number): (...args: T) => void {
    let timer: any | null = null;
    return (...args: T) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            func.call(null, ...args);
        }, delay);
    };
}
interface FilterType {
    id: number;
    text: string;
    icon?: React.ReactElement;
}

interface DataRowType {
    id: string;
    nombreInspeccion: string;
    cliente: { text: string; icon: any; iconColor: string };
    direccion: { pending: boolean };
    ramo: {
        text: string;
        icon: any;
        background: string;
        textColor: string;
        fontSize: string;
        padding: string;
        width: string;
    };
    modelo: string;
    envio: { line1: string; line2: string };
    estado: {
        text: string;
        icon: any;
        textColor: string;
        fontSize: string;
        border: string;
        padding: string;
    };
    vencimiento: { line1: string; line2: string };
}

/* const FilterType = (props: any) => {
    const types: FilterType[] = [
        { id: 0, text: 'Todos' },
        { id: 1, text: 'Hogar', icon: <IconHogar stroke="#0046CF" /> },
        { id: 2, text: 'Comercio / Oficina', icon: <IconComercio stroke="#0046CF" /> },
        { id: 3, text: 'Pyme / Industria', icon: <IconIndustria stroke="#0046CF" /> },
        { id: 4, text: 'Vehículo', icon: <IconVehiculo stroke="#0046CF" /> },
        { id: 5, text: 'Comunidad', icon: <IconComunidad stroke="#0046CF" /> },
    ];

    const [active, setActive] = React.useState(types[0]);

    const renderButtons = () => {
        return types.map((t) => (
            <ToggleButton
                selected={t.id === active.id}
                className="toggle-button-filters"
                disableRipple
                key={t.text}
                value={t}
            >
                {t.icon}
                <span>{t.text}</span>
            </ToggleButton>
        ));
    };

    const handleChange = (e: React.MouseEvent<HTMLElement>, n: any) => {
        setActive(n);
        props.onSelect(n);
    };

    return (
        <ToggleButtonGroup color="primary" value={active} exclusive onChange={handleChange}>
            {renderButtons()}
        </ToggleButtonGroup>
    );
}; */

export const renderIconEstado = (id: number) => {
    switch (id) {
        case 1:
            return <Send />;
            break;
        case 2:
            return <FileText />;
            break;
        case 3:
            return <Pencil />;
            break;
        case 4:
            return <Refresh />;
            break;
        case 5:
            return <Refresh />;
            break;
        case 6:
            return <FileCheck />;
            break;
    }
};

export const renderColorEstado = (id: number) => {
    switch (id) {
        case 1:
            return '#EB760A';
            break;
        case 2:
            return '#53BD8A';
            break;
        case 3:
            return '#E4B305';
            break;
        case 4:
            return '#635F76';
            break;
        case 5:
            return '#635F76';
            break;
        case 6:
            return '#0046CF';
            break;
    }
};

export const renderBorderEstado = (id: number) => {
    switch (id) {
        case 1:
            return '2px solid #FDF6F0';
            break;
        case 2:
            return '2px solid #E7FCF2';
            break;
        case 3:
            return '2px solid #FDF7E1';
            break;
        case 4:
            return '2px solid #D9D7E1';
            break;
        case 5:
            return '2px solid #D9D7E1';
            break;
        case 6:
            return '2px solid #EBF1FE';
            break;
    }
};

export const renderRamoIcon = (icono: string) => {
    return IconsRamos[icono];
};

export const renderEstadoText = (i: any) => {
    if (i.gestionado) {
        return 'Gestionado';
    }
    if (i.estado_inspeccion && i.estado_inspeccion.id === 6) {
        return 'Aira 0';
    }
    if (i.estado_inspeccion && (i.estado_inspeccion.id === 4 || i.estado_inspeccion.id === 5)) {
        return 'Procesando IA';
    } else {
        return i.estado_inspeccion && i.estado_inspeccion.estado;
    }
};

const toRows = (inspecciones: any) => {
    return inspecciones?.map((i: any) => {
        const horaEnvio = getTimeFormated(i.fechaEnviada);
        const horaEnvioFormated = horaEnvio;

        const horaVencimientoFormated =
            i.fechaVencimiento != null
                ? new Date(i.fechaVencimiento).toLocaleTimeString('es-ES', {
                      hour: '2-digit',
                      minute: '2-digit',
                  })
                : '';
        return {
            id: i.id,
            cliente: {
                id: i.cliente_inspeccion?.id,
                icon: determinarTipoCliente(i.cliente_inspeccion) == 1 ? <User /> : <Building />,
                iconColor: '#B0ADBE',
                text:
                    determinarTipoCliente(i.cliente_inspeccion) == 1
                        ? nombreClienteFisicoXInspeccion(i)
                        : nombreClienteJuridicoXInspeccion(i),
            },
            direccion: i.direccionLinea1 ? { line1: i.direccionLinea1, line2: i.direccionLinea2 } : { pending: true },
            envio: {
                line1: getDateFormated(i.fechaEnviada),
                line2: horaEnvioFormated,
                fechaHoraEnvio: new Date(i.fechaEnviada),
            },
            estado: {
                id: i.estado_inspeccion ? i.estado_inspeccion.id : '',
                text: renderEstadoText(i),
                icon: i.gestionado ? (
                    <CircleCheck />
                ) : (
                    renderIconEstado(i.estado_inspeccion ? i.estado_inspeccion.id : '')
                ),
                textColor: i.gestionado
                    ? '#3BA1AE'
                    : renderColorEstado(i.estado_inspeccion ? i.estado_inspeccion.id : ''),
                fontSize: '12px',
                border: i.gestionado
                    ? '2px solid #E3F6F9'
                    : renderBorderEstado(i.estado_inspeccion ? i.estado_inspeccion.id : ''),
                padding: '4px 12px 4px 8px',
                fontWeight: '800',
                vencimiento: i.estado_vencimiento_id,
            },
            modelo: i.modeloCustom ? i.modeloCustom.nombre : i.modelo_inspeccion ? i.modelo_inspeccion.descripcion : '',
            agente: i.agente_inspeccion ? i.agente_inspeccion.nombre : '',
            nombreInspeccion: i.nombreInspeccion ? i.nombreInspeccion : 'ID ' + i.id,
            ramo: {
                id: i.ramo_custom ? i.ramo_custom.id : '',
                text: i.ramo_custom ? i.ramo_custom.nombre : '',
                icon: i.ramo_custom ? renderRamoIcon(i.ramo_custom.icono) : '',
                background: '#EBF1FE',
                textColor: '#0046CF',
                fontSize: '12px',
                padding: '4px 8px',
                alignment: 'center',
                fontWeight: '800',
                gap: '0px',
            },
            estadoVencimientoId: i.estado_vencimiento_id,
            vencimiento:
                i.fechaVencimiento != null
                    ? {
                          line1: i.fechaVencimiento != '' ? getDateFormated(i.fechaVencimiento) : '',
                          line2: i.fechaVencimiento != '' ? horaVencimientoFormated : '',
                          fechaHoraVencimiento: new Date(i.fechaVencimiento),
                          pending: false,
                      }
                    : { pending: true },
        };
    });
};

const filterEstados = (filterValues: any[], inspection: any) => {
    const mostrarGestionadas = filterValues.some((s) => s.id === 8);
    if (mostrarGestionadas) {
        return filterValues.some((s) => s.id === inspection.estado.id || inspection.estado.text === 'Gestionado');
    } else {
        return filterValues.some((s) => s.id === inspection.estado.id) && inspection.estado.text !== 'Gestionado';
    }
};

const defaultFilters = {
    quickSearch: {
        filterFn: (i: Record<string, string>, v: string) =>
            v === '' || normalizeText(i.nombreInspeccion.toLowerCase()).search(normalizeText(v.toLowerCase())) != -1,
        value: '',
    },
    ramo: {
        filterFn: (i: Record<string, any>, v: number) => v === 0 || i.ramo.id === v,
        value: 0,
    },
    cliente: {
        filterFn: (i: Record<string, any>, v: any) => v === null || i.cliente.id === v.id,
        value: null,
    },
    estado: {
        filterFn: (i: Record<string, any>, v: Array<any>) => v.length === 0 || filterEstados(v, i),
        value: [],
    },
    estadoVencimiento: {
        filterFn: (i: Record<string, any>, v: Array<any>) => {
            return v.length === 0 || v.some((s) => s.id === i.estadoVencimientoId);
        },
        value: [],
    },
    agente: {
        filterFn: (i: Record<string, any>, v: any) => v === null || i.agente === v.label,
        value: null,
    },
};

const updateFilters = (filters: any, key: any, value: any) => {
    return { ...filters, [key]: { ...filters[key], value } };
};

export interface deleteInspeccionModal {
    open: boolean;
    id: GridRowId | null;
}

const InspeccionesFeature = () => {
    const [filters, setFilters] = React.useState(defaultFilters);
    const [toast, setToast] = React.useState(false);
    const route = useLocation().pathname;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const inspeccionCreada = useAppSelector(notifiedInspeccion);
    const inspecciones = useAppSelector(listaInspecciones);
    const inspeccionesMap2 = toRows(inspecciones);
    const [filteredRows, setFilteredRows] = React.useState(inspeccionesMap2);
    const clientes = useAppSelector(clientesMap);
    const [openPdfModal, setOpenPdfModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [actionError, setActionError] = React.useState('');
    const defaultDeleteInspectModalState = { open: false, id: null };
    const [deleteInspectionModal, setDeleteInspectionModal] =
        React.useState<deleteInspeccionModal>(defaultDeleteInspectModalState);
    const agentes = useSelector(agentesInspeccion);
    const isAdmin = localStorage.getItem('admin') === 'S';
    const isSuperUser = localStorage.getItem('idAseguradora') === '0';
    const aceptoToS = useSelector(selectToS);
    const ENV = conf('ENV');
    const models = useAppSelector(modelListSelector);
    const [softDeletedId, setSoftDeletedId] = React.useState(0);

    useEffect(() => {
        dispatch(ClientesSync());
        dispatch(InspeccionesSync(false));
        //@ts-ignore 123
        if (location.state && location.state.deletedId) {
            //@ts-ignore 123
            setSoftDeletedId(location.state.deletedId);
        }
    }, []);

    useEffect(() => {
        setFilteredRows(inspeccionesMap2);
        //@ts-ignore 123
        if (location.state && location.state.estadoId) {
            setFilters(
                //@ts-ignore 123
                updateFilters(filters, 'estado', [{ id: location.state.estadoId, label: location.state.estadoLabel }]),
            );

            return () => {
                window.history.replaceState({}, '');
            };
        }
    }, [inspecciones]);

    useEffect(() => {
        const newRows = inspeccionesMap2.filter((i: any) => {
            return Object.values(filters).reduce((acc, e: any) => {
                return acc && e.filterFn(i, e.value);
            }, true);
        });

        setFilteredRows(newRows);
    }, [filters]);

    const handleDownload = async (e: any, id: any) => {
        e.stopPropagation();
        if (isTest(ENV)) {
            window.open(`https://aira-pdf.s3.eu-west-1.amazonaws.com/pruebas/inspeccion-${id}.pdf`, '_blank');
        } else {
            window.open(`https://aira-pdf.s3.eu-west-1.amazonaws.com/inspeccion-${id}.pdf`, '_blank');
        }
    };

    const handleEliminarModal = (e: React.MouseEvent<Element, MouseEvent>, id: GridRowId) => {
        e.stopPropagation();
        setDeleteInspectionModal({ open: true, id });
    };

    const handleEliminarInspeccion = async (id: GridRowId | null) => {
        setLoading(true);
        const response = await deleteInspeccion(id);
        if (response === 200) {
            dispatch(removeInspeccion(id));
            setDeleteInspectionModal(defaultDeleteInspectModalState);
            setLoading(false);
        } else {
            setLoading(false);
            setActionError('Ha ocurrido un error al intentar eliminar la evaluación');
            setDeleteInspectionModal(defaultDeleteInspectModalState);
        }
    };

    const handleArchivarInspeccion = async (e: React.MouseEvent<Element, MouseEvent>, id: GridRowId) => {
        e.stopPropagation();
        const response = await archivarInspeccion(id);
        if (response === 200) {
            dispatch(removeInspeccion(id));
        } else {
            setActionError('Ha ocurrido un error al intentar archivar la evaluación');
        }
    };

    const handleDesarchivarInspeccion = async (e: React.MouseEvent<Element, MouseEvent>, id: GridRowId) => {
        e.stopPropagation();
        const response = await desarchivarInspeccion(id);
        if (response === 200) {
            dispatch(removeInspeccion(id));
        } else {
            setActionError('Ha ocurrido un error al intentar desarchivar la evaluación');
        }
    };

    const columns: GridColumns = [
        {
            field: 'nombreInspeccion',
            headerName: 'Nombre evaluación',
            flex: 1.5,
            cellClassName: 'data-grid-bold-cell',
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1.2,
            sortable: false,
        },
        {
            field: 'direccion',
            headerName: 'Dirección',
            renderCell: (params) => renderText(params.value),
            //cellClassName: 'cell-direccion',
            flex: 1.5,
            sortable: false,
        },
        {
            field: 'ramo',
            headerName: 'Ramo',
            renderCell: (params) => renderTextWithIcon(params.value),
            flex: 1.4,
            sortable: false,
        },
        { field: 'modelo', headerName: 'Modelo', flex: 0.7, sortable: false },
        {
            field: 'agente',
            headerName: 'Agente',
            renderCell: (params) => renderTextWithTooltip(params.value),
            flex: 0.8,
            sortable: false,
        },
        {
            field: 'envio',
            headerName: 'Enviada',
            renderCell: (params) => renderText(params.value),
            sortComparator: (d1: any, d2: any) => d1.fechaHoraEnvio - d2.fechaHoraEnvio,
            flex: 1,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            renderCell: (params) => renderEstadosYSubestados(params.value),
            flex: 1.2,
            sortable: false,
        },
        {
            field: 'vencimiento',
            headerName: 'Vence',
            renderCell: (params) => renderText(params.value),
            sortComparator: (d1: any, d2: any) => {
                if (d1.pending && d2.pending) {
                    return 0;
                }
                if (d1.pending && !d2.pending) {
                    return 1;
                }
                if (!d1.pending && d2.pending) {
                    return -1;
                }
                return d1.fechaHoraVencimiento - d2.fechaHoraVencimiento;
            },
            flex: 1,
            hide: true,
        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            cellClassName: 'actionCell',
            getActions: (params) => [
                <FileDownload
                    key="downloadPdf"
                    className={params.row.estado.id === 2 ? 'actionButton' : 'actionButtonHidden'}
                    onClick={(e) => handleDownload(e, params.id)}
                />,
                <X
                    key="eraseInspection"
                    style={{ paddingRight: '5px' }}
                    className={isSuperUser ? 'actionButton' : 'actionButtonHidden'}
                    onClick={(e) => handleEliminarModal(e, params.id)}
                />,
                <EyeOff
                    key="archivarInspeccion"
                    className={selectedTab === 1 ? 'actionButton' : 'actionButtonHidden'}
                    onClick={(e) => handleArchivarInspeccion(e, params.id)}
                />,
                <Eye
                    key="desarchivarInspeccion"
                    className={selectedTab === 2 ? 'actionButton' : 'actionButtonHidden'}
                    onClick={(e) => handleDesarchivarInspeccion(e, params.id)}
                />,
            ],
            flex: isSuperUser ? 0.3 : 0.1,
        },
    ];

    const onQuickSearchChanged = debounce((searchTerm: string) => {
        setFilters(updateFilters(filters, 'quickSearch', searchTerm));
    }, 300);

    const estados = [
        { id: 1, label: 'Enviada', icon: <Send />, color: '#EB760A' },
        { id: 2, label: 'Completada', icon: <FileText />, color: '#53BD8A' },
        { id: 3, label: 'En curso', icon: <Pencil />, color: '#E4B305' },
        { id: 6, label: 'Aira 0', icon: <File />, color: '#0046CF' },
        { id: 8, label: 'Gestionado', icon: <CircleCheck />, color: '#3BA1AE' },
    ];

    const estadosDeVencimiento = [
        { id: 1, label: 'Vigente', icon: <CircleCheck />, color: '#53BD8A' },
        { id: 2, label: 'Pre-Vencida', icon: <Clock />, color: '#E54B54' },
        { id: 3, label: 'Vencida', icon: <CalendarOff />, color: '#B53572' },
    ];

    interface clientesOptions {
        id: number;
        label: string;
    }

    const renderClientes = () => {
        if (clientes) {
            return clientes.map((c) => {
                return {
                    id: c.id,
                    label: c.tipoCliente === 1 ? nombreClienteFisico(c) : nombreClienteJuridico(c),
                };
            });
        } else {
            return [];
        }
    };

    const filtroCliente = () => {
        return (
            <div>
                <p className="filter-label">Cliente</p>
                <Autocomplete
                    id="clientes-filter-combo-box"
                    popupIcon={<ChevronDown />}
                    options={renderClientes()}
                    sx={{ width: 250 }}
                    onChange={(e, v) => {
                        setFilters(updateFilters(filters, 'cliente', v));
                    }}
                    value={filters.cliente.value}
                    filterOptions={(a, b) => filterPorNombre(a, b)}
                    renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                            {option.label}
                        </Box>
                    )}
                    isOptionEqualToValue={(o, v) => {
                        return o.id === v.id;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: false }}
                            label=" "
                            variant="outlined"
                            placeholder="Selecciona un cliente"
                        />
                    )}
                />
            </div>
        );
    };

    const filtroAgente = () => {
        return (
            <div>
                <p className="filter-label">Agente</p>
                <Autocomplete
                    id="agente-filter-combo-box"
                    popupIcon={<ChevronDown />}
                    options={agentes}
                    sx={{ width: 250 }}
                    onChange={(e, v) => {
                        setFilters(updateFilters(filters, 'agente', v));
                    }}
                    value={filters.agente.value}
                    filterOptions={(a, b) => filterPorNombre(a, b)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{ shrink: false }}
                            label=" "
                            variant="outlined"
                            placeholder="Selecciona un cliente"
                        />
                    )}
                />
            </div>
        );
    };

    const renderFilterClienteAgente = () => {
        if (isAdmin || isSuperUser) {
            return (
                <>
                    {filtroCliente()}
                    {filtroAgente()}
                </>
            );
        } else {
            return filtroCliente();
        }
    };

    const renderRamoFilterOptions = () => {
        const ramosOptions = models.map((model) => {
            return {
                label: model.nombre,
                id: model.id,
            };
        });

        return [{ label: 'Todos', id: 0 }, ...ramosOptions];
    };

    const [selectedTab, setSelectedTab] = React.useState(1);
    useEffect(() => {
        if (selectedTab === 1) {
            dispatch(InspeccionesSync(false));
        } else if (selectedTab === 2) {
            dispatch(InspeccionesSync(true));
        }
    }, [selectedTab]);

    const CustomToolBar = () => {
        return (
            <>
                {/* <QuickSearch onChange={onQuickSearchChanged} placeholder="Buscar por el nombre de evaluación" /> */}
                <div style={{ marginRight: 'auto' }}>
                    <Tabs
                        tabs={[
                            { title: 'Activos', id: 1 },
                            { title: 'Archivados', id: 2 },
                        ]}
                        onChange={(e) => setSelectedTab(e)}
                    />
                </div>
                {/* <FilterType onSelect={(e: any) => setFilters(updateFilters(filters, 'ramo', e.id))} /> */}
                {models && models.length ? (
                    <DropdownCustom
                        options={renderRamoFilterOptions()}
                        onSelect={(ramoId: number) => setFilters(updateFilters(filters, 'ramo', ramoId))}
                    />
                ) : null}
                <DropdownMenu buttonPadding="16px 8px" icon={<AdjustmentsAlt />}>
                    {renderFilterClienteAgente()}
                    <div>
                        <p className="filter-label">Estado</p>
                        <Autocomplete
                            multiple
                            popupIcon={<ChevronDown />}
                            disableCloseOnSelect
                            id="estados-combo-box"
                            options={estados}
                            value={filters.estado.value}
                            isOptionEqualToValue={(o, v) => {
                                return o.id == v.id;
                            }}
                            onChange={(e, v) => {
                                setFilters(updateFilters(filters, 'estado', v));
                            }}
                            limitTags={1}
                            sx={{ width: 250 }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{ color: option.color, fontSize: '12px', fontWeight: 800 }}>
                                    <Checkbox checked={selected} />
                                    {option.icon}
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: false }}
                                        label=" "
                                        variant="outlined"
                                    />
                                );
                            }}
                        />
                    </div>
                    <div>
                        <p className="filter-label">Estado de vencimiento</p>
                        <Autocomplete
                            multiple
                            popupIcon={<ChevronDown />}
                            disableCloseOnSelect
                            id="estados-combo-box"
                            options={estadosDeVencimiento}
                            value={filters.estadoVencimiento.value}
                            isOptionEqualToValue={(o, v) => {
                                return o.id == v.id;
                            }}
                            onChange={(e, v) => {
                                setFilters(updateFilters(filters, 'estadoVencimiento', v));
                            }}
                            limitTags={1}
                            sx={{ width: 250 }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{ color: option.color, fontSize: '12px', fontWeight: 800 }}>
                                    <Checkbox checked={selected} />
                                    {option.icon}
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: false }}
                                        label=" "
                                        variant="outlined"
                                    />
                                );
                            }}
                        />
                    </div>
                </DropdownMenu>
                <NuevoAiraCero />
                <NuevaInspeccion onCreated={() => setToast(true)} />
            </>
        );
    };

    const handleToastClose = () => {
        if (inspeccionCreada) dispatch(setNotified(false));
    };

    const handleActivarInspeccion = async () => {
        const results = await activarInspeccion(softDeletedId);
        if (results === 200) {
            dispatch(InspeccionesSync(false));
            setSoftDeletedId(0);
        }
    };

    return (
        <Box
            className="inspecciones"
            sx={{
                '& .actionButton': {
                    display: 'none',
                },
                '& .chevronRight': {
                    height: '18px',
                    strokeWidth: '3.5px',
                },
                '& .actionButtonHidden': {
                    display: 'none',
                },
                [`& .${gridClasses.row}:hover`]: {
                    '.actionButton': {
                        display: 'flex',
                        gap: '14px;',
                        color: '#0046CF',
                    },
                    '.actionButtonHidden': {
                        display: 'none',
                    },
                },
            }}
        >
            {aceptoToS ? (
                <>
                    <h3>Evaluaciones</h3>
                    <DataGrid
                        rows={filteredRows}
                        loader={filteredRows ? false : true}
                        columns={columns}
                        pageSize={10}
                        toolbar={CustomToolBar}
                        route={route}
                        sortingModel={[{ field: 'envio', sort: 'desc' }]}
                    />
                </>
            ) : null}
            <Snackbar
                open={inspeccionCreada}
                autoHideDuration={10000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleToastClose}
                    severity="success"
                    icon={<CircleCheck />}
                    sx={{
                        fontFamily: 'Nunito sans',
                        backgroundColor: '#011F59',
                        color: '#ffffff',
                        fontSize: '14px',
                        fontWeigh: '600',
                        borderRadius: '8px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Has creado y enviado una nueva evaluación.
                </Alert>
            </Snackbar>
            <OpenPdfModal open={openPdfModal} />
            <Modal
                open={deleteInspectionModal.open}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <ModalEliminarInspeccion
                    id={deleteInspectionModal.id}
                    onCancel={() => setDeleteInspectionModal(defaultDeleteInspectModalState)}
                    onDelete={() => handleEliminarInspeccion(deleteInspectionModal.id)}
                    loading={loading}
                />
            </Modal>
            <Snackbar
                open={actionError ? true : false}
                autoHideDuration={10000}
                onClose={() => setActionError('')}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setActionError('')} severity="error">
                    {actionError}
                </Alert>
            </Snackbar>
            <Snackbar
                open={softDeletedId ? true : false}
                autoHideDuration={10000}
                onClose={() => setSoftDeletedId(0)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSoftDeletedId(0)}
                    severity="success"
                    icon={<InfoCircle color="#0046CF" />}
                    sx={{
                        fontFamily: 'Nunito sans',
                        backgroundColor: '#EBF1FE',
                        color: '#0046CF',
                        fontSize: '14px',
                        fontWeigh: '600',
                        borderRadius: '8px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Se ha eliminado el formulario ID {softDeletedId}.{' '}
                    <span className="deshacer" onClick={handleActivarInspeccion}>
                        Deshacer
                    </span>
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default InspeccionesFeature;
export function nombreClienteFisicoXInspeccion(i: any) {
    let nombre =
        checkNullUndefined(i.cliente_inspeccion?.nombreCompleto) +
        ' ' +
        checkNullUndefined(i.cliente_inspeccion?.apellido);
    nombre = nombre.trim();
    nombre = nombre != '' ? nombre : i.cliente_inspeccion?.nombreComercio || i.cliente_inspeccion?.dni;
    if (nombre === null || nombre === undefined || nombre === '') {
        nombre = '-';
    }
    return nombre;
}

export function nombreClienteJuridicoXInspeccion(i: any) {
    let nombre =
        i.cliente_inspeccion?.nombreFiscal || i.cliente_inspeccion?.nombreComercio || i.cliente_inspeccion?.cif;
    if (nombre === null || nombre === undefined || nombre === '') {
        nombre = '-';
    }
    return nombre;
}
function determinarTipoCliente(cliente: any) {
    return cliente?.tipo_cliente_id != null ? cliente?.tipo_cliente_id : 1;
}
