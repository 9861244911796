import axios from 'axios';

interface CrearRamo {
    nombre: string;
    icono: string;
    idRamoMaestro: number;
}

interface EditarRamo {
    nombre: string;
    icono: string;
    idRamoCustom: number;
}

export function PostRamo(data: CrearRamo) {
    return axios
        .post('ramo/crearRamo', data)
        .then((res) => res.data)
        .catch(() => {
            console.log('No se ha podido crear el ramo');
        });
}

export function GetRamosCustom(aseguradoraId: number) {
    return axios
        .get('ramo/obtenerRamos')
        .then((res) => res)
        .catch(() => {
            console.log('No se pudieron obtener los ramos');
        });
}

export function GetRamosCustomConModelo(aseguradoraId: number) {
    return axios
        .get(`ramo/obtenerRamosConModelos?idAseguradora=${aseguradoraId}`)
        .then((res) => {
            return res.data;
        })
        .catch(() => {
            console.log('No se pudieron obtener los ramos');
        });
}

export function EditarRamoCustom(data: EditarRamo) {
    return axios
        .post('ramo/editarRamo', data)
        .then((res) => res)
        .catch(() => {
            console.log('No se ha podido editar el ramo');
        });
}

export function BorrarRamoCustom(idRamoCustom: number) {
    return axios
        .post('ramo/borrarRamo', { idRamoCustom: idRamoCustom })
        .then((res) => res)
        .catch((error) => {
            return error?.response?.data;
        });
}
