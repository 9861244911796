import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TerminosYCondiciones from './TerminosYCondiciones';
import './ModalTerminosYCondiciones.scss';
import { AiraLogo } from '../../assets/AiraLogo';
import { aceptarTerminosYCondiciones } from '../login/LoginAPI';
import { aceptoToS } from '../login/loginSlice';
import { useDispatch } from 'react-redux';

const ModalTerminosYCondiciones = () => {
    const [disableButton, setDisableButton] = useState(true);
    const [idAgente, setIdAgente] = useState<string | null>(null);
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const agenteId = localStorage.getItem('idAgente');
        setIdAgente(agenteId);
    }, []);

    const handleButtonClick = async () => {
        if (idAgente) {
            const result = await aceptarTerminosYCondiciones(idAgente);
            if (result.status === 200) {
                localStorage.setItem('terminosYCondiciones', '1');
                dispatch(aceptoToS(true));
                setOpen(false);
            }
        }
    };

    return (
        <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="modal-tos">
                <div className="modal-tos-logo">
                    <AiraLogo />
                </div>
                <div className="modal-tos-text">
                    <TerminosYCondiciones />
                </div>
                <div className="modal-tos-actions">
                    <div className="modal-tos-actions-check">
                        <input type="checkbox" onChange={() => setDisableButton(!disableButton)} />
                        <p>Acepto la política de privacidad</p>
                    </div>
                    <button disabled={disableButton} onClick={handleButtonClick} className="primary-button">
                        Aceptar consentimiento
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalTerminosYCondiciones;
