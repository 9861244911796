import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import { getDistribucionLeads } from './dashboardAPI';
import { MenuItem, Select } from '@mui/material';
import { ClipboardList } from 'tabler-icons-react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FiltrosDashboard } from './Dashboard';
import EmptyStateDashboard from './EmptyStateDashboard';

const markerAira0 = new Icon({
    iconUrl: 'https://aira-assets.s3.eu-west-1.amazonaws.com/markers_dashboard/markerAira0.svg',
    iconSize: [16, 16],
});

const markerCompletado = new Icon({
    iconUrl: 'https://aira-assets.s3.eu-west-1.amazonaws.com/markers_dashboard/markerCompletado.svg',
    iconSize: [16, 16],
});

const markerEnCurso = new Icon({
    iconUrl: 'https://aira-assets.s3.eu-west-1.amazonaws.com/markers_dashboard/markerEnCurso.svg',
    iconSize: [16, 16],
});

const markerGestionado = new Icon({
    iconUrl: 'https://aira-assets.s3.eu-west-1.amazonaws.com/markers_dashboard/markerGestionado.svg',
    iconSize: [16, 16],
});

const markerEnviado = new Icon({
    iconUrl: 'https://aira-assets.s3.eu-west-1.amazonaws.com/markers_dashboard/markerEnviado.svg',
    iconSize: [16, 16],
});

const getMarker = (estadoId: number) => {
    switch (estadoId) {
        case 1:
            return markerEnviado;
        case 2:
            return markerCompletado;
        case 3:
            return markerEnCurso;
        case 6:
            return markerAira0;
        case 8:
            return markerGestionado;
        default:
            markerEnviado;
    }
};

const getFilterLabel = (filterValue: number) => {
    switch (filterValue) {
        case 0:
            return 'Todos los estados';
        case 1:
            return 'Enviadas';
        case 2:
            return 'Completadas';
        case 3:
            return 'En curso';
        case 6:
            return 'Aira 0';
        case 8:
            return 'Gestionada';
        default:
            'Todos los estados';
    }
};

interface IMarkersDashboard {
    geocode: any;
    popUp: string;
    estadoId: number;
    gestionado: number;
    estado: string;
}

const DistribucionLeads = ({ archivadas, fechaInicial, fechaFinal }: FiltrosDashboard) => {
    const [markers, setMarkers] = useState<IMarkersDashboard[] | []>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<IMarkersDashboard[] | []>([]);
    const [estadoSeleccionado, setEstadoSeleccionado] = useState(0);
    const [noResults, setNoResults] = useState(false);
    const agenteId = localStorage.getItem('idAgente');

    useEffect(() => {
        const obtenerConversionLeads = async () => {
            const results = await getDistribucionLeads(Number(agenteId), archivadas, fechaInicial, fechaFinal);
            if (results && results.status === 200) {
                const toMarkers = results.data.map((data: any) => {
                    return {
                        geocode: [Number(data.latitud), Number(data.longitud)],
                        popUp: `ID${data.id}`,
                        estadoId: data.estado_inspeccion_id,
                        gestionado: data.gestionado,
                        estado: data.estado,
                    };
                });

                setMarkers(toMarkers);
                setNoResults(false);
            } else {
                setNoResults(true);
            }
        };

        obtenerConversionLeads();
    }, [archivadas, fechaInicial, fechaFinal]);

    useEffect(() => {
        setFilteredMarkers(markers);
    }, [markers]);

    useEffect(() => {
        if (estadoSeleccionado) {
            const newMarkers = markers.filter((m) => m.estadoId == estadoSeleccionado);
            setFilteredMarkers(newMarkers);
        } else {
            setFilteredMarkers(markers);
        }
    }, [estadoSeleccionado]);

    const renderMarkers = (inspeccionMarkers: IMarkersDashboard[]) => {
        return inspeccionMarkers.map((m: IMarkersDashboard) => {
            return (
                <Marker key={m.popUp} position={m.geocode} icon={getMarker(m.estadoId)}>
                    <Popup>{`${m.popUp} ${m.gestionado ? 'Gestionada' : m.estado}`}</Popup>
                </Marker>
            );
        });
    };

    return (
        <div className="distribucion-leads">
            <div className="distribucion-leads-main">
                <h2 className="distribucion-leads-main-title">Distribución de leads</h2>
                <Select
                    value={estadoSeleccionado}
                    className="distribucion-leads-main-input"
                    disabled={noResults}
                    sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '.MuiSelect-select': { color: '#0046CF', fontWeight: 800, fontSize: 14 },
                    }}
                    onChange={(e) => setEstadoSeleccionado(e.target.value as number)}
                >
                    <MenuItem value={0}>Todos los estados</MenuItem>
                    <MenuItem value={2}>Completadas</MenuItem>
                    <MenuItem value={3}>En curso</MenuItem>
                    <MenuItem value={8}>Gestionada</MenuItem>
                </Select>
            </div>
            {noResults ? (
                <EmptyStateDashboard size={200} />
            ) : (
                <>
                    <MapContainer center={[40.4165, -3.70256]} zoom={5}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markers.length ? (
                            <MarkerClusterGroup>{renderMarkers(filteredMarkers)}</MarkerClusterGroup>
                        ) : null}
                    </MapContainer>
                    <div className="distribucion-leads-tags">
                        <p>
                            <ClipboardList />
                            {filteredMarkers.length} resultados
                        </p>
                        <p>{getFilterLabel(estadoSeleccionado)}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default DistribucionLeads;
