import { Modal } from '@mui/material';
import React from 'react';
import './OpenPdfModal.scss';

const OpenPdfModal = (props: any) => {
    return (
        <Modal
            open={props.open}
            onBackdropClick={() => null}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className="abriendo-pdf">
                <p className="abriendo-pdf-msg1">Abriendo PDF</p>
                <p className="abriendo-pdf-msg2">Esta operación puede demorar unos segundos</p>
            </div>
        </Modal>
    );
};

export default OpenPdfModal;
