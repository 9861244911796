import axios from 'axios';

export function getConsumoLeads(agenteId: number, archivadas: number, fechaInicial: string, fechaFinal: string) {
    return axios
        .get('dashboard/consumoLeads', { params: { agenteId, archivadas, fechaInicial, fechaFinal } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de consumo de leads');
        });
}

export function getTiemposInspecciones(agenteId: number, archivadas: number, fechaInicial: string, fechaFinal: string) {
    return axios
        .get('dashboard/tiemposInspecciones', { params: { agenteId, archivadas, fechaInicial, fechaFinal } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de tiempos inspecciones');
        });
}

export function getTiempoPromedioXInspeccion(
    agenteId: number,
    archivadas: number,
    fechaInicial: string,
    fechaFinal: string,
) {
    return axios
        .get('dashboard/tiempoPromedio', { params: { agenteId, archivadas, fechaInicial, fechaFinal } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de tiempos inspecciones');
        });
}

export function getRamosYModelosDashboard(aseguradoraId: number, pagina: number) {
    return axios
        .get('dashboard/ramosYModelos', { params: { aseguradoraId: aseguradoraId, pagina: pagina } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de ramos y modelos');
        });
}

export function getConversionesLeads(agenteId: number, archivadas: number, fechaInicial: string, fechaFinal: string) {
    return axios
        .get('dashboard/conversionesLeads', { params: { agenteId, archivadas, fechaInicial, fechaFinal } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de tiempos inspecciones');
        });
}

export function getUsuariosHabilitados(agenteId: number, pagina: number) {
    return axios
        .get('dashboard/usuariosHabilitados', { params: { agenteId: agenteId, pagina: pagina } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de ramos y modelos');
        });
}

export function getDistribucionLeads(agenteId: number, archivadas: number, fechaInicial: string, fechaFinal: string) {
    return axios
        .get('dashboard/distribucionLeads', { params: { agenteId, archivadas, fechaInicial, fechaFinal } })
        .then((res) => res)
        .catch(() => {
            console.log('No se pudo obtener los datos de tiempos inspecciones');
        });
}
