import { ClickAwayListener } from '@mui/material';
import { useSelector } from 'react-redux';
import * as React from 'react';
import { Bell, Help, Logout } from 'tabler-icons-react';
import { User } from '../../assets/User';
import { selectLogo } from '../../features/login/loginSlice';
import Menu from '../menu/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './Header.scss';

export interface IHeaderProps {
    title: string;
    email?: string;
    isLoggedUser?: boolean;
    logout?: any;
    onTourClick?: () => void;
}

const style = {
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    height: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: -1,
};

const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps) => {
    const [openUserMenu, setOpenUserMenu] = React.useState(false);
    const logo = useSelector(selectLogo);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [showTour, setShowTour] = React.useState(false);
    const menuItems = [{ label: 'Logout', icon: <Logout />, fn: props.logout }];

    return (
        <div className="header">
            <img className="header-logo" src={logo} />
            <div className="header-right">
                <button className="boton-demo" onClick={handleOpen}>
                    Video demo
                </button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-description" sx={{ mt: 0 }}>
                            <iframe
                                width="600"
                                height="355"
                                src="https://www.youtube.com/embed/QTGv4L80xiQ"
                                title="YouTube video player"
                                frameBorder="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            />
                        </Typography>
                    </Box>
                </Modal>
                <button className="guia" onClick={props.onTourClick}>
                    <Help />
                </button>
                <Bell />
                {props.isLoggedUser ? (
                    <div className="header-right-user" onClick={() => setOpenUserMenu(!openUserMenu)}>
                        <User />
                        {openUserMenu ? (
                            <ClickAwayListener onClickAway={() => setOpenUserMenu(!openUserMenu)}>
                                <div className="header-right-user-menu">
                                    <Menu menuItems={menuItems} />
                                </div>
                            </ClickAwayListener>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Header;
