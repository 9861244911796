import React from 'react';
import { InfoCircle } from 'tabler-icons-react';
import './ErrorCard.scss';

const ErrorCard = (props: any) => {
    return (
        <div className="error-card" style={{ backgroundColor: props.type === 'warning' ? '#FDF6F0' : '#FEF3F4' }}>
            <div className="error-card-header">
                <div className="error-card-header-icon">
                    <InfoCircle style={{ color: props.type === 'warning' ? '#EB760A' : '#EA3A44' }} />
                </div>
                <h1
                    className="error-card-header-title"
                    style={{ color: props.type === 'warning' ? '#EB760A' : '#EA3A44' }}
                >
                    {props.title}
                </h1>
            </div>
            <p className="error-card-text">{props.text}</p>
            <div className="error-card-items">{props.children}</div>
            {props.viewMoreItemsLength ? (
                <div className="error-card-expand">{`Ver todos (${props.viewMoreItemsLength})`}</div>
            ) : null}
        </div>
    );
};

export default ErrorCard;
