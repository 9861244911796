import React, { useEffect, useState } from 'react';
import DatePicker from '../DatePicker/DatePicker';
import { ClickAwayListener, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import './SelectorVencimiento.scss';
import EditSquare from '../../assets/EditSquare';

interface IPropsSelectorVencimiento {
    onDateChange: (e: Date) => void;
    onAntelacionChange: (e: number) => void;
    defaultDate: Date | null;
    defaultAntelacion?: number;
}

const SelectorVencimiento = ({
    onDateChange,
    onAntelacionChange,
    defaultDate,
    defaultAntelacion,
}: IPropsSelectorVencimiento) => {
    const [showSelector, setShowSelector] = useState(false);
    const [antelacion, setAntelacion] = useState(15);

    useEffect(() => {
        if (defaultAntelacion && defaultAntelacion !== antelacion) {
            setAntelacion(defaultAntelacion);
        }
    }, [defaultAntelacion]);

    const handleAntelacionChange = (value: number) => {
        setAntelacion(value);
        onAntelacionChange(value);
    };

    const handleDateChange = (date: Date) => {
        const newDate = new Date(date);
        onDateChange(newDate);
    };

    console.log(antelacion);

    const renderSelector = () => {
        if (showSelector) {
            return (
                <ClickAwayListener onClickAway={() => setShowSelector(false)}>
                    <div className="selector-vencimiento">
                        <DatePicker defaultDate={defaultDate} onSelect={handleDateChange} />
                        <div className="selector-vencimiento-radios">
                            <p className="selector-prevencimiento-label">Avisar con antelación de:</p>
                            <RadioGroup className="selector-prevencimiento" value={antelacion}>
                                <FormControlLabel
                                    value={15}
                                    control={<Radio />}
                                    label="15 días"
                                    sx={{ color: `#171717` }}
                                    onChange={() => handleAntelacionChange(15)}
                                />
                                <FormControlLabel
                                    value={30}
                                    control={<Radio />}
                                    label="30 días"
                                    sx={{ color: `#171717` }}
                                    onChange={() => handleAntelacionChange(30)}
                                />
                                <FormControlLabel
                                    value={45}
                                    control={<Radio />}
                                    label="45 días"
                                    sx={{ color: `#171717` }}
                                    onChange={() => handleAntelacionChange(45)}
                                />
                                <FormControlLabel
                                    value={60}
                                    control={<Radio />}
                                    label="60 días"
                                    sx={{ color: `#171717` }}
                                    onChange={() => handleAntelacionChange(60)}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                </ClickAwayListener>
            );
        } else {
            return null;
        }
    };
    return (
        <div className="selector-vencimiento-main">
            <div className="edit-vencimiento" onClick={() => setShowSelector(!showSelector)}>
                <EditSquare />
            </div>

            {renderSelector()}
        </div>
    );
};

export default SelectorVencimiento;
