import React, { ReactChild } from 'react';
import './PaginatedCard.scss';
import { Pagination } from '@mui/material';

interface IPropsPaginatedCard {
    pageCount: number;
    page: number;
    children: ReactChild;
    onPageChange: (newPage: number) => void;
}

const PaginatedCard = ({ pageCount, page, children, onPageChange }: IPropsPaginatedCard) => {
    return (
        <div className="paginated-card">
            {children}
            <div className="paginated-card-pagination">
                <Pagination
                    shape="rounded"
                    color="primary"
                    count={pageCount}
                    page={page}
                    onChange={(event, value) => onPageChange(value)}
                />
            </div>
        </div>
    );
};

export default PaginatedCard;
