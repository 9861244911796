import React from 'react';
import './ModeloCard.scss';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';

const ModeloCard = ({
    ramoCustomId,
    active,
    nombre,
    icono,
    amountModelsAvailable,
    lastUpdated,
    onClick,
}: {
    ramoCustomId: number;
    active: boolean;
    nombre: string;
    icono: string;
    amountModelsAvailable: number;
    lastUpdated: string;
    onClick: (ramoCustomId: number) => void;
}) => {
    const modelIsActive = active ? 'Activo' : 'Inactivo';
    let color = '#B0ADBE';
    let legend = <span className="model-card-footer-info-last-modified">No hay modelos disponibles</span>;
    if (active) {
        color = '#0046CF';
        legend = (
            <>
                <div className="model-card-footer-info">
                    <span className="model-card-footer-info-amount-models">
                        {amountModelsAvailable} modelos disponibles
                    </span>
                    <span className="model-card-footer-info-last-modified">Última edición: {lastUpdated}</span>
                </div>
                <span className="model-card-footer-action">Ver modelos &gt;</span>
            </>
        );
    }

    const handleClick = () => {
        if (active) {
            onClick(ramoCustomId);
        }
    };

    return (
        <div className="model-card" onClick={handleClick}>
            <div className="model-card-state">
                <span className={`model-card-state-content ${!active ? 'model-card-inactive' : ''}`}>
                    {modelIsActive}
                </span>
            </div>
            <div className="model-card-content">
                <div className="model-card-content-logo">
                    {IconsRamos[icono]}
                    <span className="model-card-content-logo-title">{nombre}</span>
                </div>
            </div>
            <div className="separator"></div>
            <div className="model-card-footer">{legend}</div>
        </div>
    );
};

export default ModeloCard;
