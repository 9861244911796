import React from 'react';
import Financiero from './Financiero';
import Ganaderia from './Ganaderia';
import AccidenteCoche from './AccidenteCoche';
import AccidenteInmueble from './AccidenteInmueble';
import Agricultura from './Agricultura';
import Agro from './Agro';
import Bicicleta from './Bicicleta';
import Camion from './Camion';
import Bus from './Bus';
import Caravana from './Caravana';
import Cazador from './Cazador';
import Ciberseguridad from './Ciberseguridad';
import Comunidad from './Comunidad';
import Construccion from './Construccion';
import Dispositivos from './Dispositivos';
import Embarcaciones from './Embarcaciones';
import Factura from './Factura';
import HombreArmado from './HombreArmado';
import Instrumentos from './Instrumentos';
import Juridico from './Juridico';
import Maquinaria from './Maquinaria';
import Mascotas from './Mascotas';
import Moto from './Moto';
import Otros from './Otros';
import Patinete from './Patinete';
import Perro from './Perro';
import Quad from './Quad';
import Remolque from './Remolque';
import Salud from './Salud';
import Taxi from './Taxi';
import Vehiculo from './Vehiculo';
import Vejez from './Vejez';
import Viaje from './Viaje';
import Vida from './Vida';
import RamoIndustria from './RamoIndustria';
import RamoHogar from './RamoHogar';
import RamoComercio from './RamoComercio';

interface IconsRamos {
    [key: string]: JSX.Element;
}

export const IconsRamos: IconsRamos = {
    accidenteCoche: <AccidenteCoche />,
    accidenteInmueble: <AccidenteInmueble />,
    agricultura: <Agricultura />,
    agro: <Agro />,
    bicicleta: <Bicicleta />,
    bus: <Bus />,
    camion: <Camion />,
    caravana: <Caravana />,
    cazador: <Cazador />,
    ciberseguridad: <Ciberseguridad />,
    comunidad: <Comunidad />,
    construccion: <Construccion />,
    dispositivos: <Dispositivos />,
    embarcaciones: <Embarcaciones />,
    factura: <Factura />,
    financiero: <Financiero />,
    ganaderia: <Ganaderia />,
    hombreArmado: <HombreArmado />,
    instrumentos: <Instrumentos />,
    juridico: <Juridico />,
    maquinaria: <Maquinaria />,
    mascotas: <Mascotas />,
    moto: <Moto />,
    otros: <Otros />,
    patinete: <Patinete />,
    perro: <Perro />,
    quad: <Quad />,
    remolque: <Remolque />,
    salud: <Salud />,
    taxi: <Taxi />,
    vehiculo: <Vehiculo />,
    vejez: <Vejez />,
    viaje: <Viaje />,
    vida: <Vida />,
    ramoIndustria: <RamoIndustria />,
    ramoHogar: <RamoHogar />,
    ramoComercio: <RamoComercio />,
};
