import { Modal } from '@mui/material';
import React from 'react';
import airaLoader from '../../assets/airaLoader.gif';
import './Loader.scss';

interface IPropsLoader {
    open: boolean;
}

const Loader = ({ open }: IPropsLoader) => {
    return (
        <Modal
            open={open}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'calc(100% - 72px)',
                left: '72px',
                '& .MuiBackdrop-root': {
                    left: '72px',
                    width: 'calc(100% - 72px)',
                },
            }}
        >
            <div className="loader">
                <img src={airaLoader} alt="loader" className="loader-gif" />
            </div>
        </Modal>
    );
};

export default Loader;
