import React from 'react';

interface Props {
    background?: string;
}

export const IconClienteFisico = (props: Props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99998 7.33333C9.47274 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47274 2 7.99998 2C6.52722 2 5.33331 3.19391 5.33331 4.66667C5.33331 6.13943 6.52722 7.33333 7.99998 7.33333Z"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14"
                stroke={props.background != undefined ? props.background : '#0046CF'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
