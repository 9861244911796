export enum tipoPreguntaLibre {
    SIN_TIPO_PREGUNTA = 0,
    TIPO_PREGUNTA_SELECCION_UNICA = 1,
    TIPO_PREGUNTA_MULTISELECCION = 2,
    TIPO_PREGUNTA_CARGA_ARCHIVOS = 3,
    TIPO_PREGUNTA_ESCRITA = 4,
    TIPO_PREGUNTA_FOTOS_LIBRES = 5,
    TIPO_PREGUNTA_SELECCION_DESPLEGABLE = 6,
    TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE = 7,
}
