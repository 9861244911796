import React, { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

interface IPropsRichTextInput {
    onChange: (html: string) => void;
    defaultValue: string;
}

const RichTextInput = ({ onChange, defaultValue }: IPropsRichTextInput) => {
    const { quill, quillRef } = useQuill();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);

    useEffect(() => {
        if (quill) {
            if (defaultValue && !defaultValuesLoaded) {
                quill.clipboard.dangerouslyPasteHTML(defaultValue);
                setDefaultValuesLoaded(true);
            }
            quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
                onChange(quill.root.innerHTML);
            });
        }
    }, [quill]);

    return (
        <div style={{ width: '100%', height: 400, marginBottom: 40 }}>
            <div ref={quillRef}></div>
        </div>
    );
};

export default RichTextInput;
