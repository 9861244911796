import {
    Autocomplete,
    Box,
    FormControlLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    AlertCircle,
    Building,
    BuildingCommunity,
    BuildingFactory,
    BuildingStore,
    Car,
    ChevronDown,
    CirclePlus,
    DeviceMobile,
    Help,
    Home,
    InfoCircle,
    Link,
    Template,
    User,
} from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IconClienteFisico } from '../../assets/IconClienteFisico';
import { IconClienteJuridico } from '../../assets/IconClienteJuridico';
import CountrySelect from '../../components/country-selected/CountrySelected';
import { renderTextWithIcon } from '../../components/data-grid/DataGrid';
import InspectionCard from '../../components/inspection-card/InspectionCard';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import ModalConfirmation from '../../components/modal-confirmation/ModalConfirmation';
import { Ramos } from '../../enums/ramos';
import { TipoCliente } from '../../enums/TipoCliente';
import { filterClientes, nombreClienteFisico, nombreClienteJuridico } from '../../utlis';
import {
    clearNuevoClienteId,
    detalleCliente,
    DetalleClienteSync,
    nuevoClienteId,
    RequestDetalleCliente,
} from '../clientes/ClientesSlice';
import NuevoCliente from '../clientes/NuevoCliente';
import {
    AltaInspeccionSync,
    clientesMap,
    modelosMap,
    ModelosSync,
    ramosMap,
    RamosSync,
    requestInspecion,
    setCliente,
    setModelo,
    setRamo,
    setMovilEnvioDispatch,
    statusAltaInspeccion,
    setAgenteDispatch,
} from './inspeccionesSlice';
import './NuevaInspeccion.scss';
import { ModeloInterface, RamosConModeloSync, selectRamosCustom } from '../modelos/ModeloSlice';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import Financiero from '../../assets/iconosRamos/Financiero';

interface FilterType {
    text: string;
    icon: React.ReactElement;
}

export const renderModelos = (modelos: any) => {
    if (modelos) {
        return modelos.map((m: any) => {
            return { id: m.id, label: m.nombre };
        });
    } else {
        return [];
    }
};

const CheckBox = ({ selected }: any) => {
    return (
        <div className="toggle-buttons-checkbox">
            <label>
                <input type="checkbox" />
                <svg
                    className={`toggle-buttons-checkbox-icon ${selected ? 'toggle-buttons-checkbox-icon-active' : ''}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.99994 13L8.99994 18L19.9999 7.00001"
                        stroke={selected ? '#ffffff' : 'none'}
                        strokeWidth="4"
                        strokeLinecap="round"
                    />
                </svg>
            </label>
        </div>
    );
};

const NuevaInspeccion = (props: any) => {
    const dispatch = useAppDispatch();
    const clienteEnDetalleId = parseInt(useLocation().pathname.replace(/^.*\/.*\//, ''));
    const path = useLocation().pathname;
    const requestAltaInspecion = useAppSelector(requestInspecion);
    const clienteId = useAppSelector(nuevoClienteId);
    const clientes = useAppSelector(clientesMap);
    const ramos = useAppSelector(ramosMap);
    const ramosCustom = useAppSelector(selectRamosCustom);
    const [modelos, setModelos] = useState<any[]>([]);
    const altaInspeccionStatus = useAppSelector(statusAltaInspeccion);
    const initialState = {
        tipologia: 0,
        modelo: { id: 0, label: '' },
        cliente: { id: 0, label: '', tipo: 0 },
        movil: '',
        nuevoMovil: '',
        telefonoEmpresa: '',
    };
    const [comboNameCliente, setComboNameCliente] = useState();
    const [comboNameClienteModelo, setComboNameClienteModelo] = useState(1);
    const [esProyecto, setEsProyecto] = useState(false);
    const [tieneMovil, setTieneMovil] = useState(true);
    const [movil, setMovil] = useState('');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(initialState);
    const [modalContent, setModalContent] = useState('inspeccion');
    const [caracteristicas, setCaracteristicas] = useState([
        { id: 1, label: '+34', tipo: 2 },
        { id: 2, label: '+54', tipo: 3 },
    ]);
    const [characteristicSelected, setCharacteristicSelected] = useState({ id: 1, label: '+34', tipo: 2 });
    const cliente = useAppSelector(detalleCliente);

    useEffect(() => {
        //dispatch(ModelosSync());
        dispatch(RamosSync());
        const idAseguradora = localStorage.getItem('idAseguradora');
        idAseguradora && dispatch(RamosConModeloSync(Number(idAseguradora)));
        //dispatch(setRamo(VerificarRamo('Comercio/Oficina')));
        setData({ ...data, tipologia: 0 });
        setStateForPathInspeccionesOrClientes();
        //dispatch(setModelo(VerificarModelo(1)));
    }, []);

    useEffect(() => {
        handleChangeCallBackNuevoCliente();
    }, [clienteId, cliente, clientes]);

    useEffect(() => {
        const idClientePrueba = localStorage.getItem('idClientePrueba');
        if (esProyecto && idClientePrueba) {
            dispatch(setCliente(Number(idClientePrueba)));
            setMovil('otro');
            setData({
                ...data,
                cliente: {
                    id: Number(idClientePrueba),
                    label: 'Proyecto',
                    tipo: 2,
                },
                movil: '',
            });
        } else {
            setMovil('');
            setData({ ...data, cliente: initialState.cliente, movil: initialState.movil });
        }
    }, [esProyecto]);

    const handleOpen = () => {
        setOpen(true);
        setStateForPathInspeccionesOrClientes();
    };

    const handleClose = () => {
        setOpen(false);
        setStateForPathInspeccionesOrClientes();
        setComboNameClienteModelo(1);
        setTieneMovil(true);
        setData({ ...data, modelo: { id: 0, label: '' } });
        if (path === '/inspecciones') setData({ ...data, movil: '' });
        setModalContent('inspeccion');
        setEsProyecto(false);
    };

    const setStateForPathInspeccionesOrClientes = () => {
        if (path === '/inspecciones' || path === 'clientes') {
            dispatch(clearNuevoClienteId());
            setComboNameCliente(undefined);
            setData(initialState);
            setMovil('');
        }
    };

    const handleRamoChange = (ramo: ModeloInterface) => {
        //const modelo = modelos?.find((modelo) => modelo.ramoId === VerificarRamo(ramo));
        setData({ ...data, tipologia: ramo.id, modelo: initialState.modelo });
        dispatch(setRamo(ramo.id));
        setModelos(ramo.modelosCustom);
    };

    const renderButtons = () => {
        return ramosCustom.map((ramo: any) => {
            const fillException = ['accidenteCoche', 'comunidad', 'ramoComercio', 'ramoIndustria', 'ramoHogar'];
            const hasException = fillException.includes(ramo.icono);
            return (
                <ToggleButton
                    className={`toggle-button-inspeccion ${hasException ? 'toggle-button-inspeccion-exception' : ''}`}
                    disableRipple
                    disabled={false}
                    key={ramo.id}
                    value={ramo.id}
                    onClick={() => handleRamoChange(ramo)}
                >
                    <div>{IconsRamos[ramo.icono]}</div>
                    <p>{ramo.nombre}</p>
                </ToggleButton>
            );
        });
    };

    const handleChangeModelo = (e: any, v: any) => {
        const modelo = modelos?.find((modelo) => modelo.id === v.id);
        setData({ ...data, modelo: v });
        setComboNameClienteModelo(modelo?.id);
        console.log(modelo);
        dispatch(setModelo(modelo?.id));
    };

    const handleChangeCliente = (e: any, v: any) => {
        const cliente = clientes?.find((cliente) => cliente.id === v.id);

        if (cliente?.movil || cliente?.telefonoEmpresa) {
            setMovil('cliente');
            setTieneMovil(true);
        } else {
            setTieneMovil(false);
            setMovil('otro');
        }

        if (cliente?.tipoCliente === TipoCliente.Juridico) {
            setData({
                ...data,
                cliente: v,
                movil: cliente.telefonoEmpresa,
            });
            dispatch(setMovilEnvioDispatch(returnMovilTypeClient(cliente)));
        }
        if (cliente?.tipoCliente === TipoCliente.Fisico) {
            setData({
                ...data,
                cliente: v,
                movil: cliente.movil,
            });
            dispatch(setMovilEnvioDispatch(returnMovilTypeClient(cliente)));
        }

        setComboNameCliente(cliente?.id);
        dispatch(setCliente(cliente?.id));
    };

    const handleChangeCharacteristic = (e: any, v: any) => {
        setCharacteristicSelected(v);
    };

    const handleChangeCallBackNuevoCliente = () => {
        const cliente = clientes?.find((cliente) => cliente.id === clienteId);
        if (cliente === undefined) return;

        setMovil('cliente');

        if (cliente?.tipoCliente === TipoCliente.Juridico) {
            setData({
                ...data,
                cliente: { id: cliente.id, label: cliente.nombreComercio, tipo: cliente.tipoCliente },
                movil: cliente.telefonoEmpresa,
            });
            dispatch(setMovilEnvioDispatch(returnMovilTypeClient(cliente)));
        }
        if (cliente?.tipoCliente === TipoCliente.Fisico) {
            setData({
                ...data,
                cliente: { id: cliente.id, label: cliente.nombre, tipo: cliente.tipoCliente },
                movil: cliente.movil,
            });
            dispatch(setMovilEnvioDispatch(returnMovilTypeClient(cliente)));
        }

        setComboNameCliente(cliente?.id);
        dispatch(setCliente(cliente?.id));
    };

    const handleChangeMovilButtons = (e: React.ChangeEvent<HTMLInputElement>) => {
        const clienteSelected = clientes?.find((cliente) => cliente.id === data.cliente.id);
        const radioButtonName = e.target.value;
        setMovil((e.target as HTMLInputElement).value);
        if (radioButtonName === 'otro') {
            setData({ ...data, movil: '' });
        }
        if (radioButtonName === 'cliente') {
            setData({
                ...data,
                movil: returnMovilTypeClient(clienteSelected),
            });
            dispatch(setMovilEnvioDispatch(returnMovilTypeClient(clienteSelected)));
        }
        if (radioButtonName === 'enlace') {
            setData({
                ...data,
                movil: 'enlace',
            });
            dispatch(setMovilEnvioDispatch('enlace'));
        }
    };

    const returnMovilTypeClient = (clienteSelected: any) => {
        if (clienteSelected?.tipoCliente === TipoCliente.Fisico) {
            return clienteSelected?.movil;
        } else {
            return clienteSelected?.telefonoEmpresa;
        }
    };

    const onChangeMovilNuevo = async (movil: string) => {
        setData({ ...data, movil: movil });
        await dispatch(setMovilEnvioDispatch(characteristicSelected.label + movil));
    };

    const renderPhoneInput = () => {
        if (data.cliente.id && movil !== 'enlace') {
            return (
                <div className="phone-input">
                    {movil === 'otro' ? (
                        <Autocomplete
                            disabled={false}
                            id="caracteristicas-combo-box"
                            popupIcon={<ChevronDown />}
                            options={caracteristicas}
                            disableClearable
                            onChange={(e, v) => handleChangeCharacteristic(e, v)}
                            value={characteristicSelected}
                            isOptionEqualToValue={(o, v) => {
                                return o.id === v.id;
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return (
                                    <div style={{ position: 'relative', width: '80px', height: '100%' }}>
                                        <TextField
                                            className="prueba"
                                            {...params}
                                            InputLabelProps={{ shrink: false }}
                                            label=" "
                                            variant="outlined"
                                        />
                                    </div>
                                );
                            }}
                        />
                    ) : null}
                    <input
                        disabled={movil === 'cliente' || data.cliente.label === '' ? true : false}
                        type="tel"
                        value={data.movil}
                        onChange={(e) => onChangeMovilNuevo(e.target.value)}
                        placeholder="Ingresa otro móvil"
                        className={`phone-input-input${movil === 'otro' ? '-otro' : ''}`}
                    />
                </div>
            );
        }
    };

    const renderNombreCliente = (i: any) => {
        if (i.tipoCliente === 1) {
            return i.nombre;
        }
        if (i.tipoCliente === 2) {
            return i.nombreComercio;
        }
    };

    const renderClientes = () => {
        if (clientes) {
            return clientes.map((c) => {
                return {
                    id: c.id,
                    label: c.tipoCliente === 1 ? nombreClienteFisico(c) : nombreClienteJuridico(c),
                    tipo: c.tipoCliente,
                };
            });
        } else {
            return [];
        }
    };

    const rendersModelos = () => {
        return modelos.map((model: any) => {
            return (
                <MenuItem key={model.id} value={model.id}>
                    {model.nombre}
                </MenuItem>
            );
        });
    };

    const altaInspeccion = async () => {
        await dispatch(setAgenteDispatch(localStorage.getItem('userId')));
        const id: RequestDetalleCliente = { id: clienteEnDetalleId };
        console.log(requestAltaInspecion);
        await dispatch(AltaInspeccionSync(requestAltaInspecion));
        if (path !== '/inspecciones') await dispatch(DetalleClienteSync(id));
        handleClose();
        props.onCreated && props.onCreated();
    };

    const disableCrearInspeccion = () => {
        if (esProyecto && data.tipologia) {
            return false;
        }

        const disable =
            data.cliente.label !== '' &&
            data.tipologia &&
            data.modelo.label !== '' &&
            (data.movil !== '' || movil === 'enlace');
        return !disable;
    };

    const renderButtonNuevaInspeccion = () => {
        if (path.includes('/clientes/fisicos') || path.includes('/clientes/juridicos')) {
            return null;
        } else {
            return (
                <button onClick={() => setModalContent('cliente')} className="text-with-icon">
                    O añade un nuevo cliente <CirclePlus />
                </button>
            );
        }
    };

    const renderClienteIcon = () => {
        if (data.cliente.id) {
            const clienteSelected = clientes?.find((cliente) => cliente.id === data.cliente.id);
            return clienteSelected?.tipoCliente === 1 ? <User id="cliente-icon" /> : <Building id="cliente-icon" />;
        }
    };

    const renderModalContent = () => {
        return (
            <div className="nueva-inspeccion-modal">
                <h5>Crear evaluación</h5>
                <div className="nueva-inspeccion-select">
                    <div className="title-and-button">
                        <div className="nueva-inspeccion-modal-tooltip">
                            <h6>1. Cliente</h6>
                            <Tooltip title="Puedes buscar persona físicas o jurídicas" placement="right" arrow>
                                <div>
                                    <Help />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <Autocomplete
                        id="clientes-combo-box"
                        popupIcon={<ChevronDown />}
                        options={renderClientes()}
                        disableClearable
                        disabled={esProyecto}
                        onChange={(e, v) => handleChangeCliente(e, v)}
                        value={data.cliente}
                        filterOptions={(a, b) => filterClientes(a, b)}
                        isOptionEqualToValue={(o, v) => {
                            return o.id === v.id;
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                                {option.tipo === 1 ? (
                                    <User id="autocomplete-option-icon" />
                                ) : (
                                    <Building id="autocomplete-option-icon" />
                                )}{' '}
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => {
                            return (
                                <div style={{ position: 'relative' }}>
                                    <span
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '16px',
                                            marginTop: '5px',
                                        }}
                                    >
                                        {renderClienteIcon()}
                                    </span>
                                    <TextField
                                        className="prueba"
                                        {...params}
                                        InputLabelProps={{ shrink: false }}
                                        label=" "
                                        variant="outlined"
                                    />
                                </div>
                            );
                        }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        {renderButtonNuevaInspeccion()}
                        <button onClick={() => setEsProyecto(!esProyecto)} className="text-with-icon">
                            <p style={{ margin: 0 }}>Es un proyecto</p>
                            <CheckBox key="esproyecto" selected={esProyecto} />
                        </button>
                    </div>
                </div>
                <div className="nueva-inspeccion-mt">
                    <div className="nueva-inspeccion-modal-tooltip">
                        <h6>2. Modo de envío</h6>
                    </div>
                    <div>
                        <RadioGroup
                            className="nueva-inspeccion-radio"
                            value={movil || null}
                            onChange={handleChangeMovilButtons}
                        >
                            <FormControlLabel
                                disabled={
                                    (path === '/inspecciones' && data.cliente.label === '') || !tieneMovil || esProyecto
                                        ? true
                                        : false
                                }
                                value="cliente"
                                control={<Radio />}
                                label="Emplear móvil del cliente"
                                sx={{ color: `${movil === 'otro' ? '#635F76' : '#171717'}` }}
                            />
                            <FormControlLabel
                                disabled={path === '/inspecciones' && data.cliente.label === '' ? true : false}
                                value="otro"
                                control={<Radio />}
                                label="Ingresar otro móvil"
                                sx={{ color: `${movil === 'cliente' ? '#635F76' : '#171717'}` }}
                            />
                            <FormControlLabel
                                disabled={path === '/inspecciones' && data.cliente.label === '' ? true : false}
                                value="enlace"
                                control={<Radio />}
                                label="Crear enlace"
                                sx={{ color: `${movil === 'cliente' ? '#635F76' : '#171717'}` }}
                            />
                            {!tieneMovil ? (
                                <div className="nueva-inspeccion-no-movil">
                                    <div className="nueva-inspeccion-no-movil-icon">
                                        <InfoCircle />
                                    </div>
                                    <p>
                                        Este cliente no tiene móvil asociado. Por favor, ingresálo y lo guardaremos en
                                        el perfil de este cliente.
                                    </p>
                                </div>
                            ) : null}
                        </RadioGroup>
                        {renderPhoneInput()}
                    </div>
                </div>
                <div className="nueva-inspeccion-mt">
                    <h6>3. Ramo</h6>
                    <ToggleButtonGroup value={data.tipologia} exclusive className="toggle-buttons-container">
                        {renderButtons()}
                    </ToggleButtonGroup>
                </div>
                <div className="nueva-inspeccion-mt">
                    <h6>4. Modelo</h6>
                    <Autocomplete
                        id="modelo-combo-box"
                        popupIcon={<ChevronDown />}
                        options={renderModelos(modelos)}
                        onChange={(e, v) => handleChangeModelo(e, v)}
                        value={data.modelo}
                        disableClearable
                        isOptionEqualToValue={(o, v) => {
                            return o.id === v.id;
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputLabelProps={{ shrink: false }}
                                    label=" "
                                    variant="outlined"
                                    placeholder="Selecciona un cliente"
                                />
                            );
                        }}
                    />
                </div>
                <div className="nueva-inspeccion-buttons">
                    <button
                        className="secondary-button"
                        onClick={data.cliente.id ? () => setModalContent('cancel') : () => setOpen(false)}
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={() => setModalContent('confirm')}
                        disabled={disableCrearInspeccion()}
                        className="primary-button"
                    >
                        Crear y enviar evaluación
                    </button>
                </div>
            </div>
        );
    };

    const toClienteName = () => {
        if (esProyecto) {
            return 'Proyecto';
        }
        const client = clientes?.find((c) => c.id === data.cliente.id);
        if (client?.tipoCliente === TipoCliente.Fisico) {
            return client?.nombre;
        }
        if (client?.tipoCliente === TipoCliente.Juridico) {
            return client?.nombreComercio;
        }
    };

    const toClienteTypeIcon = () => {
        if (esProyecto) {
            return <Building />;
        }
        const client = clientes?.find((c) => c.id === data.cliente.id);
        if (client?.tipoCliente === TipoCliente.Fisico) {
            return <User />;
        }
        if (client?.tipoCliente === TipoCliente.Juridico) {
            return <Building />;
        }
    };

    const inspectionCardData = () => {
        const ramoSeleccionado = ramosCustom.find((r) => r.id === data.tipologia);
        return [
            {
                label: 'Cliente',
                icon: toClienteTypeIcon(),
                value: toClienteName(),
            },
            {
                label: 'Ramo',
                value: renderTextWithIcon({
                    icon: ramoSeleccionado ? IconsRamos[ramoSeleccionado.icono] : <Financiero />,
                    text: ramoSeleccionado ? ramoSeleccionado.nombre : '',
                    textColor: '#0046CF',
                    fontWeight: '800',
                    fontSize: '14px',
                    iconColor: '#0046CF',
                    disableScale: true,
                    verticalAlignment: 'center',
                    iconMarginBottom: '4px',
                }),
            },
            {
                label: 'Envío',
                icon: data.movil === 'enlace' ? <Link /> : <DeviceMobile />,
                value: data.movil,
            },
            {
                label: 'Modelo',
                icon: <Template />,
                value: data.modelo?.label,
            },
        ];
    };

    const renderConfirm = () => {
        return (
            <div>
                <ModalConfirmation
                    button="Crear y enviar evaluación"
                    title="¿Todo correcto para enviar la evaluación?"
                    message={
                        data.movil === 'enlace'
                            ? 'Al confirmar, generaremos un link que podrás compartir con el cliente. Al recibirlo, podrá comenzar con la evaluación.'
                            : 'Al confirmar, enviaremos un SMS (con una URL y un código) al móvil indicado. Al recibirlo, el cliente podrá comenzar con la evaluación.'
                    }
                    cancel={{
                        fn: () => setModalContent('inspeccion'),
                        width: '167px',
                        buttonText: 'No, corregir datos',
                    }}
                    success={{
                        fn: () => altaInspeccion(),
                        width: '182px',
                        buttonText:
                            altaInspeccionStatus === 'loading' ? (
                                <JsonAnimation animation="buttonLoaderWhite" height="50px" width="182px" />
                            ) : (
                                'Si, enviar evaluación'
                            ),
                    }}
                    disable={altaInspeccionStatus === 'loading'}
                    component={
                        <InspectionCard
                            items={inspectionCardData()}
                            title="Esta acción es irreversible, por favor verifica los datos ingresados:"
                        />
                    }
                />
            </div>
        );
    };

    const renderCancel = () => {
        return (
            <div>
                <ModalConfirmation
                    button="Cancel"
                    title="¿Deseas cancelar la creación y envío de esta evaluación?"
                    message="Al confirmar, se cancelará todo el proceso y perderás los datos ya ingresados."
                    cancel={{
                        fn: () => setModalContent('inspeccion'),
                        buttonText: 'No, deseo continuar con el proceso',
                    }}
                    success={{ fn: () => handleClose(), buttonText: 'Si, deseo cancelar' }}
                />
            </div>
        );
    };

    const modalContents: any = {
        confirm: renderConfirm,
        cancel: renderCancel,
        inspeccion: renderModalContent,
        cliente: () => (
            <div>
                <NuevoCliente
                    onCancel={() => setModalContent('inspeccion')}
                    onSubmit={() => {
                        setModalContent('inspeccion');
                        if (data.cliente.label == '') {
                            handleChangeCallBackNuevoCliente();
                        }
                    }}
                />
            </div>
        ),
    };

    return (
        <div>
            <button onClick={handleOpen} className="nueva-inspeccion">
                Nueva Evaluación
                {props.icon ? <CirclePlus /> : null}
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {modalContents[modalContent]()}
            </Modal>
        </div>
    );
};

export default NuevaInspeccion;
