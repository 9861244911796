import { rgbToHex } from '@mui/system';
import { PieChart } from '@mui/x-charts';
import React from 'react';

interface PieChartData {
    label?: string;
    value: number;
    color?: string;
    id?: number;
}

interface IPropsCustomPieCart {
    data: PieChartData[];
    colores?: string[];
    onPieClick?: (id: number, label: string) => void;
}

const CustomPieCart = ({ data, colores, onPieClick }: IPropsCustomPieCart) => {
    const handlePieItemClick = (e: EventTarget) => {
        const svgPathElement = e as Element;

        try {
            const color = window.getComputedStyle(svgPathElement).fill;
            const item = data.find((i) => i.color && i.color.toLowerCase() === rgbToHex(color));
            if (item && item.id && item.label && onPieClick) {
                onPieClick(item.id, item.label);
            }
        } catch (err) {
            console.error(err);
        }

        return true;
    };

    return (
        <PieChart
            colors={colores}
            series={[
                {
                    data: data,
                    innerRadius: 78,
                    outerRadius: 102,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    startAngle: -180,
                    endAngle: 180,
                },
            ]}
            height={210}
            width={300}
            slotProps={{
                legend: { hidden: true },
                pieArc: { onClick: (e) => handlePieItemClick(e.target) },
            }}
        />
    );
};

export default CustomPieCart;
