import { CircularProgress, circularProgressClasses } from '@mui/material';
import React from 'react';
import './CustomCircularProgress.scss';

interface IPropsCustomCircularProgress {
    value: number;
}

const CustomCircularProgress = ({ value }: IPropsCustomCircularProgress) => {
    return (
        <div className="custom-circular-progress">
            <CircularProgress
                variant="determinate"
                sx={{
                    color: '#EBEAF0',
                    position: 'absolute',
                }}
                size={62}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                style={{ color: '#0046CF' }}
                sx={{
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={62}
                value={value}
            />
            <p className="custom-circular-progress-label">{value}%</p>
        </div>
    );
};

export default CustomCircularProgress;
