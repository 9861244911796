import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertCircle, Building, ChevronDown, CirclePlus, Help, InfoCircle, User } from 'tabler-icons-react';
import { IconClienteFisico } from '../../assets/IconClienteFisico';
import { IconClienteJuridico } from '../../assets/IconClienteJuridico';
import { TipoCliente } from '../../enums/TipoCliente';
import { validateCifFormat, validateDniFormat, validateEmail, validatePhone } from '../../utlis';
import { existCliente, existClienteJuridico } from './ClientesAPI';
import { AltaClienteSync, RequestCliente, nuevoClienteId, requestClienteStatus } from './ClientesSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import './NuevoCliente.scss';

interface RadioLabel {
    icon: JSX.Element;
    text: string;
    helpIcon?: JSX.Element;
    helpText?: string;
}

const NuevoCliente = (props: any) => {
    const initialState = {
        nombre: '',
        apellido: '',
        movil: '',
        email: '',
        dni: '',
        nombreFiscal: '',
        cif: '',
        nombreComercio: '',
        telefonoEmpresa: '',
        agente_cliente_id: 1,
        direccion: {
            calle: '',
            numero: '',
            bloque: '',
            escalera: '',
            piso: '',
            puerta: '',
            cp: '',
            poblacion: '',
            provincia: '',
            poligono: '',
            parcela: '',
        },
        tipo_cliente_id: TipoCliente.Fisico,
        activo: true,
    };
    const [step, setStep] = useState(1);
    const altaClienteStatus = useSelector(requestClienteStatus);
    const [cliente, setCliente] = useState<RequestCliente>(initialState);
    const [warning, setWarning] = useState({ dni: false, cif: false });
    const [invalidDocument, setInvalidDocument] = useState({ dni: false, cif: false });
    const [invalidDataFisico, setInvalidDataFisico] = useState({ movil: false, email: false });
    const [invalidDataJuridico, setInvalidDataJuridico] = useState({ telefonoEmpresa: false, email: false });
    const [caracteristicas, setCaracteristicas] = useState([
        { id: 1, label: '+34', tipo: 2 },
        { id: 2, label: '+54', tipo: 3 },
    ]);
    const [characteristicSelected, setCharacteristicSelected] = useState({ id: 1, label: '+34', tipo: 2 });
    const dispatch = useDispatch();
    const idAgente = localStorage.getItem('idAgente');
    const idNuevoCliente = useSelector(nuevoClienteId);
    const renderRadioLabelFisico = (label: RadioLabel) => {
        return (
            <span className="modal-label">
                {cliente.tipo_cliente_id !== TipoCliente.Fisico ? (
                    <span className="modal-label-text" style={{ color: '#635F76' }}>
                        {label.icon} {label.text}{' '}
                    </span>
                ) : (
                    <span className="modal-label-text">
                        {label.icon} {label.text}{' '}
                    </span>
                )}

                {label.helpIcon && label.helpText ? (
                    <Tooltip title={label.helpText} placement="right" arrow>
                        <div>{label.helpIcon}</div>
                    </Tooltip>
                ) : null}
            </span>
        );
    };

    const renderRadioLabelJuridico = (label: RadioLabel) => {
        return (
            <span className="modal-label">
                {cliente.tipo_cliente_id !== TipoCliente.Juridico ? (
                    <span className="modal-label-text" style={{ color: '#635F76' }}>
                        {label.icon} {label.text}{' '}
                    </span>
                ) : (
                    <span className="modal-label-text">
                        {label.icon} {label.text}{' '}
                    </span>
                )}

                {label.helpIcon && label.helpText ? (
                    <Tooltip title={label.helpText} placement="right" arrow>
                        <div>{label.helpIcon}</div>
                    </Tooltip>
                ) : null}
            </span>
        );
    };

    const handleChangeTipoCliente = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCliente({ ...cliente, tipo_cliente_id: parseInt((e.target as HTMLInputElement).value), dni: '', cif: '' });
        setWarning({ dni: false, cif: false });
        setInvalidDocument({ dni: false, cif: false });
    };

    const handleStepChange = async () => {
        const existeCliente = cliente.dni && (await existCliente(cliente.dni, idAgente));
        const existeClienteJuridico = cliente.cif && (await existClienteJuridico(cliente.cif, idAgente));
        if (invalidDocument.cif || invalidDocument.dni) return;
        if (cliente.tipo_cliente_id === TipoCliente.Fisico && existeCliente) {
            setWarning({ dni: true, cif: false });
        } else if (cliente.tipo_cliente_id === TipoCliente.Juridico && existeClienteJuridico) {
            setWarning({ dni: false, cif: true });
        } else {
            setStep(2);
        }
    };

    const handleBackButton = () => {
        if (step === 3) {
            setStep(2);
        }
        if (step === 2) {
            setInvalidDataFisico({ movil: false, email: false });
            setInvalidDataJuridico({ telefonoEmpresa: false, email: false });
            setStep(1);
            setCliente({
                ...cliente,
                nombre: '',
                apellido: '',
                movil: '',
                email: '',
                direccion: {
                    calle: '',
                    numero: '',
                    bloque: '',
                    escalera: '',
                    piso: '',
                    puerta: '',
                    cp: '',
                    poblacion: '',
                    provincia: '',
                    poligono: '',
                    parcela: '',
                },
                nombreFiscal: '',
                nombreComercio: '',
                telefonoEmpresa: '',
                agente_cliente_id: 1,
                activo: true,
            });
        }
    };

    const handleSubmitCliente = () => {
        if (
            invalidDataFisico.email ||
            invalidDataFisico.movil ||
            invalidDataJuridico.telefonoEmpresa ||
            invalidDataFisico.email
        )
            return;

        if (step === 2) {
            setStep(3);
        }

        if (step === 3) {
            {
                const nuevoCliente = cliente.telefonoEmpresa
                    ? { ...cliente, telefonoEmpresa: `${characteristicSelected.label}${cliente.telefonoEmpresa}` }
                    : { ...cliente, movil: `${characteristicSelected.label}${cliente.movil}` };
                dispatch(AltaClienteSync(nuevoCliente));
                props.onCreate && props.onCreate();
            }
        }
    };

    useEffect(() => {
        if (altaClienteStatus === 'idle' && idNuevoCliente) {
            setStep(1);
            setCliente(initialState);
        }
        if (props.onSubmit && altaClienteStatus === 'idle' && idNuevoCliente) {
            props.onSubmit();
        }
    }, [altaClienteStatus]);

    const handleChangeCharacteristic = (e: any, v: any) => {
        console.log('handleChangeCharacteristic', v);
        setCharacteristicSelected(v);
    };

    const onCancel = () => {
        if (props.onCancel) props.onCancel();
        setStep(1);
        setCliente(initialState);
    };

    const renderWarningMessage = () => {
        if (invalidDocument.dni || invalidDocument.cif) {
            const formatoDocumentoInvalido = 'El formato del documento ingresado es invalido.';
            return (
                <span className="nuevo-cliente-modal-input-warning-message">
                    <AlertCircle /> {formatoDocumentoInvalido}
                </span>
            );
        }
        if (warning.dni || warning.cif) {
            const documentoExistente = 'Ya tienes un cliente que emplea este documento. Por favor, ingresa de nuevo.';
            return (
                <span className="nuevo-cliente-modal-input-warning-message">
                    <AlertCircle /> {documentoExistente}
                </span>
            );
        }
    };

    const validateDni = (dni: string) => {
        if (dni && !validateDniFormat(dni)) {
            setInvalidDocument((prevState) => {
                return { ...prevState, dni: true };
            });
        } else {
            setInvalidDocument((prevState) => {
                return { ...prevState, dni: false };
            });
        }
    };

    const validateCif = (cif: string) => {
        if (cif && !validateCifFormat(cif)) {
            setInvalidDocument((prevState) => {
                return { ...prevState, cif: true };
            });
        } else {
            setInvalidDocument((prevState) => {
                return { ...prevState, cif: false };
            });
        }
    };

    const renderDocumentInput = () => {
        if (cliente.tipo_cliente_id === TipoCliente.Fisico) {
            return (
                <input
                    className={`${warning.dni ? 'nuevo-cliente-modal-input-error' : 'modal-input'}`}
                    onChange={(e) => setCliente({ ...cliente, dni: e.target.value })}
                    value={cliente.dni || ''}
                    placeholder="Ingresa el DNI, NIE o NIF"
                    onBlur={() => validateDni(cliente.dni)}
                />
            );
        } else {
            return (
                <input
                    className={`${warning.cif ? 'nuevo-cliente-modal-input-error' : 'modal-input'}`}
                    onChange={(e) => setCliente({ ...cliente, cif: e.target.value })}
                    value={cliente.cif || ''}
                    placeholder="Ingresa el CIF"
                    onBlur={() => validateCif(cliente.cif)}
                />
            );
        }
    };

    const renderStepOne = () => {
        const labelJuridico = 'Código de Identificación Fiscal (CIF)';
        const labelFisico = 'Documento de identificación (DNI / NIE / NIF )';
        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Crear cliente</h3>
                    <span>1 / 3</span>
                </div>
                <RadioGroup
                    className="nuevo-cliente-modal-radio"
                    value={cliente.tipo_cliente_id}
                    onChange={handleChangeTipoCliente}
                >
                    <FormControlLabel
                        value={TipoCliente.Fisico}
                        control={<Radio />}
                        label={renderRadioLabelFisico({
                            icon: (
                                <IconClienteFisico
                                    background={cliente.tipo_cliente_id !== TipoCliente.Fisico ? '#635F76' : '#171717'}
                                />
                            ),
                            text: 'Persona física',
                            helpIcon: <Help />,
                            helpText: 'Se identifica con el DNI o NIF',
                        })}
                    />
                    <FormControlLabel
                        value={TipoCliente.Juridico}
                        control={<Radio />}
                        label={renderRadioLabelJuridico({
                            icon: (
                                <IconClienteJuridico
                                    background={
                                        cliente.tipo_cliente_id !== TipoCliente.Juridico ? '#635F76' : '#171717'
                                    }
                                />
                            ),
                            text: 'Persona jurídica',
                            helpIcon: <Help />,
                            helpText: 'Se identifica con el CIF',
                        })}
                    />
                </RadioGroup>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">
                        {cliente.tipo_cliente_id === TipoCliente.Fisico ? labelFisico : labelJuridico}
                    </span>
                    {renderDocumentInput()}
                    {renderWarningMessage()}
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={onCancel}>
                        Cancelar
                    </button>
                    <button
                        className="primary-button"
                        disabled={!cliente.dni && !cliente.cif}
                        onClick={handleStepChange}
                    >
                        Siguiente
                    </button>
                </div>
            </>
        );
    };

    const validateFieldsClienteJuridico = (data: any) => {
        const isPhoneValid = data.telefonoEmpresa && !validatePhone(data.telefonoEmpresa);
        const isEmailValid = data.email && !validateEmail(data.email);

        setInvalidDataJuridico({ telefonoEmpresa: isPhoneValid, email: isEmailValid });
    };

    const renderFormJuridico = () => {
        const disableButtonJuridico = () => {
            return !cliente.nombreFiscal || !cliente.nombreComercio || !cliente.telefonoEmpresa;
        };

        const toolTipNombreComercio = 'Si no tiene nombre comercial,' + '\n' + 'puedes repetir el nombre fiscal';

        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Crear cliente</h3>
                    <span>2 / 3</span>
                </div>
                <div className="nuevo-cliente-modal-data">
                    <div>
                        <Building /> CIF: <span>{cliente.cif}</span>
                    </div>
                    {/*                     <div>
                        <Building /> Socios: <span>Socio 1, Socio 2, Socio 3</span>
                    </div> */}
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Nombre Fiscal (SL)</span>
                    <input
                        className="modal-input"
                        value={cliente.nombreFiscal}
                        onChange={(e) => setCliente({ ...cliente, nombreFiscal: e.target.value })}
                        type="text"
                        placeholder="Ingresa el nombre Fiscal de la empresa"
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">
                        Nombre Comercial{' '}
                        <Tooltip title={toolTipNombreComercio} placement="right" arrow>
                            <div>
                                <InfoCircle />
                            </div>
                        </Tooltip>
                    </span>
                    <input
                        className="modal-input"
                        value={cliente.nombreComercio}
                        onChange={(e) => setCliente({ ...cliente, nombreComercio: e.target.value })}
                        type="text"
                        placeholder="Ingresa el nombre comercial"
                    ></input>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Teléfono empresa (preferentemente móvil)</span>
                    <div className="nuevo-cliente-modal-input-caracteristica">
                        <Autocomplete
                            disabled={false}
                            id="caracteristicas-combo-box"
                            popupIcon={<ChevronDown />}
                            options={caracteristicas}
                            disableClearable
                            onChange={(e, v) => handleChangeCharacteristic(e, v)}
                            value={characteristicSelected}
                            isOptionEqualToValue={(o, v) => {
                                return o.id === v.id;
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return (
                                    <div style={{ position: 'relative', width: '80px', height: '100%' }}>
                                        <TextField
                                            className="prueba"
                                            {...params}
                                            InputLabelProps={{ shrink: false }}
                                            label=" "
                                            variant="outlined"
                                        />
                                    </div>
                                );
                            }}
                        />
                        <input
                            className="modal-input modal-input-phone"
                            value={cliente.telefonoEmpresa}
                            onChange={(e) => setCliente({ ...cliente, telefonoEmpresa: e.target.value })}
                            type="tel"
                            placeholder="Ingresa el teléfono de la empresa"
                            onBlur={() => validateFieldsClienteJuridico(cliente)}
                        />
                        {invalidDataJuridico.telefonoEmpresa ? (
                            <span className="nuevo-cliente-modal-input-warning-message">
                                <AlertCircle /> Formato de teléfono inválido
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Email</span>
                    <input
                        className="modal-input"
                        value={cliente.email || ''}
                        onChange={(e) => setCliente({ ...cliente, email: e.target.value })}
                        type="email"
                        placeholder="Ingresa el email"
                        onBlur={() => validateFieldsClienteJuridico(cliente)}
                    />
                    {invalidDataJuridico.email ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de email inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={handleBackButton}>
                        Anterior
                    </button>
                    <button className="primary-button" disabled={disableButtonJuridico()} onClick={handleSubmitCliente}>
                        Siguiente
                    </button>
                </div>
            </>
        );
    };

    const validateFieldsClienteFisico = (data: any) => {
        const isPhoneValid = data.movil && !validatePhone(data.movil);
        const isEmailValid = data.email && !validateEmail(data.email);

        setInvalidDataFisico({ movil: isPhoneValid, email: isEmailValid });
    };

    const renderFormFisico = () => {
        const disableButtonFisico = () => {
            return !cliente.nombre || !cliente.movil || !cliente.email;
        };

        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Crear cliente</h3>
                    <span>2 / 3</span>
                </div>
                <div className="nuevo-cliente-modal-data">
                    <div>
                        <User /> DNI / NIE / NIF: <span>{cliente.dni}</span>
                    </div>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Nombre y apellido</span>
                    <div style={{ display: 'flex', gap: '8px', flexDirection: 'row', width: '100%' }}>
                        <input
                            className="modal-input"
                            value={cliente.nombre || ''}
                            onChange={(e) => setCliente({ ...cliente, nombre: e.target.value })}
                            type="text"
                            placeholder="Ingresa el nombre"
                            style={{ width: '100%' }}
                        ></input>
                        <input
                            className="modal-input"
                            value={cliente.apellido || ''}
                            onChange={(e) => setCliente({ ...cliente, apellido: e.target.value })}
                            type="text"
                            placeholder="Ingresa el apellido"
                            style={{ width: '100%' }}
                        ></input>
                    </div>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Móvil</span>
                    <div className="nuevo-cliente-modal-input-caracteristica">
                        <Autocomplete
                            disabled={false}
                            id="caracteristicas-combo-box"
                            popupIcon={<ChevronDown />}
                            options={caracteristicas}
                            disableClearable
                            onChange={(e, v) => handleChangeCharacteristic(e, v)}
                            value={characteristicSelected}
                            isOptionEqualToValue={(o, v) => {
                                return o.id === v.id;
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                    {option.label}
                                </Box>
                            )}
                            renderInput={(params) => {
                                return (
                                    <div style={{ position: 'relative', width: '80px', height: '100%' }}>
                                        <TextField
                                            className="prueba"
                                            {...params}
                                            InputLabelProps={{ shrink: false }}
                                            label=" "
                                            variant="outlined"
                                        />
                                    </div>
                                );
                            }}
                        />
                        <input
                            className="modal-input modal-input-phone"
                            value={cliente.movil || ''}
                            onChange={(e) => setCliente({ ...cliente, movil: e.target.value })}
                            type="tel"
                            placeholder="Ingresa el móvil"
                            onBlur={() => validateFieldsClienteFisico(cliente)}
                        />
                        {invalidDataFisico.movil ? (
                            <span className="nuevo-cliente-modal-input-warning-message">
                                <AlertCircle /> Formato de teléfono inválido
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className="nuevo-cliente-modal-input">
                    <span className="nuevo-cliente-modal-input-label">Email</span>
                    <input
                        className="modal-input"
                        value={cliente.email || ''}
                        onChange={(e) => setCliente({ ...cliente, email: e.target.value })}
                        type="email"
                        placeholder="Ingresa el email"
                        onBlur={() => validateFieldsClienteFisico(cliente)}
                    />
                    {invalidDataFisico.email ? (
                        <span className="nuevo-cliente-modal-input-warning-message">
                            <AlertCircle /> Formato de email inválido
                        </span>
                    ) : null}
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={handleBackButton}>
                        Anterior
                    </button>
                    <button className="primary-button" disabled={disableButtonFisico()} onClick={handleSubmitCliente}>
                        Siguiente
                    </button>
                </div>
            </>
        );
    };

    const renderFormDireccion = () => {
        return (
            <>
                <div className="nuevo-cliente-modal-title">
                    <h3>Crear cliente</h3>
                    <span>3 / 3</span>
                </div>
                <div className="nuevo-cliente-modal-data">
                    <div>
                        <User /> DNI / NIE / NIF: <span>{cliente.dni}</span>
                    </div>
                </div>
                <div className="grid-col2">
                    <div className="nuevo-cliente-modal-input span-2">
                        <span className="nuevo-cliente-modal-input-label">Calle</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.calle || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, calle: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa la calle"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Número</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.numero || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, numero: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa el número"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Bloque</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.bloque || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, bloque: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa el bloque"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Escalera</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.escalera || ''}
                            onChange={(e) =>
                                setCliente({
                                    ...cliente,
                                    direccion: { ...cliente.direccion, escalera: e.target.value },
                                })
                            }
                            type="text"
                            placeholder="Ingresa la escalera"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Piso</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.piso || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, piso: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa el piso"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Puerta</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.puerta || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, puerta: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa la puerta"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">CP *</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.cp || ''}
                            onChange={(e) =>
                                setCliente({ ...cliente, direccion: { ...cliente.direccion, cp: e.target.value } })
                            }
                            type="text"
                            placeholder="Ingresa el CP"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Población</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.poblacion || ''}
                            onChange={(e) =>
                                setCliente({
                                    ...cliente,
                                    direccion: { ...cliente.direccion, poblacion: e.target.value },
                                })
                            }
                            type="text"
                            placeholder="Ingresa la población"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Provincia</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.provincia || ''}
                            onChange={(e) =>
                                setCliente({
                                    ...cliente,
                                    direccion: { ...cliente.direccion, provincia: e.target.value },
                                })
                            }
                            type="text"
                            placeholder="Ingresa la provincia"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Polígono</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.poligono || ''}
                            onChange={(e) =>
                                setCliente({
                                    ...cliente,
                                    direccion: { ...cliente.direccion, poligono: e.target.value },
                                })
                            }
                            type="text"
                            placeholder="Ingresa el polígono"
                        ></input>
                    </div>
                    <div className="nuevo-cliente-modal-input">
                        <span className="nuevo-cliente-modal-input-label">Parcela</span>
                        <input
                            className="modal-input"
                            value={cliente.direccion.parcela || ''}
                            onChange={(e) =>
                                setCliente({
                                    ...cliente,
                                    direccion: { ...cliente.direccion, parcela: e.target.value },
                                })
                            }
                            type="text"
                            placeholder="Ingresa la parcela"
                        ></input>
                    </div>
                </div>
                <div className="nuevo-cliente-modal-actions">
                    <button className="secondary-button" onClick={handleBackButton}>
                        Anterior
                    </button>
                    <button className="primary-button" disabled={!cliente.direccion.cp} onClick={handleSubmitCliente}>
                        Crear cliente
                    </button>
                </div>
            </>
        );
    };

    const renderContent = () => {
        if (step === 1) {
            return renderStepOne();
        } else if (step === 2) {
            if (cliente.tipo_cliente_id === TipoCliente.Fisico) {
                return renderFormFisico();
            } else if (cliente.tipo_cliente_id === TipoCliente.Juridico) {
                return renderFormJuridico();
            }
        } else if (step === 3) {
            return renderFormDireccion();
        }
    };

    return <div className="nuevo-cliente-modal">{renderContent()}</div>;
};

export default NuevoCliente;
