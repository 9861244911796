import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { AdjustmentsAlt, CircleCheck, ClipboardList, FileText, InfoCircle, LetterF, Send } from 'tabler-icons-react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    DataGrid,
    renderRamosCliente,
    renderText,
    renderTextWithIcon,
    renderTextWithIcons,
} from '../../components/data-grid/DataGrid';
import DropdownMenu from '../../components/dropdown-menu/DropdownMenu';
import QuickSearch from '../../components/quick-search/QuickSearch';
import { RamosNumber } from '../../enums/ramos';
import { ClientesSync, EditarClienteSync, listaClientes, selectCsvRowsWithError } from './ClientesSlice';
import {
    Alert,
    Autocomplete,
    Checkbox,
    Modal,
    Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { IconComercio } from '../../assets/IconComercio';
import { IconHogar } from '../../assets/IconHogar';
import { IconComunidad } from '../../assets/iconComunidad';
import { IconIndustria } from '../../assets/IconIndustria';
import { IconVehiculo } from '../../assets/IconVehiculo';
import { IconClienteJuridico } from '../../assets/IconClienteJuridico';
import { IconClienteFisico } from '../../assets/IconClienteFisico';
import { TipoCliente } from '../../enums/TipoCliente';
import { listaRamos, ModelosSync, RamosSync } from '../inspecciones/inspeccionesSlice';
import { useLocation } from 'react-router-dom';
import NuevoClienteModal from './NuevoClienteModal';
import { FileUploader } from 'react-drag-drop-files';
import ModalCargaMasiva from './ModalCargaMasiva';
import conf from '../../utlis/config';
import { normalizeText } from '../../utlis';
import BulletItems from '../../components/BulletItems/BulletItems';
import { useSelector } from 'react-redux';
import EditarCliente from './detalles/EditarCliente';
import { nombreClienteFisico } from '../../utlis';
import { nombreClienteJuridico } from '../../utlis';
import { RamosConModeloSync, modelListSelector } from '../modelos/ModeloSlice';
import { renderRamoIcon } from '../inspecciones/InspeccionesFeature';
import './ClientesFeature.scss';

interface FilterType {
    text: string;
    icon: React.ReactElement;
}

const FilterType = (props: any) => {
    const [active, setActive] = React.useState('Todos');

    const types: FilterType[] = [
        { text: 'Todos', icon: <></> },
        { text: 'Jurídico', icon: <IconClienteJuridico /> },
        { text: 'Físico', icon: <IconClienteFisico /> },
    ];

    const renderButtons = () => {
        return types.map((t) => (
            <ToggleButton className="toggle-button-filters" disableRipple key={t.text} value={t.text}>
                {t.icon}
                <span>{t.text}</span>
            </ToggleButton>
        ));
    };

    const handleChange = (e: React.MouseEvent<HTMLElement>, n: string) => {
        setActive(n);
        props.onSelect(n);
    };

    return (
        <ToggleButtonGroup color="primary" value={active} exclusive onChange={handleChange}>
            {renderButtons()}
        </ToggleButtonGroup>
    );
};

const defaultFilters = {
    quickSearch: {
        filterFn: (c: any, v: string) =>
            v === '' ||
            normalizeText(c.nombreCliente.text.toLowerCase()).search(normalizeText(v.toLowerCase())) != -1 ||
            normalizeText(c.identificacion && c.identificacion.toLowerCase()).search(normalizeText(v.toLowerCase())) !=
                -1,
        value: '',
    },
    ramo: {
        filterFn: (i: Record<string, any>, v: any) => {
            return (v && v.length === 0) || i.ramo.item.find((i: any) => v.some((r: any) => r.id === i.key));
        },
        value: [],
    },
    tipo: {
        filterFn: (i: Record<string, any>, v: any) => {
            return v === '' || v === 'Todos' || i.tipo.text === v;
        },
        value: '',
    },
};

const renderNombreCliente = (cliente: any) => {
    switch (cliente.tipo_cliente.id) {
        case TipoCliente.Fisico:
            return cliente.nombreCompleto;
        case TipoCliente.Juridico:
            return cliente.nombreComercio;
    }
};

const renderIconTipoCliente = (tipoCliente: number) => {
    switch (tipoCliente) {
        case TipoCliente.Fisico:
            return <IconClienteFisico background={'#B0ADBE'} />;
        case TipoCliente.Juridico:
            return <IconClienteJuridico background={'#B0ADBE'} />;
    }
};

const renderIconRamoNoDuplicado = (ramos: Array<any>) => {
    const unique = Array.from(new Set(ramos.map((a) => a.key))).map((id) => {
        return ramos.find((a) => a.key === id);
    });

    return unique;
};

const getDocument = (cliente: any) => {
    if (cliente.tipo_cliente_id === 1 && cliente.dni) {
        return cliente.dni;
    } else if (cliente.tipo_cliente_id === 2 && cliente.cif) {
        return cliente.cif;
    } else {
        return '';
    }
};

const clientesMap = (clientes: any) => {
    return clientes.map((i: any) => {
        return {
            id: i.id,
            nombreCliente: {
                text: i.tipo_cliente_id === 1 ? nombreClienteFisico(i) : nombreClienteJuridico(i),
                icon: i.tipo_cliente ? renderIconTipoCliente(i.tipo_cliente.id) : '',
                background: '',
                textColor: '',
                fontSize: '14px',
                padding: '4px 8px',
                width: 'auto',
                fontWeight: '800',
            },
            tipo: {
                text: i.tipo_cliente ? i.tipo_cliente.nombre : '',
                icon: '',
                background: '#EBF1FE',
                textColor: '#0046CF',
                fontSize: '12px',
                padding: '4px 8px',
                width: 'auto',
                fontWeight: '800',
            },
            identificacion: getDocument(i),
            //TODO : cambiar por direccion correspondiente que viene de back.
            direccionFiscal: i.direccion,
            inspecciones: {
                text: i.inspecciones.length,
                icon: <ClipboardList />,
                background: '',
                iconColor: '#B0ADBE',
                textColor: '#171717',
                fontSize: '12px',
                padding: '4px 8px',
                width: '89px',
            },
            ramo: {
                item: renderIconRamoNoDuplicado(
                    i.inspecciones.map((e: any) => {
                        if (e.ramo_custom) {
                            return {
                                ramo: e.ramo_custom.nombre,
                                icono: e.ramo_custom.icono,
                                key: e.ramo_custom.id,
                            };
                        } else {
                            return '';
                        }
                    }),
                ),
                background: '#F7F7F9',
                textColor: '#635F76',
                fontSize: '12px',
                padding: '4px 8px',
                width: 'auto',
                fontWeight: '800',
                gap: '0px',
            },
            activo: true,
        };
    });
};

export const ClientesFeacture = () => {
    const dispatch = useAppDispatch();
    const route = useLocation().pathname;
    const clientes = useAppSelector(listaClientes);
    const clientesMap2 = clientesMap(clientes);
    const ramos = useAppSelector(modelListSelector);
    const [filters, setFilters] = React.useState(defaultFilters);
    const [filteredRows, setFilteredRows] = React.useState(clientesMap2);
    const [toast, setToast] = React.useState(false);
    const rowsWithErrors = useSelector(selectCsvRowsWithError);
    const CARGA_MASIVA = conf('CARGA_MASIVA');
    const suscripcion = localStorage.getItem('suscripcion');

    interface RamosCliente {
        ramo: string;
        icono: string;
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            hide: true,
        },
        {
            field: 'nombreCliente',
            headerName: 'Nombre cliente',
            renderCell: (params: any) => renderTextWithIcon(params.value),
            flex: 0.6,
            sortComparator: (d1: any, d2: any) => d1.text.toLowerCase().localeCompare(d2.text.toLowerCase()),
        },
        {
            field: 'tipo',
            headerName: 'Tipo',
            renderCell: (params: any) => renderTextWithIcon(params.value),
            flex: 0.4,
            sortable: false,
        },
        {
            field: 'identificacion',
            headerName: 'DNI / CIF / NIF',
            renderCell: (params: any) => params.value,
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'direccionFiscal',
            headerName: 'Dirección Fiscal',
            cellClassName: 'cell-direccion',
            flex: 0.6,
            sortable: false,
        },
        {
            field: 'inspecciones',
            headerName: 'Inspecciones',
            renderCell: (params: any) => renderTextWithIcon(params.value),
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'ramo',
            headerName: 'Ramo',
            renderCell: (params: any) => renderRamosCliente(params.value),
            flex: 1,
            sortable: false,
        },
    ];

    const renderRamos = () => {
        if (ramos) {
            return ramos.map((c) => {
                return { id: c.id, label: c.nombre, icono: c.icono };
            });
        } else {
            return [];
        }
    };

    const updateFilters = (filters: any, key: any, value: any) => {
        console.log(value);
        return { ...filters, [key]: { ...filters[key], value } };
    };

    function debounce<T extends unknown[]>(func: (...args: T) => void, delay: number): (...args: T) => void {
        let timer: any | null = null;
        return (...args: T) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func.call(null, ...args);
            }, delay);
        };
    }

    const onQuickSearchChanged = debounce((searchTerm: string) => {
        setFilters(updateFilters(filters, 'quickSearch', searchTerm));
    }, 300);

    const CustomToolBar = () => {
        const estados = [
            { label: 'Enviada', icon: <Send />, color: '#E4B305' },
            { label: 'Completada', icon: <FileText />, color: '#53BD8A' },
        ];
        return (
            <>
                <QuickSearch onChange={onQuickSearchChanged} placeholder="Buscar por el nombre, DNI, CIF o NIF" />

                <FilterType onSelect={(e: any) => setFilters(updateFilters(filters, 'tipo', e))} />
                <DropdownMenu text="Filtros" icon={<AdjustmentsAlt />}>
                    <div>
                        <p className="filter-label">Ramo</p>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id="estados-combo-box"
                            options={renderRamos()}
                            onChange={(e, v) => {
                                console.log(v);
                                setFilters(updateFilters(filters, 'ramo', v));
                            }}
                            limitTags={1}
                            isOptionEqualToValue={(o, v) => {
                                return o.id == v.id;
                            }}
                            sx={{ width: 250 }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id} style={{ color: '', fontSize: '12px', fontWeight: 800 }}>
                                    <Checkbox checked={selected} />
                                    <div
                                        style={{
                                            background: '#F7F7F9',
                                            color: '#171717',
                                            fontSize: '12px',
                                            padding: '4px 8px',
                                            width: 'auto',
                                            borderRadius: 8,
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <div className="icono-ramo-clientes">{renderRamoIcon(option.icono)}</div>
                                        <div style={{ width: 6 }}></div>
                                        <div>{option.label}</div>
                                    </div>
                                </li>
                            )}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        InputLabelProps={{ shrink: false }}
                                        label=" "
                                        variant="outlined"
                                    />
                                );
                            }}
                        />
                    </div>
                </DropdownMenu>
                {/* TODO: Agregar feacture nuevo cliente */}
                {CARGA_MASIVA === 'true' && suscripcion?.toLowerCase() === 'premium' ? <ModalCargaMasiva /> : null}
                <NuevoClienteModal onCreate={() => setToast(true)} />
            </>
        );
    };

    useEffect(() => {
        dispatch(ClientesSync());
        const idAseguradora = localStorage.getItem('idAseguradora');
        idAseguradora && dispatch(RamosConModeloSync(Number(idAseguradora)));
    }, []);

    useEffect(() => {
        setFilteredRows(clientesMap2);
    }, [clientes]);

    useEffect(() => {
        const newRows = clientesMap2.filter((i: any) => {
            return Object.values(filters).reduce((acc, e: any) => {
                console.log(i, e.value);
                return acc && e.filterFn(i, e.value);
            }, true);
        });
        setFilteredRows(newRows);
    }, [filters]);

    return (
        <div className="inspecciones">
            <h3>Clientes</h3>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                toolbar={CustomToolBar}
                route={route}
                sortingModel={[
                    {
                        field: 'id',
                        sort: 'desc',
                    },
                ]}
                rowsWithError={rowsWithErrors}
            />
            <Snackbar
                open={toast}
                autoHideDuration={6000}
                onClose={() => setToast(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToast(false)}
                    severity="success"
                    icon={<CircleCheck />}
                    sx={{
                        fontFamily: 'Nunito sans',
                        backgroundColor: '#011F59',
                        color: '#ffffff',
                        fontSize: '14px',
                        fontWeigh: '600',
                        borderRadius: '8px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Has creado un nuevo cliente.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ClientesFeacture;
