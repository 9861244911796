import React from 'react';
import PreguntaEscritaActive from './PreguntaEscritaActive';
import PreguntaFotosActive from './PreguntaFotosActive';
import SeleccionMultipleActive from './SeleccionMultipleActive';
import SeleccionUnicaActive from './SeleccionUnicaActive';
import CargaArchivoActive from './CargaArchivoActive';
import PreguntaEscrita from './PreguntaEscrita';
import PreguntaFotos from './PreguntaFotos';
import SeleccionMultiple from './SeleccionMultiple';
import SeleccionUnica from './SeleccionUnica';
import CargaArchivo from './CargaArchivo';
import SeleccionDesplegable from './SeleccionDesplegable';
import SeleccionDesplegableActive from './SeleccionDesplegableActive';

interface IconsPreguntasLibres {
    [key: string]: JSX.Element;
}

export const IconsPreguntasLibres: IconsPreguntasLibres = {
    preguntaEscrita: <PreguntaEscrita />,
    preguntaEscritaActive: <PreguntaEscritaActive />,
    preguntaFotos: <PreguntaFotos />,
    preguntaFotosActive: <PreguntaFotosActive />,
    seleccionMultiple: <SeleccionMultiple />,
    seleccionMultipleActive: <SeleccionMultipleActive />,
    seleccionUnica: <SeleccionUnica />,
    seleccionUnicaActive: <SeleccionUnicaActive />,
    cargaArchivo: <CargaArchivo />,
    cargaArchivoActive: <CargaArchivoActive />,
    seleccionDesplegable: <SeleccionDesplegable />,
    seleccionDesplegableActive: <SeleccionDesplegableActive />,
};
