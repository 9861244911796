import axios from 'axios';
import { direccion } from './ClientesSlice';
import { conf } from '../../utlis/config';

const externos = axios.create({
    baseURL: conf('BASE_URL') + 'externos/',
});

export function GetClientes() {
    return axios
        .get('clientes')
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los clientes');
        });
}

export const existCliente = (dni: string, idAgente: any) => {
    return axios
        .post('clientes/verificar-dni-cliente-fisico', { dni, idAgente })
        .then((res) => res.data.code === 100)
        .catch(() => {
            console.log(`No se encontraron clientes con DNI: ${dni}`);
            return false;
        });
};

export const existClienteJuridico = (cif: string, idAgente: any) => {
    return axios
        .post('clientes/verificar-cif-cliente-juridico', { cif, idAgente })
        .then((res) => res.data.code === 100)
        .catch(() => {
            console.log(`No se encontraron clientes con CIF: ${cif}`);
            return false;
        });
};

export function PostCliente(
    nombre: string,
    apellido: string,
    movil: string,
    email: string,
    direccion: direccion,
    dni: string,
    nombreFiscal: string,
    cif: string,
    nombreComercio: string,
    telefonoEmpresa: string,
    agente_cliente_id: number,
    tipo_cliente_id: number,
    activo: boolean,
) {
    return axios
        .post('clientes', {
            nombre,
            apellido,
            movil,
            email,
            direccion,
            dni,
            nombreFiscal,
            cif,
            nombreComercio,
            telefonoEmpresa,
            agente_cliente_id,
            tipo_cliente_id,
            activo,
        })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

interface ClienteMasivo {
    nombre: string;
    movil: string;
    email: string;
    direccion: string;
    dni: string;
    nombreFiscal: string;
    cif: string;
    nombreComercio: string;
    telefonoEmpresa: string;
    agente_cliente_id: number;
    tipo_cliente_id: number;
    activo: boolean;
    idAseguradora: boolean;
    rowNumber: number;
}

export function PostClienteMasivo(cliente: ClienteMasivo) {
    return axios
        .post('clientes/carga-masiva', cliente)
        .then((res) => res)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export function GetDetalleCliente(id: number) {
    return axios
        .post('clientes/detalle', { id })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function PutCliente(
    id: number,
    nombre: string,
    movil: string,
    email: string,
    direccion: direccion,
    dni: string,
    nombreFiscal: string,
    cif: string,
    nombreComercio: string,
    telefonoEmpresa: string,
    agente_cliente_id: number,
    tipo_cliente_id: number,
    activo: boolean,
) {
    return axios
        .put('clientes', {
            id,
            activo,
            nombre,
            movil,
            email,
            direccion,
            dni,
            nombreFiscal,
            cif,
            nombreComercio,
            telefonoEmpresa,
            agente_cliente_id,
            tipo_cliente_id,
        })
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch(() => {
            console.log('No se pudo actualizar el cliente');
        });
}

export const obtenerLogoAseguradora = (id: any) => {
    return axios
        .post('aseguradoras/urlLogoSegunAseguradoraId', { id })
        .then((res) => res)
        .catch((error) => console.log(error));
};

export function obtenerDireccionCliente(id: number) {
    return axios
        .post('clientes/direccion', { id: id })
        .then((res) => res.data)
        .catch(() => {
            console.log('No se pudieron obtener los modelos');
        });
}

export const obtenerCalles = (text: string) => {
    return externos.get(`obtenerDatosDireccion?direccion=${text}`).then((res) => res);
};
