import { Autocomplete, Modal, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './ModalPantallaBienvenida.scss';
import { ChevronDown, Edit, Paperclip, Pencil, Trash } from 'tabler-icons-react';
import * as R from 'ramda';
import { subirPoliticasPrivacidad } from './ModelosAPI';
import RichTextInput from '../../components/RichTextInput/RichTextInput';
import { useDispatch } from 'react-redux';
import { cambiarDatosBienvenida, cambiarDatosPoliticasPrivacidad } from './ModeloSlice';

interface input {
    placeholder: string;
    required: boolean;
    key: string;
    type: string;
    options?: any[];
}

interface option {
    title: string;
    inputs: input[];
}

interface IPropsEditarSubmodulo {
    buttonText?: string;
    onSubmit: (e: any) => Promise<boolean>;
    onClose: (e: any) => void;
    defaultValues?: any;
    forceOpen?: boolean;
    hideIcon?: boolean;
    idModeloCustom: number;
}

const EditarBienvenidaYPoliticas = ({
    buttonText = 'Continuar',
    onSubmit,
    defaultValues,
    forceOpen,
    onClose,
    hideIcon,
    idModeloCustom,
}: IPropsEditarSubmodulo) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState<any>();
    const [step, setStep] = useState(1);
    const [htmlPoliticas, setHtmlPoliticas] = useState<any>();
    const dispatch = useDispatch();
    const inputRef: any = useRef();

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [forceOpen]);

    const inputsEditarOnboarding = [
        {
            title: '1. Título',
            inputs: [
                {
                    placeholder: 'Escribe el título que desees incluir',
                    key: 'tituloBienvenida',
                    required: false,
                    type: 'text',
                },
            ],
        },
        {
            title: '2. Subtítulo',
            inputs: [
                {
                    placeholder: 'Escribe el subtítulo que desees incluir',
                    key: 'subtituloBienvenida',
                    required: false,
                    type: 'text',
                },
            ],
        },
    ];

    const inputsPoliticasPrivacidad = [
        {
            title: '1. Título política de privacidad',
            inputs: [
                {
                    placeholder: 'Escribe el título que desees incluir',
                    key: 'tituloPoliticas',
                    required: false,
                    type: 'text',
                },
            ],
        },
        {
            title: '2. Políticas',
            inputs: [
                {
                    placeholder: 'Escribe el subtítulo que desees incluir',
                    key: 'politicasPrivacidad',
                    required: false,
                    type: 'upload',
                },
            ],
        },
    ];

    const setDefaultvalues = () => {
        if (defaultValues) {
            const opciones = defaultValues.opciones
                ? defaultValues.opciones.length
                    ? defaultValues.opciones.reduce((acc: any, e: string, index: number) => {
                          return {
                              ...acc,
                              [`opcion${index + 1}`]: e,
                          };
                      }, {})
                    : {}
                : {};
            setValues({
                tituloBienvenida: defaultValues.tituloBienvenida,
                subtituloBienvenida: defaultValues.subtituloBienvenida,
                tituloModulo: defaultValues.tituloModulo,
                tituloPoliticas: defaultValues.tituloPoliticas,
                politicasPrivacidad: defaultValues.politicasPrivacidad,
            });
            setHtmlPoliticas(defaultValues.politicasPrivacidad);
        }
    };

    useEffect(() => {
        setDefaultvalues();
    }, [defaultValues, open]);

    const canConfirm = () => {
        const requiredInputs = R.flatten(inputsEditarOnboarding.map((o) => o.inputs))
            .filter((i) => i.required)
            .map((k) => k.key);
        if (values) {
            const valores = Object.entries(values).reduce((acc: any, [key, value]) => {
                return [...acc, { [key]: value }];
            }, []);
            const valoresObligatorios = valores.filter((d: any) => {
                return requiredInputs.find((e) => {
                    return d[e];
                });
            });

            return valoresObligatorios.length === requiredInputs.length;
        } else {
            return true;
        }
    };

    const handleOpen = (e: any) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleClose = () => {
        onClose(values && defaultValues);
        setValues(null);
        setOpen(false);
        setStep(1);
        setHtmlPoliticas(undefined);
    };

    const handleChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>, input: string) => {
        setValues({ ...values, [input]: e.target.value });
    };

    const handleDropDownChange = (v: any, key: string) => {
        setValues({ ...values, [key]: v });
    };

    const handleSubmitPoliticas = async () => {
        if (htmlPoliticas) {
            const tituloPoliticas = values['tituloPoliticas'];
            await subirPoliticasPrivacidad(idModeloCustom, htmlPoliticas, tituloPoliticas);
            dispatch(
                cambiarDatosPoliticasPrivacidad({
                    tituloPoliticas: tituloPoliticas,
                    politicasPrivacidad: htmlPoliticas,
                }),
            );
            handleClose();
        }
    };

    const renderInputs = (inputs: input[]) => {
        return inputs.map((i: input, index: number) => {
            if (i.type === 'text') {
                return (
                    <input
                        key={i.key + index}
                        value={values ? values[i.key] : ''}
                        onChange={(e) => handleChangeTextInput(e, i.key)}
                        placeholder={i.placeholder}
                    />
                );
            } else if (i.type === 'dropdown') {
                return (
                    <Autocomplete
                        value={values ? values[i.key] : ''}
                        key={i.key + index}
                        id={`dropdown-${i.key}`}
                        disableClearable={true}
                        popupIcon={<ChevronDown />}
                        options={i.options || []}
                        onChange={(e, v) => {
                            handleDropDownChange(v, i.key);
                        }}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{ shrink: false }}
                                variant="outlined"
                                label={!values ? i.placeholder : ''}
                                margin="normal"
                            />
                        )}
                    />
                );
            } else if (i.type === 'upload') {
                return (
                    <RichTextInput
                        onChange={(html: string) => setHtmlPoliticas(html)}
                        defaultValue={defaultValues.politicasPrivacidad}
                    />
                );
            }
        });
    };

    const renderForm = () => {
        if (step === 1) {
            return (
                <div className="pantalla-bienvenida-modal-items">
                    {inputsEditarOnboarding.map((o: option) => {
                        return (
                            <div key={o.title} className="pantalla-bienvenida-modal-items-item">
                                <h3>{o.title}</h3>
                                {renderInputs(o.inputs)}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className="pantalla-bienvenida-modal-items">
                    {inputsPoliticasPrivacidad.map((o: option) => {
                        return (
                            <div key={o.title} className="pantalla-bienvenida-modal-items-item">
                                <h3>{o.title}</h3>
                                {renderInputs(o.inputs)}
                            </div>
                        );
                    })}
                </div>
            );
        }
    };

    const handleSubmit = async () => {
        if (step === 1) {
            await onSubmit(values);
            setStep(2);
        } else if (step === 2) {
            await handleSubmitPoliticas();
        }
    };

    return (
        <div>
            {hideIcon ? null : (
                <button onClick={handleOpen} className="reset-button-styles pantalla-bienvenida-modal-button">
                    <Edit />
                </button>
            )}
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={`pantalla-bienvenida-modal ${step === 2 ? 'pantalla-bienvenida-modal-politicas' : ''}`}>
                    <h1>{step === 1 ? 'Pantalla bienvenida' : 'Política de privacidad'}</h1>
                    {renderForm()}
                    <div className="pantalla-bienvenida-modal-actions">
                        <button className="secondary-button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="primary-button" disabled={!canConfirm()} onClick={handleSubmit}>
                            {buttonText}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default EditarBienvenidaYPoliticas;
