import React, { useEffect, useState } from 'react';
import { getConversionesLeads } from './dashboardAPI';
import CustomCircularProgress from '../../components/CustomCircularProgress/CustomCircularProgress';
import { FiltrosDashboard } from './Dashboard';
import EmptyStateDashboard from './EmptyStateDashboard';

interface Data {
    en_curso: string;
    completadas: string;
    total_inspecciones: number;
}

const ConversionLeads = ({ archivadas, fechaInicial, fechaFinal }: FiltrosDashboard) => {
    const [data, setData] = useState<Data | null>(null);
    const agenteId = localStorage.getItem('idAgente');
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        const obtenerConversionLeads = async () => {
            const results = await getConversionesLeads(Number(agenteId), archivadas, fechaInicial, fechaFinal);
            console.log(results);
            if (results && results.status === 200) {
                setData(results.data);
                setNoResults(false);
            } else {
                setNoResults(true);
            }
        };

        obtenerConversionLeads();
    }, [archivadas, fechaInicial, fechaFinal]);

    return (
        <div className="conversion-leads dashboard-card">
            <div className="conversion-leads-title">
                <h2 className="dashboard-card-title">Conversión leads</h2>
                <p>Total: {data ? data.total_inspecciones : 0}</p>
            </div>
            {noResults ? (
                <div style={{ height: 156 }}>
                    <EmptyStateDashboard />
                </div>
            ) : (
                <>
                    <div className="conversion-leads-item conversion-leads-item-first">
                        <CustomCircularProgress value={data ? Number(data.completadas) : 0} />
                        <p className="conversion-leads-item-text">Conversión en formularios completados</p>
                    </div>
                    <div className="conversion-leads-item">
                        <CustomCircularProgress value={data ? Number(data.en_curso) : 0} />
                        <p className="conversion-leads-item-text">Conversión en formularios en curso</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default ConversionLeads;
