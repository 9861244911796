import { tipoPreguntaLibre } from '../../enums/tipoPreguntaLibre';

export const inputsTituloModulo = [
    {
        title: '1. Título',
        inputs: [
            {
                placeholder: 'Escribe el título que desees incluir',
                key: 'tituloModulo',
                required: true,
                type: 'text',
            },
        ],
    },
];

export const preguntaSeleccion = [
    {
        title: '1. Pregunta',
        inputs: [
            {
                placeholder: 'Escribe la pregunta que desees incluir',
                key: 'tituloPregunta',
                required: true,
                type: 'text',
            },
        ],
    },
    {
        title: '2. Opciones (debes rellenar al menos 2)',
        inputs: [
            {
                placeholder: 'Opción 1',
                key: 'opcion1',
                required: true,
                type: 'text',
            },
            {
                placeholder: 'Opción 2',
                key: 'opcion2',
                required: true,
                type: 'text',
            },
            {
                placeholder: 'Opción 3 (opcional)',
                key: 'opcion3',
                required: false,
                type: 'text',
            },
            {
                placeholder: 'Opción 4 (opcional)',
                key: 'opcion4',
                required: false,
                type: 'text',
            },
            {
                placeholder: 'Opción 5 (opcional)',
                key: 'opcion5',
                required: false,
                type: 'text',
            },
            {
                placeholder: 'Opción 6 (opcional)',
                key: 'opcion6',
                required: false,
                type: 'text',
            },
            {
                placeholder: 'Opción 7 (opcional)',
                key: 'opcion7',
                required: false,
                type: 'text',
            },
            {
                placeholder: 'Opción 8 (opcional)',
                key: 'opcion8',
                required: false,
                type: 'text',
            },
        ],
    },
];

export const preguntaEscrita = [
    {
        title: '1. Pregunta',
        inputs: [
            {
                placeholder: 'Escribe la pregunta que desees incluir',
                key: 'opcionEscrita',
                required: true,
                type: 'text',
            },
        ],
    },
];

export const preguntaCargaArchivos = [
    {
        title: '1. Pregunta',
        inputs: [
            {
                placeholder: 'Escribe la pregunta que desees incluir',
                key: 'opcionCargaArchivos',
                required: true,
                type: 'text',
            },
        ],
    },
];

export const preguntaFotosLibres = [
    {
        title: '1. Pregunta',
        inputs: [
            {
                placeholder: 'Escribe la pregunta que desees incluir',
                key: 'tituloPregunta',
                required: true,
                type: 'text',
            },
        ],
    },
    {
        title: '2. Selecciona el número de fotos personalizadas que desees incluir en la evaluación.',
        inputs: [
            {
                placeholder: 'Selecciona el número de fotos',
                key: 'fotosLibres',
                required: true,
                type: 'dropdown',
                options: Array.from({ length: 10 }, (_, i) => i + 1),
            },
        ],
    },
];

export interface opcionPreguntaLibre {
    tipoDePregunta: number;
    icono: string;
    iconoActivo: string;
    titulo: string;
}

export const opcionesTipoPreguntas: opcionPreguntaLibre[] = [
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA,
        icono: 'seleccionUnica',
        iconoActivo: 'seleccionUnicaActive',
        titulo: 'Selección única',
    },
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION,
        icono: 'seleccionMultiple',
        iconoActivo: 'seleccionMultipleActive',
        titulo: 'Multiselección',
    },
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA,
        icono: 'preguntaEscrita',
        iconoActivo: 'preguntaEscritaActive',
        titulo: 'Escrita',
    },
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS,
        icono: 'cargaArchivo',
        iconoActivo: 'cargaArchivoActive',
        titulo: 'Subir archivo',
    },
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES_BLOQUE,
        icono: 'preguntaFotos',
        iconoActivo: 'preguntaFotosActive',
        titulo: 'Fotos',
    },
    {
        tipoDePregunta: tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_DESPLEGABLE,
        icono: 'seleccionDesplegable',
        iconoActivo: 'seleccionDesplegableActive',
        titulo: 'Pregunta desplegable',
    },
];

export const preguntaSeleccionDesplegable = [
    {
        title: '1. Pregunta',
        inputs: [
            {
                placeholder: 'Escribe la pregunta que desees incluir',
                key: 'tituloPregunta',
                required: true,
                type: 'text',
            },
        ],
    },
    {
        title: '2. Sube el documento',
        inputs: [
            {
                placeholder: '',
                key: 'archivoCsv',
                required: true,
                type: 'cargaCsv',
            },
        ],
    },
];
