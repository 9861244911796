import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ClipboardList, FileText, Home2, Users } from 'tabler-icons-react';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import TerminosYCondiciones from './features/terminosYCondiciones/TerminosYCondiciones';
import Header from './components/header/Header';
import Loading from './components/Loading';
import Navbar from './components/navbar/Navbar';
import { obtenerLogoAseguradora } from './features/clientes/ClientesAPI';
import { cleanClientes } from './features/clientes/ClientesSlice';
import DetallesClienteFisico from './features/clientes/detalles/DetallesClienteFisico';
import DetallesClienteJuridico from './features/clientes/detalles/DetallesClienteJuridico';
import { cleanInspecciones } from './features/inspecciones/inspeccionesSlice';
import {
    aceptoToS,
    changeLogo,
    handleAseguradoraId,
    logout,
    selectAseguradoraId,
    signIn,
} from './features/login/loginSlice';
import initI18n from './i18n';
import ClientesPage from './pages/clientes/clientesPage';
import Home from './pages/Home/Home';
import DetallesInspeccion from './pages/inspecciones/detallesInspeccion';
import Inspecciones from './pages/inspecciones/inspeccionesPage';
import Login from './pages/login/LoginPage';
import Modelos from './pages/Modelos/ModelosPage';
import conf from './utlis/config';
import ModalTerminosYCondiciones from './features/terminosYCondiciones/ModalTerminosYCondiciones';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import useHubspotChat from './hooks/useHubspotChat';
import { STEPS, TourButton } from './components/appTour/steps';
import useTour from './components/appTour/useTour';
import ConfiguracionModelo from './features/modelos/ConfiguracionModelo';
import { getApiStatus } from './features/inspecciones/InspeccionesAPI';
import ApiStatus from './features/apiStatus/ApiStatus';

const defaultConfig = {
    baseUrl: conf('BASE_URL'),
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
    },
};

// Add a request interceptor
// To add token before calling API if token exists
function addRequestInterceptorAndDefaults() {
    return axios.interceptors.request.use(
        (config) => {
            config.headers = defaultConfig.headers;
            config.baseURL = defaultConfig.baseUrl;
            const access_token = localStorage.getItem('token');
            if (access_token) {
                config.headers = {
                    ...config.headers,
                    Authorization: 'Bearer ' + access_token,
                };
            }

            return config;
        },
        (error) => {
            Promise.reject(error);
        },
    );
}

const App: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const login = useAppSelector((state: any) => state.login);
    const dispatch = useAppDispatch();
    const path = useLocation().pathname;
    const [loading, setLoading] = useState(true);
    const logged = localStorage.getItem('isLoggedIn');
    const terminosYCondiciones = localStorage.getItem('terminosYCondiciones');
    const token = localStorage.getItem('token');
    const aseguradoraId = useAppSelector(selectAseguradoraId);
    const tourViewed = window.localStorage.getItem('tutorialActivo');
    const tour = useTour(STEPS, tourViewed);

    function addResponseInterceptor() {
        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalConfig = error.config;
                if (error.response) {
                    if (error.response.status === 401 && !originalConfig._retry) {
                        navigate('/login');
                        /* originalConfig._retry = true;
                    // Do something, call refreshToken() request for example;
                    // return a request
                    const email = localStorage.getItem('email');
                    const idAgente = localStorage.getItem('idAgente');
                    const key = localStorage.getItem('key');
                    const refreshToken = localStorage.getItem('refreshToken');
                    return axios.post('refrescar-token', {
                        email: email,
                        id: idAgente,
                        key: key,
                        refreshToken: refreshToken,
                    }); */
                    }
                    if (error.response.status === 'ANOTHER_STATUS_CODE') {
                        // Do something
                        return Promise.reject(error.response.data);
                    }
                }
                return Promise.reject(error);
            },
        );
    }

    useEffect(() => {
        const logged = localStorage.getItem('isLoggedIn');
        const tos = localStorage.getItem('terminosYCondiciones');
        if (logged && logged === '1') {
            dispatch(signIn());
        }

        if (tos && tos === '1') {
            dispatch(aceptoToS(true));
        }
    }, []);

    useEffect(() => {
        const logged = localStorage.getItem('isLoggedIn');
        const aseguradoraIdLocal = JSON.parse(localStorage.getItem('idAseguradora') || '{}');
        if (login.isLoggedIn && logged === '1') {
            dispatch(handleAseguradoraId(parseInt(aseguradoraIdLocal)));
        }
    }, [login.isLoggedIn]);

    useEffect(() => {
        if (aseguradoraId || aseguradoraId === 0) {
            const getAseguradoraLogo = async () => {
                const results = await obtenerLogoAseguradora(aseguradoraId);
                dispatch(changeLogo(results?.data.logo));
            };

            getAseguradoraLogo();
        }
    }, [aseguradoraId]);

    useEffect(() => {
        initI18n().then(() => {
            addRequestInterceptorAndDefaults();
            addResponseInterceptor();
            setLoading(false);
        });
    }, [token]);

    const routes = [
        {
            name: 'Home',
            path: '/home',
            icon: (
                <div className="fourth-step">
                    <Home2 />
                </div>
            ),
        },
        {
            name: 'Evaluaciones',
            path: '/inspecciones',
            icon: (
                <div className="fourth-step">
                    <ClipboardList />
                </div>
            ),
        },
        {
            name: 'Clientes',
            path: '/clientes',
            icon: (
                <div className="first-step">
                    <Users />
                </div>
            ),
        },
        {
            name: 'Modelos',
            path: '/modelos',
            icon: (
                <div className="third-step">
                    <FileText />
                </div>
            ),
        },
    ];

    const backgroundColor = () => {
        if (routes.find((r) => r.path === path)) return '#F6F6F7';
        else return '#FFFFFF';
    };

    const getUserEmailLogged = (): string => {
        const email = localStorage.getItem('email');
        if (!email) return '';
        return email;
    };

    const Logout = async () => {
        localStorage.setItem('isLoggedIn', '0');
        dispatch(cleanClientes());
        dispatch(cleanInspecciones());
        await dispatch(logout());
        navigate('/login');
    };

    const [showTour, setShowTour] = React.useState(false);
    const [run, setRun] = useState(true);

    const handleTourClick = React.useCallback(() => {
        setShowTour((prevShowTour) => !prevShowTour);
        setRun(true);
    }, []);
    const { hasLoaded, activeConversation, openHandler, closeHandler } = useHubspotChat('26767650');

    return loading ? (
        <Loading />
    ) : (
        <>
            {showTour && <TourButton />}
            <div
                style={{ backgroundColor: `${path == '/home' ? '#F6F6F7' : '#FFFFFF%'}` }}
                className={`${path !== '/login' && path !== '/status' ? 'flex-container' : ''}`}
            >
                {path !== '/login' && path !== '/status' ? <Navbar routes={routes} /> : null}
                <div
                    style={{ backgroundColor: backgroundColor(), width: `${path == '/home' ? 'auto' : '100%'}` }}
                    className={`${path !== '/login' && path !== '/status' ? 'routes-container' : ''}`}
                >
                    {path !== '/login' && path !== '/status' ? (
                        <>
                            <Header
                                title="Header"
                                logout={() => Logout()}
                                isLoggedUser={login.isLoggedIn}
                                email={getUserEmailLogged()}
                                onTourClick={handleTourClick}
                            />
                            {tour}
                        </>
                    ) : null}
                    <Routes>
                        <Route path="/" element={<Navigate to="/login" replace />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/modelos" element={<Modelos />} />
                        <Route path="/modelos/:id" element={<ConfiguracionModelo />} />
                        <Route path="/inspecciones" element={<Inspecciones />} />
                        <Route path="/inspecciones/:id" element={<DetallesInspeccion />} />
                        <Route path="/clientes" element={<ClientesPage />} />
                        <Route path="/clientes/fisicos/:id" element={<DetallesClienteFisico />} />
                        <Route path="/clientes/juridicos/:id" element={<DetallesClienteJuridico />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/status" element={<ApiStatus />} />
                    </Routes>
                </div>
                {login.isLoggedIn && logged === '1' && terminosYCondiciones !== '1' ? (
                    <ModalTerminosYCondiciones />
                ) : null}
            </div>
        </>
    );
};

export default App;
