export enum Ramos {
    Hogar = 'Hogar',
    Comercio = 'Comercio / Oficina',
    Industria = 'Pyme / Industria',
    Vehiculo = 'Vehículo',
    Comunidad = 'Comunidad',
}

export enum RamosNumber {
    Hogar = 1,
    Comercio = 2,
    Industria = 3,
    Vehiculo = 4,
    Comunidad = 5,
}
