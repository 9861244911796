import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { CirclePlus } from 'tabler-icons-react';
import NuevoCliente from './NuevoCliente';
import './NuevoCliente.scss';

const NuevoClienteModal = (props: any) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <button onClick={() => setOpen(true)} className={`${props.buttonStyle || 'primary-button'}`}>
                Nuevo cliente
                {props.icon ? <CirclePlus /> : null}
            </button>
            <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <NuevoCliente
                        onSubmit={() => setOpen(false)}
                        onCancel={() => setOpen(false)}
                        onCreate={props.onCreate}
                    />
                </div>
            </Modal>
        </>
    );
};

export default NuevoClienteModal;
