export enum LimitePreguntasLibresBloques {
    SeleccionUnica = 20,
    SeleccionMultiple = 20,
    CargaArchivo = 10,
    Escrita = 10,
    Fotos = 10,
    SeleccionDesplegable = 20,
}

export enum LimitePreguntasLibres {
    SeleccionUnica = 50,
    SeleccionMultiple = 50,
    CargaArchivo = 20,
    Escrita = 20,
    Fotos = 20,
    SeleccionDesplegable = 50,
}
