import React, { useEffect, useState } from 'react';
import { IconHogar } from '../../assets/IconHogar';
import IconComercio2 from '../../assets/svg/IconComercio2';
import { IconIndustria2 } from '../../assets/IconIndustria2';
import { IconVehiculo2 } from '../../assets/IconVehiculo2';
import { IconComunidad2 } from '../../assets/iconComunidad2';
import { IconVida } from '../../assets/IconVida';
import './SideBar.scss';
import { IconCopy } from '../../assets/IconCopy';
import QuickSearch from '../../components/quick-search/QuickSearch';
import NuevoModelo from './NuevoModelo';
import { ModeloInstanceInterface, ModeloInterface, ModelosCustomSync, RamosConModeloSync } from './ModeloSlice';
import { useDispatch } from 'react-redux';
import { conf } from '../../utlis/config';
import { Check } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { IconsRamos } from '../../assets/iconosRamos/IconsRamos';
import { dateStringToObj } from '../../utlis';
import ModalEliminar from '../../components/ModalEliminar/ModalEliminar';
import EditarRamo from './EditarRamo';
import { BorrarRamoCustom } from './RamosAPI';
import ToastMessage from '../../components/reusable/ToastMessage';
import { EmptyStateImage } from '../../assets/EmptyStateImage';

interface Props {
    modelo: ModeloInterface | undefined;
    show: boolean;
    onClose: () => void;
}

const SideBar = ({ modelo, show, onClose }: Props) => {
    if (!show) return null;
    const modelInstances = modelo?.modelosCustom || [];
    const [filteredInstances, setFilteredInstances] = useState(modelInstances);
    const [searchTerm, setSearchTerm] = useState('');
    const [idLinkCopiado, setIdLinkCopiado] = useState<number | undefined>(undefined);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState<string | null>(null);
    const baseUrl = conf('MOBILE_BASE_URL');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (modelo) {
            console.log(modelo);
            dispatch(ModelosCustomSync(modelo?.idModeloMaestro));
        }
    }, []);

    useEffect(() => {
        console.log('pepe');
        setFilteredInstances(modelInstances);
        setSearchTerm('');
    }, [modelInstances]);

    useEffect(() => {
        const newInstances = modelInstances.filter((i) => i.nombre.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredInstances(newInstances);
    }, [searchTerm]);

    const handleCopiarEnlace = (event: any, modelInstance: ModeloInstanceInterface) => {
        event.stopPropagation();
        navigator.clipboard.writeText(baseUrl + modelInstance.enlace);
        setIdLinkCopiado(modelInstance.id);
    };

    const handleSummaryClick = (modeloId: number) => {
        navigate(`/modelos/${modeloId}`);
    };

    const handleClickBorrarRamo = async () => {
        if (modelo) {
            const results = await BorrarRamoCustom(modelo.id);
            console.log(results);
            if (results && results.status === 200) {
                const idAseguradora = localStorage.getItem('idAseguradora');
                dispatch(RamosConModeloSync(Number(idAseguradora)));
                onClose();
            } else {
                setShowToastMessage(results);
            }
            setOpenModalEliminar(false);
        }
    };

    const modelSummary = (modelInstance: ModeloInstanceInterface) => {
        return (
            <div className="model-summary" key={modelInstance.id} onClick={(e) => handleSummaryClick(modelInstance.id)}>
                <div className="model-summary-state">
                    <span className="model-summary-state">{modelInstance.activo ? 'Activo' : 'Inactivo'}</span>
                </div>
                <div className="model-summary-title">{modelInstance.nombre}</div>
                <div className="model-summary-footer">
                    <div className="model-summary-date">{modelInstance.ultima_actualizacion}</div>
                    <div className="model-summary-link" onClick={(e) => handleCopiarEnlace(e, modelInstance)}>
                        <span>{idLinkCopiado === modelInstance.id ? 'Enlace copiado' : 'Copiar enlace'}</span>
                        {idLinkCopiado === modelInstance.id ? <Check /> : <IconCopy />}
                    </div>
                </div>
            </div>
        );
    };

    const modelList = () => {
        if (filteredInstances.length) {
            return filteredInstances.map((instance) => modelSummary(instance));
        } else {
            return (
                <div className="modelos-empty-state">
                    <EmptyStateImage />
                    <p>Aún no tienes modelos creados.</p>
                </div>
            );
        }
    };

    return (
        <div className="modelos-sidebar">
            <div className="modelos-sidebar-close">
                <span onClick={onClose}>X</span>
            </div>
            <div className="modelos-sidebar-header">
                <div>
                    <div className="sidebar-logo">
                        <div className="sidebar-logo-image">{modelo && IconsRamos[modelo.icono]}</div>
                        <div className="sidebar-logo-title">{modelo && modelo.nombre}</div>
                    </div>
                </div>
                <NuevoModelo selectedModelId={modelo?.id} />
                {/* <button onClick={(e) => onCreateModelHandler()} className="nuevo-modelo-button">
                    Crear Modelo
                </button> */}
            </div>
            <div className="modelos-sidebar-search">
                <QuickSearch
                    key={modelo?.id}
                    styleInputBackground="grey50"
                    onChange={(value) => setSearchTerm(value)}
                    placeholder="Buscar"
                />
            </div>
            <div className="modelos-sidebar-model-list">{modelList()}</div>
            <div className="modelos-sidebar-actions">
                {modelo && (
                    <EditarRamo
                        defaultIcon={modelo.icono}
                        defaultName={modelo.nombre}
                        idRamoCustom={modelo.id}
                        onEdit={onClose}
                        onError={(m: string) => setShowToastMessage(m)}
                    />
                )}
                <button className="delete-button" onClick={() => setOpenModalEliminar(true)}>
                    Borrar Ramo
                </button>
            </div>
            <ModalEliminar
                open={openModalEliminar}
                onCancelDelete={() => setOpenModalEliminar(false)}
                onDelete={handleClickBorrarRamo}
                titulo="Ramo"
                descripcion={`¿Esta seguro que desea eliminar el ramo ${modelo && modelo.nombre}?`}
            />
            {showToastMessage && (
                <ToastMessage
                    message={showToastMessage || ''}
                    handleClose={() => setShowToastMessage(null)}
                    severity="error"
                />
            )}
        </div>
    );
};

export default SideBar;
