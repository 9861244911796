import React from 'react';
import { CalendarEvent, FileDownload, Trash } from 'tabler-icons-react';
import { getDateFormated } from '../../utlis';
import './Adjunto.scss';
import { Link } from 'react-router-dom';
import { downloadAdjuntos } from '../../features/inspecciones/inspeccionesHelpers';
import { borrarAdjunto } from '../../features/inspecciones/InspeccionesAPI';

interface IAgente {
    nombre: string;
    apellido: string;
}
interface IAdjunto {
    nombreArchivo: string;
    url: string;
    nombreAgente: string;
    fechaCreacion: Date;
    id: number;
    agente: IAgente;
    arn: string;
    contentType: string;
}

interface IPropsAdjunto {
    adjuntos: IAdjunto[];
    onDelete: () => void;
}

const Adjunto = ({ adjuntos, onDelete }: IPropsAdjunto) => {
    console.log(adjuntos);
    const hora = new Date(adjuntos[0].fechaCreacion).toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const handleBorrarAdjunto = async (arn: string) => {
        const results = await borrarAdjunto(arn);
        if (results.status === 200) {
            onDelete();
        }
    };

    const renderAdjuntos = () => {
        return adjuntos.map((a) => {
            return (
                <div key={a.id} className="adjunto-agente-item">
                    <p>{a.nombreArchivo}</p>
                    <div className="adjunto-agente-item-actions">
                        <FileDownload
                            className="adjunto-agente-item-actions-download"
                            onClick={async () => await downloadAdjuntos(a.arn, a.contentType, a.nombreArchivo)}
                        />
                        <Trash
                            className="adjunto-agente-item-actions-delete"
                            onClick={() => handleBorrarAdjunto(a.arn)}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="adjunto-agente">
            <div className="adjunto-agente-header">
                <CalendarEvent />
                <p>
                    {hora} {getDateFormated(adjuntos[0].fechaCreacion)} - {adjuntos[0].agente.nombre}{' '}
                    {adjuntos[0].agente.apellido}
                </p>
            </div>
            <div className="adjunto-agente-lista">{renderAdjuntos()}</div>
        </div>
    );
};

export default Adjunto;
