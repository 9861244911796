import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigHeader from './ConfigHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConfigModeloSync,
    ModelosCustomSync,
    ModuloCustomDto,
    SubModuloCustomDto,
    UpdateModelName,
    activarPregunta,
    changeModuleTitle,
    changeSubmoduleValue,
    configModeloCustom,
    modelInstancesSelector,
    cambiarOrdenPreguntaLibre,
    borrarPreguntaLibre,
    cambiarDatosBienvenida,
} from './ModeloSlice';
import './ConfiguracionModelo.scss';
import Tabs from '../../components/tabs/Tabs';
import { Accordion, AccordionDetails, AccordionSummary, Modal, Tooltip } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import CustomSwitch from '../../components/customSwitch/CustomSwitch';
import { ChevronDown, ChevronUp, DeviceMobile, Eye, EyeOff, InfoCircle, Pencil, Trash, X } from 'tabler-icons-react';
import ModalEliminarModelo from './ModalEliminarModelo';
import {
    DeleteModeloCustom,
    RestablecerModeloModeloCustom,
    UpdateModeloCustom,
    UpdatePreguntaModeloCustom,
    updateBienvenidaModeloCustom,
    updateFotosLibres,
    updatePreguntaModeloCustom,
} from './ModelosAPI';
import ModalConfirmation from '../../components/modal-confirmation/ModalConfirmation';
import { JsonAnimation } from '../../components/JsonAnimation/JsonAnimation';
import EditarSubmodulo from './EditarSubmodulo';
import {
    inputsTituloModulo,
    preguntaCargaArchivos,
    preguntaEscrita,
    preguntaFotosLibres,
    preguntaSeleccion,
} from './OpcionesEdicionSubmodulos';
import EvaluacionesEnviadasTab from './EvaluacionesEnviadasTab';
import ToastMessage from '../../components/reusable/ToastMessage';
import { getOnBoardingSVG } from '../../helper/svgImages';
import { tipoPreguntaLibre } from '../../enums/tipoPreguntaLibre';
import AddButton from '../../components/AddButton/AddButton';
import * as R from 'ramda';
import ModalPreguntaLibre from './ModalPreguntaLibre';
import EditarBienvenidaYPoliticas from './EditarBienvenidaYPoliticas';
import Loader from '../../components/Loader/Loader';

const ConfiguracionModelo = () => {
    const modeloCustomId = parseInt(useLocation().pathname.replace(/^.*\/.*\//, ''));
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [collapsedModules, setCollapsedModules] = useState<string[]>(['']);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);
    const [showPreviewOnBoarding, setShowPreviewOnBoarding] = useState(false);
    const [openModalRestablecer, setOpenModalRestablecer] = useState(false);
    const [valuesChanged, setValuesChanged] = useState(false);
    const [showImagePreviewSrc, setShowImagePreviewSrc] = useState<{ src: string; description: string } | null>(null);
    const [modeloRestablecido, setModeloRestablecido] = useState(false);
    const [loadingImagePreview, setLoadingImagePreview] = useState(false);
    const [showToastMessage, setShowToastMessage] = useState<string | null>(null);
    const [openModalPregunta, setOpenModalPregunta] = useState<any>({ value: false, key: '', submoduleId: 0 });
    const [editarTituloModulo, setEditarTituloModulo] = useState({ moduleId: 0, defaultTitle: '' });
    const [loading, setLoading] = useState(false);
    const initialPreguntaLibreModal = {
        idModulo: 0,
        subModulos: [],
        idsSubmodulosActivables: null,
    };
    const [modalPreguntaLibre, setModalPreguntaLibre] = useState(initialPreguntaLibreModal);
    const navigate = useNavigate();
    const modelData = useSelector(configModeloCustom);
    const dispatch = useDispatch();

    const tabs = [
        { title: 'Módulos', id: 1 },
        { title: 'Evaluaciones enviadas', id: 2 },
    ];

    useEffect(() => {
        dispatch(ConfigModeloSync(modeloCustomId));
    }, []);

    useEffect(() => {
        if (modelData) {
            dispatch(ModelosCustomSync(modelData?.maestro_modelo_id));
        }
    }, [modelData]);

    useEffect(() => {
        dispatch(ConfigModeloSync(modeloCustomId));
    }, [modeloRestablecido]);

    const handleInferirDatosChange = (
        value: boolean,
        moduleId: number,
        submoduleId: number,
        key: string,
        esPadre?: boolean,
        hijos?: number[],
        padreId?: number,
    ) => {
        setValuesChanged(true);
        dispatch(changeSubmoduleValue({ value, moduleId, submoduleId, key, esPadre, hijos, padreId }));
    };

    const handlePreguntarAlUsuarioChange = (
        value: boolean,
        moduleId: number,
        submoduleId: number,
        key: string,
        esPadre?: boolean,
        hijos?: number[],
    ) => {
        setValuesChanged(true);
        dispatch(changeSubmoduleValue({ value, moduleId, submoduleId, key, esPadre, hijos }));
        setOpenModalPregunta({ value, key, id: submoduleId });
    };

    const handlePressShowImagePreview = (modulo: ModuloCustomDto, sm: SubModuloCustomDto) => {
        if (sm?.urlPreview !== showImagePreviewSrc?.src) {
            if (showPreviewOnBoarding) setShowPreviewOnBoarding(false);
            setShowImagePreviewSrc({ src: sm?.urlPreview, description: `${modulo.titulo} - ${sm.titulo}` } || null);
            setLoadingImagePreview(true);
        }
    };

    const handleCollapse = (modulo: string) => {
        if (collapsedModules.includes(modulo)) {
            const newCollapsedModules = collapsedModules.filter((c) => c !== modulo);
            setCollapsedModules(newCollapsedModules);
        } else {
            setCollapsedModules((prevState) => [...prevState, modulo]);
        }
    };

    const handleDeleteModelo = async (id: number) => {
        const { success, message } = await DeleteModeloCustom(id);
        setOpenModalEliminar(false);
        if (success) navigate('/modelos');
        else setShowToastMessage(message);
    };

    const getCustomHtmlTitle = (title: string) => {
        const newTitle = title.replaceAll('\n', '<br />');
        return { __html: newTitle };
    };

    const handleSubmitEditarSubmodulo = async (e: any, submoduleId: number) => {
        const data = Object.entries(e).reduce(
            (acc: UpdatePreguntaModeloCustom, [key, value]: any) => {
                return {
                    ...acc,
                    idSubModulo: submoduleId,
                    titulo: 'Preguntas adicionales',
                    subtitulo: key === 'tituloPregunta' ? value : acc.subtitulo,
                    opciones:
                        key === 'tituloPregunta' || key === 'opcionEscrita' || key === 'opcionCargaArchivos'
                            ? acc.opciones
                            : [...acc.opciones, value],
                };
            },
            {
                idSubModulo: 0,
                titulo: '',
                subtitulo: '',
                texto: '',
                etiqueta: '',
                opciones: [],
            },
        );

        setValuesChanged(true);
        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleSubmitPreguntaLibreTitulo = async (e: any, submoduleId: number, keyValor: string) => {
        const data = Object.entries(e).reduce(
            (acc: UpdatePreguntaModeloCustom, [key, value]: any) => {
                return {
                    ...acc,
                    idSubModulo: submoduleId,
                    titulo: 'Preguntas adicionales',
                    subtitulo: key === keyValor ? value : acc.subtitulo,
                };
            },
            {
                idSubModulo: 0,
                titulo: '',
                subtitulo: '',
                texto: '',
                etiqueta: '',
                opciones: [],
            },
        );

        setValuesChanged(true);
        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleSubmitFotosLibres = async (e: any, submoduleId: number) => {
        const data = { idSubModulo: submoduleId, cantidad: Number(e.fotosLibres), subtitulo: e.tituloPregunta };
        setValuesChanged(true);
        const esPadre = false;
        const key = 'preguntaConfiguracion';
        dispatch(changeSubmoduleValue({ submoduleId, key, value: data, esPadre }));
        dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: true, esPadre }));
        return false;
    };

    const handleEditarBienvenida = async (data: any) => {
        const datos = {
            idModelo: modeloCustomId,
            titulo: data.tituloBienvenida,
            subtitulo: data.subtituloBienvenida,
        };
        await updateBienvenidaModeloCustom(datos);
        dispatch(cambiarDatosBienvenida(data));
        return false;
    };

    const handlePreguntaLibreClose = (submoduleId: number, values: any) => {
        if (values) {
            return;
        } else {
            setOpenModalPregunta({ value: false, key: '', submoduleId: 0 });
            dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: false, esPadre: false }));
        }
    };

    const handleModalEditarTituloModulo = (submoduleId: number, values: any) => {
        setEditarTituloModulo({ moduleId: 0, defaultTitle: '' });
    };

    const handleModalTituloModuloClose = (submoduleId: number, values: any) => {
        if (values) {
            return;
        } else {
            setOpenModalPregunta({ value: false, key: '', submoduleId: 0 });
            //dispatch(changeSubmoduleValue({ submoduleId, key: 'preguntarAlUsuario', value: false, esPadre: false }));
        }
    };

    const renderEditModal = (titulo: string, submoduleId: number, valoresDefault: string, tipoPregunta: number) => {
        const { value, key, id } = openModalPregunta;
        switch (tipoPregunta) {
            case tipoPreguntaLibre.TIPO_PREGUNTA_SELECCION_UNICA: {
                const open = value && key === 'preguntarAlUsuario' && id === submoduleId;
                return (
                    <EditarSubmodulo
                        forceOpen={open}
                        titulo={titulo}
                        options={preguntaSeleccion}
                        buttonText="Confirmar pregunta"
                        onSubmit={(e) => handleSubmitEditarSubmodulo(e, submoduleId)}
                        onClose={(e) => handlePreguntaLibreClose(submoduleId, e)}
                        defaultValues={valoresDefault}
                    />
                );
            }
            case tipoPreguntaLibre.TIPO_PREGUNTA_MULTISELECCION: {
                const open = value && key === 'preguntarAlUsuario' && id === submoduleId;
                return (
                    <EditarSubmodulo
                        forceOpen={open}
                        titulo={titulo}
                        options={preguntaSeleccion}
                        buttonText="Confirmar pregunta"
                        onSubmit={(e) => handleSubmitEditarSubmodulo(e, submoduleId)}
                        onClose={(e) => handlePreguntaLibreClose(submoduleId, e)}
                        defaultValues={valoresDefault}
                    />
                );
            }
            case tipoPreguntaLibre.TIPO_PREGUNTA_ESCRITA: {
                const open = value && key === 'preguntarAlUsuario' && id === submoduleId;
                return (
                    <EditarSubmodulo
                        forceOpen={open}
                        titulo={titulo}
                        options={preguntaEscrita}
                        buttonText="Confirmar pregunta"
                        onSubmit={(e) => handleSubmitPreguntaLibreTitulo(e, submoduleId, 'opcionEscrita')}
                        onClose={(e) => handlePreguntaLibreClose(submoduleId, e)}
                        defaultValues={valoresDefault}
                    />
                );
            }
            case tipoPreguntaLibre.TIPO_PREGUNTA_CARGA_ARCHIVOS: {
                const open = value && key === 'preguntarAlUsuario' && id === submoduleId;
                return (
                    <EditarSubmodulo
                        forceOpen={open}
                        titulo={'Pregunta carga de archivo'}
                        options={preguntaCargaArchivos}
                        buttonText="Confirmar pregunta"
                        onSubmit={(e) => handleSubmitPreguntaLibreTitulo(e, submoduleId, 'opcionCargaArchivos')}
                        onClose={(e) => handlePreguntaLibreClose(submoduleId, e)}
                        defaultValues={valoresDefault}
                    />
                );
            }
            case tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES: {
                const open = value && key === 'preguntarAlUsuario' && id === submoduleId;
                return (
                    <EditarSubmodulo
                        forceOpen={open}
                        titulo={titulo}
                        options={preguntaFotosLibres}
                        buttonText="Confirmar pregunta"
                        onSubmit={(e) => handleSubmitFotosLibres(e, submoduleId)}
                        onClose={(e) => handlePreguntaLibreClose(submoduleId, e)}
                        defaultValues={valoresDefault}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    const handlePreviewClick = (sm: SubModuloCustomDto, modulo: ModuloCustomDto) => {
        if (sm.urlPreview) {
            handlePressShowImagePreview(modulo, sm);
        } else {
            null;
        }
    };

    const handleActivarPreguntaLibre = (idModulo: number, subModulos: any, idsSubmodulosActivables: any) => {
        setModalPreguntaLibre({ idModulo, subModulos, idsSubmodulosActivables });
        setValuesChanged(true);
    };

    const renderAddNewPregunta = (modulo: ModuloCustomDto, idsSubmodulosActivables: any) => {
        return (
            <div className="modeloconfig-submodulos-add">
                <AddButton
                    onClick={() => handleActivarPreguntaLibre(modulo.id, modulo.subModulos, idsSubmodulosActivables)}
                    text="Añadir pregunta Personalizada"
                />
            </div>
        );
    };

    const lastSubmoduleOfEachType = (submodules: SubModuloCustomDto[]) => {
        return submodules.reduce((acc: any, sm: SubModuloCustomDto) => {
            return {
                ...acc,
                ultimaActiva: sm.id,
            };
        }, {});
    };

    const firstSubmoduleOfEachType = (submodules: SubModuloCustomDto[]) => {
        return submodules.reduceRight((acc: any, sm: SubModuloCustomDto) => {
            return {
                ...acc,
                [sm.tipoPregunta]: sm.id,
            };
        }, {});
    };

    const handleReorder = (idModulo: number, idSubmodulo: number, direccion: string) => {
        dispatch(cambiarOrdenPreguntaLibre({ idModulo, idSubmodulo, direccion }));
        setValuesChanged(true);
    };

    const obtenerPrimerPreguntaLibreId = (submodules: SubModuloCustomDto[]) => {
        const primerPreguntaLibre = submodules.find((sm) => sm.tipoPregunta);
        if (primerPreguntaLibre) {
            return primerPreguntaLibre.id;
        } else {
            return null;
        }
    };

    const handleBorrarPreguntaLibre = (idSubmodulo: number) => {
        dispatch(borrarPreguntaLibre(idSubmodulo));
        setValuesChanged(true);
    };

    const toSubmodules = (modulo: ModuloCustomDto, moduleId: number) => {
        const activeSubmodules = modulo.subModulos.filter((s) => s.activo).sort((a, b) => a.orden - b.orden);
        const inactiveSubmodules = modulo.subModulos.filter((s) => !s.activo);
        const lastOfEachTypeActive = lastSubmoduleOfEachType(activeSubmodules);
        const preguntasCustomAActivar = firstSubmoduleOfEachType(inactiveSubmodules);
        const primerPreguntaLibreId = obtenerPrimerPreguntaLibreId(activeSubmodules);
        if (modulo.titulo.includes('personalizadas') && R.isEmpty(lastOfEachTypeActive)) {
            return renderAddNewPregunta(modulo, preguntasCustomAActivar);
        }
        return activeSubmodules.map((sm, index) => {
            return (
                <Fragment key={sm.id}>
                    <div className="modeloconfig-submodulos">
                        {sm.ayuda ? (
                            <Tooltip
                                title={<div dangerouslySetInnerHTML={getCustomHtmlTitle(sm.ayuda)}></div>}
                                placement="right"
                                className="modeloconfig-submodulos-tooltip"
                                arrow
                            >
                                <div>
                                    <InfoCircle className="modeloconfig-submodulos-tooltip-icon" />
                                </div>
                            </Tooltip>
                        ) : null}
                        {sm.tipoPregunta && sm.tipoPregunta !== tipoPreguntaLibre.TIPO_PREGUNTA_FOTOS_LIBRES ? (
                            <div className="modeloconfig-submodulos-orden">
                                {primerPreguntaLibreId && primerPreguntaLibreId === sm.id ? (
                                    <ChevronUp color="#EBEAF0" />
                                ) : (
                                    <ChevronUp
                                        color="#89869B"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleReorder(moduleId, sm.id, 'subir')}
                                    />
                                )}
                                {activeSubmodules.length === index + 1 ? (
                                    <ChevronDown color="#EBEAF0" />
                                ) : (
                                    <ChevronDown
                                        color="#89869B"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleReorder(moduleId, sm.id, 'bajar')}
                                    />
                                )}
                            </div>
                        ) : null}
                        <div className="modeloconfig-submodulos-title">
                            {sm.preguntaConfiguracion && sm.preguntaConfiguracion.subtitulo
                                ? sm.preguntaConfiguracion.subtitulo
                                : sm.titulo}
                            {renderEditModal(sm.titulo, sm.id, sm.preguntaConfiguracion, sm.tipoPregunta)}
                        </div>
                        {sm.mostrarInferirDatosAutomaticos ? (
                            <CustomSwitch
                                defaultValue={sm.inferirDatosAutomaticos}
                                onChange={(e) =>
                                    handleInferirDatosChange(
                                        e,
                                        moduleId,
                                        sm.id,
                                        'inferirDatosAutomaticos',
                                        sm.esPadre,
                                        sm.hijos,
                                        sm.padreId,
                                    )
                                }
                            />
                        ) : null}
                        {sm.tipoPregunta && sm.tipoPregunta !== 5 ? (
                            <Trash
                                color="#E64B54"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleBorrarPreguntaLibre(sm.id)}
                            />
                        ) : null}
                        <div
                            className="cursor-pointer"
                            style={{ gridColumn: 4 }}
                            onClick={(e) =>
                                handlePreguntarAlUsuarioChange(
                                    !sm.preguntarAlUsuario,
                                    moduleId,
                                    sm.id,
                                    'preguntarAlUsuario',
                                    sm.esPadre,
                                    sm.hijos,
                                )
                            }
                        >
                            {sm.preguntarAlUsuario ? <Eye color="#0046CF" /> : <EyeOff color="#D9D7E1" />}
                        </div>
                        <div className="cursor-pointer">
                            <DeviceMobile
                                onClick={() => handlePreviewClick(sm, modulo)}
                                color={sm.urlPreview ? '#0046CF' : '#D9D7E1'}
                            />
                        </div>
                    </div>
                    {lastOfEachTypeActive.ultimaActiva === sm.id && !R.isEmpty(preguntasCustomAActivar)
                        ? renderAddNewPregunta(modulo, preguntasCustomAActivar)
                        : null}
                </Fragment>
            );
        });
    };

    const renderBienvenidaModule = () => {
        return (
            <div key="bienvenida" className={`modeloconfig-modulo`}>
                <Accordion
                    expanded={!collapsedModules.includes('bienvenida')}
                    onChange={() => handleCollapse('bienvenida')}
                    sx={{ boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="0. Pantalla bienvenida"
                    >
                        <p className="modeloconfig-modulo-title">0. Pantalla bienvenida</p>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="modeloconfig-submodulos">
                            <div></div>
                            <div className="modeloconfig-submodulos-title">
                                Pantalla bienvenida
                                <EditarBienvenidaYPoliticas
                                    onSubmit={handleEditarBienvenida}
                                    onClose={(e) => console.log(e)}
                                    idModeloCustom={modeloCustomId}
                                    defaultValues={{
                                        tituloBienvenida: modelData?.tituloBienvenida || '',
                                        subtituloBienvenida: modelData?.subtituloBienvenida || '',
                                        tituloPoliticas: modelData?.tituloPoliticas || '',
                                        politicasPrivacidad: modelData?.politicasDePrivacidad || '',
                                    }}
                                />
                            </div>
                            <div />
                            <div />
                            <div className="cursor-pointer">
                                <DeviceMobile
                                    onClick={() => {
                                        setShowImagePreviewSrc(null);
                                        setLoadingImagePreview(true);
                                        setShowPreviewOnBoarding(true);
                                    }}
                                    color={'#0046CF'}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const handleChangeTituloModulo = async (value: any, moduleId: number) => {
        dispatch(changeModuleTitle({ idModulo: moduleId, titulo: value.tituloModulo }));
        setEditarTituloModulo({ moduleId: 0, defaultTitle: '' });
        setValuesChanged(true);
        return false;
    };

    const renderTitleEditModulo = (moduleId: number, titulo: string, defaultValue: string) => {
        if (moduleId === editarTituloModulo.moduleId) {
            return (
                <EditarSubmodulo
                    forceOpen={true}
                    hideIcon={true}
                    titulo={titulo}
                    options={inputsTituloModulo}
                    buttonText="Confirmar titulo"
                    onSubmit={(e) => handleChangeTituloModulo(e, moduleId)}
                    onClose={(e) => handleModalEditarTituloModulo(moduleId, e)}
                    defaultValues={{ tituloModulo: defaultValue }}
                />
            );
        }
    };

    const toModules = () => {
        return (
            modelData &&
            modelData.modulos.map((modulo, index: number) => {
                return (
                    <div
                        key={modulo.id}
                        className={`modeloconfig-modulo ${modelData.modulos.length - 1 === index ? 'remove-line' : ''}`}
                    >
                        <Accordion
                            expanded={!collapsedModules.includes(modulo.titulo)}
                            onChange={() => handleCollapse(modulo.titulo)}
                            sx={{ boxShadow: 'none' }}
                        >
                            <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={modulo.titulo}
                            >
                                <div className="modeloconfig-modulo-info">
                                    <p className="modeloconfig-modulo-title">
                                        {modulo.tituloCustom ? `${index + 1}. ${modulo.tituloCustom}` : modulo.titulo}
                                    </p>
                                    <Pencil
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditarTituloModulo({
                                                moduleId: modulo.id,
                                                defaultTitle: modulo.titulo,
                                            });
                                        }}
                                    />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {renderTitleEditModulo(modulo.id, modulo.titulo, modulo.tituloCustom)}
                                {toSubmodules(modulo, modulo.id)}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            })
        );
    };

    const handleModeloCustomSave = async () => {
        if (modelData) {
            setLoading(true);
            const results = await UpdateModeloCustom({
                idModelo: modelData.id,
                nombre: modelData.titulo,
                modulos: modelData.modulos,
            });
            setLoading(false);

            if (results && results.success) {
                navigate('/modelos');
            } else {
                setShowToastMessage('No se puedo actualizar, ' + results?.message);
            }
        }
    };

    const renderModulos = () => {
        return (
            <div className="modeloconfig-container">
                <div className="modeloconfig-modulo-container">
                    <div className="modeloconfig-modulo-header">
                        <p className="modeloconfig-modulo-header-inferir">Captación de dato</p>
                        <p className="modeloconfig-modulo-header-preguntar">Preguntar al usuario</p>
                        <p className="modeloconfig-modulo-header-previsualizar">Previsualizar pantalla</p>
                    </div>
                    {renderBienvenidaModule()}
                    {toModules()}
                    <div className="modeloconfig-actions">
                        <button className="delete-button" onClick={() => setOpenModalEliminar(true)}>
                            Eliminar modelo
                        </button>
                        <button
                            className="primary-button"
                            disabled={!valuesChanged || loading}
                            onClick={handleModeloCustomSave}
                        >
                            Guardar cambios
                        </button>
                    </div>
                </div>
                {showImagePreviewSrc && (
                    <div className="modeloconfig-preview-container">
                        {loadingImagePreview && <JsonAnimation animation="loader" height="100px" />}
                        {!loadingImagePreview && (
                            <a onClick={() => setShowImagePreviewSrc(null)} className="modeloconfig-preview-close-icon">
                                <X />
                            </a>
                        )}
                        {showImagePreviewSrc?.description && <p>{showImagePreviewSrc?.description}</p>}
                        <img
                            onLoad={() => setLoadingImagePreview(false)}
                            onError={() => setLoadingImagePreview(false)}
                            className={loadingImagePreview ? 'd-none' : ''}
                            src={showImagePreviewSrc?.src || undefined}
                        />
                    </div>
                )}
                {showPreviewOnBoarding && (
                    <div className="modeloconfig-preview-container">
                        <a onClick={() => setShowPreviewOnBoarding(false)} className="modeloconfig-preview-close-icon">
                            <X />
                        </a>
                        <img src={getOnBoardingSVG(modelData?.ramo_id)} />
                    </div>
                )}
            </div>
        );
    };

    const renderEvaluacionesEnviadas = () => {
        return <EvaluacionesEnviadasTab modeloCustomId={modeloCustomId} />;
    };

    const renderContent = (tabId: number) => {
        switch (tabId) {
            case 1: {
                return renderModulos();
            }
            case 2: {
                return renderEvaluacionesEnviadas();
            }
        }
    };

    const onTitleEdit = (newTitle: string) => {
        dispatch(UpdateModelName({ idModeloCustom: modeloCustomId, nombre: newTitle }));
    };

    const restablecer = async () => {
        const { success, message } = await RestablecerModeloModeloCustom(modeloCustomId);
        if (success) setModeloRestablecido(!modeloRestablecido);
        else setShowToastMessage('No se puedo reestablecer, ' + message);
        setOpenModalRestablecer(false);
    };

    return (
        <div className="modeloconfig">
            {modelData && (
                <div className="modeloconfig-header">
                    <ConfigHeader
                        title={modelData.titulo}
                        fechaCreacion={`Creación: ${modelData.fecha_creacion} hs`}
                        icono={modelData.icono}
                        link={modelData.invitacion}
                        valuesChanged={valuesChanged}
                        onDelete={() => setOpenModalEliminar(!openModalEliminar)}
                        onEdit={onTitleEdit}
                        onSave={handleModeloCustomSave}
                        onRestoreToDefault={() => setOpenModalRestablecer(true)}
                    />
                </div>
            )}
            <div className="modeloconfig-tabs">
                <Tabs tabs={tabs} onChange={(e) => setSelectedTab(e)} />
            </div>
            {renderContent(selectedTab)}
            <ModalEliminarModelo
                open={openModalEliminar}
                onCancelDelete={() => setOpenModalEliminar(false)}
                onDelete={() => handleDeleteModelo(modeloCustomId)}
            />
            <Modal open={openModalRestablecer} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ModalConfirmation
                    button={`Restablecer modelo`}
                    message={`Al aceptar, se restableceran todos los modulos del modelo seleccionado a sus valores por defecto.`}
                    title={`¿Deseas restablecer el modelo ${modelData?.titulo}?`}
                    cancel={{
                        fn: () => setOpenModalRestablecer(false),
                        buttonText: 'No',
                    }}
                    success={{
                        fn: () => restablecer(),
                        buttonText: 'Si, restablecer modelo',
                    }}
                />
            </Modal>
            {showToastMessage && (
                <ToastMessage
                    message={showToastMessage || ''}
                    handleClose={() => setShowToastMessage(null)}
                    severity="error"
                />
            )}
            <ModalPreguntaLibre
                open={modalPreguntaLibre.idModulo ? true : false}
                onClose={() => setModalPreguntaLibre(initialPreguntaLibreModal)}
                moduloId={modalPreguntaLibre.idModulo}
                subModulos={modalPreguntaLibre.subModulos}
                idsSubmodulosActivables={modalPreguntaLibre.idsSubmodulosActivables}
            />
            <Loader open={loading} />
        </div>
    );
};

export default ConfiguracionModelo;
