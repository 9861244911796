import React from 'react';
import './ModalConfirmation.scss';
import { JsonAnimation } from '../JsonAnimation/JsonAnimation';

interface ModalAction {
    fn: () => void;
    buttonText: any;
    width?: string;
}

interface IModalConfirmationProps {
    button?: string;
    title: string;
    message: string;
    component?: JSX.Element;
    cancel: ModalAction;
    success: ModalAction;
    disable?: any;
    loadingSuccess?: boolean;
}

const ModalConfirmation: React.FunctionComponent<IModalConfirmationProps> = (props) => {
    const { disable, title, message, component, success, cancel, loadingSuccess } = props || {};
    const loading = loadingSuccess === undefined ? false : loadingSuccess;
    const disableClick = (fn: () => void) => {
        if (disable) {
            return null;
        } else {
            fn();
        }
    };

    return (
        <div className="modal-confirmation">
            <h3>{title}</h3>
            <p>{message}</p>
            {component}
            <div className="modal-confirmation-actions">
                <button
                    className="secondary-button"
                    onClick={cancel.fn}
                    style={{ width: cancel.width }}
                    disabled={disable}
                >
                    {cancel.buttonText}
                </button>
                <button
                    className="primary-button"
                    style={{ width: success.width }}
                    onClick={() => disableClick(success.fn)}
                    disabled={loading}
                >
                    {loading ? (
                        <JsonAnimation animation="buttonLoaderBlue" height="30px" width="105px" />
                    ) : (
                        success.buttonText
                    )}
                </button>
            </div>
        </div>
    );
};

export default ModalConfirmation;
