import React from 'react';

const Enviado = () => {
    return (
        <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.457031" width="33.1901" height="32" rx="8" fill="#FCF1E7" />
            <g clipPath="url(#clip0_2338_54368)">
                <path
                    d="M16.1816 17.3333L23.5423 10"
                    stroke="#E79142"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.5427 10L19.1932 22C19.1638 22.0638 19.1167 22.1179 19.0574 22.1558C18.9981 22.1938 18.9291 22.2139 18.8586 22.2139C18.7881 22.2139 18.7191 22.1938 18.6598 22.1558C18.6005 22.1179 18.5534 22.0638 18.524 22L16.182 17.3333L11.4979 15C11.4338 14.9708 11.3796 14.9238 11.3415 14.8647C11.3034 14.8056 11.2832 14.7369 11.2832 14.6667C11.2832 14.5965 11.3034 14.5277 11.3415 14.4686C11.3796 14.4095 11.4338 14.3626 11.4979 14.3333L23.5427 10Z"
                    stroke="#E79142"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2338_54368">
                    <rect width="16.0597" height="16" fill="white" transform="translate(9.49023 8)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Enviado;
