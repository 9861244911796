import React from 'react';

const Vejez = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8925 9.13583C14.7797 9.13583 14.6727 9.08637 14.5996 9.00047L13.0111 7.13321C12.8735 6.97145 12.8931 6.72875 13.0548 6.59111C13.2166 6.4535 13.4593 6.47304 13.597 6.63484L14.8925 8.15777L16.188 6.63484C16.3257 6.47301 16.5684 6.4535 16.7302 6.59111C16.8919 6.72875 16.9115 6.97141 16.7739 7.13321L15.1854 9.00047C15.1123 9.08637 15.0052 9.13583 14.8925 9.13583Z"
                fill="#4A88FF"
            />
            <path
                d="M18.7756 14.4536C19.1474 14.4536 19.45 14.7604 19.45 15.1375V20.5153C19.45 20.7277 19.6221 20.8999 19.8345 20.8999C20.0469 20.8999 20.2191 20.7277 20.2191 20.5153V15.1375C20.2191 14.3363 19.5715 13.6845 18.7756 13.6845H18.4541V9.37393C18.4541 7.97893 17.5431 6.79414 16.2892 6.38957C16.6452 6.0299 16.8657 5.53401 16.8657 4.98719C16.8657 3.89145 15.9806 2.99998 14.8926 2.99998C13.8047 2.99998 12.9196 3.89145 12.9196 4.98723C12.9196 5.5043 13.1168 5.97574 13.4393 6.32962L10.5214 6.88731C10.3128 6.92717 10.176 7.12861 10.2159 7.33722C10.2558 7.54583 10.4572 7.68267 10.6658 7.64274L14.0104 7.00352L15.3398 7.01002C16.6329 7.01002 17.685 8.07049 17.685 9.37397V13.6845H12.457C12.2446 13.6845 12.0724 13.8567 12.0724 14.0691C12.0724 14.2815 12.2446 14.4536 12.457 14.4536H16.7662L16.7499 20.1308H15.2428V16.2149C15.2428 16.0025 15.0706 15.8303 14.8582 15.8303C14.6459 15.8303 14.4737 16.0025 14.4737 16.2149V20.1308H12.914V18.3474C12.914 18.135 12.7418 17.9628 12.5294 17.9628C12.317 17.9628 12.1448 18.135 12.1448 18.3474V20.5153C12.1448 20.7277 12.317 20.8999 12.5294 20.8999H17.1334C17.3453 20.8999 17.5174 20.7284 17.518 20.5164L17.5353 14.4536H18.7756ZM14.8926 3.76912C15.5565 3.76912 16.0965 4.31556 16.0965 4.98723C16.0965 5.65889 15.5565 6.20533 14.8926 6.20533C14.2288 6.20533 13.6888 5.65889 13.6888 4.98723C13.6888 4.31556 14.2288 3.76912 14.8926 3.76912Z"
                fill="#0046CF"
            />
            <path
                d="M11.0405 18.1053C10.8311 18.0696 10.6324 18.2103 10.5967 18.4197L10.3047 20.1308H9.23676V18.4844C9.23676 18.272 9.06458 18.0998 8.8522 18.0998C8.63981 18.0998 8.46763 18.272 8.46763 18.4844V20.1308H7.40366L7.12968 18.3987C7.0965 18.1889 6.89939 18.0455 6.68976 18.079C6.47996 18.1121 6.33683 18.3091 6.37001 18.5189L6.69536 20.5754C6.7249 20.7623 6.886 20.8999 7.07521 20.8999H10.6293C10.8167 20.8999 10.9769 20.7648 11.0084 20.58L11.355 18.549C11.3906 18.3397 11.2498 18.1411 11.0405 18.1053Z"
                fill="#0046CF"
            />
            <path
                d="M12.6747 10.1512C12.6746 10.1491 12.6745 10.147 12.6743 10.1449C12.5982 8.84209 11.6697 7.45411 10.5145 6.91634C10.3219 6.82663 10.0932 6.91015 10.0036 7.10268C9.91395 7.29521 9.99736 7.52399 10.1899 7.61363C10.7761 7.88654 11.3109 8.48321 11.6235 9.14712C10.8674 9.78362 9.8786 10.1579 8.92868 10.1579C7.98781 10.1579 6.99629 9.77477 6.22813 9.12352C6.53317 8.46178 7.04793 7.89206 7.59664 7.68209C7.79501 7.60619 7.89429 7.38387 7.81836 7.1855C7.74249 6.98714 7.52007 6.88781 7.32178 6.96375C6.76279 7.1776 6.23236 7.6401 5.82818 8.266C5.46836 8.82321 5.21724 9.47837 5.17952 10.1449C5.17941 10.147 5.17931 10.1491 5.1792 10.1512L4.83627 18.3312C4.8319 18.4359 4.87039 18.5379 4.94294 18.6135C5.01548 18.6891 5.11571 18.7319 5.22049 18.7319H12.6334C12.7382 18.7319 12.8385 18.6891 12.911 18.6135C12.9835 18.5379 13.022 18.4359 13.0176 18.3312L12.6747 10.1512ZM5.62156 17.9628L5.94757 10.1867C5.95306 10.0946 5.96428 10.0016 5.98023 9.90839C6.84568 10.5535 7.91013 10.927 8.92872 10.927C9.95597 10.927 11.0193 10.5604 11.8756 9.92727C11.8906 10.0145 11.9012 10.1012 11.9064 10.1867L12.2324 17.9627H5.62156V17.9628Z"
                fill="#4A88FF"
            />
            <path
                d="M10.335 5.27009V4.45079C10.335 3.65082 9.68742 2.99998 8.89147 2.99998C8.09552 2.99998 7.44794 3.65082 7.44794 4.45079V5.27013C7.11976 5.62456 6.91846 6.09926 6.91846 6.62056C6.91846 7.71452 7.80356 8.60448 8.89147 8.60448C9.97942 8.60448 10.8645 7.71448 10.8645 6.62056C10.8645 6.09926 10.6632 5.62453 10.335 5.27009ZM8.89147 3.76912C9.26335 3.76912 9.5659 4.07492 9.5659 4.45079V4.7561C9.3554 4.67887 9.12826 4.63664 8.89147 4.63664C8.65472 4.63664 8.42758 4.67887 8.21708 4.7561V4.45079C8.21704 4.07492 8.51959 3.76912 8.89147 3.76912ZM8.89147 7.83534C8.22763 7.83534 7.68759 7.29041 7.68759 6.62056C7.68759 5.97469 8.23928 5.40578 8.89147 5.40578C9.54632 5.40578 10.0954 5.96522 10.0954 6.62053C10.0954 7.29041 9.55531 7.83534 8.89147 7.83534Z"
                fill="#0046CF"
            />
        </svg>
    );
};

export default Vejez;
