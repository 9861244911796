import React from 'react';

const Comunidad = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.86969 15.0274V19.2511M10.5583 19.3775H7.14624V14.901H10.5583V19.3775ZM8.85818 15.0274V19.2569V15.0274Z"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0387 19.3679L4.71509 19.3775V3.57452L13.0387 3.56499V19.3679V19.3679Z"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.4722 12.2609V13.4851M7.50781 4.91588V6.14008V4.91588ZM8.82929 4.91588V6.14008V4.91588ZM10.1508 4.91588V6.14008V4.91588ZM7.50781 7.36424V8.58839V7.36424ZM8.82929 7.36424V8.58839V7.36424ZM10.1508 7.36424V8.58839V7.36424ZM7.50781 9.8126V11.0368V9.8126ZM8.82929 9.8126V11.0368V9.8126ZM10.1508 9.8126V11.0368V9.8126ZM7.50781 12.2609V13.4851V12.2609ZM8.82929 12.2609V13.4851V12.2609ZM10.1508 12.2609V13.4851V12.2609ZM11.4722 4.91588V6.14008V4.91588ZM11.4722 7.36424V8.58839V7.36424ZM11.4722 9.8126V11.0368V9.8126Z"
                stroke="#86AFFF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.18604 12.2609V13.4851M6.18604 4.91588V6.14008V4.91588ZM6.18604 7.36424V8.58839V7.36424ZM6.18604 9.8126V11.0368V9.8126Z"
                stroke="#86AFFF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.0134 8.10767H18.2606V19.3545H13.0134"
                stroke="#0046CF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.2231 17.7145H17.0466M14.2231 9.80907H17.0466H14.2231ZM14.2231 11.1266H17.0466H14.2231ZM14.2231 12.4442H17.0466H14.2231ZM14.2231 13.7618H17.0466H14.2231ZM14.2231 15.0794H17.0466H14.2231ZM14.2231 16.397H17.0466H14.2231Z"
                stroke="#86AFFF"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Comunidad;
