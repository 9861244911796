import React from 'react';
import './ProgressBar.scss';

interface IProgressBar {
    max: number;
    value: number;
}

const ProgressBar: React.FunctionComponent<IProgressBar> = ({ max, value }) => {
    return <progress className="progressbar" max={max} value={value} />;
};

export default ProgressBar;
